import { IconButton } from './IconButton';


interface DeleteButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  dataTooltipId?: string;
  dataTooltipHtml?: string;
  disabled?: boolean;
}

export function DeleteButton({
  onClick,
  dataTooltipId,
  dataTooltipHtml,
  disabled,
}: DeleteButtonProps) {
  return (
    <IconButton
      icon='bi-backspace' // bi-x-lg  bi-trash  bi-dash-circle
      onClick={onClick}
      dataTooltipId={dataTooltipId}
      dataTooltipHtml={dataTooltipHtml}
      disabled={disabled}
    />
  )
}
