import { useAnnouncements } from 'src/hooks/useAnnouncements';




export function MessageBar() {

  // persistent messages displayed just under the navbar

  const [announcement, upgradeMessage] = useAnnouncements()


  return (
    <>
      {announcement && announcement.text && (
        <div className='alert alert-warning p-3 d-flex align-items-center'>
          <i className='bi bi-info-circle-fill display-6 ms-2 me-4'></i>
          <div>
            {announcement.title && (
              <b>{announcement.title}: </b>
            )}
            <span>{announcement.text}</span>
          </div>
        </div>
      )}
      {upgradeMessage && (
        <div className='alert alert-danger p-3 d-flex align-items-center'>
          <i className='bi bi-exclamation-triangle-fill display-6 ms-2 me-4'></i>
          <div>
            <b>WARNING: </b>
            <span>{upgradeMessage}</span>
          </div>
        </div>
      )}
    </>
  )

}
