import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { Dispatch, SetStateAction } from 'react'
import { Form } from 'react-bootstrap'
import { ModalPopup } from 'src/components/Modal/ModalPopup'
import { useAppContext } from 'src/hooks/useAppContext'
import { DateInput } from 'src/pages/ExpenseSheet/DateInput'
import { EDPaymentSourceAccountDropdown } from 'src/pages/Invoices/EDPaymentSourceAccountDropdown'
import { dateFormatJpShort } from 'src/util/dateformattools'
import { jst0_from_local0, local0_from_jst0 } from 'src/util/datetools'
import { formatNum2 } from 'src/util/util_formatnum'
import './expensepaymentsmodal.css'


function getGridRowsDescribingPayment(modalAction: ModalActionType) {
  return (
    <>
      <div className='pt-2'>Payment type:</div>
      <div className='py-2'>
        <div className='form-check'>
          <input className='form-check-input' type='radio' name='radioPaymentType' id='radioPaymentType_CASH' value='CASH' checked={modalAction.paymentType === 'CASH'} disabled={true} />
          <label className='form-check-label' htmlFor='radioPaymentType_CASH'>
            Cash
          </label>
        </div>
        <div className='form-check'>
          <input className='form-check-input' type='radio' name='radioPaymentType' id='radioPaymentType_ADHOC_BANK_TRANSFER' value='ADHOC_BANK_TRANSFER' checked={modalAction.paymentType === 'ADHOC_BANK_TRANSFER'} disabled={true} />
          <label className='form-check-label' htmlFor='radioPaymentType_ADHOC_BANK_TRANSFER'>
            Adhoc bank transfer
          </label>
        </div>
        <div className='form-check'>
          <input className='form-check-input' type='radio' name='radioPaymentType' id='radioPaymentType_WITH_SALARY' value='WITH_SALARY' checked={modalAction.paymentType === 'WITH_SALARY'} disabled={true} />
          <label className='form-check-label' htmlFor='radioPaymentType_WITH_SALARY'>
            With salary
          </label>
        </div>
      </div>

      {(modalAction.paymentFor === 'GUIDING_EXPENSE' && modalAction.paymentDbProps.isAdvance) ? (
        <>
          <div className='pt-2'>Tour code:</div>
          <div className=''><Form.Control type='text' disabled={true} value={modalAction.paymentDbProps.tourCode} /></div>

          <div className='pt-2'>Desired payment date:</div>
          <div className='pt-2'>{dateFormatJpShort(modalAction.paymentDisplayProps.desiredDate)}</div>
        </>
      ) : null}

      {modalAction.paymentType === 'WITH_SALARY' ? (
        <>
          <div className='pt-2'>Salary month:</div>
          <div className=''><Form.Control type='text' disabled={true} value={modalAction.paymentDbProps.salaryMonth} /></div>
        </>
      ) : null}

    </>
  )
}


export type ModalActionPaymentDbPropsType = {
  // guiding expense
  isAdvance?: boolean,
  tourCode?: string,
  sheetId?: string,
  advancePaymentId?: string,
  // general expense
  generalExpenseId?: string,
  // common
  salaryMonth?: string,
}

export type ModalActionType = {
  action: string,
  showModal: boolean,
  paymentRecipient: {
    id: string,
    email: string,
  },
  paymentDbProps: ModalActionPaymentDbPropsType,
  paymentDisplayProps?: {
    desiredDate: Date, // JST
  },
  paymentId: string,
  paymentFor: string,
  paymentType: string,
  paymentSourceAccount: string,
  amount: number | string, // will be string if user input is not a valid number, e.g. ''
  paymentDate: Date, // JST
  callbackOnSuccess?: () => void,
}


interface ModalPopupMakeExpensePaymentProps {
  modalAction: ModalActionType,
  setModalAction: Dispatch<SetStateAction<ModalActionType>>,
}

export function ModalPopupMakeExpensePayment({ modalAction, setModalAction }: ModalPopupMakeExpensePaymentProps) {

  const { db, userDetails, setDbError } = useAppContext()
  const makePayment = modalAction?.action === 'make payment'

  return (
    <ModalPopup
      title={makePayment ? 'Mark Paid' : 'View payment'}
      okLabel='OK'
      hideOkButton={!makePayment}
      show={modalAction && modalAction.showModal}
      className='modal-lg'
      callbackClose={() => setModalAction({ ...modalAction, showModal: false })} // here we do not set modalAction to null, as we want to preserve the modal form appearence while it fades out
      onSubmit={(e, onSuccess) => {
        e.preventDefault()

        if (!modalAction)
          throw new Error('modalAction is null')

        if (modalAction.action === 'make payment') {

          const paymentSourceAccount = modalAction.paymentSourceAccount
          if (!paymentSourceAccount || paymentSourceAccount === 'Select payment source account') {
            alert('Select payment source account')
            return
          }

          const amount = modalAction.amount
          if (!(typeof amount === 'number')) {
            alert('input valid amount')
            return
          }

          const paymentDate = modalAction.paymentDate


          const paymentObj = {
            ...modalAction.paymentDbProps,
            userPaymentToUid: modalAction.paymentRecipient.id,
            userPaymentToEmail: modalAction.paymentRecipient.email,
            paymentSourceAccount, // SBI, SMBC, Rakuten, Cash, Other, etc.
            paymentFor: modalAction.paymentFor, // GUIDING_EXPENSE, GENERAL_EXPENSE
            paymentType: modalAction.paymentType, // CASH, ADHOC_BANK_TRANSFER, WITH_SALARY
            amount,
            paymentDate,

            // metadata
            _isDeleted: false,
            status: 'PAID', // can be 'DELETED'
            dateCreated: serverTimestamp(),
            dateModified: serverTimestamp(),
            userCreatorUid: userDetails.id,
            userCreatorEmail: userDetails.email,
            userCreatorName: userDetails.displayNameEn,
          }

          addDoc(collection(db, 'expensepayments'), paymentObj)
            .then((result) => {
              // success
              console.log('added to db', paymentObj, result)
              if (modalAction.callbackOnSuccess) modalAction.callbackOnSuccess()
              if (onSuccess) onSuccess()
            })
            .catch((err) => {
              console.log(err)
              alert(`Error saving payment: ${err}`)
            })


        } else {
          throw new Error(`unknown modal action ${modalAction.action}`)
        }

      }}
      body={modalAction && (

        <div className='modal-grid'>

          <div className='pt-2'>Payment to:</div>
          <div className=''><Form.Control type='text' disabled={true} value={modalAction.paymentRecipient.email} /></div>

          {getGridRowsDescribingPayment(modalAction)}

          <div style={{ gridColumn: 'span 2' }}><hr /></div>


          <div className='pt-2'>Payment source account:</div>
          <div className=''>
            <EDPaymentSourceAccountDropdown id='paymentSourceAccount' disabled={!makePayment} value={modalAction.paymentSourceAccount} onChange={(e) => {
              setModalAction((modalAction: ModalActionType) => ({ ...modalAction, paymentSourceAccount: e.target.value }))
            }} />
          </div>

          <div className='pt-2'>Amount:</div>
          <div className=''>
            {makePayment && (
              <div style={{ color: 'darkred' }}>
                Please input the actual amount that will be paid on <b>{dateFormatJpShort(modalAction.paymentDate)}</b>.<br />
                If nothing is paid this month, please input <b>0</b> and click OK to set the payment amount to zero.<br />
                Any unpaid balance will automatically be carried over to the next month.
              </div>
            )}
            <Form.Control type='text' id='amount' disabled={!makePayment} value={formatNum2(modalAction.amount)} onChange={(e) => {
              console.log('change')
              const newValue = e.target.value
              const num = Number(newValue.replaceAll(',', ''))
              const newValueDb = (newValue !== '' && !isNaN(num)) ? num : newValue

              setModalAction((modalAction: ModalActionType) => ({ ...modalAction, amount: newValueDb }))
            }} />
          </div>

          <div className='pt-2'>Payment date:</div>
          <div className=''><DateInput disabled={!makePayment} value_local0={local0_from_jst0(modalAction.paymentDate)} onChange={(date_local0: Date) => {
            // TODO: payment date should be stored as iso string
            setModalAction((modalAction: ModalActionType) => ({ ...modalAction, paymentDate: jst0_from_local0(date_local0) }))
          }} /></div>

          {modalAction.action === 'show payment' && (
            <>
              <div className=''>Payment ID:</div>
              <div className=''>{modalAction.paymentId}</div>
            </>
          )}

        </div>

      )} />


  )
}
