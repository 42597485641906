import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useAppContext } from 'src/hooks/useAppContext';

export function SignInButton() {

  const { auth } = useAppContext()

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();

    // allow user to switch between Google accounts
    provider.setCustomParameters({
      prompt: 'select_account'
    });

    signInWithPopup(auth, provider)
      .then((cred) => {
        // retrieve userDetails from 'users' collection here?
        // this is now done in App.js instead
      })

  }

  return (
    <div>
      <button type='button' className='btn btn-outline-warning  me-2' onClick={() => signInWithGoogle()}>Sign in</button>
    </div>
  )
}

