import { Firestore, addDoc, collection } from 'firebase/firestore'
import { renderToStaticMarkup } from 'react-dom/server'
import { ExpenseSheetType } from 'src/types/types_expensesheet'
import { UserDetailsType } from 'src/types/types_user'
import { dateFormatJpWithTime } from 'src/util/dateformattools'
import { serverTimestampAsDate } from 'src/util/util_firestoredates'



export function send_expense_sheet_email(
  comment: string,
  sheet: ExpenseSheetType,
  userDetails: UserDetailsType,
  db: Firestore,
) {

  const url = `${import.meta.env.VITE_SITE_URL}${location.pathname}`
  const date = dateFormatJpWithTime(new Date())
  const subject = `Expense sheet "${sheet.tourCode}" updated by ${userDetails.displayNameEn}`
  const bodyText = `Expense sheet "${sheet.tourCode}" updated by ${userDetails.displayNameEn}.

${comment ? `Comment:\n${comment}` : ''}

${url}

ID: ${sheet.id}

${date}`

  const bodyHtmlJsx =
    <html>
      <body>
        <p>Expense sheet {sheet.tourCode} updated by {userDetails.displayNameEn}.</p>

        {comment && (
          <p>Comment:<br />{comment}</p>
        )}

        <p><a href={url}>Open in web browser</a></p>

        <p>ID: {sheet.id}</p>

        <p>{date}</p>
      </body>
    </html>

  const bodyHtml = renderToStaticMarkup(bodyHtmlJsx)

  addDoc(collection(db, 'emailnotifications'), {
    recipient: [...sheet.usersDesigners.map((user) => user.email), sheet.userGuideEmail],
    subject,
    bodyText,
    bodyHtml,

    dateCreated: serverTimestampAsDate(),
    userCreatedUid: userDetails.id,
    userCreatedEmail: userDetails.email,
    userCreatedName: userDetails.displayNameEn,
  })
    .then(() => {
      console.log('Email sent successfully')
    })

}
