import { QuerySnapshot, collection, onSnapshot, query, where } from 'firebase/firestore'
import { useEffect, useMemo, useState } from 'react'
import { isTestDb } from 'src/config/config-select'
import { useAppContext } from 'src/hooks/useAppContext'
import { PayeeType } from 'src/types/types_payee'
import { UserSimpleTeamType } from 'src/types/types_user'
import { userrole_canAddInvoice } from 'src/util/user_roles'



export const calendarGuideTeamList = ['Eighty Days Tour Leaders', 'Eighty Days Other', 'Freelance Guides'] as const
export type CalendarGuideTeamType = typeof calendarGuideTeamList[number]

export type UserSimpleCalendarGuideType = {
  id: string,
  email: string,
  name: string,
  teamName: CalendarGuideTeamType,
}


type FreelanceGuideType = {
  // id, email, name, teamName are required for TypeaheadUserList
  id: string,
  email: string,
  name: string,
  teamName: 'Freelance Guides',
  payeeId: string,
}

/**
 * @returns List of guides (not sorted), including:
 *     - 80days guides
 *     - 80days other employees, inc. office employees
 *     - Freelance guides
 */
export function useGuideList(userListSimple: UserSimpleTeamType[] | undefined): UserSimpleCalendarGuideType[] | undefined {

  const { db, setDbError, userDetails } = useAppContext()

  const isTravelDesigner = userrole_canAddInvoice(userDetails.roles)


  const [freelanceGuides, setFreelanceGuides] = useState<FreelanceGuideType[]>()
  useEffect(() => {
    if (!isTravelDesigner) {
      setFreelanceGuides([])
      return
    }

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const list: FreelanceGuideType[] = []
      snapshot.docs.forEach((doc) => {
        const payee = { ...doc.data(), id: doc.id } as PayeeType
        const freelanceGuide: FreelanceGuideType = {
          id: `payee:${doc.id}`,
          email: `payee:${doc.id}`,
          name: payee.nameEnMain,
          teamName: 'Freelance Guides',
          payeeId: doc.id,
        }
        list.push(freelanceGuide)
      })
      setFreelanceGuides(list)
    }

    const q = query(collection(db, 'payees'), where('_isDeleted', '==', false), where('isFreelanceGuide', '==', true))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting freelance guides list', err));

    return unsubscribe

  }, [db, setDbError, isTravelDesigner])


  const guideList = useMemo<UserSimpleCalendarGuideType[] | undefined>(() => {

    if (!isTravelDesigner) {
      const userGuide: UserSimpleCalendarGuideType = {
        id: userDetails.id,
        email: userDetails.email,
        name: userDetails.displayNameEn,
        teamName: 'Eighty Days Tour Leaders',
      }
      return [userGuide]
    }


    if (!userListSimple || !freelanceGuides)
      // still loading
      return undefined


    const userListGuides = userListSimple
      .filter((user) =>
        user.teamName
        && user.teamName !== 'Former employees'
        && user.teamName !== 'External accountant'
        && (isTestDb() || user.teamName !== 'Other'))
      .map((user) => {
        const userGuide: UserSimpleCalendarGuideType = {
          ...user,
          teamName: user.teamName === 'Guide' ? 'Eighty Days Tour Leaders' : 'Eighty Days Other',
        }
        return userGuide
      })

    const guideList = [...userListGuides, ...freelanceGuides]

    return guideList
  }, [freelanceGuides, userListSimple, isTravelDesigner, userDetails])

  return guideList
}
