import { collection, getCountFromServer, limit, onSnapshot, orderBy, query, QueryConstraint, QuerySnapshot, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { ButtonTW } from 'src/components/Buttons/ButtonTW'
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch'
import { TypeaheadUserList } from 'src/components/FormControls/TypeaheadUserList'
import { LoadingSpinner } from 'src/components/Spinner/LoadingSpinner'
import { useAppContext } from 'src/hooks/useAppContext'
import { logkeys, LogMessageType } from 'src/types/objectTypes'
import { UserSimpleType, UserSimpleUidType } from 'src/types/types_user'
import { dateFormatJpWithTime, dateFormatJpWithTimeAndSeconds } from 'src/util/dateformattools'
import { userrole_isAdmin } from 'src/util/user_roles'
import { convertLogMessageDates } from 'src/util/util_firestoredates'
import { formatNum } from 'src/util/util_formatnum'
import { useUserListSimple } from '../ExpenseSheet/util_getuserlist'



export function ViewLogs() {

  const { db, setDbError, userDetails } = useAppContext()

  const [showAll, setShowAll] = useState(false)
  const [showErrorsOnly, setShowErrorsOnly] = useState(false)
  const [excludeMine, setExcludeMine] = useState(true)
  const [showLogkeyTotalCounts, setShowLogkeyTotalCounts] = useState(false)


  const userListSimple = useUserListSimple(true)
  const [selectedEmployee, setSelectedEmployee] = useState<UserSimpleType>(null) // filter in query
  const [filterUid, setFilterUid] = useState<string | null>(null) // filter on client

  const [selectedLogKeys, setSelectedLogKeys] = useState<string[]>()


  if (!userrole_isAdmin(userDetails.roles))
    throw new Error('Unauthorized')


  const [logmessages, setLogmessages] = useState<LogMessageType[]>()
  const [usersInData, setUsersInData] = useState<Map<string, UserSimpleUidType>>()
  const [logkeyCounts, setLogkeyCounts] = useState<Map<string, number>>()
  useEffect(() => {
    const processSnapshot = function (snapshot: QuerySnapshot) {
      console.log('got logmessages', snapshot.docs.length)
      const list: LogMessageType[] = []
      const usersInData = new Map<string, UserSimpleUidType>()
      const logkeyCounts = new Map<string, number>()
      snapshot.docs.forEach((doc) => {
        const logmessage = { ...doc.data(), id: doc.id } as LogMessageType
        convertLogMessageDates(logmessage)

        const uid = logmessage.user?.uid ?? ''
        if (!usersInData.has(uid))
          usersInData.set(uid, logmessage.user)
        list.push(logmessage)
        logkeyCounts.set(logmessage.logkey, (logkeyCounts.get(logmessage.logkey) || 0) + 1)
      })
      setLogmessages(list)
      setUsersInData(usersInData)
      setLogkeyCounts(logkeyCounts)
    }

    const constraints: QueryConstraint[] = [orderBy('dateCreated', 'desc')]
    constraints.push(limit(showAll ? 1000 : 100))
    if (selectedEmployee)
      constraints.push(where('user.uid', '==', selectedEmployee.id))
    if (showErrorsOnly)
      constraints.push(where('level', '==', 'error'))
    if (selectedLogKeys && selectedLogKeys.length === 1)
      constraints.push(where('logkey', '==', selectedLogKeys[0]))
    const q = query(collection(db, 'logmessages'), ...constraints)
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting log messages', err));

    return unsubscribe
  }, [db, setDbError, showAll, selectedEmployee, showErrorsOnly, selectedLogKeys])



  const [totalRows, setTotalRows] = useState<number>()
  useEffect(() => {
    const q = query(collection(db, 'logmessages'))
    getCountFromServer(q)
      .then((snapshot) => setTotalRows(snapshot.data().count))
      .catch((err) => setDbError('Get logmessages count', err))
  }, [db, setDbError])


  const [logkeyTotals, setLogkeyTotals] = useState(new Map<string, number>())
  useEffect(() => {
    if (!showLogkeyTotalCounts) {
      setLogkeyTotals(new Map())
      return
    }

    const promises = []
    for (const logkey of logkeys) {
      const q = query(collection(db, 'logmessages'), where('logkey', '==', logkey))
      promises.push(getCountFromServer(q))
    }
    Promise.all(promises)
      .then((snapshots) => {
        const logkeyTotals = new Map<string, number>()
        for (let i = 0; i < logkeys.length; i++) {
          const snapshot = snapshots[i]
          const count = snapshot.data().count
          logkeyTotals.set(logkeys[i], count)
        }
        setLogkeyTotals(logkeyTotals)
      })
  }, [db, setDbError, showLogkeyTotalCounts])





  // *** hooks above

  if (!logmessages || !userListSimple)
    return <LoadingSpinner />

  const oldestTime = logmessages.at(-1).dateCreated
  const elapsedHours = (new Date().getTime() - oldestTime.getTime()) / (1000 * 60 * 60)


  return (
    <div className='container-fluid'>
      <Helmet><title>View Logs</title></Helmet>

      <div className='tw-flex tw-gap-4 tw-pt-4'>
        <div className='_leftCol tw-border tw-border-solid tw-border-slate-400 tw-p-4'>
          <h5>
            Log Keys
            {' '}
            <span className='tw-cursor-pointer tw-underline tw-text-sm tw-text-blue-700'
              onClick={() => setSelectedLogKeys(null)}>
              (Clear filter)
            </span>
          </h5>
          <CheckboxSwitch id='showLogkeyTotalCounts' checked={showLogkeyTotalCounts} onChange={(e) => setShowLogkeyTotalCounts(e.target.checked)} label='Show logkey total counts' />
          <ul>
            {logkeys.map((key) => {
              const count = logkeyCounts.get(key)
              const totalCount = logkeyTotals.get(key)
              const selected = selectedLogKeys?.includes(key)
              return (
                <li
                  key={key}
                  className={`tw-whitespace-nowrap ${count ? '' : 'tw-text-slate-400'} tw-cursor-pointer ${selected ? 'tw-font-bold' : ''}`}
                  onClick={() => {
                    setSelectedLogKeys([key])
                  }}>
                  {totalCount && <span className='tw-text-blue-500'>[{totalCount}]</span>}
                  {count && <b className='tw-text-red-600'>({count})</b>} {key}
                </li>
              )
            })}
          </ul>
        </div>
        <div className='_rightCol'>
          <div>{formatNum(totalRows)} total rows</div>

          <h5 className='my-3'>Query level filtering:</h5>

          <CheckboxSwitch id='showAllLogs' checked={showAll} onChange={(e) => setShowAll(e.target.checked)} label='Show all (1000)' />
          <CheckboxSwitch id='showErrorsOnly' checked={showErrorsOnly} onChange={(e) => setShowErrorsOnly(e.target.checked)} label='Show errors only' />


          <div style={{ width: '30em' }}>
            <TypeaheadUserList
              id='select-employee'
              multiple={false}
              onChange={(array) => {
                setSelectedEmployee(array.length ? array[0] : null)
              }}
              userList={userListSimple}
              selected={selectedEmployee ? [selectedEmployee] : []}
              disabled={false}
              labelKey='email'
            />
          </div>

          <div>
            Showing logs since {dateFormatJpWithTime(logmessages.at(-1).dateCreated)} ({elapsedHours.toFixed(1)} hours)
          </div>

          <h5 className='mt-4 mb-3'>Client side filtering</h5>

          <CheckboxSwitch id='chkExcludeMine' checked={excludeMine} onChange={(e) => setExcludeMine(e.target.checked)} label='Exclude mine' />
          <div className='my-3'>
            Users in current data set: (<ButtonTW variant='link' className='p-0' onClick={() => setFilterUid(null)}>clear filter</ButtonTW>)
            <ul>
              {[...usersInData.entries()].map(([uid, user]) => {
                const firstLog = logmessages.find((msg) => msg.user?.uid === uid)
                return (
                  <li key={uid}>
                    <ButtonTW variant='link' className='p-0' onClick={() => {
                      setFilterUid(uid)
                    }}>
                      {user?.name} {firstLog.dateCreated && `(${dateFormatJpWithTime(firstLog.dateCreated)})`}
                    </ButtonTW>
                  </li>
                )
              })}
            </ul>
          </div>



          <h2>Log messages</h2>
          <div>{logmessages.length} rows</div>
          <table className='table'>
            <thead>
              <tr>
                <th>Date</th>
                <th>User</th>
                <th>Level</th>
                <th>Log key</th>
                <th>Desc</th>
                <th>Error</th>
              </tr>
            </thead>
            <tbody>
              {logmessages.filter((msg) => {
                if (filterUid !== null && msg.user?.uid !== filterUid)
                  return false
                if (excludeMine && msg.user?.uid === userDetails.id)
                  return false
                if (selectedLogKeys && !selectedLogKeys.includes(msg.logkey))
                  return false

                return true
              }).map((logmessage) => {

                return (
                  <tr key={logmessage.id}>
                    <td>{logmessage.dateCreated && dateFormatJpWithTimeAndSeconds(logmessage.dateCreated)}</td>
                    <td>
                      <div>{logmessage.user.email}</div>
                      {logmessage.userAs && (
                        <div className='tw-text-blue-500'>As: {logmessage.userAs.email}</div>
                      )}
                    </td>
                    <td>{logmessage.level}</td>
                    <td>{logmessage.logkey}</td>
                    <td>{logmessage.desc}</td>
                    <td>
                      {logmessage.error && (
                        <ul>
                          <li>{logmessage.error.name}</li>
                          <li>{logmessage.error.errorCode}</li>
                          <li>{logmessage.error.errorMessage}</li>
                        </ul>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

        </div>
      </div>

    </div>
  )
}
