import stringify from 'json-stable-stringify';
import { MouseEvent, useEffect, useState } from 'react';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { EditableField } from 'src/components/EditableField/EditableField';
import { TypeaheadUserList } from 'src/components/FormControls/TypeaheadUserList';
import { useAppContext } from 'src/hooks/useAppContext';
import { TourRequestTypeWithDates } from 'src/types/types_tourrequest';
import { UserSimpleHoursType } from 'src/types/types_user';
import { addDays, getSpanDaysExact, iso_from_utc0, utc0_from_iso } from 'src/util/datetools';
import { HoursDropdown } from '../Components/HoursDropdown';
import { EditWindowParamsType } from '../PageTourCalendar';
import { UserSimpleCalendarGuideType, calendarGuideTeamList } from '../useGuideList';
import { getDayListLabel, parseDayListLabel } from '../util_daylistparsing';
import { updateTourRequestCalendar } from '../util_db_calendar';



interface TourEditWindowProps {
  shownEditWindow: EditWindowParamsType | null;
  setShownEditWindow: (value: EditWindowParamsType | null) => void;
  tourrequest: TourRequestTypeWithDates | null;
  listAllGuides: UserSimpleCalendarGuideType[];
  dateutcCalendarStart: Date;
}

export function TourEditWindow({
  shownEditWindow,
  setShownEditWindow,
  tourrequest,
  listAllGuides,
  dateutcCalendarStart,
}: TourEditWindowProps) {

  const { db, setDbError, userDetails } = useAppContext()

  const [editedCell, setEditedCell] = useState<string | null>(null)

  const [typeaheadSelectedGuide, setTypeaheadSelectedGuide] = useState<UserSimpleCalendarGuideType[]>([])
  const [guideHoursInput, setGuideHoursInput] = useState(8)
  const [guideAmPmInput, setGuideAmPmInput] = useState<'am_only' | 'pm_only' | ''>('')

  useEffect(() => {
    // reset to 8hr every time tour changes
    setGuideHoursInput(8)
    setGuideAmPmInput('')
    setTypeaheadSelectedGuide([])
  }, [tourrequest])


  // *** hooks above ***


  if (!shownEditWindow || !tourrequest)
    // nothing to display
    return null

  if (!listAllGuides)
    // still loading
    return null


  const toDateisoSorted = (dayIndexes: number[]) => {
    return [...dayIndexes].sort((a, b) => a - b).map((dayIndex) => {
      const dateutc = addDays(dateutcCalendarStart, dayIndex)
      return iso_from_utc0(dateutc)
    })
  }

  const selectedDateisosSorted = toDateisoSorted(shownEditWindow.selectedDayIndexesU)


  // determine guide list for current selection
  let selectedGuides: UserSimpleHoursType[] = []
  let mismatchGuides = false
  if (selectedDateisosSorted.length > 0) {
    const dateiso0 = selectedDateisosSorted[0]
    selectedGuides = tourrequest.calendarDays?.[dateiso0]?.guides || []
    for (const dateiso of selectedDateisosSorted) {
      const guides = tourrequest.calendarDays?.[dateiso]?.guides || []
      if (stringify(selectedGuides) !== stringify(guides)) {
        mismatchGuides = true
        selectedGuides = []
        break
      }
    }
  }

  let selectedCellsAdditionalText = ''
  let mismatchAdditionalText = false
  if (selectedDateisosSorted.length > 0) {
    const dateiso0 = selectedDateisosSorted[0]
    selectedCellsAdditionalText = tourrequest.calendarDays?.[dateiso0]?.additionalText || ''
    for (const dateiso of selectedDateisosSorted) {
      const additionalText = tourrequest.calendarDays?.[dateiso]?.additionalText || ''
      if (selectedCellsAdditionalText !== additionalText) {
        mismatchAdditionalText = true
        selectedCellsAdditionalText = ''
        break
      }
    }
  }

  const dateutcTourStart = utc0_from_iso(tourrequest.dateisoTourStart)
  const dateutcTourEnd = utc0_from_iso(tourrequest.dateisoTourEnd)
  const numDaysInTour = getSpanDaysExact(dateutcTourStart, dateutcTourEnd) + 1
  const dayOneIndex = getSpanDaysExact(dateutcCalendarStart, dateutcTourStart)

  const saveGuideToCalendar = (selectedGuideArray: UserSimpleCalendarGuideType[]) => {
    const addedUserBase = selectedGuideArray[0]
    if (!addedUserBase)
      return
    const addedUser: UserSimpleHoursType = {
      id: addedUserBase.id,
      email: addedUserBase.email,
      name: addedUserBase.name,
      hours: guideHoursInput,
      am_pm_only: guideAmPmInput,
    }
    const updateObj: Record<string, any> = {}
    for (const dateiso of selectedDateisosSorted) {
      const existingArray: UserSimpleHoursType[] = tourrequest.calendarDays?.[dateiso]?.guides ?? []
      const newArray = [...existingArray.filter((u) => u.id !== addedUser.id), addedUser]
      updateObj[`calendarDays.${dateiso}.guides`] = newArray
    }

    updateTourRequestCalendar(db, userDetails, tourrequest, updateObj,
      `Set Tour Leader to [${addedUser.name}] with [${guideHoursInput}hrs] on days ${selectedDateisosSorted.map((dateiso) => `[${dateiso}]`).join('')}`,
      `tourrequestId=${shownEditWindow.tourrequestId} requestCode=${tourrequest.requestCode} guideId=[${addedUser.id}][${addedUser.name}]`,
      'tourcalendar.toureditwindow.set_guide'
    )
  }

  return (
    <div className='windowEditTour' style={{
      left: shownEditWindow.cssLeft,
      top: shownEditWindow.cssTop,
      width: '37em',
      zIndex: 10, // to be above the hovered day highlight column
    }}
      onMouseDown={(e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation() // <- stop propagation to prevent the onMouseDown on calendarGrid from being triggered and closing the window
        // e.preventDefault() // <- preventDefault will prevent user from selecting any text
      }}
      onDoubleClick={(e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation() // <- stop propagation to prevent the onMouseDown on calendarGrid from being triggered and closing the window
      }}
    >

      {shownEditWindow.selectedDayIndexesU.length === 0 ? (
        <div>No days selected</div>
      ) : (
        <>
          <h5 className='mb-2'>
            Selected:{' '}
            {shownEditWindow.selectedDayIndexesU.length === 1 ? 'Day' : 'Days'}{' '}
            <EditableField
              tableid='calendargrid'
              rowid={tourrequest.id}
              fieldname='selectedDays'
              currentValue={getDayListLabel(shownEditWindow.selectedDayIndexesU, dayOneIndex, numDaysInTour)}
              validationType=''
              isClickableToEdit={true}
              editedCell={editedCell}
              setEditedCell={setEditedCell}
              useSpan={true}
              customValidator={(value: string) => {

                const dayArray = parseDayListLabel(value, numDaysInTour)
                if (dayArray === null) {
                  // invalid string
                  return [false, undefined, value]
                }
                return [true, dayArray, value]

              }}
              callbackCommitChange={(arrayDayNum: number[]) => {

                console.log('arrayDayNum', arrayDayNum)
                const arrayIndexes = arrayDayNum.map((dayNum) => dayOneIndex + dayNum - 1)

                setShownEditWindow({
                  ...shownEditWindow,
                  selectedDayIndexesU: arrayIndexes,
                })

                setEditedCell(null)
              }}
            />
          </h5>
          {mismatchGuides ? (
            <div className='mismatchWarning'>
              <i className='bi bi-exclamation-triangle'></i>
              {' '}
              Current selection has different Tour Leaders on different days.
            </div>
          ) : selectedGuides.length > 0 ? (
            <table className='tourDaysList'>
              <thead className='[&>tr>th]:tw-bg-slate-200'>
                <tr>
                  <th>Tour Leader</th>
                  <th>Hours</th>
                  <th>AM/PM</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {selectedGuides.map((guide) => {
                  return (
                    <tr key={guide.id}>
                      <td>{guide.name}</td>
                      <td>
                        {true ? (guide.hours || '') : (
                          <EditableField
                            tableid='tourEditWindowTable'
                            rowid={guide.id}
                            fieldname='guidingHours'
                            validationType=''
                            currentValue={guide.hours || ''}
                            isClickableToEdit={true}
                            editedCell={editedCell}
                            setEditedCell={setEditedCell}
                            callbackCommitChange={(value) => {
                              // validate value: integer 1-12
                              if (!value)
                                return
                              if (!value.match(/^\d{1,2}$/))
                                return
                              const numHours = Number(value)
                              if (numHours < 1 || numHours > 12)
                                return

                              const newArray = selectedGuides.map((g) => {
                                if (g.id === guide.id)
                                  return { ...g, hours: numHours }
                                return g
                              })
                              const updateObj: Record<string, any> = {}
                              for (const dateiso of selectedDateisosSorted) {
                                updateObj[`calendarDays.${dateiso}.guides`] = newArray
                              }

                              updateTourRequestCalendar(db, userDetails, tourrequest, updateObj,
                                `Change hours for Tour Leader [${guide.name}] from [${guide.hours}] to [${value}] on days ${selectedDateisosSorted.map((dateiso) => `[${dateiso}]`).join('')}`,
                                `tourrequestId=${shownEditWindow.tourrequestId} requestCode=${tourrequest.requestCode} guideId=${guide.id}`,
                                'tourcalendar.toureditwindow.change_guide_hours'
                              )
                              setEditedCell(null)
                            }}
                          />
                        )}
                      </td>
                      <td>
                        {guide.am_pm_only === 'am_only' ? 'AM only' : guide.am_pm_only === 'pm_only' ? 'PM only' : ''}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <button className='tw-border-none tw-bg-transparent tw-0 tw-0'
                          title='Edit'
                          onClick={() => {
                            const guideObj = listAllGuides.find((g) => g.id === guide.id)
                            setTypeaheadSelectedGuide([guideObj])
                            setGuideHoursInput(guide.hours || 8)
                            setGuideAmPmInput(guide.am_pm_only || '')
                          }}>
                          <i className='bi bi-pencil-square'></i>
                        </button>
                        <button className='tw-border-none tw-bg-transparent tw-0 tw-0'
                          title='Delete'
                          onClick={() => {
                            const newArray = selectedGuides.filter((g) => g.id !== guide.id)
                            const updateObj: Record<string, any> = {}
                            for (const dateiso of selectedDateisosSorted) {
                              updateObj[`calendarDays.${dateiso}.guides`] = newArray
                              // maybe later: if newArray is empty, remove the guides field with deleteField;
                              // if additionalText is also empty or missing, remove entire dateiso entry.
                            }

                            updateTourRequestCalendar(db, userDetails, tourrequest, updateObj,
                              `Delete Tour Leader [${guide.name}] on days ${selectedDateisosSorted.map((dateiso) => `[${dateiso}]`).join('')}`,
                              `tourrequestId=${shownEditWindow.tourrequestId} requestCode=${tourrequest.requestCode} guideId=${guide.id}`,
                              'tourcalendar.toureditwindow.delete_guide'
                            )
                          }}>
                          <i className='bi bi-x-square'></i>
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : null}
          <div>

          </div>
          <div>
            Tour Leader:

            <ButtonTW variant='link' style={{ fontSize: 'inherit' }} onClick={() => {
              const updateObj: Record<string, any> = {}
              for (const dateiso of selectedDateisosSorted) {
                updateObj[`calendarDays.${dateiso}.guides`] = []
              }

              updateTourRequestCalendar(db, userDetails, tourrequest, updateObj,
                `Clear all Tour Leaders on days ${selectedDateisosSorted.map((dateiso) => `[${dateiso}]`).join('')}`,
                `tourrequestId=${shownEditWindow.tourrequestId} requestCode=${tourrequest.requestCode}`,
                'tourcalendar.toureditwindow.clear_all_guides'
              )
            }}>(Clear Tour Leaders)</ButtonTW>

            <div style={{
              display: 'flex',
              gap: '0.5em'
            }}>
              <div>
                <HoursDropdown hours={guideHoursInput} setHours={setGuideHoursInput} className='' />
              </div>

              <div className='tw-flex tw-flex-col tw-gap-0.5'>
                {['AM only', 'PM only'].map((label) => {
                  const code = label === 'AM only' ? 'am_only' : 'pm_only'
                  return <>
                    <button
                      className={`tw-rounded tw-text-xs tw-border tw-border-solid tw-border-slate-200 tw-outline-none ${guideAmPmInput === code ? 'tw-bg-slate-700 tw-text-white ' : 'tw-bg-white tw-text-black'}`}
                      onClick={() => {
                        const newCode = guideAmPmInput === code ? '' : code
                        setGuideAmPmInput(newCode)
                        if (newCode !== '') {
                          if (guideHoursInput > 4) {
                            setGuideHoursInput(4)
                          }
                        } else {
                          if (guideHoursInput === 4) {
                            setGuideHoursInput(8)
                          }
                        }
                      }}
                    >
                      {label}
                    </button>
                  </>
                })}
              </div>

              <div style={{
                width: '17em'
              }}>
                <TypeaheadUserList
                  id='typeaheadUserList'
                  multiple={true}
                  userList={listAllGuides}
                  guidesFirst={true}
                  selected={typeaheadSelectedGuide}
                  onChange={(selectedGuideArray: UserSimpleCalendarGuideType[]) => {
                    saveGuideToCalendar(selectedGuideArray)
                    setTypeaheadSelectedGuide([])
                  }}
                  customPlaceholder={'Select Tour Leader'}
                  teamList={[...calendarGuideTeamList]}
                />
              </div>

              <div>
                {typeaheadSelectedGuide.length > 0 && (
                  <ButtonTW
                    variant='blue_outline'
                    onClick={() => {
                      saveGuideToCalendar(typeaheadSelectedGuide)
                    }}>
                    Set
                  </ButtonTW>
                )}
              </div>
            </div>
          </div>

          <div className='mt-2'>
            Memo:
          </div>

          <div style={{ fontSize: 'inherit' }} className='form-control'>
            {/* <Form.Control
          type='text'
          value={selectedCellsAdditionalText}
          style={{ fontSize: 'inherit' }}
          onChange={(e) => {

          }} /> */}

            <EditableField
              tableid='calendargrid'
              rowid={tourrequest.id}
              fieldname='additionalText'
              currentValue={selectedCellsAdditionalText}
              validationType=''
              isClickableToEdit={true}
              editedCell={editedCell}
              setEditedCell={setEditedCell}
              callbackCommitChange={(newValue) => {
                const updateObj: Record<string, any> = {}
                const dateisoList: string[] = []
                for (const dateiso of selectedDateisosSorted) {
                  updateObj[`calendarDays.${dateiso}.additionalText`] = newValue
                  dateisoList.push(dateiso)
                }

                updateTourRequestCalendar(db, userDetails, tourrequest, updateObj,
                  `Set memo to [${newValue}] on days ${dateisoList.map((dateiso) => `[${dateiso}]`).join('')}`,
                  `tourrequestId=${shownEditWindow.tourrequestId} requestCode=${tourrequest.requestCode}`,
                  'tourcalendar.toureditwindow.set_memo'
                )
                setEditedCell(null)
              }}
            />
          </div>
          {mismatchAdditionalText && (
            <div className='mismatchWarning'>
              <i className='bi bi-exclamation-triangle'></i>
              {' '}
              Current selection has different additional text on different days.
            </div>
          )}
        </>
      )}
    </div>
  )
}
