

// PROD / DEV
import { firebaseConfigDev } from './config-dev';
import { firebaseConfigProd } from './config-prod';

// use PROD database when developing and testing locally?
const TEST_IN_PROD = 0

// force DEV when deploying to the Firebase dev project
//const DEPLOY_DEV = import.meta.env.FIREBASE_CONFIG === 'TEST'

export function getFireBaseConfig() {
  //console.log(process.env)
  // if (import.meta.env.DEV && !TEST_IN_PROD) {
  //   // thankfully, if we are in PROD and this line is never hit, the compiler will not include the Dev config in the compiled code
  //   // (we do not want to expose the Dev config on the production website)
  //   return firebaseConfigDev
  // }

  if (import.meta.env.VITE_FIREBASE_CONFIG === 'FIREBASE_CONFIG_TEST') {
    return firebaseConfigDev
  }

  return firebaseConfigProd
}

export function isTestDb() {
  // if (import.meta.env.DEV && !TEST_IN_PROD) {
  //   return true
  // }

  if (import.meta.env.VITE_FIREBASE_CONFIG === 'FIREBASE_CONFIG_TEST') {
    return true
  }

  return false
}
