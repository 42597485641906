import { EnumLineItemType, QuotationLineItemsByTypeType, QuotationLineItemTypeAccommodation, QuotationLineItemTypeGuide, QuotationLineItemTypeOther, QuotationLineItemTypeTicket, QuotationLineItemTypeTransportation } from 'src/types/types_quotation'



export function addBlankItems(
  lineItemsByType: QuotationLineItemsByTypeType,
  dayNum: number,
  dateiso: string,
  NEW_ROW_ID: string,
  itemType: EnumLineItemType,
) {

  if (itemType === 'accommodation') {
    const blankAccommodation: QuotationLineItemTypeAccommodation = {
      id: NEW_ROW_ID,
      dayNum,
      date: dateiso,
      index: lineItemsByType['accommodation'].length,
      itemType: 'accommodation',
      name: '',
      room_type: '',
      room_size: '',
      room_otherFeatures: '',
      room_numberOfPaxPerRoom: null,
      room_pricePerPerson: null,
      totalPrice: null,
      memorandum: '',
    }

    lineItemsByType['accommodation'].push(blankAccommodation)
  }

  if (itemType === 'transportation') {
    const blankTransportation: QuotationLineItemTypeTransportation = {
      id: NEW_ROW_ID,
      dayNum,
      date: dateiso,
      index: lineItemsByType['transportation'].length,
      itemType: 'transportation',
      name: '',
      transportation_from: '',
      transportation_to: '',
      transportation_carType: '',
      transportation_unitPrice: null,
      transportation_unitCount: null,
      totalPrice: null,
      memorandum: '',
    }

    lineItemsByType['transportation'].push(blankTransportation)
  }

  if (itemType === 'tickets') {
    const blankTicket: QuotationLineItemTypeTicket = {
      id: NEW_ROW_ID,
      dayNum,
      date: dateiso,
      index: lineItemsByType['tickets'].length,
      itemType: 'tickets',
      name: '',
      ticket_numberOfAdults: null,
      ticket_pricePerAdult: null,
      ticket_numberOfChildren: null,
      ticket_pricePerChild: null,
      totalPrice: null,
      memorandum: '',
    }

    lineItemsByType['tickets'].push(blankTicket)
  }

  if (itemType === 'guide') {
    const blankGuide: QuotationLineItemTypeGuide = {
      id: NEW_ROW_ID,
      dayNum,
      date: dateiso,
      index: lineItemsByType['guide'].length,
      itemType: 'guide',
      name: '',
      guide_accommodation: null,
      guide_transportation: null,
      guide_meals: null,
      guide_numberOfHours: null,
      guide_pricePerHour: null,
      totalPrice: null,
      memorandum: '',
    }

    lineItemsByType['guide'].push(blankGuide)
  }

  if (itemType === 'other') {
    const blankOther: QuotationLineItemTypeOther = {
      id: NEW_ROW_ID,
      dayNum,
      date: dateiso,
      index: lineItemsByType['other'].length,
      itemType: 'other',
      name: '',
      totalPrice: null,
      memorandum: '',
    }

    lineItemsByType['other'].push(blankOther)
  }
}
