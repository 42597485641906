import { FirebaseStorage, getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { FileStoragePathAndDownloadUrlType } from 'src/types/types_invoices'
import { dateFormatCompact } from 'src/util/dateformattools'


type FileAndStoragePathType = {
  file: File,
  storagePath: string,
}

export async function uploadFilesToFirebase(storage: FirebaseStorage, storageFolder: string, filelist: FileList | File[]): Promise<FileStoragePathAndDownloadUrlType[]> {
  // filelist parameter can be either of the below:
  //  - a FileList object, from input.files property (NOT an array, see https://stackoverflow.com/a/25333553/ )
  //  - an array of File objects
  // so we first convert it to an array 
  const files = Array.from(filelist)

  const now = new Date()
  const fileNamePrefix = `${dateFormatCompact(now)} `

  const filesAndStoragePaths = files.map((file) => {
    const storagePath = `${storageFolder}/${fileNamePrefix}${file.name}`
    return { file, storagePath }
  })

  const uploadBytesAndGetUrl = async ({ file, storagePath }: FileAndStoragePathType) => {
    const snapshot = await uploadBytes(ref(storage, storagePath), file)
    console.log('Uploaded file', storagePath)
    const downloadURL = await getDownloadURL(snapshot.ref)
    return {
      storagePath,
      downloadURL,
      metadata: snapshot.metadata,
    }
  }

  const promises = filesAndStoragePaths.map(({ file, storagePath }: FileAndStoragePathType) => uploadBytesAndGetUrl({ file, storagePath }))

  const uploadResultArray = await Promise.all(promises)
  console.log('upload success', uploadResultArray)

  // only need to return the paths, file objects are not useful after upload is done.
  // we also don't return the metadata object here.
  return uploadResultArray.map(({ storagePath, downloadURL }) => ({ storagePath, downloadURL }))
}
