import { NavLink, Navigate, Route, Routes } from 'react-router-dom'
import { useAppContext } from 'src/hooks/useAppContext'
import { userrole_isAdmin } from 'src/util/user_roles'
import { Unauthorized } from '../Unauthorized'
import { AdminTools } from './AdminTools'
import { AlgoliaInit } from './AlgoliaInit'
import { EmergencyPhoneList } from './EmergencyPhoneList'
import { FixAgencyNames } from './FixAgencyNames'
import { ImportTourCalendar } from './ImportTourCalendar'
import { KintoneSalesInformationImporter } from './KintoneSalesInformationImporter'
import { KintoneTourRequestImporter } from './KintoneTourRequestImporter'
import { RequestSearchTestPage } from './RequestSearchTestPage'
import { RulesTesting } from './RulesTesting'
import { TestLatency } from './TestLatency'
import { TestPage } from './TestPage'
import { UserList } from './UserList'
import { ViewLogs } from './ViewLogs'



export function PageAdmin() {

  const { userDetails } = useAppContext()
  if (!userrole_isAdmin(userDetails.roles)) {
    return <Unauthorized />
  }

  return (
    <>
      {false && (
        <div>
          <header className='d-flex py-3 border-bottom'>
            <ul className='nav'>
              <li className='nav-item mx-4'><NavLink className={({ isActive }) => `nav-link ${isActive ? 'active bg-laurent' : ''} border rounded-2  text-primary-emphasis`} to='/admin/users'>User list</NavLink></li>
              <li className='nav-item'><NavLink className={({ isActive }) => `nav-link ${isActive ? 'active bg-laurent' : ''} border rounded-2 text-primary-emphasis`} to='/admin/expensepayments'>Expense payments</NavLink></li>
            </ul>
          </header>
        </div>
      )}

      <Routes>
        {/* Admin */}
        <Route path='/users' element={<UserList />} />
        <Route path='/emergencyphones' element={<EmergencyPhoneList />} />
        {/* Dev */}
        <Route path='/rulestesting' element={<RulesTesting />} />
        <Route path='/tools' element={<AdminTools />} />
        <Route path='/testlatency' element={<TestLatency />} />
        <Route path='/importtourcalendar' element={<ImportTourCalendar />} />
        <Route path='/kintoneimport' element={<KintoneTourRequestImporter />} />
        <Route path='/salesinfoimport' element={<KintoneSalesInformationImporter />} />
        <Route path='/fixagencynames' element={<FixAgencyNames />} />
        <Route path='/viewlogs' element={<ViewLogs />} />
        <Route path='/algolia-init' element={<AlgoliaInit />} />
        <Route path='/request-search-test' element={<RequestSearchTestPage />} />
        <Route path='/testpage' element={<TestPage />} />
        {/* Default */}
        <Route path='/' element={<Navigate to='users' />} />
      </Routes>
    </>
  )
}

