import { Firestore, addDoc, collection, deleteField, doc, setDoc, updateDoc } from 'firebase/firestore'
import { CalendarLogType, LogKeyType } from 'src/types/objectTypes'
import { TourRequestType, TourRequestTypeWithDates } from 'src/types/types_tourrequest'
import { UserDetailsType, UserSimpleType } from 'src/types/types_user'
import { serverTimestampAsDate } from 'src/util/util_firestoredates'
import { log_db_write } from 'src/util/util_log'



// In order to ensure that the calendar log is exhaustive and does not miss any action, we require all
// write operations to the calendar in Firestore to be done in this tile util_db_calendar.ts


export function setDocGuidecal(
  db: Firestore,
  guideObj: UserSimpleType,
  updateObj: Record<string, any>,
  debug_action: string,
  logkey: LogKeyType,
  userDetails: UserDetailsType,
  calendarLogMessage: string,
) {

  // setDoc {merge:true} with empty object {} has a special meaning:
  // instead of merging, it replaces the field value with {} (deleting all existing data under the field)
  // so we carefully check there is at least 1 key

  for (const [key, value] of Object.entries(updateObj)) {
    if (typeof value === 'object' && Object.keys(value).length === 0) {
      throw new Error(`${debug_action} caused empty update`)
    }
  }

  // here we use setDoc, in case the document doesn't already exist (first time ever setting office work or days off for this guide)
  setDoc(doc(db, 'guidecalendars', guideObj.id), updateObj, { merge: true })

  log_db_write({ db, userDetails, logkey, desc: `Tour calendar: ${debug_action} for guide ${guideObj.id} ${guideObj.name}` })

  calendarLog(db, userDetails, {
    objectType: 'guide',
    objectId: guideObj.id,
    guideObj: {
      id: guideObj.id,
      email: guideObj.email,
      name: guideObj.name,
    },
  }, calendarLogMessage)
}

export function toggleHideRequestOnCalendar(
  db: Firestore,
  userDetails: UserDetailsType,
  tourrequest: TourRequestTypeWithDates,
) {
  const newHiddenState = !tourrequest.hiddenInCalendar
  const updateObj: Partial<TourRequestType> = { hiddenInCalendar: newHiddenState }
  updateDoc(doc(db, 'tourrequests', tourrequest.id), updateObj)
  log_db_write({ db, userDetails, logkey: 'tourcalendar.set_hidden_in_calendar', desc: `Tour calendar: set hiddenInCalendar=${newHiddenState} for tourrequest ${tourrequest.id} ${tourrequest.requestCode}` })

  calendarLog(db, userDetails, {
    objectType: 'tourrequest',
    objectId: tourrequest.id,
    tourrequestDetails: {
      id: tourrequest.id,
      requestCode: tourrequest.requestCode,
      paxName: tourrequest.travellerName,
      agencyOrPlatform: tourrequest.agencyOrPlatform,
    },
  },
    `${newHiddenState ? 'Hide' : 'Unhide'} tour in calendar`
  )
}

export function moveTourrequestCalendayDay(
  db: Firestore,
  userDetails: UserDetailsType,
  tourrequest: TourRequestTypeWithDates,
  dateiso_src: string,
  dateiso_dest: string,
) {
  const updateObj: Record<string, any> = {}

  const dragDroppedData = tourrequest.calendarDays[dateiso_src]

  updateObj[`calendarDays.${dateiso_dest}`] = dragDroppedData
  updateObj[`calendarDays.${dateiso_src}`] = deleteField()

  const promise = updateDoc(doc(db, 'tourrequests', tourrequest.id), updateObj)

  log_db_write({ db, userDetails, logkey: 'tourcalendar.drag_and_drop_day', desc: `Tour calendar: Drag and drop day ${dateiso_src} to ${dateiso_dest} on tour ${tourrequest.id} ${tourrequest.requestCode}` })

  calendarLog(db, userDetails, {
    objectType: 'tourrequest',
    objectId: tourrequest.id,
    tourrequestDetails: {
      id: tourrequest.id,
      requestCode: tourrequest.requestCode,
      paxName: tourrequest.travellerName,
      agencyOrPlatform: tourrequest.agencyOrPlatform,
    },
  },
    `Drag-and-drop from ${dateiso_src} to ${dateiso_dest}: ${(dragDroppedData.guides ?? []).map((g) => `[${g.name}:${g.hours}hrs]`).join('')} ${dragDroppedData.additionalText}`
  )

  return promise
}

export function updateTourRequestCalendar(
  db: Firestore,
  userDetails: UserDetailsType,
  tourrequest: TourRequestTypeWithDates,
  updateObj: Record<string, any>,
  logmessage: string,
  logmessageExtraDetails: string,
  logkey: LogKeyType,
) {

  const promise = updateDoc(doc(db, 'tourrequests', tourrequest.id), updateObj)
  log_db_write({ db, userDetails, logkey, desc: `Tour calendar: ${logmessage} ${logmessageExtraDetails}` })

  calendarLog(db, userDetails, {
    objectType: 'tourrequest',
    objectId: tourrequest.id,
    tourrequestDetails: {
      id: tourrequest.id,
      requestCode: tourrequest.requestCode,
      paxName: tourrequest.travellerName,
      agencyOrPlatform: tourrequest.agencyOrPlatform,
    },
  },
    logmessage,
  )

  return promise
}

export function calendarLog(
  db: Firestore,
  userDetails: UserDetailsType,
  objectChanged: {
    objectType: 'guide',
    objectId: string,
    guideObj: {
      id: string,
      email: string,
      name: string,
    },
  } | {
    objectType: 'tourrequest',
    objectId: string,
    tourrequestDetails: {
      id: string,
      requestCode: string,
      paxName: string,
      agencyOrPlatform: string,
    },
  },
  message: string,
) {

  const obj: Omit<CalendarLogType, 'id'> = {
    dateCreated: serverTimestampAsDate(),
    userCreated: {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    },
    objectChanged,
    message,
  }

  addDoc(collection(db, 'calendarlogs'), obj)

}
