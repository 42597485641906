import { useState } from 'react';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { InputTW } from 'src/components/Buttons/InputTW';
import { useAppContext } from 'src/hooks/useAppContext';
import { GuideCalendarNoteType } from 'src/types/types_tourrequest';
import { UserSimpleType } from 'src/types/types_user';
import { addDays, iso_from_utc0 } from 'src/util/datetools';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { GuideCalendarSelectedCellType } from '../CalendarBlocks/R3_Guides';
import { setDocGuidecal } from '../util_db_calendar';



interface GuideCalendarNotesButtonsProps {
  userSimple: UserSimpleType;
  dateutcCalendarStart: Date;
  selectedCell: GuideCalendarSelectedCellType;
  selectedEventDates: string[]; // ['2024-06-01', '2024-06-02', ...]
  showDeletionConfirmationPopup: string | null;
  setShowDeletionConfirmationPopup: (value: string | null) => void;
  closePopup: () => void;
}

export function GuideCalendarNotesButtons({
  userSimple,
  dateutcCalendarStart,
  selectedCell,
  selectedEventDates,
  showDeletionConfirmationPopup,
  setShowDeletionConfirmationPopup,
  closePopup,
}: GuideCalendarNotesButtonsProps) {

  const { db, userDetails } = useAppContext()

  const [calendarNoteContent, setCalendarNoteContent] = useState('')

  const saveCalendarNote = () => {
    const updateObj = {
      calendarNotes: {} as Record<string, GuideCalendarNoteType>,
      dateModified: serverTimestampAsDate(),
      userModified: userSimple,
    }

    for (const dayIndex of selectedCell.colList) {
      const dateutc = addDays(dateutcCalendarStart, dayIndex)
      const dateiso = iso_from_utc0(dateutc)
      const calendarNote: GuideCalendarNoteType = {
        _isDeleted: false,
        dateiso,
        calendarNoteContent,
        dateModified: serverTimestampAsDate(),
        userModified: userSimple,
      }
      updateObj.calendarNotes[dateiso] = calendarNote
    }

    console.log('updating guidecalendar', selectedCell.guideObj.id)

    setDocGuidecal(db, selectedCell.guideObj, updateObj, 'Set calendar note', 'tourcalendar.guide_action.calendar_note.set', userDetails,
      `Set calendar note to “${calendarNoteContent}” on ${Object.keys(updateObj.calendarNotes).map((dateiso) => `[${dateiso}]`).join('')}`
    )


    setShowDeletionConfirmationPopup(null)
    closePopup()
  }

  const hasEvent = selectedEventDates.length > 0

  if (!hasEvent) {
    // cells selected by the user do NOT have a calendar note in them already
    return (
      <div style={{
        // this div's purpose is to be position:relative, to be able to correctly absloute-position the deletion confirmation popup
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'stretch',
      }}>
        <ButtonTW variant='lavender'
          onMouseDown={(e) => {
            e.stopPropagation()
          }}
          onClick={(e) => {
            e.stopPropagation()
            setShowDeletionConfirmationPopup('create calendar note')
          }}>
          Set calendar note
        </ButtonTW>
        {showDeletionConfirmationPopup === 'create calendar note' && (
          <div className='guideCalendarPopup' style={{
            position: 'absolute',
            top: '0.5em',
            left: '0.75em',
            width: 'calc(100% + 1em)',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5em',
            zIndex: 1,
          }}>
            <div>Calendar note contents:</div>
            <div>
              <InputTW type='text'
                style={{ fontSize: 'inherit' }}
                value={calendarNoteContent}
                onChange={(e) => setCalendarNoteContent(e.currentTarget.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter')
                    saveCalendarNote()
                }} />
            </div>
            <ButtonTW variant='lavender' onClick={(e) => {
              e.stopPropagation()
              saveCalendarNote()
            }}>Confirm</ButtonTW>
            <ButtonTW variant='darkgray_outline' onClick={() =>
              setShowDeletionConfirmationPopup(null)
            }>Cancel</ButtonTW>
          </div>
        )}
      </div>
    )

  } else {
    // cells selected by the user DO have a calendar note in them already

    return (
      <div style={{
        // this div's purpose is to be position:relative, to be able to correctly absloute-position the deletion confirmation popup
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'stretch',
      }}>
        <ButtonTW variant='lavender'
          onMouseDown={(e) => {
            e.stopPropagation()
          }}
          onClick={(e) => {
            e.stopPropagation()
            setShowDeletionConfirmationPopup('delete calendar note')
          }}>
          Delete calendar notes
        </ButtonTW>
        {showDeletionConfirmationPopup === 'delete calendar note' && (
          <div className='guideCalendarPopup' style={{
            position: 'absolute',
            top: '0.5em',
            left: '0.75em',
            width: 'calc(100% + 1em)',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5em',
            zIndex: 1,
          }}>
            <div>Delete selected calendar notes?</div>
            <ButtonTW variant='bsDarkGray' onClick={() => {
              const datesToDelete = selectedEventDates

              if (datesToDelete.length > 0) {

                const updateObj = {
                  calendarNotes: {} as Record<string, Omit<GuideCalendarNoteType, 'dateiso' | 'calendarNoteContent'>>,
                  dateModified: serverTimestampAsDate(),
                  userModified: userSimple,
                }

                for (const dateiso of datesToDelete) {
                  const calendarNote: Omit<GuideCalendarNoteType, 'dateiso' | 'calendarNoteContent'> = {
                    _isDeleted: true,
                    dateModified: serverTimestampAsDate(),
                    userModified: userSimple,
                  }
                  updateObj.calendarNotes[dateiso] = calendarNote
                }

                setDocGuidecal(db, selectedCell.guideObj, updateObj, 'Delete calendar note', 'tourcalendar.guide_action.calendar_note.delete', userDetails,
                  `Delete calendar note on ${datesToDelete.map((dateiso) => `[${dateiso}]`).join('')}`
                )
              }
              setShowDeletionConfirmationPopup(null)
              closePopup()
            }}>Confirm</ButtonTW>
            <ButtonTW variant='darkgray_outline' onClick={() =>
              setShowDeletionConfirmationPopup(null)
            }>Cancel</ButtonTW>
          </div>
        )}
      </div>
    )
  }

}
