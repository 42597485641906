import { Firestore, Query, collection, query, where } from 'firebase/firestore'
import { ColumnFilterDateisoType } from 'src/types/types_columnfilters'
import { getMinMaxDates } from './util_filter_dates'



export function getQueryWhenFilteringByDeadline(
  db: Firestore,
  selectedDeadline: string,
  appliedFilterDeadline: ColumnFilterDateisoType,
  descBase: string,
): [string, Query] {

  const { desc, dateisoMin, dateisoMaxExc } = getMinMaxDates(selectedDeadline, appliedFilterDeadline, descBase)

  let qInvoices = query(collection(db, 'invoices'), where('_isDeleted', '==', false))
  if (dateisoMin)
    qInvoices = query(qInvoices, where('dateisoDeadline', '>=', dateisoMin))
  if (dateisoMaxExc)
    qInvoices = query(qInvoices, where('dateisoDeadline', '<', dateisoMaxExc))

  return [desc, qInvoices]
}
