import { QuerySnapshot, collection, onSnapshot, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useAppContext } from 'src/hooks/useAppContext'
import { AgencyType } from 'src/types/objectTypes'
import { convertDatesAgency } from 'src/util/util_firestoredates'
import { log_db_read } from 'src/util/util_log'


export function useAgencyList() {

  const { db, setDbError, userDetails } = useAppContext()

  const [agencyList, setAgencyList] = useState<AgencyType[]>(null)

  useEffect(() => {
    log_db_read({ db, userDetails, logkey: 'db_read.list_agencies', desc: 'List agencies' })

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const agencies: AgencyType[] = []
      snapshot.docs.forEach((doc) => {
        const agency = {
          ...doc.data(),
          requestListLength: (doc.data().requestList || []).length,
          id: doc.id,
        } as AgencyType
        convertDatesAgency(agency)
        agencies.push(agency)
      })
      setAgencyList(agencies)
    }

    const q = query(collection(db, 'agencies'), where('_isDeleted', '==', false))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting agency list', err));

    return unsubscribe
  }, [db, setDbError, userDetails])

  return agencyList

}
