import { WhereClauseType } from 'src/pages/Requests/RequestsList/useQueryRequestList'
import { ColumnFilterDateisoType, ThreeLevelDateTree, TreeListStateMonthType, TreeListStateType, TreeListStateYearType } from 'src/types/types_columnfilters'
import { TourRequestType } from 'src/types/types_tourrequest'
import { addDaysIso, addMonthsIso, dateiso_from_parts, getDateIso, maxDateiso, minDateiso } from 'src/util/datetools'
import { max, min } from 'src/util/util_misc'
import { refreshTernaryState } from './util_filters'



export function constructDefaultTreeListState(filterCacheDates: ThreeLevelDateTree) {
  const treeListState: TreeListStateType = {
    ternarySelected: 0,
    ternarySelectedPaid: 0,
    years: new Map<number, TreeListStateYearType>(),
    emptyValues: 0,
  }

  for (const [year, months] of filterCacheDates) {
    const yearState: TreeListStateYearType = {
      year,
      ternarySelected: 0,
      months: new Map<number, TreeListStateMonthType>(),
    }
    for (const [month, days] of months) {
      const monthState: TreeListStateMonthType = {
        month,
        ternarySelected: 0,
        days: new Map<number, 0 | 1>(),
      }
      for (const day of days) {
        monthState.days.set(day, 0)
      }
      yearState.months.set(month, monthState)
    }
    treeListState.years.set(year, yearState)
  }

  return treeListState
}





export function getDateFilterFromParam(selectedDate: string | null, filterCacheDates: ThreeLevelDateTree): ColumnFilterDateisoType | null {
  if (!selectedDate) return null
  if (!filterCacheDates) return undefined // still loading

  {
    const match = selectedDate.match(/^(\d{4})-(\d{2})-(\d{2})$/)
    if (match) {
      return {
        filterMode: 'equals',
        filterEquals: selectedDate,
      }
    }
  }

  {
    const match = selectedDate.match(/^(\d{4}-\d{2}-\d{2})~$/)
    if (match) {
      return {
        filterMode: 'range',
        filterGreaterThan: match[1],
      }
    }
  }

  {
    const match = selectedDate.match(/^~(\d{4}-\d{2}-\d{2})$/)
    if (match) {
      return {
        filterMode: 'range',
        filterSmallerThan: match[1],
      }
    }
  }

  {
    const match = selectedDate.match(/^(\d{4}-\d{2}-\d{2})~(\d{4}-\d{2}-\d{2})$/)
    if (match) {
      return {
        filterMode: 'range',
        filterGreaterThan: match[1],
        filterSmallerThan: match[2],
      }
    }
  }

  if (!selectedDate.match(/^\[.+\]$/))
    throw new Error(`Invalid date filter parameter: ${selectedDate}`)

  const treeListState = constructDefaultTreeListState(filterCacheDates)

  const list = selectedDate.slice(1, -1).split(',')


  if (list.includes('all')) {
    // ALL SELECTED
    treeListState.emptyValues = 1
    treeListState.ternarySelected = 1
    treeListState.ternarySelectedPaid = 1
    for (const yearState of treeListState.years.values()) {
      yearState.ternarySelected = 1
      for (const monthState of yearState.months.values()) {
        monthState.ternarySelected = 1
        for (const day of monthState.days.keys()) {
          monthState.days.set(day, 1)
        }
      }
    }
  } else {

    treeListState.emptyValues = list.includes('notpaid') ? 1 : 0

    if (list.includes('paid')) {
      // FULL DECADE
      treeListState.ternarySelectedPaid = 1
      for (const yearState of treeListState.years.values()) {
        yearState.ternarySelected = 1
        for (const monthState of yearState.months.values()) {
          monthState.ternarySelected = 1
          for (const day of monthState.days.keys()) {
            monthState.days.set(day, 1)
          }
        }
      }

    } else {
      for (const [year, yearState] of treeListState.years) {
        if (list.includes(year.toString())) {
          // FULL YEAR
          yearState.ternarySelected = 1
          for (const monthState of yearState.months.values()) {
            monthState.ternarySelected = 1
            for (const day of monthState.days.keys()) {
              monthState.days.set(day, 1)
            }
          }
        } else {
          // PARTIAL YEAR
          for (const [month, monthState] of yearState.months) {
            if (list.includes(`${year}-${month.toString().padStart(2, '0')}`)) {
              // FULL MONTH
              monthState.ternarySelected = 1
              for (const day of monthState.days.keys()) {
                monthState.days.set(day, 1)
              }
            } else {
              // PARTIAL MONTH
              for (const day of monthState.days.keys()) {
                if (list.includes(`${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`)) {
                  monthState.days.set(day, 1)
                }
              } // each day
              refreshMonthTernaryState(monthState)
            }
          } // each month
          refreshYearTernaryState(yearState)
        }
      } // each year

    } // not 'all paid'

    refreshOverallTernaryState(treeListState)

  } // not 'all'

  return {
    filterMode: 'checkboxes',
    treeListState,
  }
}



export function refreshMonthTernaryState(monthState: TreeListStateMonthType) {
  monthState.ternarySelected = refreshTernaryState(monthState.days)
}


export function refreshYearTernaryState(yearState: TreeListStateYearType) {
  let oneMonthOn = false
  let oneMonthOff = false
  let oneMonthHalf = false
  for (const monthState of yearState.months.values()) {
    if (monthState.ternarySelected === 1) oneMonthOn = true
    if (monthState.ternarySelected === 0) oneMonthOff = true
    if (monthState.ternarySelected === 0.5) oneMonthHalf = true
  }
  if (oneMonthOn && !oneMonthOff && !oneMonthHalf) {
    yearState.ternarySelected = 1
  } else if (!oneMonthOn && oneMonthOff && !oneMonthHalf) {
    yearState.ternarySelected = 0
  } else {
    yearState.ternarySelected = 0.5
  }
}

export function refreshOverallTernaryState(treeListState: TreeListStateType) {
  let oneYearOn = false
  let oneYearOff = false
  let oneYearHalf = false
  for (const yearState of treeListState.years.values()) {
    if (yearState.ternarySelected === 1) oneYearOn = true
    if (yearState.ternarySelected === 0) oneYearOff = true
    if (yearState.ternarySelected === 0.5) oneYearHalf = true
  }

  if (oneYearOn && !oneYearOff && !oneYearHalf) {
    treeListState.ternarySelectedPaid = 1
  } else if (!oneYearOn && oneYearOff && !oneYearHalf) {
    treeListState.ternarySelectedPaid = 0
  } else {
    treeListState.ternarySelectedPaid = 0.5
  }

  if (treeListState.emptyValues === 1) oneYearOn = true
  if (treeListState.emptyValues === 0) oneYearOff = true

  if (oneYearOn && !oneYearOff && !oneYearHalf) {
    treeListState.ternarySelected = 1
  } else if (!oneYearOn && oneYearOff && !oneYearHalf) {
    treeListState.ternarySelected = 0
  } else {
    treeListState.ternarySelected = 0.5
  }
}


export function getMinMaxDates(
  selectedDate: string,
  appliedFilter: ColumnFilterDateisoType,
  descBase: string,
): {
  desc: string,
  dateisoMin: string,
  dateisoMaxExc: string,
} {

  let dateisoMin: string
  let dateisoMaxExc: string
  let desc: string

  if (appliedFilter.filterMode === 'equals') {
    desc = `${descBase} equals ${selectedDate}`
    dateisoMin = appliedFilter.filterEquals
    dateisoMaxExc = addDaysIso(dateisoMin, 1)
  } else if (appliedFilter.filterMode === 'range') {
    desc = `${descBase} range ${selectedDate}`
    dateisoMin = appliedFilter.filterGreaterThan
    dateisoMaxExc = appliedFilter.filterSmallerThan ? addDaysIso(appliedFilter.filterSmallerThan, 1) : null
  } else if (appliedFilter.filterMode === 'checkboxes') {
    desc = `${descBase} checkboxes ${selectedDate}`
    dateisoMin = '2100-01-01'
    dateisoMaxExc = '1900-01-01'
    for (const [yearKey, year] of appliedFilter.treeListState.years) {
      if (year.ternarySelected === 0) {
        continue
      }
      if (year.ternarySelected === 1) {
        const dateisoYearStart = getDateIso(yearKey, 1, 1)
        const dateisoYearEndExc = getDateIso(yearKey + 1, 1, 1)
        dateisoMin = minDateiso(dateisoMin, dateisoYearStart)
        dateisoMaxExc = maxDateiso(dateisoMaxExc, dateisoYearEndExc)
      }
      if (year.ternarySelected === 0.5) {
        for (const [monthKey, month] of year.months) {
          if (month.ternarySelected === 0) {
            continue
          }
          if (month.ternarySelected === 1) {
            const dateisoMonthStart = getDateIso(yearKey, monthKey, 1)
            const dateisoMonthEndExc = addMonthsIso(dateisoMonthStart, 1)
            dateisoMin = minDateiso(dateisoMin, dateisoMonthStart)
            dateisoMaxExc = maxDateiso(dateisoMaxExc, dateisoMonthEndExc)
          }
          if (month.ternarySelected === 0.5) {
            for (const [dayKey, day] of month.days) {
              if (day === 0) {
                continue
              }
              const dateisoDayStart = getDateIso(yearKey, monthKey, dayKey)
              const dateisoDayEndExc = addDaysIso(dateisoDayStart, 1)
              dateisoMin = minDateiso(dateisoMin, dateisoDayStart)
              dateisoMaxExc = maxDateiso(dateisoMaxExc, dateisoDayEndExc)
            } // each day
          }
        } // each month
      }
    } // each year

  } else {
    throw new Error('invalid filter mode')
  }

  return { desc, dateisoMin, dateisoMaxExc }
}


export function getQueryConstraintsBasedOnDateFilter(appliedFilter: ColumnFilterDateisoType, fieldName: string): {
  queryConstraints: WhereClauseType[],
  clientSideFiltering: boolean,
} {

  if (appliedFilter.filterMode === 'equals') {
    return {
      queryConstraints: [[fieldName, '==', appliedFilter.filterEquals]],
      clientSideFiltering: false,
    }
  }

  if (appliedFilter.filterMode === 'range') {
    const queryConstraints: WhereClauseType[] = []
    if (appliedFilter.filterGreaterThan)
      queryConstraints.push([fieldName, '>=', appliedFilter.filterGreaterThan])
    if (appliedFilter.filterSmallerThan)
      queryConstraints.push([fieldName, '<=', appliedFilter.filterSmallerThan])
    return {
      queryConstraints,
      clientSideFiltering: false,
    }
  }

  if (appliedFilter.filterMode === 'checkboxes') {
    let minDate = null
    let maxDate = null
    let onlySpecificDates = true
    let mergedMultipleRanges = false
    const specificDates: string[] = []
    for (const [year, yearState] of appliedFilter.treeListState.years) {
      if (yearState.ternarySelected === 0)
        continue
      if (yearState.ternarySelected === 1) {
        onlySpecificDates = false
        const thisMinDate = dateiso_from_parts(year, 1, 1)
        const thisMaxDate = dateiso_from_parts(year, 12, 31)
        if (minDate === null) {
          minDate = thisMinDate
          maxDate = thisMaxDate
        } else {
          minDate = min(minDate, thisMinDate)
          maxDate = max(maxDate, thisMaxDate)
          mergedMultipleRanges = true
        }
        continue
      }
      for (const [month, monthState] of yearState.months) {
        if (monthState.ternarySelected === 0)
          continue
        if (monthState.ternarySelected === 1) {
          onlySpecificDates = false
          const thisMinDate = dateiso_from_parts(year, month, 1)
          const thisMaxDate = addDaysIso(addMonthsIso(thisMinDate, 1), -1)
          if (minDate === null) {
            minDate = thisMinDate
            maxDate = thisMaxDate
          } else {
            minDate = min(minDate, thisMinDate)
            maxDate = max(maxDate, thisMaxDate)
            mergedMultipleRanges = true
          }
          continue
        }
        for (const [day, dayState] of monthState.days) {
          if (dayState === 0)
            continue
          const thisDay = dateiso_from_parts(year, month, day)
          if (minDate === null) {
            minDate = thisDay
            maxDate = thisDay
          } else {
            minDate = min(minDate, thisDay)
            maxDate = max(maxDate, thisDay)
            mergedMultipleRanges = true
          }
          specificDates.push(dateiso_from_parts(year, month, day))
        }
      }
    }

    if (onlySpecificDates) {
      return {
        queryConstraints: [[fieldName, 'in', specificDates]],
        clientSideFiltering: false,
      }
    } else {
      return {
        queryConstraints: [
          [fieldName, '>=', minDate],
          [fieldName, '<=', maxDate],
        ],
        clientSideFiltering: mergedMultipleRanges,
      }
    }
  }

  throw new Error('unknown filter mode')
}

export function tourRequestClientSideFiltering(tourrequests: TourRequestType[], appliedFilter: ColumnFilterDateisoType, fieldName: 'dateisoTourStart' | 'dateisoTourEnd') {
  console.log('CLIENT SIDE FILTERING: tour start/end')
  const filteringFunction = getFilteringFunction(appliedFilter)
  return tourrequests.filter((tourrequest) => {
    return filteringFunction(tourrequest[fieldName])
  })
}

export function tourRequestClientSideFilteringPaymentDates(tourrequests: TourRequestType[], appliedFilter: ColumnFilterDateisoType) {
  console.log('CLIENT SIDE FILTERING: payment dates')
  const filteringFunction = getFilteringFunction(appliedFilter)
  return tourrequests.filter((tourrequest) => {
    if (!tourrequest.paymentDatesCache || tourrequest.paymentDatesCache.length === 0)
      return false
    let atLeastOneMatch = false
    for (const paymentDateiso of tourrequest.paymentDatesCache) {
      if (filteringFunction(paymentDateiso))
        atLeastOneMatch = true
    }
    return atLeastOneMatch
  })
}

export function getFilteringFunction(appliedFilter: ColumnFilterDateisoType): ((dateiso: string) => boolean) {
  if (appliedFilter.filterMode === 'equals') {
    return (dateiso) => {
      return dateiso === appliedFilter.filterEquals
    }
  } else if (appliedFilter.filterMode === 'range') {
    return (dateiso) => {
      if (appliedFilter.filterGreaterThan && dateiso < appliedFilter.filterGreaterThan)
        return false
      if (appliedFilter.filterSmallerThan && dateiso > appliedFilter.filterSmallerThan)
        return false
      return true
    }
  } else if (appliedFilter.filterMode === 'checkboxes') {
    return (dateiso) => {
      const year = Number(dateiso.slice(0, 4))
      const month = Number(dateiso.slice(5, 7))
      const day = Number(dateiso.slice(8, 10))
      if (appliedFilter.treeListState.years.has(year)) {
        const yearState = appliedFilter.treeListState.years.get(year)
        if (yearState.ternarySelected === 0)
          return false
        if (yearState.ternarySelected === 1)
          return true
        if (yearState.ternarySelected === 0.5) {
          if (yearState.months.has(month)) {
            const monthState = yearState.months.get(month)
            if (monthState.ternarySelected === 0)
              return false
            if (monthState.ternarySelected === 1)
              return true
            if (monthState.ternarySelected === 0.5) {
              if (monthState.days.has(day)) {
                return monthState.days.get(day) === 1
              }
            }
          }
        }
      }
      // unreachable??
      console.error('unreachable code reached')
      return true
    }
  } else {
    throw new Error('unknown filter mode')
  }

}

