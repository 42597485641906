import { serverTimestamp } from 'firebase/firestore'
import React, { ClipboardEvent, Dispatch, ReactNode, SetStateAction } from 'react'
import { Form } from 'react-bootstrap'
import { ButtonTW } from 'src/components/Buttons/ButtonTW'
import { EditableFieldQuotation, FunctionSaveFieldToDbType, FunctionTabKeyPress } from 'src/components/EditableField/EditableFieldQuotation'
import { EnumLineItemType, QuotationDayType, QuotationLineItemType, QuotationLineItemTypeUnion, QuotationOverallType } from 'src/types/types_quotation'
import { UserSimpleUidType } from 'src/types/types_user'
import { formatNum } from 'src/util/util_formatnum'
import { nano_id } from 'src/util/util_nano_id'
import { NEW_ROW_ID } from '../util_calculateQuotationTotals'
import { UpdateQuotationType } from '../util_handlepaste'
import { TableRowActions } from './TableRowActions'


export type FunctionCallbackOnPasteQuotation = (
  e: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  baseField: 'lineItems' | 'days',
  item: QuotationLineItemType | QuotationDayType,
  field: string,
) => void;

export type QuotationLineItemTableColumn = {
  field: string, // room_type
  header: string, // Room type
  widthEm: number, // 10
  isNumeric?: boolean,
}



interface LineItemTableProps {
  lineItemsThisTable: QuotationLineItemType[];
  lineItemsAll: QuotationLineItemTypeUnion[];
  dayNum: number;
  dateiso: string;
  updateQuotation: UpdateQuotationType;
  enableEditing: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  saveFieldToDb: FunctionSaveFieldToDbType; // save 1 value in 1 field, by later calling `updateQuotation`
  prepareSaveRowsToDb: (newItems: QuotationLineItemTypeUnion[]) => { updateObj: Record<string, any> }
  dayInfo: QuotationDayType;
  userSimple: UserSimpleUidType;
  tabKeyPress: FunctionTabKeyPress;
  callbackOnPasteQuotation: FunctionCallbackOnPasteQuotation;
  renderImages: (text: string) => ReactNode;
  showDebugInfo: boolean;
  overall: QuotationOverallType;
  setNumBlankRows: Dispatch<SetStateAction<Record<string, number>>>;

  tablename: EnumLineItemType;
  columns: QuotationLineItemTableColumn[];
  bgcolor: string;
}

export function LineItemTable({
  lineItemsThisTable,
  lineItemsAll,
  dayNum,
  dateiso,
  updateQuotation,
  enableEditing,
  editedCell,
  setEditedCell,
  saveFieldToDb,
  prepareSaveRowsToDb,
  dayInfo,
  userSimple,
  tabKeyPress,
  callbackOnPasteQuotation,
  renderImages,
  showDebugInfo,
  overall,
  setNumBlankRows,

  tablename,
  columns,
  bgcolor,
}: LineItemTableProps) {


  const listDateRowSpans: number[] = []

  {
    let curDayNum = -1
    let curDayNumIndex = -1
    lineItemsThisTable.forEach((item, index) => {
      if (item.dayNum !== curDayNum) {
        curDayNum = item.dayNum
        curDayNumIndex = index
        listDateRowSpans.push(1)
      } else {
        listDateRowSpans[curDayNumIndex]++
        listDateRowSpans.push(0)
      }
    })
  }

  const listNameRowSpans: number[] = [] // e.g. 1,1,1,3,0,0,1,2,0,1,1,1  (non-1 'N' is followed by [N-1] zeros)

  {
    let curName = '-----'
    let curDayNum = -1
    let curNameIndex = -1
    lineItemsThisTable.forEach((item, index) => {
      if (item.name !== curName
        || item.dayNum !== curDayNum // don't span accross multiple days
      ) {
        curName = item.name
        curDayNum = item.dayNum
        curNameIndex = index
        listNameRowSpans.push(1)
      } else {
        listNameRowSpans[curNameIndex]++
        listNameRowSpans.push(0)
      }
    })
  }



  const lineItems_multiple: boolean = lineItemsThisTable.length > (enableEditing ? 2 : 1)
  const lineItems_total: number = lineItemsThisTable.reduce((acc, item) => acc + (item.totalPrice || 0), 0)

  if (!enableEditing && lineItemsThisTable.length === 0)
    return null

  // if (tablename === 'accommodation' && dayNum === 0)
  //   console.log('line items', lineItemsThisTable.map((item) => item.index))

  return (
    <div style={{ display: 'flex', alignItems: 'start' }}>
      <div className='divGridLineItemTable divGridTable tw-mb-4'
        style={{
          display: 'grid',
          width: enableEditing ? '98em' : '90em',
          gridTemplateColumns: '10em 1em 19em 10em 10em 10em 10em 10em 10em 8em',
          // left area: 10em
          // colored col: 1em
          // main cell: 19em
          // remaing cols: 6 * 10em
          // buttons: 8em
        }}
      >
        <div className='leftArea' style={{
          gridRow: '1',
          gridColumn: '1',
        }}></div>
        <div className='cellHeader cellBorderLeft' style={{
          borderRightStyle: 'none',
          gridColumn: '2',
        }}></div>
        {columns.map((col, index) => {
          return (
            <div
              className='cellHeader'
              key={index}
              style={{
                borderLeftStyle: index === 0 ? 'none' : undefined,
              }}>
              {(index === 0) && (
                <>Day {1 + dayNum}<br /></>
              )}
              {col.header}
            </div>
          )
        })}

        {/* <tbody> */}
        {lineItemsThisTable.map((item, indexRow) => {

          // check index in DB is correct
          // (im summary table, index will reset to zero for each day, e.g. 0,1,2,3,0,1,0,1)
          if (item.index !== indexRow) {
            console.error(`Incorrect index in DB: day [${1 + item.dayNum}] table [${item.itemType}] item [${item.id}] has index ${item.index} but should have index ${indexRow}`)
          }

          const rowInfo = {
            baseField: 'lineItems' as const,
            rowObj: item,
            tableid: `day_${dayNum}_${tablename}`,
            isClickableToEdit: enableEditing,
            editedCell,
            setEditedCell,
            tabKeyPress,
            saveFieldToDb,
          }

          const hasMemoRow = item.memorandum || enableEditing

          const gridRow = 2 + 2 * indexRow


          return (
            <React.Fragment key={item.id}>
              {/* <tr className='rowNonMemorandum'> */}
              <div className='leftArea' style={{
                gridRow: `${gridRow} / span 2`,
                gridColumn: '1',
              }}>
                <div style={{ borderColor: enableEditing ? 'silver' : 'transparent', height: enableEditing ? '3em' : null }}>
                  <EditableFieldQuotation
                    fieldname='leftAreaMemorandum'
                    placeholderText={'Memo'}
                    validationType=''
                    {...rowInfo}
                  />
                </div>
              </div>
              {listNameRowSpans[indexRow] > 0 && (
                <div
                  className='cellBorder cellBorderLeft cellBorderBottom'
                  style={{
                    backgroundColor: bgcolor,
                    padding: 0,
                    gridRow: `${gridRow} / span ${2 * listNameRowSpans[indexRow]}`
                  }}>

                </div>
              )}
              {columns.map((col, indexCol) => {
                let rowSpan
                if (indexCol === columns.length - 1) {
                  rowSpan = 2
                } else if (indexCol === 0) {
                  if (!enableEditing) {
                    rowSpan = 2 * listNameRowSpans[indexRow]
                  } else {
                    rowSpan = 2
                  }
                } else {
                  rowSpan = hasMemoRow ? 1 : 2
                }

                if (rowSpan === 0) {
                  return null
                }

                return (
                  <div
                    key={indexCol}
                    className={`cellData ${col.isNumeric ? 'quotationCellNumeric' : null} ${rowSpan % 2 === 0 ? 'cellBorderBottom' : ''}`}
                    style={{
                      gridRow: `${gridRow} / span ${rowSpan}`,
                    }}
                  >
                    {col.field ? (
                      <EditableFieldQuotation
                        fieldname={col.field}
                        validationType={col.isNumeric ? 'formula' : ''}
                        {...rowInfo}
                      />
                    ) : (
                      <>&nbsp;</>
                    )}
                  </div>
                )
              })}
              {enableEditing && (
                <div style={{
                  whiteSpace: 'nowrap',
                  gridRow: `${gridRow} / span 2`,
                }}>
                  <div>
                    <TableRowActions
                      lineItems={lineItemsThisTable}
                      itemId={item.id}
                      index={indexRow}
                      prepareSaveRowsToDb={prepareSaveRowsToDb}
                      updateQuotation={updateQuotation}
                      lineItemsAll={lineItemsAll}
                      overall={overall}
                      showDebugInfo={showDebugInfo}
                      blankRowKey={item.id === NEW_ROW_ID ? `day_${dayNum}_${tablename}` : null}
                      setNumBlankRows={setNumBlankRows}
                    />
                  </div>
                </div>
              )}
              {/* </tr> */}

              {/* <tr> */}
              {/* MEMO ROW */}
              {hasMemoRow && (
                <div
                  className='cellMemorandum cellBorder'
                  style={{
                    gridColumn: '4 / span 5',
                    gridRow: `${gridRow + 1}`,
                  }}>
                  <div style={{ display: 'flex' }}>

                    {enableEditing && (
                      <div className='labelMemorandum'>
                        Memo
                      </div>
                    )}

                    <div style={{ flexGrow: 1, padding: '0.125rem 0.375rem' }}>
                      {enableEditing ? (
                        <EditableFieldQuotation
                          fieldname='memorandum'
                          isTextArea={true}
                          validationType=''
                          {...rowInfo}

                          callbackOnPaste={(e) => {
                            callbackOnPasteQuotation(e, 'lineItems', item, 'memorandum')
                          }}

                          getDisplayValue={renderImages}
                        />
                      ) : (
                        <div>
                          {renderImages(item.memorandum)}
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              )}
              {/* </tr> */}
            </React.Fragment>
          )
        })}

        {/* <tr> */}
        {/* TABLE TOTAL ROW */}
        <div className='leftArea' style={{
          borderRight: 'none',
          gridColumn: '1',
        }}></div>
        <div style={{
          backgroundColor: 'transparent',
          borderLeft: 'none',
          borderBottom: 'none',
          gridColumn: `2 / span ${columns.length}`,
        }}>
          {enableEditing && (
            <div>
              <ButtonTW variant='blue_outline' textSize='md'
                disabled={lineItemsThisTable.some((item) => item.id === NEW_ROW_ID)}
                onClick={() => {
                  const key = `day_${dayNum}_${tablename}`
                  setNumBlankRows((obj) => {
                    return {
                      ...obj,
                      [key]: 1, // (obj[key] || 0) + 1, // only allow 1 blank row, as it gets buggy otherwise (all blank rows have the same id)
                    }
                  })
                }}
              >Add row</ButtonTW>
              {' '}
              <ButtonTW variant='blue' textSize='md'
                disabled={dayNum === 0}
                onClick={() => {
                  let lastIndex = lineItemsThisTable.length - 1
                  if (lastIndex >= 0 && lineItemsThisTable[lastIndex].id === NEW_ROW_ID)
                    lastIndex--
                  const linesToCopy = lineItemsAll.filter((item) => item.dayNum === dayNum - 1 && item.itemType === tablename && item.id !== NEW_ROW_ID)
                    .sort((a, b) => a.index - b.index)
                    .map((item) => ({ ...item })) // copy items
                  if (linesToCopy.length === 0)
                    return
                  linesToCopy.forEach((item) => {
                    delete item.id
                    item.dayNum = dayNum
                    item.date = dateiso;
                    item.index = lastIndex + 1 + item.index
                  })

                  const updateObj: any = {}
                  linesToCopy.forEach((item) => {
                    const newRowNanoId = nano_id()
                    const newRowId = `lineItems.${newRowNanoId}`
                    updateObj[newRowId] = item
                    console.log(newRowId)
                  })

                  console.log('updateobj', { ...updateObj })
                  updateQuotation(
                    updateObj,
                    `Duplicate rows in table “${tablename}” from day ${1 + (dayNum - 1)} to day ${1 + dayNum}`,
                    false
                  )

                }}>Duplicate previous day</ButtonTW>
            </div>
          )}
        </div>
        {(lineItems_multiple || enableEditing) && ( // always show total when editing (CEO request)
          <div className='quotationCellNumeric cellData cellBorderLeft cellBorder cellBorderBottom' style={{
            gridColumn: '9',
          }}>{formatNum(lineItems_total)}</div>
        )}
        {/* </tr> */}
        {/* </tbody> */}
      </div>

      {(tablename === 'accommodation') && (
        <table className='table tableBLD' style={{ width: 'auto', marginLeft: '1em' }}>
          <thead>
            <tr>
              <th title='Breakfast'>B</th>
              <th title='Lunch'>L</th>
              <th title='Dinner'>D</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Form.Check type='checkbox'
                  checked={!!(dayInfo && dayInfo.accommodationBreakfast)}
                  onChange={(e) => {
                    if (!enableEditing)
                      return
                    updateQuotation({
                      [`days.day_${dayNum}.accommodationBreakfast`]: e.target.checked,
                      [`days.day_${dayNum}.dateModified`]: serverTimestamp(),
                      [`days.day_${dayNum}.userModified`]: userSimple,
                    }, 'Set breakfast')
                  }}
                />
              </td>
              <td>
                <Form.Check type='checkbox'
                  checked={!!(dayInfo && dayInfo.accommodationLunch)}
                  onChange={(e) => {
                    if (!enableEditing)
                      return
                    updateQuotation({
                      [`days.day_${dayNum}.accommodationLunch`]: e.target.checked,
                      [`days.day_${dayNum}.dateModified`]: serverTimestamp(),
                      [`days.day_${dayNum}.userModified`]: userSimple,
                    }, 'Set lunch')
                  }}
                />
              </td>
              <td>
                <Form.Check type='checkbox'
                  checked={!!(dayInfo && dayInfo.accommodationDinner)}
                  onChange={(e) => {
                    if (!enableEditing)
                      return
                    updateQuotation({
                      [`days.day_${dayNum}.accommodationDinner`]: e.target.checked,
                      [`days.day_${dayNum}.dateModified`]: serverTimestamp(),
                      [`days.day_${dayNum}.userModified`]: userSimple,
                    }, 'Set dinner')
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}

    </div>
  )
}
