import { EditableField } from 'src/components/EditableField/EditableField';
import { ExpenseMealAllowance, ExpenseSheetType } from 'src/types/types_expensesheet';
import { dateisoFormatJpShort } from 'src/util/dateformattools';
import { addDaysIso } from 'src/util/datetools';
import { formatNum } from 'src/util/util_formatnum';
import { AutosaveSheetType } from '../ExpenseSheet';


interface D_MealAllowanceProps {
  sheet: ExpenseSheetType;
  isReadOnly: boolean;
  tourDays: number[];
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  autosaveNewStep: AutosaveSheetType;
}

export function D_MealAllowance({
  sheet,
  isReadOnly,
  tourDays,
  editedCell,
  setEditedCell,
  autosaveNewStep,
}: D_MealAllowanceProps) {

  return (
    <>
      <h5 className='mt-4'>Meal allowance</h5>

      <div>
        <table className='table mb-1 inputtingTable' style={{ width: `${4 + 7 + 8 + 7 + 7 + 16 + 4}em` }}>
          <colgroup>
            <col style={{ width: '4em' }} />
            <col style={{ width: '7em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '7em' }} />
            <col style={{ width: '7em' }} />
            <col style={{ width: '16em' }} />
            <col style={{ width: '4em' }} />
          </colgroup>
          <thead>
            <tr className={`row-header ${!isReadOnly ? 'no-bottom-border' : ''}`}>
              <th className='pb-0'></th>
              <th className='pb-0'>Day №</th>
              <th className='pb-0'>Date</th>
              <th className='text-center pb-0'>Lunch</th>
              <th className='text-center pb-0'>Dinner</th>
              <th className='pb-0'>Memo</th>
              <th className='pb-0'></th>
            </tr>
            {!isReadOnly && (
              <tr className='row-header'>
                <th></th>
                <th></th>
                <th></th>
                <th className='text-center'>
                  <input type='checkbox' className='form-check-input' checked={sheet.calc.numLunches === sheet.calc.numGuidingDays} onChange={(e) => {
                    const updateObj: Partial<ExpenseSheetType> = {
                      mealAllowance: sheet.mealAllowance.map((item) => {
                        const newItem: ExpenseMealAllowance = {
                          ...item,
                          lunch: e.target.checked,
                        }
                        return newItem
                      }),
                    }

                    autosaveNewStep(`Meal allowance: ${e.target.checked ? 'set' : 'unset'} all lunches`, updateObj, 'u')
                  }} />
                </th>
                <th className='text-center'>
                  <input type='checkbox' className='form-check-input' checked={sheet.calc.numDinners === sheet.calc.numGuidingDays} onChange={(e) => {
                    const updateObj: Partial<ExpenseSheetType> = {
                      mealAllowance: sheet.mealAllowance.map((item) => {
                        const newItem: ExpenseMealAllowance = {
                          ...item,
                          dinner: e.target.checked,
                        }
                        return newItem
                      }),
                    }

                    autosaveNewStep(`Meal allowance: ${e.target.checked ? 'set' : 'unset'} all dinners`, updateObj, 'u')
                  }} />
                </th>
                <th></th>
                <th></th>
              </tr>
            )}
          </thead>
          <tbody>
            {tourDays
              .filter((i) => sheet.mealAllowance[i].guiding)
              .map((i, index) => {

                // if (!sheet.mealAllowance[i].guiding)
                //   return <></>

                return (
                  <tr key={i}>
                    <td>
                    </td>
                    <td>
                      {i + 1}
                    </td>
                    <td>
                      {dateisoFormatJpShort(addDaysIso(sheet.dateisoTourStart, i))}
                    </td>
                    <td className='text-center'>
                      {/* readonly attribute doesn't work on checkboxes. we can use disabled instead, but it dims the appearance. here we just prevent updating the state in JS */}
                      <input type='checkbox' className='form-check-input' checked={sheet.mealAllowance[i].lunch} disabled={isReadOnly} onChange={(e) => {
                        if (isReadOnly) return

                        const updateObj: Partial<ExpenseSheetType> = {
                          mealAllowance: sheet.mealAllowance.map((item, index) => {
                            if (index === i) {
                              const newItem: ExpenseMealAllowance = {
                                ...item,
                                lunch: e.target.checked,
                              }
                              return newItem
                            } else {
                              return item
                            }
                          }),
                        }

                        autosaveNewStep(`Meal allowance: ${e.target.checked ? 'set' : 'unset'} lunch on day ${1 + i}`, updateObj, 'u')
                      }} />
                    </td>
                    <td className='text-center'>
                      <input type='checkbox' className='form-check-input' checked={sheet.mealAllowance[i].dinner} disabled={isReadOnly} onChange={(e) => {
                        if (isReadOnly) return

                        const updateObj: Partial<ExpenseSheetType> = {
                          mealAllowance: sheet.mealAllowance.map((item, index) => {
                            if (index === i) {
                              const newItem: ExpenseMealAllowance = {
                                ...item,
                                dinner: e.target.checked,
                              }
                              return newItem
                            } else {
                              return item
                            }
                          }),
                        }

                        autosaveNewStep(`Meal allowance: ${e.target.checked ? 'set' : 'unset'} dinner on day ${1 + i}`, updateObj, 'u')
                      }} />
                    </td>
                    <td>
                      <EditableField
                        tableid='mealAllowance'
                        rowid={`${i}`}
                        fieldname='memorandum'
                        validationType=''
                        currentValue={sheet.mealAllowance[i].memorandum}
                        isClickableToEdit={!isReadOnly}
                        editedCell={editedCell}
                        setEditedCell={setEditedCell}
                        callbackCommitChange={(dbvalue) => {
                          const updateObj: Partial<ExpenseSheetType> = {
                            mealAllowance: sheet.mealAllowance.map((item, index) => {
                              if (index === i) {
                                const newItem: ExpenseMealAllowance = {
                                  ...item,
                                  memorandum: dbvalue,
                                }
                                return newItem
                              } else {
                                return item
                              }
                            }),
                          }

                          setEditedCell('')

                          autosaveNewStep(`Meal allowance: set memo on day ${1 + i}`, updateObj, 'u')
                        }}
                      />
                    </td>
                    <td>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>

      <div className='mt-4'>
        <table className='table inputtingTable meal-allowance-summary-table'>
          <colgroup>
            <col style={{ width: '4em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '4em' }} />
          </colgroup>
          <thead>
            <tr className='row-header'>
              <th></th>
              <th>Meal</th>
              <th>Unit</th>
              <th>Qty</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>Lunch</td>
              <td>{formatNum(sheet.mealAllowancePrices.lunchPrice)}</td>
              <td>{sheet.calc.numLunches}</td>
              <td>{formatNum(sheet.calc.totalMealAllowanceLunch)}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>Dinner</td>
              <td>{formatNum(sheet.mealAllowancePrices.dinnerPrice)}</td>
              <td>{sheet.calc.numDinners}</td>
              <td>{formatNum(sheet.calc.totalMealAllowanceDinner)}</td>
              <td></td>
            </tr>
            <tr className='row-footer'>
              <td></td>
              <td>Total</td>
              <td></td>
              <td></td>
              <td>{formatNum(sheet.calc.totalMealAllowance)}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}
