

interface FullMonthGridCellProps {
  iRow: number;
  indexStart: number; // 0-based
  indexEnd: number;   // 0-based, exclusive
  className?: string;
  CELL_WIDTH_EM: number;
  CELL_HEIGHT_EM: number;
}

export function FullMonthGridCell({
  iRow,
  indexStart,
  indexEnd,
  className,
  CELL_WIDTH_EM,
  CELL_HEIGHT_EM,
}: FullMonthGridCellProps) {


  const width = indexEnd - indexStart

  return (
    <div
      style={{
        position: 'absolute',
        left: `${indexStart * CELL_WIDTH_EM}em`,
        top: `${iRow * CELL_HEIGHT_EM}em`,
        width: `${width * CELL_WIDTH_EM}em`,
        height: `${CELL_HEIGHT_EM}em`,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
      }}
      className={`gridCell fullMonthGridCell ${className ?? ''}`}
    >
    </div>
  )
}
