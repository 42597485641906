

export function paymentTypeLabel(paymentType: string) {
  switch (paymentType) {
    case 'CASH':
      return 'Cash'
    case 'ADHOC_BANK_TRANSFER':
      return 'Ad hoc bank transfer'
    case 'WITH_SALARY':
      return 'With next salary'
    default:
      return paymentType
  }
}

export function paymentFlowShortLabel(paymentFlow: string, companyCreditCard: string) {
  switch (paymentFlow) {
    case 'VIA_EMPLOYEE':
      return (
        <div className='paymentFlowIcon'>
          <i className='bi bi-person' title='Via employee'></i>
          <div>Employee</div>
        </div>
      )
    case 'CEO_CREDITCARD':
      return (
        <div className='paymentFlowIcon'>
          <i className='bi bi-credit-card' title='Credit card'></i>
          <div>CC: CEO</div>
        </div>
      )
    case 'COMPANY_CREDITCARD':
      return (
        <div className='paymentFlowIcon'>
          <i className='bi bi-credit-card' title='Company card'></i>
          <div style={{ textAlign: 'center' }}>CC: {companyCreditCard.split('_')[0]}</div>
        </div>
      )
    case 'DIRECT':
      return (
        <div className='paymentFlowIcon'>
          <i className='bi bi-building-up' title='Direct'></i>
          <div>Direct</div>
        </div>
      )
    default:
      return paymentFlow
  }
}

