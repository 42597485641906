import { Firestore, Query, collection, query, where } from 'firebase/firestore'
import { ColumnFilterRequestCodeType } from 'src/types/types_columnfilters'




export function getQueryWhenFilteringByRequestCode(
  db: Firestore,
  requestCodeFilter: string,
  appliedFilterRequestCode: ColumnFilterRequestCodeType,
): [string, Query] {


  const desc = `FILTER_REQUESTCODE checkboxes ${requestCodeFilter}`

  const listRequestCodes: string[] =
    appliedFilterRequestCode
      ? [...appliedFilterRequestCode.keys()]
      : []

  const qInvoices = query(
    collection(db, 'invoices'),
    where('_isDeleted', '==', false),
    where('tripcode', 'in', listRequestCodes)
  )

  return [desc, qInvoices]
}

