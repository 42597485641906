import { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';


// Eighty Days Payment SourceAccount Dropdown

interface EDPaymentSourceAccountDropdownProps {
  id: string,
  value: string,
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void,
  disabled?: boolean,
}

export function EDPaymentSourceAccountDropdown({ id, value, onChange, disabled }: EDPaymentSourceAccountDropdownProps) {

  return (
    <Form.Select id={id} className='form-control' disabled={disabled} value={value} onChange={onChange}>
      <option>Select payment source account</option>
      <option>Bank account: SMBC</option>
      <option>Bank account: SBI</option>
      <option>Bank account: Rakuten</option>
      <option>Credit card: SMBC</option>
      <option>Cash</option>
      <option>Other</option>
    </Form.Select>
  )
}
