import { arraySum } from 'src/util/util_misc';
import { TourListColType } from '../PageTourCalendar';



interface L1_HeaderProps {
  TOUR_LIST_COLS: TourListColType[];
  CELL_HEIGHT_EM: number;
}


export function L1_Header({
  TOUR_LIST_COLS,
  CELL_HEIGHT_EM,
}: L1_HeaderProps) {

  const tourListtotalWidth = arraySum(TOUR_LIST_COLS.map((col) => col.widthEm))

  return (
    <div className='tourListHeader' style={{
      width: `${tourListtotalWidth}em`,
      height: `${CELL_HEIGHT_EM * 2}em`
    }}>
      <div className='tourListRow tourListHeaderTopRow'>
        <div style={{
          // gridColumn: `1 / ${TOUR_LIST_COLS.length + 1}`,
          // left: '0em',
          width: `${tourListtotalWidth}em`,
          // top: '0em',
          height: `${CELL_HEIGHT_EM}em`,
        }}>Tour list</div>
      </div>
      <div className='tourListRow tourListHeaderSecondRow'>
        {TOUR_LIST_COLS.map((col, i) => {
          return (
            <div key={i} style={{
              // gridColumn: `${i + 1} / ${i + 2}`,
              position: 'absolute',
              left: `${TOUR_LIST_COLS.reduce((acc, val, index) => index < i ? acc + val.widthEm : acc, 0)}em`,
              top: `${CELL_HEIGHT_EM}em`,
              width: `${col.widthEm}em`,
              height: `${CELL_HEIGHT_EM}em`,
            }}>{col.title}</div>
          )
        })}
      </div>
    </div>
  )
}
