import { ButtonTW } from 'src/components/Buttons/ButtonTW'
import { useAppContext } from 'src/hooks/useAppContext'
import { EitherLegType, TransportationLegType } from 'src/types/types_commute'
import { CurrentActionType } from './CommutingExpenses'
import { ModalTripInputActionType } from './ModalTripInput'
import { TripLegGrid } from './TripLegGrid'



function reverseLegList(legList: TransportationLegType[]) {
  const reversedLegList = []
  for (let i = legList.length - 1; i >= 0; i--) {
    const leg = legList[i]
    const reversedLeg = {
      ...leg,
      stationFrom: leg.stationTo,
      stationTo: leg.stationFrom,
    }
    reversedLegList.push(reversedLeg)
  }
  return reversedLegList
}


interface CommutingExpenseCellProps {
  editMode: boolean;
  currentAction: CurrentActionType | null;
  setCurrentAction: (action: CurrentActionType | null) => void;
  setModalAction: (action: ModalTripInputActionType | null) => void;
  legList: EitherLegType[];
  saveLegList: (legList: EitherLegType[]) => void;
  category: string;
  sIsoDate: string;
  defaultLegList?: TransportationLegType[];
}

export function CommutingExpenseCell({
  editMode, // bool
  currentAction, // {action:str, cellId:str, etc.}
  setCurrentAction,
  setModalAction,
  legList,
  saveLegList,
  category, // commuteToWork or commuteFromWork
  sIsoDate, // 2023-10-01
  defaultLegList,
}: CommutingExpenseCellProps) {

  const { _lang } = useAppContext()

  const isCommute = category === 'commuteToWork' || category === 'commuteFromWork'
  const isOtherTransportation = category === 'otherTransportationExpenses'
  const isTransportation = isCommute || isOtherTransportation
  const isNonTransportation = category === 'otherNonTransportationExpenses'


  const thisCellId = `${category}.${sIsoDate}`

  const copyingThisCell = currentAction && currentAction.action === 'copypaste' && currentAction.cellId === thisCellId


  const getActionButtons = () => {

    if (!editMode)
      return null

    if (currentAction && currentAction.action === 'copypaste') {
      const stopCopying = (
        <>
          {' '}
          <ButtonTW variant='blue_outline' onClick={(e) => {
            setCurrentAction(null)
          }}>Stop copying</ButtonTW>
        </>
      )
      if (currentAction.cellId === thisCellId) {
        return (
          <>
            {stopCopying}
          </>
        )
      } else if (isCommute && currentAction.cellId.startsWith('commute')) {
        const reverse = category !== currentAction.category
        if (!reverse) {
          return (
            <>
              <ButtonTW variant='blue_outline' onClick={(e) => {
                saveLegList(currentAction.legList)
              }}>Paste</ButtonTW>
              {stopCopying}
            </>
          )
        } else {
          return (
            <>
              <ButtonTW variant='blue_outline' onClick={(e) => {
                const reversedLegList = reverseLegList(currentAction.legList as TransportationLegType[])
                saveLegList(reversedLegList)
              }}>Paste reversed</ButtonTW>
              {stopCopying}
            </>
          )
        }
      } else if (isOtherTransportation && currentAction.cellId.startsWith('otherTransportation')) {
        return (
          <>
            <ButtonTW variant='blue_outline' onClick={(e) => {
              saveLegList(currentAction.legList)
            }}>Paste</ButtonTW>
            {stopCopying}
          </>
        )
      } else if (isNonTransportation && currentAction.cellId.startsWith('otherNonTransportation')) {
        return (
          <>
            <ButtonTW variant='blue_outline' onClick={(e) => {
              saveLegList(currentAction.legList)
            }}>Paste</ButtonTW>
            {stopCopying}
          </>
        )
      }
    }

    if (currentAction && currentAction.action === 'delete' && currentAction.cellId === thisCellId) {
      return (
        <>
          <ButtonTW variant='blue_outline' onClick={(e) => {
            saveLegList(null)
            setCurrentAction(null)
          }}>{_lang('Confirm Delete', '削除を確定')}</ButtonTW>
          {' '}
          <ButtonTW variant='blue_outline' onClick={(e) => {
            setCurrentAction(null)
          }}>{_lang('Cancel', 'キャンセル')}</ButtonTW>
        </>
      )
    }

    return (
      <>
        {isCommute && sIsoDate !== 'DEFAULT' && (
          <ButtonTW variant='blue_outline' onClick={(e) => {
            if (defaultLegList)
              saveLegList(defaultLegList)
          }}>{_lang('Set default', 'デフォルトにする')}</ButtonTW>
        )}
        {' '}
        <ButtonTW variant='blue_outline' onClick={(e) => {
          setModalAction({
            category,
            sIsoDate,
            legList,
            saveLegList,
          })
        }}>{_lang('Edit', '編集')}</ButtonTW>
        {' '}
        <ButtonTW variant={legList ? 'blue_outline' : 'darkgray_outline'} onClick={(e) => {
          if (!legList)
            return
          setCurrentAction({
            action: 'copypaste',
            category,
            cellId: thisCellId,
            legList,
          })
        }}>{_lang('Copy', 'コピー')}</ButtonTW>
        {' '}
        <ButtonTW variant={legList ? 'blue_outline' : 'darkgray_outline'} onClick={(e) => {
          if (!legList)
            return
          setCurrentAction({
            action: 'delete',
            cellId: thisCellId,
          })
        }}>{_lang('Delete', '削除')}</ButtonTW>
      </>
    )
  }


  return (
    <div className={copyingThisCell ? 'beingCopied' : null}>
      <TripLegGrid category={category} legList={legList} />
      {getActionButtons()}
    </div>
  )


}
