import { Firestore, addDoc, collection } from 'firebase/firestore'
import { AgencySimpleType, AgencyType } from 'src/types/objectTypes'
import { UserDetailsType } from 'src/types/types_user'
import { serverTimestampAsDate } from 'src/util/util_firestoredates'
import { luhnCodePoints } from 'src/util/util_luhn'
import { stringCompare } from 'src/util/util_misc'



export async function addNewAgency(db: Firestore, userDetails: UserDetailsType, currentSimpleAgencyList: AgencySimpleType[], newAgencyName: string, twoCharacterCode: string) {

  const newAgency: Omit<AgencyType, 'id' | 'requestListLength'> = {
    name: newAgencyName,
    _isDeleted: false,
    status: '',
    officialName: '',
    country: '',
    requestList: [],
    twoCharacterCode: twoCharacterCode || '',
    dateCreated: serverTimestampAsDate(),
    userCreatedUid: userDetails.id,
    userCreatedName: userDetails.displayNameEn,
    userCreatedEmail: userDetails.email,
    dateModified: serverTimestampAsDate(),
    userModifiedUid: userDetails.id,
    userModifiedName: userDetails.displayNameEn,
    userModifiedEmail: userDetails.email,
  }

  const addedAgency = await addDoc(collection(db, 'agencies'), newAgency)

  console.log('Agency entry saved', addedAgency.id)

  const addedAgencySimple: AgencySimpleType = {
    id: addedAgency.id,
    name: newAgencyName,
    twoCharacterCode: twoCharacterCode || '',
  }

  const newSimpleAgencyList = [...currentSimpleAgencyList, addedAgencySimple].sort((a, b) => stringCompare(a.name, b.name))

  // _cachedlists/cachedlistAgencies will be updated by cloud function

  return { newSimpleAgencyList, addedAgencySimple }
}

export function normalizeForLuhn(str: string) {
  return str.toUpperCase().replace(/O/g, '0').replace(/I/g, '1')
}

export function validateTwoCharacterCode(str: string) {
  // help the user out by trying to convert the string to use only Luhn characters:
  str = normalizeForLuhn(str)

  if (str.length !== 2) {
    return { str, err: 'must be 2 characters long' }
  }

  if (!luhnCodePoints.includes(str[0])) {
    return { str, err: 'first char is not valid' }
  }

  if (!luhnCodePoints.includes(str[1])) {
    return { str, err: 'second char is not valid' }
  }

  if (str[0] === '9') {
    return { str, err: 'First character cannot be 9' } // 9 is reserved for special cases, e.g. 98 means an agency does not have any two character code assigned
  }


  // does not check for dupes here!

  return { str, err: null }
}
