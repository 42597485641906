import { DocumentSnapshot, QuerySnapshot, collection, doc, onSnapshot, or, query, where } from 'firebase/firestore';
import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Dropdown, DropdownButton } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { ColumnFilterButton } from 'src/components/ColumnFilters/ColumnFilterButton';
import { ColumnFilterPopupSimpleAmount } from 'src/components/ColumnFilters/ColumnFilterPopup_SimpleAmount';
import { ColumnFilterPopupSimpleDate } from 'src/components/ColumnFilters/ColumnFilterPopup_SimpleDate';
import { ColumnFilterPopupString } from 'src/components/ColumnFilters/ColumnFilterPopup_String';
import { convertDateListToThreeLevelCache, getSimpleAmountFilterFromParam, getStringFilterFromParam } from 'src/components/ColumnFilters/util_filters';
import { SortCaret } from 'src/components/ColumnSorter/SortCaret';
import { useColumnSorter } from 'src/components/ColumnSorter/useColumnSorter';
import { RequestCodeLinkToAggregator } from 'src/components/ContextMenus/RequestCodeLinkToAggregator';
import { ModalPopup } from 'src/components/Modal/ModalPopup';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { GeneralExpenseStatusPill } from 'src/components/StatusPill/StatusPill';
import { useAutosaveDocumentInList } from 'src/hooks/autosave/util_autosave';
import { useAppContext } from 'src/hooks/useAppContext';
import { ColumnFilterRequestCodeType, ColumnFilterSimpleAmountType, ColumnFilterSimpleDateType, ColumnFilterStringType, ThreeLevelDateTree } from 'src/types/types_columnfilters';
import { ExpensePaymentType } from 'src/types/types_expensesheet';
import { GeneralExpenseType } from 'src/types/types_generalexpense';
import { UserSimpleType } from 'src/types/types_user';
import { getCurrentMonth, getSalaryPaymentDateJst, getTodayJST } from 'src/util/datetools';
import { userrole_canAddInvoice, userrole_canEditFreee, userrole_canMarkPaid, userrole_isAdmin } from 'src/util/user_roles';
import { convertExpensePaymentDates, convertGeneralExpenseDates } from 'src/util/util_firestoredates';
import { formatNum } from 'src/util/util_formatnum';
import { stringCompare } from 'src/util/util_misc';
import { getMonthList } from 'src/util/util_monthlist';
import { ModalActionPaymentDbPropsType, ModalActionType, ModalPopupMakeExpensePayment } from '../Expenses/ExpenseAccounting/ModalPopupMakeExpensePayment/ModalPopupMakeExpensePayment';
import { paymentFlowShortLabel, paymentTypeLabel } from '../Expenses/ExpenseAccounting/ModalPopupMakeExpensePayment/util_makeexpensepayment';
import { FreeeInputCell } from '../Invoices/FreeeInputCell';
import { FreeePadlock } from '../Invoices/FreeePadlock';
import { ExpenseDetailsTable } from './EditGeneralExpense/PageComponents/ExpenseDetailsTable';
import { GeneralExpenseReceipts } from './EditGeneralExpense/PageComponents/GeneralExpenseReceipts';
import { addMetadataModifiedGeneralExpense } from './EditGeneralExpense/util_generalexpense';
import './generalexpenseslist.css';


type GeneralExpenseFieldType =
  'expenseDate'
  | 'requestCode'
  | 'paxName'
  | 'totalAmount'

export function GeneralExpensesList() {

  const { userDetails, db, setDbError, cloudFunctions, _lang } = useAppContext()

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const selectedMonth = searchParams.get('month')

  const isAdmin = userrole_isAdmin(userDetails.roles)
  const isPaymentIssuer = userrole_canMarkPaid(userDetails.roles)
  const isOfficeStaff = userrole_canAddInvoice(userDetails.roles)
  const canViewAllExpenses = isOfficeStaff || userrole_canEditFreee(userDetails.roles)

  const [modalAction, setModalAction] = useState<ModalActionType>(null)
  const [modalMarkDone, setModalMarkDone] = useState<{ generalExpenseObj: GeneralExpenseType }>()

  const [selectedEmployee, setSelectedEmployee] = useState<UserSimpleType>() // only relevant for guides (non-office staff)
  const [expenseList, setExpenseList] = useState<GeneralExpenseType[]>()

  const [viewStatusDRAFT, setViewStatusDRAFT] = useState(true)
  const [viewStatusSUBMITTED, setViewStatusSUBMITTED] = useState(true)
  const [viewStatusPAID, setViewStatusPAID] = useState(true)

  const [viewFlowEmployee, setViewFlowEmployee] = useState(true)
  const [viewFlowCC, setViewFlowCC] = useState(true)
  const [viewFlowDirect, setViewFlowDirect] = useState(true)

  const [expandAllEntries, setExpandAllEntries] = useState(false)

  const [expandedEntries, setExpandedEntries] = useState([])

  const [shownPopup, setShownPopup] = useState<string | null>(null)

  const [freeeUnlocked, setFreeeUnlocked] = useState(false)
  const [editedCell, setEditedCell] = useState<string | null>(null)

  const userBasic = useMemo(() => {
    return {
      id: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    }
  }, [userDetails])


  // initialize with current user
  useEffect(() => {
    setSelectedEmployee(userBasic)
  }, [userBasic])



  const [donePayments, setDonePayments] = useState<Map<string, ExpensePaymentType>>()
  useEffect(() => {

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const dict = new Map<string, ExpensePaymentType>()
      snapshot.docs.forEach((doc) => {
        const item = { ...doc.data(), id: doc.id } as ExpensePaymentType

        convertExpensePaymentDates(item)

        if (dict.has(item.generalExpenseId)) {
          setDbError(`duplicate payment with generalExpenseId=${item.generalExpenseId}  id1=${dict.get(item.generalExpenseId).id}  id2=${item.id}`)
        }

        dict.set(item.generalExpenseId, item)
      })

      setDonePayments(dict)
    }

    const onlyOwnExpensePayments = canViewAllExpenses ? null : where('userPaymentToUid', '==', userDetails.id) // necessary for permissions if user is a Guide
    const q = query(collection(db, 'expensepayments'),
      onlyOwnExpensePayments,
      where('_isDeleted', '==', false),
      where('paymentFor', '==', 'GENERAL_EXPENSE')
    );
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting expense payments list (general expenses)', err));

    return unsubscribe

  }, [db, setDbError, canViewAllExpenses, userDetails.id])

  const [sortCol, sortDir, setSortSetting, sortFunc] = useColumnSorter(['expenseDate', -1]) // [colName, (-1|1)] where (1)=asc (-1)=desc



  const [monthListItems, monthListCodes] = useMemo(() => {
    const pastMonths = getMonthList(2023, 11)
    const listCodes = ['ALL', ...pastMonths]
    const pastMonthsLabels = pastMonths.map((month) => `${month}`)
    const listLabels = ['All dates', ...pastMonthsLabels]
    const listItems = listCodes.map((code, index) => { return { code, label: listLabels[index] } })
    return [listItems, listCodes]
  }, [])



  // <<< START FILTER RELATED CODE >>>

  const [filterPopupColumn, setFilterPopupColumn] = useState<GeneralExpenseFieldType>()

  const [filterCacheExpenseDates, setFilterCacheExpenseDates] = useState<ThreeLevelDateTree>() // year => month => days[]
  const [filterCacheTotalAmounts, setFilterCacheTotalAmounts] = useState<number[]>()
  const [filterCacheRequestCodes, setFilterCacheRequestCodes] = useState<string[]>()
  const [filterCachePaxNames, setFilterCachePaxNames] = useState<string[]>()

  useEffect(() => {

    const processSnapshot = function (snapshot: DocumentSnapshot) {
      const cache = snapshot.data()?.filterCache

      // expense dates
      const cache_expenseDates = convertDateListToThreeLevelCache(Object.keys(cache.expenseDates))
      setFilterCacheExpenseDates(cache_expenseDates)

      // pax names
      const cache_paxnames = [...Object.keys(cache.paxNames)]
      cache_paxnames.sort(stringCompare)
      setFilterCachePaxNames(cache_paxnames)

      // amounts
      const amountList = Object.keys(cache.expenseAmounts).map((x) => Number(x))
      amountList.sort((a, b) => a - b)
      setFilterCacheTotalAmounts(amountList)

      // request codes
      const requestCodeList = [...Object.keys(cache.requestCodes)]
      requestCodeList.sort(stringCompare)
      setFilterCacheRequestCodes(requestCodeList)
    }

    const unsubscribe = onSnapshot(doc(db, '_cachedlists', 'filterCacheGeneralExpenses'), processSnapshot,
      (err) => setDbError('Getting _cachedlists/filterCacheGeneralExpenses', err)
    );

    return unsubscribe
  }, [db, setDbError])



  const paramExpenseDate = searchParams.get('expenseDate')
  const paramRequestCode = searchParams.get('requestCode')
  const paramPaxName = searchParams.get('paxName')
  const paramExpenseAmount = searchParams.get('expenseAmount')

  const [appliedFilterExpenseDate, setAppliedFilterExpenseDate] = useState<ColumnFilterSimpleDateType | null>(null)
  const [appliedFilterRequestCode, setAppliedFilterRequestCode] = useState<ColumnFilterRequestCodeType | null>(null)
  const [appliedFilterExpenseAmount, setAppliedFilterExpenseAmount] = useState<ColumnFilterSimpleAmountType | null>(null)
  const [appliedFilterPaxName, setAppliedFilterPaxName] = useState<ColumnFilterStringType | null>(null)



  // convert the 'paymentDate' search parameter to a ColumnFilterDateType object
  useEffect(() => {
    setAppliedFilterExpenseDate(getStringFilterFromParam(paramExpenseDate))
  }, [paramExpenseDate])


  // convert the 'expenseAmount' search parameter to a ColumnFilterAmountType object
  useEffect(() => {
    setAppliedFilterExpenseAmount(getSimpleAmountFilterFromParam(paramExpenseAmount))
  }, [paramExpenseAmount])


  // convert the 'requestCode' search parameter to a ColumnFilterRequestCodeType object
  useEffect(() => {
    setAppliedFilterRequestCode(getStringFilterFromParam(paramRequestCode))
  }, [paramRequestCode])


  // convert the 'paxName' search parameter to a ColumnFilterStringType object
  useEffect(() => {
    setAppliedFilterPaxName(getStringFilterFromParam(paramPaxName))
  }, [paramPaxName])

  // <<< /END FILTER RELATED CODE >>>


  useEffect(() => {

    if (!selectedEmployee)
      // still loading
      return

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const list: GeneralExpenseType[] = []
      snapshot.docs.forEach((doc) => {
        const expense = { ...doc.data(), id: doc.id } as GeneralExpenseType
        convertGeneralExpenseDates(expense)
        list.push(expense)
      })
      setExpenseList(list)
    }

    let q = query(collection(db, 'generalexpenses'), where('_isDeleted', '==', false))
    // if (isAdmin) {
    //   // @ts-expect-error Firestore
    //   queryConstraint = where('_isDeleted', '==', false)
    // } else

    //   now, office staff can see ALL general expenses
    //   NOTE: below OR with both a variable key (collaborators) and a '!=' condition requires impossible index in firestore (index with variable key)
    // or(
    //   // where('userOwner.id', '==', selectedEmployee.id),
    //   // where('userReimbursed.id', '==', selectedEmployee.id),
    //   // where(`usersCollaborators.${selectedEmployee.id}.id`, '==', selectedEmployee.id),
    //   where('requestCode', '!=', '')
    // )

    if (canViewAllExpenses) {
      if (paramRequestCode && appliedFilterRequestCode) {
        const listRequestCodes: string[] = [...appliedFilterRequestCode.keys()]
        q = query(q, where('requestCode', 'in', listRequestCodes))
      }
      if (paramPaxName && appliedFilterPaxName) {
        const listPaxNames: string[] = [...appliedFilterPaxName.keys()]
        q = query(q, where('paxName', 'in', listPaxNames))
      }
      if (paramExpenseAmount && appliedFilterExpenseAmount) {
        q = query(q, where('totalAmount', 'in', [...appliedFilterExpenseAmount.keys()].map((sAmount) => Number(sAmount))))
      }
      if (paramExpenseDate && appliedFilterExpenseDate) {
        q = query(q, where('expenseDate', 'in', [...appliedFilterExpenseDate.keys()]))
      }

    } else {
      // Non-office staff (i.e. tour guides) cannot use filters.
      // Reasons:
      //   1) avoid complicating the query (with filtering for column filters as well as filtering for users)
      //   2) avoir revealing lists of pax names, lists of amounts, etc. in the filter dropdowns

      q = query(q, or(
        where('userOwner.id', '==', selectedEmployee.id),
        where('userReimbursed.id', '==', selectedEmployee.id),
        where(`usersCollaborators.${selectedEmployee.id}.id`, '==', selectedEmployee.id),
      )
      )
    }

    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting generalexpenses list', err));

    return unsubscribe

  }, [
    db, setDbError, selectedEmployee, canViewAllExpenses,
    paramRequestCode, appliedFilterRequestCode,
    paramPaxName, appliedFilterPaxName,
    paramExpenseAmount, appliedFilterExpenseAmount,
    paramExpenseDate, appliedFilterExpenseDate,
  ])


  const autosaveNewStep = useAutosaveDocumentInList('generalexpenses', addMetadataModifiedGeneralExpense)


  // *** all hooks above this line


  const loadingSpinner = getLoadingSpinnerOrNull([
    ['general expenses', expenseList],
    ['expense payments', donePayments],
  ])
  if (!expenseList || !donePayments)
    return loadingSpinner

  const expenseListFiltered = expenseList.filter((expense) => {

    if (!viewStatusDRAFT && expense.status === 'DRAFT')
      return false
    if (!viewStatusSUBMITTED && expense.status === 'SUBMITTED')
      return false
    if (!viewStatusPAID && (expense.status === 'PAID' || expense.status === 'DONE'))
      return false

    if (!viewFlowEmployee && expense.paymentFlow === 'VIA_EMPLOYEE')
      return false
    if (!viewFlowCC && (expense.paymentFlow === 'CEO_CREDITCARD' || expense.paymentFlow === 'COMPANY_CREDITCARD'))
      return false
    if (!viewFlowDirect && expense.paymentFlow === 'DIRECT')
      return false

    if (selectedMonth) {
      const expenseMonth = expense.expenseDate.substring(0, 7)
      if (expenseMonth !== selectedMonth)
        return false
    }
    return true
  })

  expenseListFiltered.sort(sortFunc)

  return (
    <div className='container-xl'>
      <Helmet><title>General Expenses</title></Helmet>
      <h2 className='my-3'>{_lang('General Expenses', '一般経費')}</h2>


      <div className='my-3'>
        <ButtonTW textSize='md' to='new'>Create New</ButtonTW>
      </div>

      <div className='generalExpenseToolbar'>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.25em',
        }}>
          <div className='generalExpenseFilterByStatus'>
            <div>
              Filter by status:
            </div>
            <CheckboxSwitch id='chkViewStatusDRAFT' label='DRAFT' checked={viewStatusDRAFT} onChange={(e) => {
              setViewStatusDRAFT(e.target.checked)
            }} />
            <CheckboxSwitch id='chkViewStatusSUBMITTED' label='SUBMITTED' checked={viewStatusSUBMITTED} onChange={(e) => {
              setViewStatusSUBMITTED(e.target.checked)
            }} />
            <CheckboxSwitch id='chkViewStatusPAID' label='PAID/DONE' checked={viewStatusPAID} onChange={(e) => {
              setViewStatusPAID(e.target.checked)
            }} />
          </div>

          <div className='generalExpenseFilterByStatus'>
            <div>
              Filter by payment flow:
            </div>
            <CheckboxSwitch id='chkViewFlowEmployee' label='Employee' checked={viewFlowEmployee} onChange={(e) => {
              setViewFlowEmployee(e.target.checked)
            }} />
            <CheckboxSwitch id='chkViewFlowCC' label='Credit Card' checked={viewFlowCC} onChange={(e) => {
              setViewFlowCC(e.target.checked)
            }} />
            <CheckboxSwitch id='chkViewFlowDirect' label='Direct' checked={viewFlowDirect} onChange={(e) => {
              setViewFlowDirect(e.target.checked)
            }} />
          </div>

        </div>

        <DropdownButton id='dropdown-month'
          title={selectedMonth ? monthListItems.find((item) => item.code === selectedMonth).label : monthListItems[0].label}>
          {monthListItems.map(({ code, label }) => {
            return (
              <Dropdown.Item key={code} onClick={() => {
                navigate(`/general-expenses/${code !== monthListItems[0].code ? `?month=${code}` : ''}`)
              }}>{label}</Dropdown.Item>
            )
          })}
        </DropdownButton>

        <CheckboxSwitch id='chkShowAllDetails' label='Expand all details' checked={expandAllEntries} onChange={(e) => {
          setExpandAllEntries(e.target.checked)
        }} />


        <div>
          {expenseListFiltered.length} entries
        </div>

      </div>


      <table className='table'>
        <thead>
          <tr>
            <th></th>
            <th className='text-nowrap'>
              <SortCaret colName='expenseDate' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} />
              {' '}
              Date
              {' '}
              {canViewAllExpenses && (
                <>
                  <ColumnFilterButton
                    isPopupOpen={filterPopupColumn === 'expenseDate'}
                    isFilterActive={!!appliedFilterExpenseDate}
                    openPopup={() => setFilterPopupColumn('expenseDate')}
                    closePopup={() => setFilterPopupColumn(null)}
                    columnName='generalexpense/expenseDate'
                  />
                  <ColumnFilterPopupSimpleDate
                    urlParameterName='expenseDate'
                    allDates={filterCacheExpenseDates}
                    appliedFilter={appliedFilterExpenseDate}
                    popupIsOpen={filterPopupColumn === 'expenseDate'}
                    closePopup={() => setFilterPopupColumn(null)}
                    sortCaretProps={{
                      colName: 'expenseDate',
                      sortCol,
                      sortDir,
                      setSortSetting,
                    }}
                  />
                </>
              )}
            </th>
            <th className='text-nowrap'>
              <SortCaret colName='requestCode' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} />
              {' '}
              Request code
              {' '}
              {canViewAllExpenses && (
                <>
                  <ColumnFilterButton
                    isPopupOpen={filterPopupColumn === 'requestCode'}
                    isFilterActive={!!appliedFilterRequestCode}
                    openPopup={() => setFilterPopupColumn('requestCode')}
                    closePopup={() => setFilterPopupColumn(null)}
                    columnName='generalexpense/requestCode'
                  />
                  <ColumnFilterPopupString
                    urlParameterName='requestCode'
                    allValues={filterCacheRequestCodes}
                    appliedFilter={appliedFilterRequestCode}
                    popupIsOpen={filterPopupColumn === 'requestCode'}
                    closePopup={() => setFilterPopupColumn(null)}
                    sortCaretProps={{
                      colName: 'requestCode',
                      sortCol,
                      sortDir,
                      setSortSetting,
                    }}
                  />
                </>
              )}
            </th>
            <th className='text-nowrap'>
              <SortCaret colName='paxName' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} />
              {' '}
              Pax name
              {' '}
              {canViewAllExpenses && (
                <>
                  <ColumnFilterButton
                    isPopupOpen={filterPopupColumn === 'paxName'}
                    isFilterActive={!!appliedFilterPaxName}
                    openPopup={() => setFilterPopupColumn('paxName')}
                    closePopup={() => setFilterPopupColumn(null)}
                    columnName='generalexpense/paxName'
                  />
                  <ColumnFilterPopupString
                    urlParameterName='paxName'
                    allValues={filterCachePaxNames}
                    appliedFilter={appliedFilterPaxName}
                    popupIsOpen={filterPopupColumn === 'paxName'}
                    closePopup={() => setFilterPopupColumn(null)}
                    sortCaretProps={{
                      colName: 'paxName',
                      sortCol,
                      sortDir,
                      setSortSetting,
                    }}
                  />
                </>
              )}
            </th>
            <th className='text-nowrap'><SortCaret colName='userOwner.name' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Users</th>
            <th className='text-nowrap'><SortCaret colName='description' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Description</th>
            <th className='text-nowrap'>
              <SortCaret colName='totalAmount' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} />
              {' '}
              Amount
              {' '}
              {canViewAllExpenses && (
                <>
                  <ColumnFilterButton
                    isPopupOpen={filterPopupColumn === 'totalAmount'}
                    isFilterActive={!!appliedFilterExpenseAmount}
                    openPopup={() => setFilterPopupColumn('totalAmount')}
                    closePopup={() => setFilterPopupColumn(null)}
                    columnName='generalexpense/totalAmount'
                  />
                  <ColumnFilterPopupSimpleAmount
                    urlParameterName='expenseAmount'
                    allAmounts={filterCacheTotalAmounts}
                    appliedFilter={appliedFilterExpenseAmount}
                    popupIsOpen={filterPopupColumn === 'totalAmount'}
                    closePopup={() => setFilterPopupColumn(null)}
                    sortCaretProps={{
                      colName: 'totalAmount',
                      sortCol,
                      sortDir,
                      setSortSetting,
                    }}
                  />
                </>
              )}
            </th>
            <th className='text-nowrapx'><SortCaret colName='paymentFlow' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Payment flow</th>
            <th className='text-nowrapx'><SortCaret colName='paymentType' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Reimbursement<br />method</th>
            <th className='text-nowrapx'>Employee to reimburse</th>
            <th className='text-nowrapx'>Receipts</th>
            <th className='text-nowrap'><SortCaret colName='status' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Status</th>
            {userrole_canEditFreee(userDetails.roles) && (
              <th>
                {_lang('Freee', 'Freee')}
                <FreeePadlock
                  freeeUnlocked={freeeUnlocked}
                  setFreeeUnlocked={setFreeeUnlocked}
                />
              </th>
            )}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {expenseListFiltered.map((generalExpense) => {

            const isExpanded = expandAllEntries || expandedEntries.includes(generalExpense.id)

            const dateUTC = new Date(generalExpense.expenseDate)
            const jpDate = generalExpense.expenseDate.replaceAll('-', '/')


            const paymentObj = donePayments.get(generalExpense.id)
            const alreadyPaid = !!paymentObj

            const buttonClickMarkDone = () => {
              setModalMarkDone({ generalExpenseObj: generalExpense })
            }

            const buttonClickMarkPaid = () => {

              // determine payment date
              let paymentDate // JST
              const paymentDbProps: ModalActionPaymentDbPropsType = {
                generalExpenseId: generalExpense.id,
              }

              if (generalExpense.paymentType === 'WITH_SALARY') {
                const sMonth = getCurrentMonth()
                paymentDbProps.salaryMonth = sMonth
                paymentDate = getSalaryPaymentDateJst(sMonth)
              } else {
                // CASH, ADHOC_BANK_TRANSFER
                paymentDate = getTodayJST()
              }

              const modal: ModalActionType = {
                action: alreadyPaid ? 'show payment' : 'make payment',
                showModal: true,

                // general objects that describe the payment:
                paymentRecipient: generalExpense.userReimbursed,
                paymentFor: 'GENERAL_EXPENSE',
                paymentType: generalExpense.paymentType,

                paymentDbProps,

                ...(alreadyPaid ? ({
                  // actual payment details:
                  paymentSourceAccount: paymentObj.paymentSourceAccount,
                  amount: paymentObj.amount,
                  paymentDate: paymentObj.paymentDate,
                  paymentId: paymentObj.id,
                }) : ({
                  // default values for the form fields that the user will input:
                  paymentSourceAccount: '',
                  amount: generalExpense.totalAmount,
                  paymentDate,
                  paymentId: '',
                  callbackOnSuccess: () => {
                    const updateObj: Partial<GeneralExpenseType> = {
                      status: 'PAID',
                    }

                    autosaveNewStep('Status: PAID', generalExpense, updateObj, 'UNDOWALL') // NOT undoable
                      .catch((err) => setDbError(`Failed to set general expense status to PAID ${generalExpense.id}`, err))
                  }
                })),
              }
              setModalAction(modal)

            }

            const currentUserCanEdit =
              generalExpense.userOwner.id === userDetails.id
              || generalExpense.userReimbursed?.id === userDetails.id // userReimbursed can be null
              || generalExpense.usersCollaborators[userDetails.id]


            // make a list of all *unique* users involved in this expense
            const userList = [generalExpense.userOwner]
            if (generalExpense.paymentFlow === 'VIA_EMPLOYEE' && generalExpense.userReimbursed.id !== generalExpense.userOwner.id)
              userList.push(generalExpense.userReimbursed)
            for (const collaborator of Object.values(generalExpense.usersCollaborators)) {
              let isDuplicate = false
              for (let i = 0; i < userList.length; i++) {
                if (userList[i].id === collaborator.id) {
                  isDuplicate = true
                  break
                }
              }
              if (!isDuplicate)
                userList.push(collaborator)
            }

            const userNames = userList.map((user) => user.name).join(', ')

            return (
              <React.Fragment key={generalExpense.id}>
                <tr className={`entry paymentFlow_${generalExpense.paymentFlow}`}>
                  <td>
                    <div style={{ cursor: 'pointer' }} onClick={(e) => {
                      if (isExpanded) {
                        setExpandedEntries((expandedEntries) => expandedEntries.filter((x) => x !== generalExpense.id))
                        setExpandAllEntries(false)
                      } else {
                        setExpandedEntries((expandedEntries) => [...expandedEntries, generalExpense.id])
                      }
                    }}>
                      <i className={`bi ${isExpanded ? 'bi-chevron-down' : 'bi-chevron-right'}`}></i>
                    </div>
                  </td>
                  <td>
                    {jpDate}
                  </td>
                  <td>

                    <RequestCodeLinkToAggregator
                      requestCode={generalExpense.requestCode}
                      linkId={generalExpense.id}
                      shownPopup={shownPopup}
                      setShownPopup={setShownPopup}
                    />

                  </td>
                  <td>
                    {generalExpense.paxName}
                  </td>
                  <td>
                    {userNames}
                  </td>
                  <td>
                    {generalExpense.description}
                  </td>
                  <td className='numeric'>
                    {formatNum(generalExpense.totalAmount)}
                  </td>
                  <td>
                    {paymentFlowShortLabel(generalExpense.paymentFlow, generalExpense.companyCreditCard)}
                  </td>
                  <td>
                    {paymentTypeLabel(generalExpense.paymentType)}
                  </td>
                  <td>
                    {generalExpense.paymentFlow === 'VIA_EMPLOYEE' && (
                      // only show user reimbursed if flow is VIA_EMPLOYEE
                      generalExpense.userReimbursed?.name
                    )}
                  </td>
                  <td>
                    {generalExpense.itemList.map((item) => {
                      return (
                        <GeneralExpenseReceipts
                          key={item.id}
                          isReadOnly={true}
                          isExpandable={false}
                          row={item}
                          tableid={`receipts_${generalExpense.id}_${item.id}`}
                          callbackUploadSuccess={null}
                          handleDeleteFile={null}
                          generalExpenseIdForLog={generalExpense.id}
                        />
                      )
                    })}
                  </td>
                  <td>
                    <span>
                      <GeneralExpenseStatusPill status={generalExpense.status} />
                      {(alreadyPaid !== (generalExpense.status === 'PAID')) && (
                        <div>
                          ERROR: inconsistent PAID status
                        </div>
                      )}
                    </span>
                  </td>
                  {userrole_canEditFreee(userDetails.roles) && (
                    <td style={{ color: freeeUnlocked ? '#4488ff' : null }}>
                      <FreeeInputCell
                        freeeUnlocked={freeeUnlocked}
                        checked={generalExpense.freeeStatus?.checked}
                        memorandum={generalExpense.freeeStatus?.memorandum}
                        callbackUpdateDoc={async (freeeStatus) => {
                          const updateObj: Partial<GeneralExpenseType> = {
                            freeeStatus,
                          }

                          autosaveNewStep('Set Freee status', generalExpense, updateObj, 'UNDOWALL') // NOT undoable
                            .catch((err) => setDbError(`Failed to set Freee status ${generalExpense.id}`, err))
                        }}
                        editedCell={editedCell}
                        setEditedCell={setEditedCell}
                        cellid={generalExpense.id}
                      />
                    </td>
                  )}
                  <td className='text-nowrap'>
                    {(isPaymentIssuer || (
                      // isOfficeStaff &&
                      currentUserCanEdit
                      //&& !alreadyPaid
                    )) && (
                        <ButtonTW variant='blue_outline' to={`edit/${generalExpense.id}`}>Edit</ButtonTW>
                      )}
                    {' '}
                    {(isPaymentIssuer || (
                      isOfficeStaff
                      && currentUserCanEdit
                      && !alreadyPaid
                    )) && (
                        <ButtonTW variant='blue_outline' onClick={(e) => {
                          if (generalExpense.status !== 'DRAFT' && generalExpense.status !== 'SUBMITTED') {
                            window.alert(`Expense is already paid, so it cannot be deleted.\nID: ${generalExpense.id}`)
                            return
                          }

                          if (!window.confirm('Are you sure you want to delete this expense?'))
                            return

                          const updateObj: Partial<GeneralExpenseType> = {
                            _isDeleted: true,
                          }

                          autosaveNewStep('DELETE', generalExpense, updateObj, 'UNDOWALL') // NOT undoable
                            .catch((err) => setDbError(`Failed to delete general expense ${generalExpense.id}`, err))
                        }}>Delete</ButtonTW>
                      )}
                    {' '}
                    {isPaymentIssuer && (
                      alreadyPaid ? (
                        <ButtonTW variant='darkgray_outline' onClick={buttonClickMarkPaid}>SHOW</ButtonTW>
                      ) : generalExpense.paymentFlow === 'VIA_EMPLOYEE' ? (
                        <ButtonTW variant='bsgreen_outline' onClick={buttonClickMarkPaid}>Mark PAID</ButtonTW>
                      ) : generalExpense.status === 'DONE' ? (
                        <></>
                      ) : (
                        <ButtonTW variant='bsgreen_outline' onClick={buttonClickMarkDone}>Mark DONE</ButtonTW>
                      )

                    )}
                  </td>
                </tr>
                {isExpanded && (
                  <tr className='entryDetails'>
                    <td colSpan={12}>
                      <div>
                        <ExpenseDetailsTable
                          generalExpense={generalExpense}
                          autosaveOrSetNewStep={null}
                          isReadOnly={true}
                        />
                        {generalExpense.memorandum && (
                          <div>
                            <div><b>Memo</b></div>
                            <div>{generalExpense.memorandum}</div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )
          })}
        </tbody>
      </table>

      <Alert variant='primary'>
        <div>Note:</div>
        <div>Travel designers can see all General Expenses.</div>
        <div>Guides can see only General Expenses where they are an owner/reimbursed employee/collaborator.</div>
      </Alert>

      <ModalPopupMakeExpensePayment
        modalAction={modalAction}
        setModalAction={setModalAction}
      />

      <ModalPopup
        title='Mark DONE'
        okLabel='OK'
        show={!!modalMarkDone}
        body={
          <div>
            <p>Before marking this as DONE, first confirm that receipt was correctly uploaded.</p>
            <p><b style={{ textTransform: 'uppercase' }}>No payment should be made to employee.</b></p>
          </div>
        }
        callbackClose={() => setModalMarkDone(null)}
        onSubmit={(e, onSuccess) => {
          e.preventDefault()

          const updateObj: Partial<GeneralExpenseType> = {
            status: 'DONE',
          }

          autosaveNewStep('Status: DONE', modalMarkDone.generalExpenseObj, updateObj, 'UNDOWALL') // NOT undoable
            .then(() => {
              console.log('Status set to DONE')
              onSuccess()
            })
            .catch((err) => setDbError(`Failed to mark expense as done ${modalMarkDone.generalExpenseObj.id}`, err))
        }}
      />

    </div>
  )
}
