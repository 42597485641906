import { ReactNode } from 'react';



interface HighlightFieldBasicProps {
  fieldValue: string | number;
  highlightedValue: string;
}

export function HighlightFieldBasic({
  fieldValue,
  highlightedValue,
}: HighlightFieldBasicProps) {

  if (highlightedValue && highlightedValue.includes('<em>')) {

    // check data is consistent and hasn't been updated
    const plainText = highlightedValue.replaceAll('<em>', '').replaceAll('</em>', '')
    if (plainText !== fieldValue) {
      // inconsistent data, either algolia index is out of date, or data was modified in Firestore after the search was performed.
      // we show the most up to date data, but without highlighting
      return fieldValue
    }

    // !! highlightedValue contains <em>highlight</em> blocks, BUT html special chars like & < > are NOT escaped,
    // so using dangerouslySetInnerHTML will render any random <tags> directly as HTML !!

    const arrayHighlighted: ReactNode[] = []

    let indexCurrent = 0
    while (true) {
      const indexEm = highlightedValue.indexOf('<em>', indexCurrent)
      const indexSlashEm = indexEm !== -1 && highlightedValue.indexOf('</em>', indexEm)
      if (indexEm === -1 || indexSlashEm === -1)
        break

      if (indexCurrent < indexEm)
        arrayHighlighted.push(highlightedValue.substring(indexCurrent, indexEm))

      if (indexEm + 4 < indexSlashEm)
        arrayHighlighted.push(<em key={indexEm}>{highlightedValue.substring(indexEm + 4, indexSlashEm)}</em>)

      indexCurrent = indexSlashEm + 5
    }

    if (indexCurrent < highlightedValue.length)
      arrayHighlighted.push(highlightedValue.substring(indexCurrent))

    return <span>{arrayHighlighted}</span>
  } else {
    return <span>{fieldValue}</span>
  }

}
