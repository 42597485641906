import { collection, deleteField, doc, getDoc, getDocs, limit, orderBy, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { requestClassificationListNames } from 'src/appsettings/appsettings';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAppContext } from 'src/hooks/useAppContext';
import { PayeeCategoryEditor } from 'src/pages/Payees/PayeeCrud/PayeeCategoryEditor';
import { PayeeCategoryType } from 'src/types/types_payee';
import { TourRequestType } from 'src/types/types_tourrequest';
import { userrole_isAdmin } from 'src/util/user_roles';
import { getBuildTimeStr } from 'src/util/util_getbuildtime';


export function AdminTools() {

  const { db, userDetails, setDbError, cloudFunctions } = useAppContext()

  if (!userrole_isAdmin(userDetails.roles))
    throw new Error('Unauthorized')

  const [message, setMessage] = useState<string>()


  const [payeeCategories, setPayeeCategories] = useState<PayeeCategoryType[]>()
  useEffect(() => {
    getDoc(doc(db, 'settings', 'payeecategories'))
      .then((catsDoc) => {
        const cats = catsDoc.data().categories as PayeeCategoryType[]
        setPayeeCategories(cats)
      })
      .catch((err) => setDbError('Getting payee categories', err))
  }, [db, setDbError])


  const [requestClassificationListsText, setRequestClassificationListsText] = useState<string>(null)
  useEffect(() => {
    getDoc(doc(db, 'settings', 'requestClassificationLists'))
      .then((doc) => {
        const dbData = doc.data()
        let s = ''
        for (const field of requestClassificationListNames) {
          s += `${field}\n`
          if (dbData) {
            const isTeams = field === 'eightyDaysTeams'
            if (isTeams) {
              for (const group of dbData[field]) {
                s += `- ${group.name}\n`
                for (const subitem of group.items) {
                  s += `--- ${subitem}\n`
                }
              }
            } else {
              for (const choice of dbData[field]) {
                s += `- ${choice}\n`
              }
            }
          } else {
            s += '- [MISSING]\n'
          }
        }
        setRequestClassificationListsText(s.trim())
      })
      .catch((err) => setDbError('Getting requestClassificationLists', err))
  }, [db, setDbError])


  const [countryListText, setCountryListText] = useState<string>(null)
  useEffect(() => {
    getDoc(doc(db, 'settings', 'countryList'))
      .then((doc) => {
        let s = ''
        if (doc.data()) {
          const list = doc.data().list
          for (const country of list) {
            s += `${country}\n`
          }
        }
        setCountryListText(s.trim())
      })
      .catch((err) => setDbError('Getting countryList', err))
  }, [db, setDbError])



  // *** all hooks above ***

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['request classification lists', requestClassificationListsText],
    ['country list', countryListText]
  ])
  if (!requestClassificationListsText || !countryListText)
    return loadingSpinner


  return (
    <div className='container'>
      <Helmet><title>Admin tools</title></Helmet>
      <h2>Admin tools</h2>

      <hr />

      <div>Version: {import.meta.env.VITE_APP_VERSION}</div>
      <div>Build time: {getBuildTimeStr()}</div>

      <hr />

      <div className='alert alert-primary'>{message}</div>

      <hr />

      <h5>Payee Categories</h5>

      <PayeeCategoryEditor
        payeeCategories={payeeCategories}
        setPayeeCategories={setPayeeCategories}
        finishEditing={() => setMessage('Payee categories saved')}
      />

      <hr />

      <h5>Save requestClassificationLists to db</h5>

      <textarea className='form-control' rows={20}
        value={requestClassificationListsText}
        onChange={(e) => {
          setRequestClassificationListsText(e.target.value)
        }}
      ></textarea>

      <ButtonTW textSize='md' onClick={(e) => {
        setMessage('')


        const lines = requestClassificationListsText.trim().split('\n')
        const obj: Record<string, (string | { name: string, items: string[] })[]> = {}
        let currentGroup = null
        let currentSubGroup: { name: string, items: string[] } = null
        for (let line of lines) {
          line = line.trim()
          if (!line)
            continue
          if (line.startsWith('//'))
            continue
          if (!line.startsWith('-')) {
            const groupName = line.trim()
            if (groupName in obj) {
              window.alert(`duplicate name ${groupName}`)
              return
            }
            obj[groupName] = []
            currentGroup = groupName
            continue
          }

          const isTeams = currentGroup === 'eightyDaysTeams'

          if (line.startsWith('- ')) {
            const item = line.substring(1).trim()
            if (!currentGroup) {
              window.alert('No current group')
              return
            }
            if (obj[currentGroup].includes(item)) {
              window.alert(`Duplicate item ${item}`)
              return
            }

            if (isTeams) {
              currentSubGroup = { name: item, items: [] }
              obj[currentGroup].push(currentSubGroup)
            } else {
              obj[currentGroup].push(item)
            }
            continue
          }

          if (isTeams && line.startsWith('--- ')) {
            const subitem = line.substring(4).trim()
            if (!currentSubGroup) {
              window.alert('no subgroup')
              return
            }
            currentSubGroup.items.push(subitem)
            continue
          }

          window.alert(`Invalid line ${line}`)
        }

        console.log('save obj', obj)

        setDoc(doc(db, 'settings', 'requestClassificationLists'), {
          ...obj,
          userModifiedUid: userDetails.id,
          userModifiedName: userDetails.displayNameEn,
          userModifiedEmail: userDetails.email,
          dateModified: serverTimestamp(),
        })
          .then(() => {
            setMessage('success')
          })
          .catch((err) => setDbError('Saving requestClassificationLists', err))

      }}>Save requestClassificationLists to db</ButtonTW>


      <hr />

      <h5>Country list</h5>

      <textarea className='form-control' rows={20}
        value={countryListText}
        onChange={(e) => {
          setCountryListText(e.target.value)
        }}
      ></textarea>

      <ButtonTW textSize='md' onClick={(e) => {
        setMessage('')


        const lines = countryListText.trim().split('\n')
        const list: string[] = []
        for (let line of lines) {
          line = line.trim()
          if (!line)
            continue
          if (list.includes(line)) {
            window.alert(`Duplicate item ${line}`)
            return
          }
          list.push(line)
        }

        console.log(list)

        setDoc(doc(db, 'settings', 'countryList'), {
          list,
          userModifiedUid: userDetails.id,
          userModifiedName: userDetails.displayNameEn,
          userModifiedEmail: userDetails.email,
          dateModified: serverTimestamp(),
        })
          .then(() => {
            setMessage('success')
          })
          .catch((err) => setDbError('Saving countryList', err))

      }}>Save countryList to db</ButtonTW>



      <hr />

      <ButtonTW textSize='md' onClick={(e) => {
        const tourrequests_rebuildIndex = httpsCallable(cloudFunctions, 'tourrequests_rebuildIndex');
        tourrequests_rebuildIndex()
          .then((result) => {
            console.log(result)
          })
      }}>Rebuild Algolia index ‘tourrequests’</ButtonTW>




      <hr />

      <h5>Temporary Admin Tools</h5>

      <div style={{ border: '1px solid #00000033', borderRadius: '1em', padding: '1em', width: '30em', display: 'flex', flexDirection: 'column' }}>

        <ButtonTW textSize='md' onClick={(e) => {

          const payeeCats = new Map()

          getDocs(query(collection(db, 'payees')))
            .then((snapshot) => {
              snapshot.forEach((docu) => {
                payeeCats.set(docu.id, docu.data().categories)
              })

              getDocs(query(collection(db, 'invoices')))
                .then((snapshot) => {
                  snapshot.forEach((docu) => {
                    const invoiceId = docu.id
                    const payeeId = docu.data().payeeId
                    const payeeName = docu.data().payee
                    const cat = payeeCats.get(payeeId)
                    if (!payeeId || !cat)
                      return
                    console.log({ invoiceId, payeeId, payeeName, cat })



                    updateDoc(docu.ref, { payeeCategories: cat })
                      .then(() => {
                        console.log('success')
                      })
                      .catch((err) => setDbError('saving cat to invoice', err))
                  })
                })
                .catch((err) => setDbError('getting invoices', err))
            })
            .catch((err) => setDbError('getting payees', err))

        }}>Store payee category on invoices</ButtonTW>

        <hr />

        <ButtonTW textSize='md' onClick={(e) => {
          // code used one time
          if (false) {
            getDoc(doc(db, 'tourrequests', '8emGU3KDtftKjV9bA4Vk'))
              .then((req) => {
                const salesInfo = req.data().salesInformation
                console.log(salesInfo)
                updateDoc(doc(db, 'tourrequests', 'RWxd8rAntT94yow3hLr7'), { salesInformation: salesInfo })
                  .then(() => {
                    console.log('success')
                  })
              })
          }
        }}>Move sales info number 283 from 8emGU3KDtftKjV9bA4Vk to RWxd8rAntT94yow3hLr7</ButtonTW>

        <hr />

        <ButtonTW textSize='md' onClick={(e) => {
          getDocs(query(collection(db, 'tourrequests'), orderBy('numOfPax', 'desc'), limit(15)))
            .then((snapshot) => {
              let count = 0
              snapshot.docs.forEach((docu) => {
                const numpax = docu.data().numOfPax
                if (typeof numpax === 'string') {
                  console.log(numpax)
                  count++
                }
              })
              console.log('count', count)
            })
        }}>log str numofpax</ButtonTW>

        <hr />

        <ButtonTW textSize='md' onClick={(e) => {
          const q = query(collection(db, 'tourrequests'), where('status', '==', ''))
          getDocs(q)
            .then((snapshot) => {
              console.log(`${snapshot.docs.length} docs`)
              snapshot.docs.forEach((docu) => {
                const status = docu.data().status
                console.log('status', status)
              })
            })
        }}>List status of tour requests</ButtonTW>

        <hr />

        <ButtonTW textSize='md' onClick={(e) => {
          const q = query(collection(db, 'expensesheets'))
          getDocs(q)
            .then((snapshot) => {
              console.log(`${snapshot.docs.length} docs`)
              for (const docu of snapshot.docs) {
                console.log(docu.id)

                if (
                  // || !docu.data().userCreatorUid
                  // || !docu.data().userCreatorEmail
                  // || !docu.data().userCreatorName
                  // || !docu.data().userModifiedUid
                  // || !docu.data().userModifiedEmail
                  // || !docu.data().userModifiedName
                  // || !docu.data().userGuideUid
                  // || !docu.data().userGuideEmail
                  // || !docu.data().userGuideName
                  // ||
                  !docu.data().userDesignerUid
                  || !docu.data().userDesignerEmail
                  || !docu.data().userDesignerName
                ) {
                  console.log('Some user data missing', docu.data())
                } else {

                  const changes = {
                    // userCreator: {
                    //   uid: docu.data().userCreatorUid,
                    //   email: docu.data().userCreatorEmail,
                    //   name: docu.data().userCreatorName,
                    // },
                    // userModified: {
                    //   uid: docu.data().userModifiedUid,
                    //   email: docu.data().userModifiedEmail,
                    //   name: docu.data().userModifiedName,
                    // },
                    // userGuide: {
                    //   uid: docu.data().userGuideUid,
                    //   email: docu.data().userGuideEmail,
                    //   name: docu.data().userGuideName,
                    // },
                    usersDesigners: [
                      {
                        uid: docu.data().userDesignerUid,
                        email: docu.data().userDesignerEmail,
                        name: docu.data().userDesignerName,
                      }
                    ],

                    // userCreatorUid: deleteField(),
                    // userCreatorEmail: deleteField(),
                    // userCreatorName: deleteField(),
                    // userModifiedUid: deleteField(),
                    // userModifiedEmail: deleteField(),
                    // userModifiedName: deleteField(),
                    // userGuideUid: deleteField(),
                    // userGuideEmail: deleteField(),
                    // userGuideName: deleteField(),
                    userDesignerUid: deleteField(),
                    userDesignerEmail: deleteField(),
                    userDesignerName: deleteField(),

                  }
                  console.log('Updating...')

                  updateDoc(docu.ref, changes)
                    .then(() => console.log('Updated successfully', docu.id))

                  //return

                }
              }
            })
        }}>Convert users on expense sheets</ButtonTW>

        <hr />

        <ButtonTW textSize='md' onClick={(e) => {

          //const tableName = 'invoices'
          const tableName = 'expensepayments'

          const step = 0

          getDocs(query(collection(db, tableName)))
            .then((snapshot) => {
              console.log('doc count', snapshot.size)

              for (const doc of snapshot.docs) {
                const data = doc.data()
                // @ts-expect-error Step changed manually
                if (step === 1) {
                  if ('paymentMethod' in data && !('paymentSourceAccount' in data)) {
                    console.log('updating doc...')
                    updateDoc(doc.ref, {
                      paymentSourceAccount: data.paymentMethod,
                    })
                      .then(() => {
                        console.log('success')
                      })
                  }
                }
                // @ts-expect-error Step changed manually
                if (step === 2) {
                  if (('paymentMethod' in data) && ('paymentSourceAccount' in data) && data.paymentMethod === data.paymentSourceAccount) {
                    console.log('updating doc...')
                    updateDoc(doc.ref, {
                      paymentMethod: deleteField(),
                    })
                      .then(() => {
                        console.log('success')
                      })
                  }
                }

              }
            })

        }}>Convert paymentMethod to paymentSourceAccount on invoices/expensepayments</ButtonTW>

        <hr />

        <ButtonTW textSize='md' onClick={(e) => {

          getDocs(query(collection(db, 'expensepayments')))
            .then((snapshot) => {
              console.log('doc count', snapshot.size)

              for (const doc of snapshot.docs) {
                const data = doc.data()

                const hasField1 = 'paymentType' in data ? 1 : 0
                const hasField2 = 'paymentFor' in data ? 1 : 0
                const hasField = hasField1 + hasField2

                if (hasField === 2) {
                  console.log('skip')
                  continue
                }

                if (hasField !== 0)
                  throw new Error('hasField NOT ZERO!!')

                if (data.isAdvance !== true && data.isAdvance !== false)
                  throw new Error('isAdvance NOT TRUE/FALSE!!')

                console.log('updating doc...')

                updateDoc(doc.ref, {
                  paymentFor: 'GUIDING_EXPENSE',
                  paymentType: data.isAdvance ? 'ADHOC_BANK_TRANSFER' : 'WITH_SALARY',
                })
                  .then(() => {
                    console.log('success')
                  })

              }
            })

        }}>Add paymentFor and paymentType fields</ButtonTW>

        <hr />

        <ButtonTW textSize='md' onClick={(e) => {

          getDocs(query(collection(db, 'invoices')))
            .then((snapshot) => {
              console.log('doc count', snapshot.size)

              for (const doc of snapshot.docs) {
                const data = doc.data()
                if (!('tripcode' in data)) {
                  console.error(`TRIP CODE MISSING ${doc.id}`)
                  continue
                }

                if (data.tripcode === data.tripcode.trim()) {
                  console.log('ok')
                  continue
                }
                console.log('bad tripcode', data.tripcode)

                console.log('updating doc...')
                updateDoc(doc.ref, {
                  tripcode: data.tripcode.trim(),
                })
                  .then(() => {
                    console.log('success')
                  })
              }
            })

        }}>Trim tripcode on invoices</ButtonTW>

        <hr />

        <ButtonTW textSize='md' onClick={(e) => {

          getDocs(query(collection(db, 'tourrequests'),
            //where('_isDeleted', '==', false), // we include delete ones as requestNum max for new requests includes deleted ones
            orderBy('dateCreated', 'desc')))
            .then((snapshot) => {
              const tourrequests = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as TourRequestType))

              for (const tourrequest of tourrequests) {
                const num = tourrequest.requestNumber
                if (!(typeof num === 'number')) {
                  console.log('requestNumber is not a number', tourrequest.id)
                  continue
                }

                if (num < 1000) {
                  // no problem
                  // check for dupes anyway
                  const dupes = tourrequests.filter((t) => t.requestNumber === num)
                  if (dupes.length >= 2) {
                    console.log('requestNumber correct but duplicate', num)
                  }
                  continue
                }

                if (tourrequest.requestCode.length !== 10) {
                  // problem with requestCode, don't touch requestNumber
                  console.log('invalid requestCode', tourrequest.requestCode)
                  continue
                }

                const sNum = `${num}`
                if (sNum.length === 9 && (
                  sNum.startsWith('230900')
                  || sNum.startsWith('230800')
                  || sNum.startsWith('230600')
                )) {
                  const correctNum = parseInt(sNum.substring(6))
                  // check not a duplicate
                  const dupes = tourrequests.filter((t) => t.requestNumber === correctNum)
                  if (dupes.length > 0) {
                    console.log(`duplicate requestNumber: ${num} should be ${correctNum} (${tourrequest.id} _isDeleted=${tourrequest._isDeleted}) but ${correctNum} found on ${dupes.map((d) => `[${d.id} isdel=${d._isDeleted}]`).join(' ; ')}`)
                    //continue
                    // fix it anyway
                  }

                  // fix this one
                  console.log(`Fix requestNumber: [${sNum}]->[${correctNum}]`)
                  updateDoc(doc(db, 'tourrequests', tourrequest.id), {
                    requestNumber_old: num,
                    requestNumber: correctNum,
                  })
                    .then(() => {
                      console.log('success')
                    })
                } else {
                  // some other problem?
                  console.log('check requestNumber', num)
                  continue
                }
              }
            })

        }}>Fix requestNumbers</ButtonTW>

        <hr />

        <ButtonTW textSize='md' onClick={(e) => {
          const convertPdf = httpsCallable(cloudFunctions, 'convertPdf');

          convertPdf()
            .then((result) => {
              console.log(result)
            })

        }}>convertPdf</ButtonTW>

        <hr />

        <ButtonTW textSize='md' onClick={(e) => {
          const convertPdf = httpsCallable(cloudFunctions, 'testGhostScript');

          convertPdf()
            .then((result) => {
              console.log('Finished running')
              console.log(result)
            })

        }}>testGhostScript</ButtonTW>

        <hr />

        <ButtonTW textSize='md' onClick={(e) => {
          // // FYI: this works, it creates the nested maps
          // updateDoc(doc(db, 'expensesheets', '00000000'), {
          //   'a.b.c.d.e': 123
          // })

          // // this will create a single field whose name contains dots :(
          // addDoc(collection(db, 'expensesheets'), {
          //   'a.b.c.d.e': 123
          // })
          //   .then((added) => console.log('added', added))

          // // this will create a single field whose name contains dots :(
          // setDoc(doc(db, 'expensesheets', '00000000'), {
          //   'a.b.c.d.e': 123
          // })

          // // this will create a single field whose name contains dots :(
          // setDoc(doc(db, 'expensesheets', '00000001'), {
          //   'a.b.c.d.e': 123
          // }, { merge: true })


          // setDoc {merge:true} does a granular merge: no fields are ever deleted, only updated or added.
          // updateDoc on the other hand, without the dot notation, will NOT merge and will erase any sibling fields within the field being updated.

          // setDoc(doc(db, 'expensesheets', '00000000'), {
          //   dept1: {
          //     dept1_team1: {
          //       dept1_team1_a: 1,
          //       dept1_team1_b: 2,
          //       dept1_team1_c: 3,
          //     },
          //     dept1_team2: {
          //       dept1_team2_a: 4,
          //       dept1_team2_b: 5,
          //       dept1_team2_c: 6,
          //     },
          //     dept1_team3: {
          //       dept1_team3_a: 7,
          //       dept1_team3_b: 8,
          //       dept1_team3_c: 9,
          //     },
          //   },
          //   dept2: {
          //     dept2_team1: {
          //       dept2_team1_a: 10,
          //       dept2_team1_b: 20,
          //       dept2_team1_c: 30,
          //     },
          //     dept2_team2: {
          //       dept2_team2_a: 40,
          //       dept2_team2_b: 50,
          //       dept2_team2_c: 60,
          //     },
          //     dept2_team3: {
          //       dept2_team3_a: 70,
          //       dept2_team3_b: 80,
          //       dept2_team3_c: 90,
          //     },
          //   },
          //   dept3: {
          //     dept3_team1: {
          //       dept3_team1_a: 11,
          //       dept3_team1_b: 21,
          //       dept3_team1_c: 31,
          //     },
          //     dept3_team2: {
          //       dept3_team2_a: 42,
          //       dept3_team2_b: 52,
          //       dept3_team2_c: 62,
          //     },
          //     dept3_team3: {
          //       dept3_team3_a: 73,
          //       dept3_team3_b: 83,
          //       dept3_team3_c: 93,
          //     },
          //   },
          // })
          //   .then(() => {

          //     // setDoc(doc(db, 'expensesheets', '00000000'), {
          //     //   dept1: {
          //     //     dept1_team1: {
          //     //       dept1_team1_a: 99,
          //     //       dept1_team1_c: deleteField(),
          //     //     },
          //     //     dept1_team3: deleteField(),
          //     //   },
          //     //   dept3: deleteField(),
          //     // }, { merge: true })

          //     // the below DOES erase the rest of dept1
          //     updateDoc(doc(db, 'expensesheets', '00000000'), {
          //       dept1: {
          //         dept1_team1: {
          //           dept1_team1_a: 1234,
          //         }
          //       }
          //     })

          //   })

        }}>Try deep object update</ButtonTW>

        <hr />

        <ButtonTW textSize='md' onClick={() => {
          // setDoc(doc(db, 'settings', 'emergencyPhoneList'), {
          //   list: [
          //     'Boutique Japan',
          //     'Remote Lands',
          //     'Elsewhere',
          //     'UK Market',
          //     'French Market',
          //     'Explore',
          //     'FIT Yellow Phone',
          //     'Group Phone',
          //   ],
          // })
        }}>Init emergency phones</ButtonTW>

        <hr />

        <ButtonTW textSize='md' onClick={() => {

          // const date1 = Temporal.PlainDate.from('2024-01-01')
          // const date2 = Temporal.PlainDate.from({ year: 2024, month: 1, day: 1 })
          // console.log(date1.equals(date2)) // true
          // console.log(date1 === date2) // false

          // const list = [date1]
          // console.log(list.includes(date2)) // false

          // temporal plain dates are compared by reference, so we can't use array.includes to check if a date is present or not


        }}>Test temporal</ButtonTW>

        <hr />


      </div>

    </div>
  )
}
