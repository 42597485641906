import { PayeeType } from 'src/types/types_payee'



export function getNameJaWithPrefixSuffix(payee: PayeeType, nospaces?: boolean) {
  let s = ''
  if (payee.nameJaPrefix.trim()) s += payee.nameJaPrefix.trim() + (nospaces ? '' : ' ')
  s += stripCommas(payee.nameJaMain.trim())
  if (payee.nameJaSuffix.trim()) s += (nospaces ? '' : ' ') + payee.nameJaSuffix.trim()
  return s
}

export function getFullDisplayNameJa(payee: PayeeType, nospaces?: boolean) {
  let s = getNameJaWithPrefixSuffix(payee, nospaces)
  if (payee.nameBrandJa?.trim()) s += (nospaces ? '' : '　') + stripCommas(payee.nameBrandJa.trim())
  if (payee.namePersonJa.trim()) s += (nospaces ? '' : '　') + stripCommas(payee.namePersonJa.trim())
  return s
}

export function getShortDisplayNameJa(payee: { nameBrandJa: string, nameJaMain: string, namePersonJa: string }) {
  let s = ''
  //if (getAbbr(payee.nameJaPrefix.trim())) s += getAbbr(payee.nameJaPrefix.trim()) + ' '
  const brandName = stripCommas((payee.nameBrandJa || '').trim())
  s += brandName || stripCommas(payee.nameJaMain.trim())
  //if (getAbbr(payee.nameJaSuffix.trim())) s += ' ' + getAbbr(payee.nameJaSuffix.trim())
  if (payee.namePersonJa.trim()) s += '　' + stripCommas(payee.namePersonJa.trim())
  return s
}

export function getShortDisplayNameEn(payee: { nameBrandEn: string, nameEnMain: string, namePersonEn: string }) {
  let s = ''
  const brandName = stripCommas((payee.nameBrandEn || '').trim())
  s += brandName || stripCommas(payee.nameEnMain.trim())
  if (payee.namePersonEn.trim()) s += ' - ' + stripCommas(payee.namePersonEn.trim())
  return s
}

export function getFullDisplayNameKana(payee: PayeeType) {
  let s = ''
  if (payee.nameKanaPrefix.trim()) s += payee.nameKanaPrefix.trim() + ' '
  s += stripCommas(payee.nameKanaMain.trim())
  if (payee.nameKanaSuffix.trim()) s += ' ' + payee.nameKanaSuffix.trim()
  if (payee.nameBrandKana?.trim()) s += '　' + payee.nameBrandKana.trim()
  if (payee.namePersonKana.trim()) s += '　' + payee.namePersonKana.trim()
  return s
}

export function getFullDisplayNameEn(payee: PayeeType) {
  let s = ''
  s += payee.nameEnMain.trim()
  if (payee.nameBrandEn?.trim()) s += '　' + payee.nameBrandEn.trim()
  if (payee.namePersonEn.trim()) s += '　' + payee.namePersonEn.trim()
  return s
}

export function stripCommas(text: string) {
  if (text === undefined || text === null)
    return ''
  return text.replaceAll(',', '')
}
