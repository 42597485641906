import { Firestore, Query, collection, query, where } from 'firebase/firestore'
import { ColumnFilterAmountType, TreeListStateAmountType } from 'src/types/types_columnfilters'
import { refreshTernaryState } from './util_filters'



export function constructDefaultAmountTreeListState(allAmounts: number[]) {
  const treeListState: TreeListStateAmountType = {
    amounts: new Map<number, 0 | 1>(),
    ternarySelected: 0,
  }

  allAmounts.forEach((amount) => {
    treeListState.amounts.set(amount, 0)
  })

  return treeListState
}


export function refreshAmountsTernaryState(treeListState: TreeListStateAmountType) {
  treeListState.ternarySelected = refreshTernaryState(treeListState.amounts)
}


export function getAmountFilterFromParam(selectedAmount: string | null, allAmounts: number[] | undefined): ColumnFilterAmountType | null {
  if (!allAmounts) return null  // still loading
  if (!selectedAmount) return null

  {
    const match = selectedAmount.match(/^(\d+(\.\d+)?)$/)
    if (match) {
      return {
        filterMode: 'equals',
        filterEquals: Number(selectedAmount),
      }
    }
  }

  {
    const match = selectedAmount.match(/^(\d+(\.\d+)?)~$/)
    if (match) {
      return {
        filterMode: 'range',
        filterGreaterThan: Number(selectedAmount.slice(0, -1)),
      }
    }
  }

  {
    const match = selectedAmount.match(/^~(\d+(\.\d+)?)$/)
    if (match) {
      return {
        filterMode: 'range',
        filterSmallerThan: Number(selectedAmount.slice(1)),
      }
    }
  }

  {
    const match = selectedAmount.match(/^(\d+(\.\d+)?)~(\d+(\.\d+)?)$/)
    if (match) {
      return {
        filterMode: 'range',
        filterGreaterThan: Number(match[1]),
        filterSmallerThan: Number(match[3]),
      }
    }
  }

  if (!selectedAmount.match(/^\[.+\]$/))
    throw new Error(`Invalid amount filter parameter: ${selectedAmount}`)

  const treeListState = constructDefaultAmountTreeListState(allAmounts)

  const list = selectedAmount.slice(1, -1).split(',')


  if (list.includes('all')) {
    // ALL SELECTED
    treeListState.ternarySelected = 1
    treeListState.amounts.forEach((amountSelected, amount) => {
      treeListState.amounts.set(amount, 1)
    })
  } else {


    treeListState.amounts.forEach((amountSelected, amount) => {
      const included = list.includes(amount.toString())
      treeListState.amounts.set(amount, included ? 1 : 0)
    })

    refreshAmountsTernaryState(treeListState)

  } // not 'all'

  return {
    filterMode: 'checkboxes',
    treeListState,
  }
}


export function getQueryWhenFilteringByAmount(
  db: Firestore,
  selectedAmount: string,
  appliedFilterAmount: ColumnFilterAmountType,
): [string, Query] {

  let minAmount: number
  let maxAmount: number

  let desc: string
  let qInvoices: Query

  if (appliedFilterAmount.filterMode === 'equals') {
    desc = `FILTER_AMOUNT equals ${selectedAmount}`
    minAmount = appliedFilterAmount.filterEquals
    maxAmount = minAmount
  } else if (appliedFilterAmount.filterMode === 'range') {
    desc = `FILTER_AMOUNT range ${selectedAmount}`
    minAmount = appliedFilterAmount.filterGreaterThan
    maxAmount = appliedFilterAmount.filterSmallerThan
  } else if (appliedFilterAmount.filterMode === 'checkboxes') {
    desc = `FILTER_AMOUNT checkboxes ${selectedAmount}`
    minAmount = 9999999999
    maxAmount = -9999999999
    appliedFilterAmount.treeListState.amounts.forEach((amountSelected, amount) => {
      if (amountSelected === 0) {
        return
      }
      minAmount = Math.min(minAmount, amount)
      maxAmount = Math.max(maxAmount, amount)

    }) // each year
  }

  qInvoices = query(collection(db, 'invoices'), where('_isDeleted', '==', false))
  if (minAmount)
    qInvoices = query(qInvoices, where('amount', '>=', minAmount))
  if (maxAmount)
    qInvoices = query(qInvoices, where('amount', '<=', maxAmount))

  return [desc, qInvoices]
}

