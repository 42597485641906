import { Dispatch, SetStateAction } from 'react'
import { AddButton } from 'src/components/Buttons/AddButton'
import { DeleteButton } from 'src/components/Buttons/DeleteButton'
import { MoveUpDownButtons } from 'src/components/Buttons/MoveUpDownButtons'
import { EditableFieldExpenses } from 'src/components/EditableField/EditableFieldExpenses'
import { FileDownloadButton } from 'src/components/FileDownloadUpload/FileDownloadButton'
import { FileUploadButton } from 'src/components/FileDownloadUpload/FileUploadButton'
import { EitherLegType, NonTransportationLegType } from 'src/types/types_commute'
import { formatNum } from 'src/util/util_formatnum'
import { nano_id } from 'src/util/util_nano_id'
import { ModalTripInputActionType } from './ModalTripInput'


interface ModalTripInputNonTransportationTableProps {
  legList: NonTransportationLegType[]
  setLegList: Dispatch<SetStateAction<EitherLegType[]>>
  isReadOnly: boolean
  editedCell: string | null
  setEditedCell: (cellid: string | null) => void
  userDetails: any
  attachmentDeletionMode: boolean
  setAttachmentDeletionMode: (isDeletionMode: boolean) => void
  modalAction: ModalTripInputActionType | null
}

export function ModalTripInputNonTransportationTable({
  legList,
  setLegList,
  isReadOnly,
  editedCell,
  setEditedCell,
  userDetails,
  attachmentDeletionMode,
  setAttachmentDeletionMode,
  modalAction,
}: ModalTripInputNonTransportationTableProps) {


  return (
    <table className='table mb-1 inputtingTable'>
      <colgroup>
        <col style={{ width: '4em' }} />
        <col style={{ width: '8em' }} />
        <col style={{ width: '8em' }} />
        <col style={{ width: '8em' }} />
        <col style={{ width: '8em' }} />
        <col style={{ width: '16em' }} />
        <col style={{ width: '4em' }} />
      </colgroup>
      <thead>
        <tr className='row-header'>
          <th></th>
          <th>№</th>
          <th>Description</th>
          <th>Cost</th>
          <th>Receipt</th>
          <th>Memo</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {legList.map((row, index) => {

          const rowInfo = {
            rowid: row.id,
            tableid: 'nonTransportationList',
            isClickableToEdit: !isReadOnly,
            editedCell: editedCell,
            setEditedCell: setEditedCell,
          }

          return (
            <tr key={index}>
              <td>
              </td>
              <td>
                {1 + index}
              </td>
              <td>
                <EditableFieldExpenses
                  fieldname='name'
                  currentValue={row.name}
                  validationType=''
                  //previousField=''
                  nextField='cost'
                  {...rowInfo}
                  customSaveCallback={(dbvalue) => {
                    if (row.name !== dbvalue) {
                      setLegList((current) => current.map((leg) => {
                        if (leg.id === row.id) {
                          const newLeg: EitherLegType = {
                            ...leg,
                            name: dbvalue,
                          }
                          return newLeg
                        } else {
                          return leg
                        }
                      }))
                    }
                  }}
                />
              </td>
              <td>
                <EditableFieldExpenses
                  fieldname='cost'
                  currentValue={row.cost}
                  validationType='number'
                  previousField='name'
                  nextField='memorandum'
                  {...rowInfo}
                  customSaveCallback={(dbvalue) => {
                    if (row.cost !== dbvalue) {
                      setLegList((current) => current.map((leg) => {
                        if (leg.id === row.id) {
                          const newLeg: EitherLegType = {
                            ...leg,
                            cost: dbvalue,
                          }
                          return newLeg
                        } else {
                          return leg
                        }
                      }))
                    }
                  }}
                />
              </td>
              <td>
                {row.receipts && row.receipts.map((filenameOrObj) => {
                  const { storagePath: filename, downloadURL } = filenameOrObj
                  return <FileDownloadButton
                    key={filename}
                    filename={filename}
                    downloadURL={downloadURL}
                    isDeletionMode={attachmentDeletionMode}
                    callbackAfterDelete={() => {
                      setLegList((current) => current.map((leg) => {
                        if (leg.id === row.id) {
                          const newLeg: EitherLegType = {
                            ...leg,
                            receipts: leg.receipts.filter((file) => file.storagePath !== filename),
                            receiptsDeleted: [...(leg.receiptsDeleted ?? []), filenameOrObj],
                          }
                          return newLeg
                        } else {
                          return leg
                        }
                      }))
                      setAttachmentDeletionMode(false)
                    }}
                    loginfo={`non-commute expenses category=${modalAction.category} date=${modalAction.sIsoDate}`}
                  />
                })}

                <FileUploadButton
                  itemId={`${rowInfo.tableid}_${row.id}`}
                  storageFolder={`receipts/${userDetails.id}`}
                  callbackUploadSuccess={(uploadedFilePathsAndURLs) => {
                    setLegList((current) => current.map((leg) => {
                      if (leg.id === row.id) {
                        const newLeg: EitherLegType = {
                          ...leg,
                          receipts: [...(leg.receipts ?? []), ...uploadedFilePathsAndURLs],
                        }
                        return newLeg
                      } else {
                        return leg
                      }
                    }))
                  }}
                />
              </td>
              <td>
                <EditableFieldExpenses
                  fieldname='memorandum'
                  currentValue={row.memorandum}
                  validationType=''
                  previousField='cost'
                  {...rowInfo}
                  customSaveCallback={(dbvalue) => {
                    if (row.memorandum !== dbvalue) {
                      setLegList((current) => current.map((leg) => {
                        if (leg.id === row.id) {
                          const newLeg: EitherLegType = {
                            ...leg,
                            memorandum: dbvalue,
                          }
                          return newLeg
                        } else {
                          return leg
                        }
                      }))
                    }
                  }}
                />
              </td>
              <td>
                {!isReadOnly && (
                  <div className='d-flex'>

                    <DeleteButton onClick={() => {
                      if (!window.confirm('Delete row?'))
                        return
                      setLegList(legList.filter((_row, _index) => _index !== index))
                    }} />

                    <MoveUpDownButtons index={index} array={legList} setArray={setLegList} />

                  </div>
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td></td>
          <td>Total</td>
          <td>{formatNum(legList.map((item) => item.cost).reduce((a, b) => a + b, 0))}</td>
          <td></td>
          <td></td>
          <td>
            {!isReadOnly && (
              <AddButton onClick={() => {
                const newLeg: NonTransportationLegType = {
                  id: nano_id(),
                  name: '',
                  cost: null,
                  receipts: [],
                  memorandum: '',
                }
                setLegList([...legList, newLeg])
              }} />
            )}
          </td>
        </tr>
      </tfoot>
    </table>
  )
}
