import { useState } from 'react';
import { Link } from 'react-router-dom';
import { TourRequestType } from 'src/types/types_tourrequest';
import { RequestCodeLinkToAggregator } from './RequestCodeLinkToAggregator';



interface RequestQuickSummaryGridProps {
  tourrequest: TourRequestType;
  requestCodeLinksToRequestPage: boolean;
}

export function RequestQuickSummaryGrid({
  tourrequest,
  requestCodeLinksToRequestPage,
}: RequestQuickSummaryGridProps) {


  // TODO: if multiple RequestQuickSummaryGrids will be displayed at the same time,
  // shownPopup and setShownPopup should be passed in by the parent to prevent multiple
  // popups from being shown at the same time
  const [shownPopup, setShownPopup] = useState<string | null>(null)
  const linkId = 'linkid'

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '2em auto' }}>
      {/* <i className='bi bi-link-45deg'></i> */}
      <i className='bi bi-code-square'></i>
      <div>
        {requestCodeLinksToRequestPage ? (
          <Link to={`/requests/edit/${tourrequest.id}`}>{tourrequest.requestCode}</Link>
        ) : (
          <RequestCodeLinkToAggregator
            requestCode={tourrequest.requestCode}
            linkId={linkId}
            shownPopup={shownPopup}
            setShownPopup={setShownPopup}
          />
        )}
      </div>
      {tourrequest.agencyOrPlatform && (
        <>
          <i className='bi bi-shop'></i>
          <div>{tourrequest.agencyOrPlatform}</div>
        </>
      )}
      {tourrequest.travellerName && (
        <>
          <i className='bi bi-person-walking'></i>
          <div>{tourrequest.travellerName}</div>
        </>
      )}
      {/* TODO: travel designer should be taken from dedicated field, not userCreated metadata */}
      {/* {tourrequest.userCreatedName && (
        <>
          <i className='bi bi-person-circle'></i>
          <div>{tourrequest.userCreatedName}</div>
        </>
      )} */}
      {(tourrequest.dateisoTourStart || tourrequest.dateisoTourEnd) && (
        <>
          <i className='bi bi-calendar3'></i>
          <div>
            {tourrequest.dateisoTourStart?.replaceAll('-0', '/')?.replaceAll('-', '/')}
            {' - '}
            {tourrequest.dateisoTourEnd?.replaceAll('-0', '/')?.replaceAll('-', '/')}
          </div>
        </>
      )}
    </div>
  )
}
