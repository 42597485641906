import { Dropdown, SplitButton } from 'react-bootstrap';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { useAppContext } from 'src/hooks/useAppContext';
import { HistoryInfoType, HistoryStepDropdownType } from 'src/types/types_historized';



interface TopWhiteBarEditControlsProps {
  whiteBarActive: boolean; // inactive when first creating a new document as autosave and undo/redo is disabled
  enableEditing: boolean;
  setEnableEditing: (value: boolean) => void;
  saveStatus: string;
  setSaveStatus: (value: string) => void;
  autosaveUndoRedoStep: (action: 'Undo' | 'Redo', targetStep: number) => Promise<void>;
  history: HistoryInfoType;
  divFloatingTotals: JSX.Element;
  userIsAllowedToEdit: boolean;
}

export function TopWhiteBarEditControls({
  whiteBarActive,
  enableEditing,
  setEnableEditing,
  saveStatus,
  setSaveStatus,
  autosaveUndoRedoStep,
  history,
  divFloatingTotals,
  userIsAllowedToEdit,
}: TopWhiteBarEditControlsProps) {

  const { db, setDbError, userDetails } = useAppContext()

  const currentStep = history?.currentStep ?? 1
  const lastStep = history?.lastStep ?? 1
  const currentStepObj = history?.steps?.[`step_${currentStep}`]

  const historySteps =
    Object.entries(history?.steps ?? {})
      .map(([stepKey, step]) => {
        const stepNew: HistoryStepDropdownType = {
          ...step,
          stepNum: Number(stepKey.match(/^step_(\d+)$/)[1]),
          stepDisabled: false,
        }
        return stepNew
      })
      .sort((a, b) => a.stepNum - b.stepNum)

  // REDO steps
  const historyStepsRedo = historySteps.filter((step) => step.stepNum > currentStep)

  // UNDO steps
  const historyStepsUndo = historySteps.filter((step) => step.stepNum <= currentStep)
    .slice(1) // need to remove the fist step as when we undo step N, we actually jump to target step N-1 and we need the target step to be in our history list
    .reverse()

  let reachedDisabledStep = false
  for (const step of historyStepsUndo) {
    reachedDisabledStep ||= step.isUndoWall // if true once, all the subsequent elements are also disabled
    step.stepDisabled = reachedDisabledStep
  }

  const canUndo = historyStepsUndo.length > 0
  const canRedo = historyStepsRedo.length > 0

  return (
    <>
      <div className='topControls' style={{
        // position: sticky;
        // top: 0;
        position: 'fixed',
        top: '4.125rem',

        width: '100%',
        // height: 7.5em;
        padding: '0.5em 2em',
        backgroundColor: '#ffffffe0',
        // border-radius: 0.5em;
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // flex-direction: column;
        // align-items: center;
        height: '3em',
        // gap: 1em;
        // margin-bottom: 1em;
        boxShadow: '0.125rem 0.125rem 1rem rgba(0, 0, 0, 0.25)',
        // typeahead (on guiding expense sheet page) uses z-index:1 for its items and X button when multiple selection is enabled, so we need a higher z-index here
        zIndex: 10,
      }}>

        <div className='topControlsLeft [&_div.form-check]:tw-mb-0' style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '1em',
        }}>
          {whiteBarActive && (
            <CheckboxSwitch id='chkEnableEditing' label='Edit mode' disabled={!userIsAllowedToEdit || !whiteBarActive} checked={enableEditing} onChange={(e) => {
              setSaveStatus('')
              setEnableEditing(e.target.checked)
            }} />
          )}

          <div style={(whiteBarActive && enableEditing) ? undefined : { visibility: 'hidden' }}>
            <SplitButton
              id='splitbutton-undo'
              variant={`${canUndo ? 'outline-primary' : 'outline-secondary'} btn-sm btnUndoRedo`}
              disabled={!canUndo}
              // title={undoLabel}
              title={<i className='bi bi-arrow-counterclockwise'></i>}
              onClick={() => {
                if (currentStepObj?.isUndoWall)
                  return
                autosaveUndoRedoStep('Undo', currentStep - 1)
                  .catch((err) => setDbError('Undo failed', err))
              }}>
              {historyStepsUndo.map((step) => {
                return (
                  <Dropdown.Item
                    key={step.stepNum}
                    disabled={step.stepDisabled}
                    onClick={() => {
                      if (step.stepDisabled)
                        return
                      autosaveUndoRedoStep('Undo', step.stepNum - 1)
                        .catch((err) => setDbError('Undo failed', err))
                    }}
                  >
                    {step.stepAction}
                  </Dropdown.Item>
                )
              })}
            </SplitButton>
          </div>

          {/* {userrole_isDev(userDetails.roles) && (
            <div>{currentStep}/{lastStep}</div>
          )} */}

          <div style={(whiteBarActive && enableEditing) ? undefined : { visibility: 'hidden' }}>
            <SplitButton
              variant={`${canRedo ? 'outline-primary' : 'outline-secondary'} btn-sm btnUndoRedo`}
              disabled={!canRedo}
              // title={redoLabel}
              title={<i className='bi bi-arrow-clockwise'></i>}
              onClick={() => {
                if (!canRedo)
                  return

                autosaveUndoRedoStep('Redo', currentStep + 1)
                  .catch((err) => setDbError('Redo failed', err))

              }}>
              {historyStepsRedo.map((step) => {
                return (
                  <Dropdown.Item
                    key={step.stepNum}
                    onClick={() => {
                      autosaveUndoRedoStep('Redo', step.stepNum)
                        .catch((err) => setDbError('Redo failed', err))
                    }}
                  >
                    {step.stepAction}
                  </Dropdown.Item>
                )
              })}
            </SplitButton>
          </div>


          {divFloatingTotals}
        </div>

        {saveStatus && (
          <div className='saveStatus'>
            {saveStatus}
          </div>
        )}
      </div>

      {/* div to take up equivalent height as the position:fixed bar */}
      <div style={{ height: '3em' }}></div>
    </>
  )
}
