import { EditableField, ValidationType } from 'src/components/EditableField/EditableField';
import { convertLfToBr } from 'src/components/EditableField/util_textformatting';
import { formatNum } from 'src/util/util_formatnum';



interface EditableFieldAddInvoiceProps {
  tableid: string;
  rowid: string;
  fieldname: string;
  validationType: ValidationType;
  currentValue: any;
  isClickableToEdit: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  callbackCommitChange: (value: any, tabKey?: -1 | 1, formula?: string) => void;
  isTextArea?: boolean;
  textareaRows?: number;
}

export function EditableFieldAddInvoice({
  tableid,
  rowid,
  fieldname,
  validationType,
  currentValue,
  isClickableToEdit,
  editedCell,
  setEditedCell,
  callbackCommitChange, // save value AND end editing (=setEditedField(''))
  isTextArea,
  textareaRows,
}: EditableFieldAddInvoiceProps) {


  return (
    <div>
      <EditableField
        tableid={tableid}
        rowid={rowid}
        fieldname={fieldname}
        validationType={validationType}
        currentValue={currentValue}
        isClickableToEdit={isClickableToEdit}
        editedCell={editedCell}
        setEditedCell={setEditedCell}
        callbackCommitChange={callbackCommitChange}
        isTextArea={isTextArea}
        textareaRows={textareaRows}
        getDisplayValue={(value) => {
          let text = value
          if (isTextArea) {
            text = convertLfToBr(value)
          }
          if (typeof value === 'number') {
            text = formatNum(value)
          }

          return ( //tw-border-blue-300
            <div className={`tw-border tw-border-solid ${fieldname === 'amount' ? 'tw-w-[20ch]' : ''}
                             ${isClickableToEdit ? 'tw-border-gray-200 tw-bg-white ' : 'tw-border-slate-300 tw-bg-transparent '}
                             tw-rounded tw-px-3 tw-py-1`}
              style={{
                minHeight: `${2 * (textareaRows || 1)}rem`,
              }}>
              {text}
            </div>
          )
        }}
      />
    </div>
  )

}
