//import { Hit } from '@algolia/client-search';
import { TourRequestHitType } from 'src/types/types_tourrequest';
import { HighlightFieldBasic } from './HighlightFieldBasic';


export type HighlightMatch = { // copy/pasted from algolia package
  readonly value: string;
  readonly matchLevel: 'none' | 'partial' | 'full';
  readonly matchedWords: readonly string[];
  readonly fullyHighlighted?: boolean;
}


interface HighlightFieldProps {
  // tourrequest: Hit<TourRequestType>;
  tourrequest: TourRequestHitType;
  fieldname:
  'customerType'
  | 'requestNumber'
  | 'requestCode'
  | 'agencyOrPlatform'
  | 'travellerName'
  | 'country'
  | 'stateOrRegion'
  | 'userCreatedName'
  | 'userModifiedName'
  | 'userCreatedUid'
  | 'userCreatedEmail'
  | 'userModifiedUid'
  | 'userModifiedEmail'
  | 'specialOccasion'
  | 'guidePreference'
  | 'guideLanguage'
  | 'dietaryRequirements'
  | 'customersInterests'
  | 'requestComments'
  | 'updatedComment'
}

export function HighlightField({ tourrequest, fieldname }: HighlightFieldProps) {

  const highlightMatch = tourrequest._highlightResult?.[fieldname] as HighlightMatch
  const hightlightedValue = highlightMatch?.value

  return (
    <HighlightFieldBasic
      fieldValue={tourrequest[fieldname]}
      highlightedValue={hightlightedValue}
    />
  )
}
