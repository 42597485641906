import { Firestore, collection, getDocs, limit, orderBy, query } from 'firebase/firestore'
import { getNewHistoryInfoObj } from 'src/hooks/autosave/util_undoredo'
import { TourRequestType } from 'src/types/types_tourrequest'
import { UserSimpleUidType } from 'src/types/types_user'
import { getTodayUTC, iso_from_utc0 } from 'src/util/datetools'
import { serverTimestampAsDate } from 'src/util/util_firestoredates'
import { StatusKeyType } from './PageTourRequests'


export function getBlankTourRequest(userSimple: UserSimpleUidType) {
  const tourrequest: TourRequestType = {

    id: null,
    _isDeleted: false,
    history: getNewHistoryInfoObj(userSimple, 'Newly created tour request'),
    status: '',

    requestNumber: null,
    requestCode: '',
    dateOriginallyReceived: iso_from_utc0(getTodayUTC()),

    accommodationStars: [],
    accommodationPreference: [],
    travellers: [],

    agencyOrPlatform: '',
    agencyOrPlatformId: '',
    travellerName: '',
    country: '',
    stateOrRegion: '',
    customerType: '',
    dateisoTourStart: '',
    dateisoTourEnd: '',
    numOfPax: null,

    statusOngoing: true,                  // new
    statusLostSale: false,
    statusCancelled: false,
    statusConfirmed_pretour: false,       // new
    statusConfirmed_ontour: false,        // new
    statusConfirmed_posttour: false,      // new
    statusConfirmed_archived: false,      // new
    // merged statuses
    statusConfirmed_active: false,        // new
    statusConfirmed: false,
    statusCancelled_or_lost: false,       // new
    // meta
    statusSetDatetime: serverTimestampAsDate(), // new
    // other statuses
    statusReceivedDeposit: false,
    statusReceivedTotalTripFee: false,

    eightyDaysDepartment: '',
    isExploreSeries: false,
    specialOccasion: '',
    guidePreference: '',
    guideLanguage: '',
    dietaryRequirements: '',
    customersInterests: '',
    requestComments: '',
    updatedComment: '',
    hiddenInCalendar: false,
    salesInformation: null,
    dateCreated: serverTimestampAsDate(),
    userCreatedUid: userSimple.uid,
    userCreatedEmail: userSimple.email,
    userCreatedName: userSimple.name,
    dateModified: serverTimestampAsDate(),
    userModifiedUid: userSimple.uid,
    userModifiedEmail: userSimple.email,
    userModifiedName: userSimple.name,
    usersDesigners: [
      {
        uid: userSimple.uid,
        email: userSimple.email,
        name: userSimple.name,
      }
    ],
    usersDesignersUids: [userSimple.uid],

    // optional fields:
    // calendarColorMain: ...,
    paymentDatesCache: [],
    paymentAmountsCache: [],

    salesInfo2: {
      // usersInCharge: [], // list of {uid,name,email}

      quotations: [], // list of {dateQuotationSent, quotedAmount}

      payments: [],
      // list of {
      //   dateInvoiceSent,
      //   paymentType[deposit/balance/fullamount],
      //   percent,
      //   paymentAmount,
      //   dateDue,
      //   paymentMethod,
      //   isPaid,
      //   datePaymentReceived,
      //}

      balanceDue: null, // calculated

      note: '', //misc note
    },

    // userModifiedUid: '',
    // userModifiedEmail: '',
    // userModifiedName: '',
    // userCreatedUid: '',
    // userCreatedEmail: '',
    // userCreatedName: '',
    // dateCreated: '',
    // dateModified: '',

    // kintoneRecordNumber: '',

  }

  return tourrequest
}


export async function getNextRequestNumber(db: Firestore) {
  const snapshot = await getDocs(query(collection(db, 'tourrequests'), orderBy('requestNumber', 'desc'), limit(10)))

  // unfortunately there are a few invalid numbers in the data set: some string values
  // so we loop until we find an integer and take that as the max
  let maxNumber = null
  for (const docu of snapshot.docs) {
    const num = docu.data().requestNumber
    if (typeof num === 'number') {
      maxNumber = num
      console.log(`MAX request number is ${num} on tourrequest ${docu.id}`)
      break
    }
  }

  if (!maxNumber)
    throw new Error('No max num found')

  return maxNumber + 1
}

export function getTwBorder(statusKey: StatusKeyType) {
  if (statusKey === 'confirmed_active') {
    return 'tw-border-green-700'
  } else if (statusKey === 'lost_and_cancelled') {
    return 'tw-border-slate-400'
  } else if (statusKey === 'ongoing') {
    return 'tw-border-amber-400'
  } else if (statusKey === 'confirmed_archived') {
    return 'tw-border-lime-600'
  } else {
    return 'tw-border-transparent'
  }
}

export function getTwBackground(statusKey: StatusKeyType) {
  if (statusKey === 'confirmed_active') {
    return '!tw-bg-green-700'
  } else if (statusKey === 'lost_and_cancelled') {
    return '!tw-bg-slate-400'
  } else if (statusKey === 'ongoing') {
    return '!tw-bg-amber-400'
  } else if (statusKey === 'confirmed_archived') {
    return '!tw-bg-lime-600'
  } else {
    return '!tw-bg-slate-400'
  }
}
