

const defaultSort = new Map<string, 'asc' | 'desc'>([
  // no need to specify the asc ones
  //['payby', 'asc'],
  //['amount', 'desc'],
  ['dateCreated', 'desc'],
  ['dateModified', 'desc'],
  ['expenseDate', 'desc'],
])


export interface SortCaretProps {
  colName: string;
  sortCol: string;
  sortDir: 1 | -1;
  setSortSetting: (sortSetting: [string, 1 | -1]) => void;
}

export function SortCaret({ colName, sortCol, sortDir, setSortSetting }: SortCaretProps) {

  if (colName === sortCol) {
    if (sortDir === 1) {
      return <i className='bi-caret-up-square' onClick={() => setSortSetting([colName, -1])}></i>
    } else {
      return <i className='bi-caret-down-square' onClick={() => setSortSetting([colName, 1])}></i>
    }
  } else {
    if (defaultSort.get(colName) === 'desc' && false) {
      // always show 'asc' caret for esthetic reasons
      return <i className='bi-caret-down' onClick={() => setSortSetting([colName, -1])}></i>
    } else {
      return <i className='bi-caret-up' onClick={() => setSortSetting([colName, defaultSort.get(colName) === 'desc' ? -1 : 1])}></i>
    }
  }
}
