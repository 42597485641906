import { useAppContext } from 'src/hooks/useAppContext'
import { TourRequestType } from 'src/types/types_tourrequest'
import { PillListType, RequestStatusOnePill } from './RequestStatusOnePill'



interface RequestStatusPillCurrentProps {
  tourrequest: TourRequestType
  vertical: boolean
  clickHandler: ((clicked: PillListType) => void) | null // null means not clickable; cursor will not be pointer
}


export function RequestStatusPillCurrent({ // just shows the tourrequest's current status
  tourrequest,
  vertical,
  clickHandler,
}: RequestStatusPillCurrentProps) {

  const { db, userDetails, setDbError } = useAppContext()



  return (
    <div className={`tw-flex tw-gap-1 ${vertical ? 'tw-flex-col' : ''}`} style={{ fontSize: '0.875em', }}>
      {tourrequest.statusOngoing && (
        <RequestStatusOnePill
          label='ONGOING'
          selected={tourrequest.statusOngoing}
          clickHandler={clickHandler}
        />
      )}
      {tourrequest.statusLostSale && (
        <RequestStatusOnePill
          label='LOST'
          selected={tourrequest.statusLostSale}
          clickHandler={clickHandler}
        />
      )}
      {tourrequest.statusCancelled && (
        <RequestStatusOnePill
          label='CANCELLED'
          selected={tourrequest.statusCancelled}
          clickHandler={clickHandler}
        />
      )}
      {tourrequest.statusConfirmed && (
        <RequestStatusOnePill
          label='CONFIRMED'
          selected={tourrequest.statusConfirmed}
          clickHandler={clickHandler}
        />
      )}
      {tourrequest.statusConfirmed && (
        <div>
          {tourrequest.statusConfirmed_pretour && (
            <RequestStatusOnePill
              label='Pre-tour'
              selected={tourrequest.statusConfirmed_pretour}
              clickHandler={clickHandler}
            />
          )}
          {tourrequest.statusConfirmed_ontour && (
            <RequestStatusOnePill
              label='On-tour'
              selected={tourrequest.statusConfirmed_ontour}
              clickHandler={clickHandler}
            />
          )}
          {tourrequest.statusConfirmed_posttour && (
            <RequestStatusOnePill
              label='Post-tour'
              selected={tourrequest.statusConfirmed_posttour}
              clickHandler={clickHandler}
            />
          )}
          {tourrequest.statusConfirmed_archived && (
            <RequestStatusOnePill
              label='Archived'
              selected={tourrequest.statusConfirmed_archived}
              clickHandler={clickHandler}
            />
          )}
        </div>
      )}
    </div>
  )

}
