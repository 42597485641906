import { useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useAppContext } from 'src/hooks/useAppContext';
import { useOutsideAlerter } from '../EditableField/util_useOutsideAlerter';


type ListItemType = {
  label: string;
  customOption?: boolean;
}

interface EditableFieldTypeaheadProps {
  tableid: string;
  rowid: string;
  fieldname: string;
  currentValue: string;
  isClickableToEdit: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  callbackCommitChange: (dbvalue: any) => void;
  typeaheadOptionsList: string[];
  menuMinWidth?: string; // string with css unit e.g. '15rem'
  hasButtonForEditing?: boolean;
}

export function EditableFieldTypeahead({
  tableid,
  rowid,
  fieldname,
  currentValue, // string
  isClickableToEdit,
  editedCell,
  setEditedCell,
  callbackCommitChange,
  typeaheadOptionsList,
  menuMinWidth,
  hasButtonForEditing,
}: EditableFieldTypeaheadProps) {


  const { db, setDbError } = useAppContext()

  const cellid = `${tableid}_${rowid}_${fieldname}`


  // the below allows a user to click anywhere on the page, to make the current edited cell to stop being edited
  const inputRef = useRef(null);
  // _eslint-disable-next-line react-compiler/react-compiler -- https://github.com/facebook/react/issues/30745
  useOutsideAlerter(inputRef, () => {

    // no need to commit here as Typeahead always updates the underlying db/object on every change, so we just stop editing
    setEditedCell('')

  });


  const currentlyEditing = editedCell === cellid


  if (!currentlyEditing) {

    // to make the space clickable for editing:
    const whitespace = (isClickableToEdit && !hasButtonForEditing) ? '\xa0'.repeat(20) : '' // \xa0 is &nbsp;

    const onclickHandler = (() => {
      if (isClickableToEdit)
        setEditedCell(cellid)
    })

    const displayValue = currentValue

    return (
      <div onClick={(isClickableToEdit && !hasButtonForEditing) ? onclickHandler : null}>
        {displayValue || whitespace}
        {(isClickableToEdit && hasButtonForEditing) && (
          <>
            {' '}
            <i className='bi bi-pencil-square' onClick={onclickHandler}></i>
          </>
        )}
      </div>
    )


  } else {

    if (!typeaheadOptionsList) // shouldn't happen
      return <span>Loading...</span>

    const objList: ListItemType[] = typeaheadOptionsList.map((str) => ({ label: str }))

    const currentValueObj = objList.find((x) => x.label === currentValue)
    let currentSelection: ListItemType[]
    if (currentValueObj)
      currentSelection = [currentValueObj]
    else
      currentSelection = []

    return (

      <div
        style={{ minWidth: menuMinWidth }}
        ref={inputRef}
      >

        <Typeahead
          className='typeahead-suppress-popper-warning'
          id={cellid}
          labelKey='label'
          // @ts-expect-error Typeahead
          onChange={(list: ListItemType[]) => {
            if (list.length > 0 && list[0].customOption) {
              callbackCommitChange(list[0].label)
              return
            }
            if (list.length > 0)
              callbackCommitChange(list[0].label)
            else
              callbackCommitChange('')
          }}
          options={objList}
          //placeholder="Choose an item..."
          selected={currentSelection}
          allowNew={true}
          newSelectionPrefix='▷ '
          filterBy={['label']}
          autoFocus={true}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              setEditedCell('')
              // prevent modal from closing etc.
              e.stopPropagation()
            }
          }}
        />

      </div>

    )
  }
}
