import { Firestore, deleteField, doc, updateDoc } from 'firebase/firestore'
import { HistorizedDocumentType, HistoryInfoType, HistoryStepType } from 'src/types/types_historized'
import { UserDetailsType, UserSimpleUidType } from 'src/types/types_user'
import { dateFormatJpWithTimeAndSeconds } from 'src/util/dateformattools'
import { serverTimestampAsDate } from 'src/util/util_firestoredates'
import { nano_id } from 'src/util/util_nano_id'
import { getUserSimpleUid } from 'src/util/util_users'
import { useAppContext } from '../useAppContext'
import { getIsUndoWall, getNewHistoryInfoObj } from './util_undoredo'



export const PRESERVE_HISTORY_STEPS = 10 // must be at least 2 in order to handle Undo steps correctly


// convenience hook, for component that handles a single document
export function useAutosaveDocumentSingle(
  collectionName: 'quotationsheets' | 'expensesheets' | 'tourrequests' | 'invoices' | 'generalexpenses',
  document: HistorizedDocumentType,
  addMetadataModified: (updateObj: any, userDetails: UserDetailsType) => void,
) {

  const { db, userDetails } = useAppContext()

  const autosaveDocu = (
    userAction: string,
    updateObj: any,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ): Promise<void> => {
    return autosaveDocument(
      updateObj,
      userAction,
      false,
      undefined,
      sUndoWall,
      //---
      document.id,
      document.history,
      getUserSimpleUid(userDetails),
      db,
      collectionName,
      (updateObj) => addMetadataModified(updateObj, userDetails),
      null,
    )
  }

  return autosaveDocu
}

// convenience hook, for component that handles a list of documents
export function useAutosaveDocumentInList(
  collectionName: 'quotationsheets' | 'expensesheets' | 'tourrequests' | 'invoices' | 'generalexpenses',
  addMetadataModified: (updateObj: any, userDetails: UserDetailsType) => void,
) {

  const { db, userDetails } = useAppContext()

  const autosaveDocu = (
    userAction: string,
    document: HistorizedDocumentType,
    updateObj: any,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ): Promise<void> => {
    return autosaveDocument(
      updateObj,
      userAction,
      false,
      undefined,
      sUndoWall,
      //---
      document.id,
      document.history,
      getUserSimpleUid(userDetails),
      db,
      collectionName,
      (updateObj) => addMetadataModified(updateObj, userDetails),
      null,
    )
  }

  return autosaveDocu
}

export async function autosaveDocument(
  updateObj: any,
  userAction: string,
  isUndoRedo: boolean,
  undoRedoTargetStep: number | undefined,
  sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  //---
  documentId: string,
  history: HistoryInfoType,
  userSimple: UserSimpleUidType,
  db: Firestore,
  collectionName: 'quotationsheets' | 'expensesheets' | 'tourrequests' | 'invoices' | 'generalexpenses',
  addMetadataModified: (updateObj: any) => void,
  setSaveStatus: ((status: string) => void) | null,
): Promise<void> {
  if (!userAction)
    throw new Error('userAction not provided')

  setSaveStatus?.('Saving...')
  // refSaveStatus.current.style.transition = 'none'
  // refSaveStatus.current.style.opacity = 1



  if (!history) {
    // first store an initial history step
    console.log('INIT HISTORY')

    history = getNewHistoryInfoObj(userSimple, 'Init history')

    const updateObjInit: Record<string, any> = {
      history,
    }

    addMetadataModified(updateObjInit)

    await updateDoc(doc(db, collectionName, documentId), updateObjInit)

    console.log('finished INIT history')
  }

  addMetadataModified(updateObj)



  let newCurrentStep: number

  if (!isUndoRedo) {
    let newStep = history.lastStep + 1

    if (history.currentStep !== history.lastStep) {
      // this step takes place after having done Undo one or several times

      const numStepsUndone = history.lastStep - history.currentStep

      let undoStepAction
      if (numStepsUndone === 1) {
        undoStepAction = `Undo [${history.steps[`step_${history.currentStep + 1}`].stepAction}]`
      } else {
        undoStepAction = `Undo ${numStepsUndone} steps`
      }

      const undoStep: HistoryStepType = {
        stepId: history.currentStepId,
        stepAction: undoStepAction,
        dateModified: serverTimestampAsDate(),
        userModified: userSimple,
        isUndoStep: true,
        isUndoWall: false, // you can always undo an undo step
      }
      updateObj[`history.steps.step_${newStep}`] = undoStep

      newStep++

    } // if this step follows some Undos

    const newStepId = nano_id()

    updateObj['history.currentStep'] = newStep
    updateObj['history.lastStep'] = newStep
    updateObj['history.currentStepId'] = newStepId
    const userActionStep: HistoryStepType = {
      stepId: newStepId,
      stepAction: userAction,
      dateModified: serverTimestampAsDate(),
      userModified: userSimple,
      isUndoStep: false,
      isUndoWall: getIsUndoWall(sUndoWall),
    }
    updateObj[`history.steps.step_${newStep}`] = userActionStep

    newCurrentStep = newStep

  } else {
    // if isUndoRedo
    newCurrentStep = undoRedoTargetStep
  }

  // Trim older history steps
  const pastSteps = Object.keys(history.steps)
  for (const pastStep of pastSteps) {
    const match = pastStep.match(/^step_(\d+)$/)
    const stepNum = Number(match[1])
    if (stepNum < newCurrentStep - PRESERVE_HISTORY_STEPS) {
      // trim step
      updateObj[`history.steps.step_${stepNum}`] = deleteField()
    }
  }


  // NOTE: this autosaveDocument function is also called for
  //   - quotationsheets
  //   - expensesheets
  //   - tourrequests
  // Each of these types has subtly different fields for storing metadata (dateModified, userModified)
  // so we cannot handle that here.
  // In the past, there was a bug as we added the `metadataModified` field here, which caused it to be
  // incorrectly added to expensesheets.

  // updateObj.metadataModified = {
  //   dateModified: serverTimestampAsDate(),
  //   userModified: userSimple,
  // }


  await updateDoc(doc(db, collectionName, documentId), updateObj)

  // console.log('update done')
  const timestamp = dateFormatJpWithTimeAndSeconds(new Date())
  setSaveStatus?.(`Saved ${timestamp}`)
  // console.log('refSaveStatus', refSaveStatus)
  // console.log('refSaveStatus.current', refSaveStatus.current)
  // window.setTimeout(() => {
  //   refSaveStatus.current.style.transition = 'opacity 2s'
  //   refSaveStatus.current.style.opacity = 0
  // }, 500)

}
