import { MoveUpDownButtonsGeneric } from './MoveUpDownButtonsGeneric';


interface MoveUpDownButtonsProps<T> {
  array: T[];
  setArray: (array: T[]) => void;
  index: number;
}

export function MoveUpDownButtons<T>({
  array,
  setArray,
  index,
}: MoveUpDownButtonsProps<T>) {

  return (
    <MoveUpDownButtonsGeneric
      callbackMoveUp={() => {
        if (index === 0)
          return
        setArray([
          ...array.slice(0, index - 1),
          array[index],
          array[index - 1],
          ...array.slice(index + 1),
        ])
      }}
      callbackMoveDown={() => {
        if (index === array.length - 1)
          return
        setArray([
          ...array.slice(0, index),
          array[index + 1],
          array[index],
          ...array.slice(index + 2),
        ])
      }}
    />
  )
}
