import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';


interface EmergencyPhoneCellProps {
  editMode: boolean;
  legList: { phoneName: string, shift: string }[];
  saveLegList: (legList: { phoneName: string, shift: string }[]) => void;
  selectedPhone: string;
  isHoliday: boolean;
}

export function EmergencyPhoneCell({
  editMode,
  legList,
  saveLegList,
  selectedPhone,
  isHoliday
}: EmergencyPhoneCellProps) {

  const [shift, setShift] = useState(isHoliday ? 'holiday' : 'night') // night or holiday

  const getActionButtons = () => {

    if (!editMode)
      return null

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
        {selectedPhone && !(legList && legList.filter((item) => item.phoneName === selectedPhone).length > 0) && (
          <div>
            <Form.Select
              style={{ fontSize: 'small' }}
              value={shift}
              onChange={(e) => {
                setShift(e.target.value)
              }}>
              <option value='night'>Night shift</option>
              <option value='holiday'>Holiday shift</option>
            </Form.Select>
            <ButtonTW variant='blue_outline' onClick={(e) => {
              const newItem = {
                phoneName: selectedPhone,
                shift,
              }
              if (!legList) {
                saveLegList([newItem])
                return
              }
              if (legList.filter((item) => item.phoneName === selectedPhone).length > 0)
                return // already set
              saveLegList([...legList, newItem])
            }}>
              Add
              {' \xa0'}
              <i className='bi bi-phone-vibrate' style={{ fontSize: '1.25em' }}></i>
              {' '}
              {selectedPhone}
            </ButtonTW>
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      <ul className='phoneList'>
        {legList && legList.map((item, index) => {
          return (
            <li key={index}>
              {item.shift === 'holiday'
                // ? <i className='bi bi-calendar-event'></i>
                ? <i className='bi bi-sun'></i>
                : item.shift === 'night'
                  ? <i className='bi bi-moon-stars'></i>
                  : null
              }
              {' '}
              <i className='bi bi-phone-vibrate' style={{ fontSize: '1.25em' }}></i>
              {' '}
              {item.phoneName}
              {' '}
              {editMode && (
                <ButtonTW variant='darkgray_outline' onClick={(e) => {
                  saveLegList(legList.filter((ph) => ph.phoneName !== item.phoneName))
                }}>Remove</ButtonTW>
              )}
            </li>
          )
        })}
      </ul>
      {getActionButtons()}
    </div>
  )


}
