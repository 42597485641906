import { useState } from 'react';
import { BarLoader } from 'react-spinners';
import { EditableField } from 'src/components/EditableField/EditableField';
import { useAppContext } from 'src/hooks/useAppContext';
import { InvoiceFreeeStatusType } from 'src/types/types_invoices';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';



interface FreeeInputCellProps {
  freeeUnlocked: boolean;
  checked: boolean;
  memorandum: string;
  callbackUpdateDoc: (freeeStatus: InvoiceFreeeStatusType, field: 'checkbox' | 'memo') => Promise<void>;
  editedCell: string | null,
  setEditedCell: (cellid: string | null) => void;
  cellid: string;
}

export function FreeeInputCell({
  freeeUnlocked,
  checked,
  memorandum,
  callbackUpdateDoc,
  editedCell,
  setEditedCell,
  cellid,
}: FreeeInputCellProps) {

  const { db, setDbError, userDetails } = useAppContext()

  const [isLoading, setIsLoading] = useState(false)


  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em', minWidth: '12em' }}>
      <div>
        <i
          className={`bi ${checked ? 'bi-check-square-fill' : 'bi-square'}`}
          style={{ fontSize: '1.5em' }}
          onClick={() => {
            if (!freeeUnlocked)
              return
            const freeeStatus: InvoiceFreeeStatusType = {
              checked: !checked,
              memorandum: memorandum || '',
              dateModified: serverTimestampAsDate(),
              userModified: {
                uid: userDetails.id,
                email: userDetails.email,
                name: userDetails.displayNameEn,
              },
            }

            setIsLoading(true)

            callbackUpdateDoc(freeeStatus, 'checkbox')
              .then(() => setIsLoading(false))
          }}
        ></i>
        <BarLoader
          loading={isLoading}
          width='1.5em'
        />
      </div>
      <div>
        <EditableField
          tableid='freeeStatusInput'
          rowid={cellid}
          fieldname='freeeMemorandum'
          validationType=''
          currentValue={memorandum || ''}
          isClickableToEdit={freeeUnlocked}
          editedCell={editedCell}
          setEditedCell={setEditedCell}
          hasButtonForEditing={false}
          isTextArea={true}
          placeholderText='(click to input memo)'
          callbackCommitChange={(dbvalue) => {
            setEditedCell(null)
            const freeeStatus: InvoiceFreeeStatusType = {
              checked: !!checked,
              memorandum: dbvalue || '',
              dateModified: serverTimestampAsDate(),
              userModified: {
                uid: userDetails.id,
                email: userDetails.email,
                name: userDetails.displayNameEn,
              },
            }

            setIsLoading(true)
            callbackUpdateDoc(freeeStatus, 'memo')
              .then(() => setIsLoading(false))
          }}
        />
      </div>
    </div>
  )
}
