import { autosaveDocument } from 'src/hooks/autosave/util_autosave'
import { useAppContext } from 'src/hooks/useAppContext'
import { addMetadataModifiedInvoices } from 'src/pages/Invoices/util_invoices'
import { InvoiceStatusDropdownType, invoiceStatusesDropdown, InvoiceType } from 'src/types/types_invoices'
import { log_db_write } from 'src/util/util_log'
import { getUserSimpleUid } from 'src/util/util_users'
import { InvoiceStatusPill } from '../StatusPill/StatusPill'



interface EditableFieldInvoiceStatusProps {
  tableid: string
  rowid: string
  currentValue: string
  invoice: InvoiceType
  displayStatus: string
  isClickableToEdit: boolean
  editedCell: string | null
  setEditedCell: (cellid: string | null) => void
  setEditedInvoice: (invoiceid: string) => void
}

export function EditableFieldInvoiceStatus({
  tableid,
  rowid,
  currentValue,
  invoice,
  displayStatus,
  isClickableToEdit,
  editedCell,
  setEditedCell,
  setEditedInvoice,
}: EditableFieldInvoiceStatusProps) {

  const { db, setDbError, userDetails } = useAppContext()

  //const [value, setValue] = useState(currentValue)

  const cellid = `${tableid}_${rowid}_status`

  const currentlyEditing = editedCell === cellid
  if (!currentlyEditing) {
    let pill = null
    if (!isClickableToEdit) {
      const hideStatus = displayStatus === 'RECEIVED'
      if (!hideStatus)
        pill = <InvoiceStatusPill status={displayStatus} />
      else
        pill = null
    } else {
      pill = <InvoiceStatusPill status={currentValue} />
    }

    return (
      <div onClick={(e) => {
        if (isClickableToEdit)
          setEditedCell(cellid)
      }}>
        {pill}
      </div>
    )
  } else {
    return (

      <select className='form-control' id='dropdownStatus' value={currentValue} onChange={(e) => {
        const newStatus = e.target.value as InvoiceStatusDropdownType

        const updateObj: Partial<InvoiceType> = {
          status: newStatus,
        }

        autosaveDocument(
          updateObj,
          `Inline: Set status to ${newStatus}`,
          false,
          undefined,
          'u',
          invoice.id,
          invoice.history,
          getUserSimpleUid(userDetails),
          db,
          'invoices',
          (updateObj) => addMetadataModifiedInvoices(updateObj, userDetails),
          null,
        )
          .then(() => {
            setEditedCell('')
            setEditedInvoice('')
          })
          .catch((err) => setDbError(`Set status to ${newStatus} on invoice ${invoice.id}`, err))

        log_db_write({ db, userDetails, logkey: 'db_write.invoice.set_status', desc: `Set status to ${newStatus} on invoice ${invoice.id}` })

      }}>
        {invoiceStatusesDropdown.filter((x) => x === currentValue || (x !== 'SCHEDULED' && x !== 'PAID')).map((item) => (
          <option key={item}>{item}</option>
        ))}
      </select>
    )
  }
}

