

export function PageNotFound() {
  return (
    <div className='container m-4 bg-danger-subtle col-sm-5 p-4 border border-danger-subtle rounded border-2 rounded-3'>
      <h3 className='mb-3'><i className='bi-x-octagon'></i> Page Not Found</h3>
      <div>Please report this error to an administrator if you reached this page through a link.</div>
    </div>
  )
}

