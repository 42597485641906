import { IconButton } from './IconButton';


interface MoveUpDownButtonsGenericProps {
  callbackMoveUp: () => void;
  callbackMoveDown: () => void;
  dataTooltipId?: string;
  dataTooltipHtmlUp?: string;
  dataTooltipHtmlDown?: string;
}

export function MoveUpDownButtonsGeneric({
  callbackMoveUp,
  callbackMoveDown,
  dataTooltipId,
  dataTooltipHtmlUp,
  dataTooltipHtmlDown,
}: MoveUpDownButtonsGenericProps) {

  return (
    <>
      <IconButton
        icon='bi-arrow-up'
        className='ms-1'
        onClick={callbackMoveUp}
        dataTooltipId={dataTooltipId}
        dataTooltipHtml={dataTooltipHtmlUp}
      />

      <IconButton
        icon='bi-arrow-down'
        className='ms-1'
        onClick={callbackMoveDown}
        dataTooltipId={dataTooltipId}
        dataTooltipHtml={dataTooltipHtmlDown}
      />
    </>
  )
}
