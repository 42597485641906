import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import Papa from 'papaparse';
import { useState } from 'react';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { LoadingSpinner } from 'src/components/Spinner/LoadingSpinner';
import { useAppContext } from 'src/hooks/useAppContext';
import { dateFormatJpShort } from 'src/util/dateformattools';
import { parseDateCsv, parseDateTimeCsv } from 'src/util/datetools_dayjs';
import { mapUsers, useUserListKintone } from './util_kintone';
import { stripFinalLf } from './util_misc';


const mapKintoneRenameFields = [
  ['作成者', 'Created by'],
  ['作成者(Display Name)', 'Created by(Display Name)'],
  ['作成日時', 'Created datetime'],
  ['更新者', 'Updated by'],
  ['更新者(Display Name)', 'Updated by(Display Name)'],
  ['更新日時', 'Updated datetime'],
  ['レコード番号', 'Record number'],
]

const mapKintoneFirestore = new Map([
  ['Code', 'requestNumber'], // not always numeric...
  ['Agency/Platform', 'agencyOrPlatform'],
  ['Arrival Date', 'arrivalDate'],
  ['Customer Information', 'customerInformation'],
  ['Client Name (add if not travel related customers)', 'clientName'],
  ['Project Name (add if not travel related customers)', 'projectName'],
  ['Invoice Date', 'invoiceDate'],
  ['Invoiced Amount', 'invoicedAmount'],
  ['Payment Method', 'paymentMethod'],
  ['Deposit Due by', 'depositDueBy'],
  ['Payment[済]', 'depositPaymentDone'],
  ['Balance Due By', 'balanceDueBy'],
  ['Payment[済]_1', 'balancePaymentDone'],
  ['Record number', 'kintoneRecordNumber'],
  ['Note', 'note'],
  ['Balance Amount', 'balanceAmount'],
  ['Quoted Date', 'quotedDate'],
  ['Quoted Amount', 'quotedAmount'],
  ['Freee入力[済]', 'freeeInput1'],
  ['Freee入力[済]_2', 'freeeInput2'],
  ['Freee入力[済]_3', 'freeeInput3'],
  ['Deposit Amount', 'depositAmount'],
  ['Freee入力[済]_4', 'freeeInput4'],
  ['Departure Date', 'departureDate'],
  ['Created datetime', 'dateCreated'],
  ['Updated datetime', 'dateModified'],
])

const dateFields = [
  'Arrival Date',
  'Invoice Date',
  'Deposit Due by',
  'Balance Due By',
  'Quoted Date',
  'Departure Date',
]

const numericFields = ['Record number', 'Invoiced Amount', 'Balance Amount', 'Quoted Amount', 'Deposit Amount']
const dateTimeFields = ['Created datetime', 'Updated datetime']


export function KintoneSalesInformationImporter() {

  const { db, setDbError } = useAppContext()

  // substantive data
  const [salesText, setSalesText] = useState()
  const [salesInfos, setSalesInfos] = useState()
  const [requestList, setRequestList] = useState()

  // UI etc.
  const [saveToDb, setSaveToDb] = useState(false)
  const [statusDownload, setStatusDownload] = useState()
  const [statusParsing, setStatusParsing] = useState()
  const [missingText, setMissingText] = useState()
  const [dbUpdateCount, setDbUpdateCount] = useState()


  const userList = useUserListKintone()

  // *** all hooks above this line ***

  if (!userList)
    return <LoadingSpinner />


  // *** all hooks above

  return (
    <div className='container'>
      <h2>Kintone Sales information importer</h2>

      <hr />

      <div className='alert alert-primary'>{statusDownload}</div>

      <ButtonTW onClick={(e) => {
        fetch('/dev_data/Sales Information_20230815T190604+0900 utf8.csv')
          // fetch('/dev_data/Sales Information _ 1 row.csv')
          .then((response) => {
            if (!response.ok) {
              setDbError(`Failed to download csv. ${response.status} ${response.statusText}`)
              return
            }
            response.text().then((text) => {
              text = stripFinalLf(text)
              setSalesText(text)
              setStatusDownload('downloaded')
            })

          })
          .catch((err) => setDbError('Downloading calendar csv', err))

      }}>Download Sales information csv</ButtonTW>

      <hr />

      <div className='alert alert-primary'>{statusParsing}</div>

      <CheckboxSwitch id='saveToDb' label='Save to DB' checked={saveToDb} onChange={(e) => setSaveToDb(e.target.checked)} />

      <ButtonTW onClick={(e) => {

        // need to make col headers unique as Kintone has duplicate headers...
        const lines = salesText.split('\n')
        if (lines[0] !== '"Code","Agency/Platform","Arrival Date","Customer Information","Client Name (add if not travel related customers)","Project Name (add if not travel related customers)","Invoice Date","Invoiced Amount","Payment Method","Deposit Due by","Payment[済]","Balance Due By","Payment[済]","作成者","作成者(Display Name)","作成日時","更新日時","更新者","更新者(Display Name)","レコード番号","Note","Balance Amount","Quoted Date","Quoted Amount","Freee入力[済]","Freee入力[済]","Freee入力[済]","Deposit Amount","Freee入力[済]","Departure Date","Person in Charge","Person in Charge(Display Name)"')
          throw new Error('unexpected first row')
        // we use the same underscore suffixes as excel assigns automatically:
        lines[0] = '"Code","Agency/Platform","Arrival Date","Customer Information","Client Name (add if not travel related customers)","Project Name (add if not travel related customers)","Invoice Date","Invoiced Amount","Payment Method","Deposit Due by","Payment[済]","Balance Due By","Payment[済]_1","作成者","作成者(Display Name)","作成日時","更新日時","更新者","更新者(Display Name)","レコード番号","Note","Balance Amount","Quoted Date","Quoted Amount","Freee入力[済]","Freee入力[済]_2","Freee入力[済]_3","Deposit Amount","Freee入力[済]_4","Departure Date","Person in Charge","Person in Charge(Display Name)"'
        const salesText2 = lines.join('\n')

        Papa.parse(salesText2, {
          header: true,
          dynamicTyping: true,
          complete: function (results) {

            console.log(results)

            if (results.errors.length > 0) {
              console.log('csv parsing errors', results.errors)
              setStatusParsing(results.errors.map((err, index) => <div key={index}>{err}</div>))
              return
            }

            // rename fields
            results.data.forEach((row) => {
              mapKintoneRenameFields.forEach(([a, b]) => {
                row[b] = row[a]
                delete row[a]
              })
            })


            const salesinfos = []

            results.data.forEach((_row, index) => {

              const row = { ..._row }
              //console.log(row)

              const obj = {
                _isDeleted: false,
              };


              // handle user ids
              function handleUser(colName, destFieldName) {

                const colNameDisplayName = `${colName}(Display Name)`
                const kintoneUser = `${row[colName]} _ ${row[colNameDisplayName]}`

                const userEmail = mapUsers.get(kintoneUser)
                if (!userEmail)
                  throw new Error(`missing in mapping: ${kintoneUser} code=${row['Code']}`)

                const userObj = userList.get(userEmail)
                if (!userObj)
                  throw new Error(`user not found: ${userEmail}`)

                obj[`user${destFieldName}Uid`] = userObj.id
                obj[`user${destFieldName}Email`] = userObj.email
                obj[`user${destFieldName}Name`] = userObj.displayNameEn

                delete row[colName]
                delete row[colNameDisplayName]
              }

              function handleUserMultiple(colName, destFieldName) {

                const colNameDisplayName = `${colName}(Display Name)`

                const users = []

                if (row[colName]) {

                  const part1 = row[colName].split('\n')
                  const part2 = row[colNameDisplayName].split('\n')

                  if (part1.length !== part2.length)
                    throw new Error('inconsistent lengths of user fields')


                  part1.forEach((name1, index) => {
                    const name2 = part2[index]

                    const kintoneUser = `${name1} _ ${name2}`

                    const userEmail = mapUsers.get(kintoneUser)
                    if (!userEmail)
                      throw new Error(`missing in mapping: ${kintoneUser} code=${row['Code']}`)

                    const userObj = userList.get(userEmail)
                    if (!userObj)
                      throw new Error(`user not found: ${userEmail}`)

                    const user = {
                      uid: userObj.id,
                      email: userObj.email,
                      name: userObj.displayNameEn,
                    }

                    users.push(user)
                  })
                }

                obj['usersInCharge'] = users

                delete row[colName]
                delete row[colNameDisplayName]
              }

              handleUser('Created by', 'Created')
              handleUser('Updated by', 'Modified')
              handleUserMultiple('Person in Charge', 'Responsible')


              for (const field of mapKintoneFirestore.keys()) {
                const newField = mapKintoneFirestore.get(field)

                let value = row[field]

                if (value === undefined)
                  throw new Error(`undefined field ${field}`) // should not happen

                if (dateFields.includes(field)) {
                  if (value)
                    value = parseDateCsv(value)
                  // else
                  //   console.log(`null date ${field}`)
                }

                if (dateTimeFields.includes(field)) {
                  if (value)
                    value = parseDateTimeCsv(value)
                  else
                    throw new Error(`null datetime ${field}`)
                }

                const isBoolField = field.includes('[済]')
                if (isBoolField)
                  value = !!value // null and 1 -> false and true

                if (value === null) {
                  if (field === 'Deposit Amount'
                    || field === 'Deposit Due by'
                    || field === 'Quoted Date'
                    || field === 'Invoice Date'
                    || field === 'Balance Due By'
                  ) {
                    //value = 0 // there are lots of null amounts
                  } else if (numericFields.includes(field)
                    || isBoolField
                    || dateFields.includes(field)
                    || dateTimeFields.includes(field)
                  ) {
                    console.log(`NULL: ${field}`)
                  } else {
                    // this is a string field, replace with empty string
                    value = ''
                  }
                }

                obj[newField] = value
                delete row[field]
              }


              obj.requestCode = '' // need to determine separately
              delete row['Code']


              for (const field of Object.keys(row)) {
                console.log(`UNKNOWN FIELD ${field}`)
              }
              salesinfos.push(obj)

            }) // each row

            console.log(`Parsing complete. ${salesinfos.length} sales infos`)

            console.log(salesinfos)

            console.log('multiple in charge', salesinfos.find((s) => s.usersInCharge.length > 1))

            setSalesInfos(salesinfos)

            if (!saveToDb)
              return

            // store to database
            // tourrequests.forEach(req => {
            //   console.log(`Adding doc ${req.requestNumber}`)
            //   addDoc(collection(db, 'tourrequests'), req)
            //     .then(addedDoc => {
            //       console.log('tour request added to db')
            //       setSuccess(suc => [...suc, addedDoc])
            //     })
            //     .catch(err => {
            //       console.log(err)
            //       setErr(`addDoc error ${err}`)
            //     })
            // })



          }, // `complete` handler
        });


      }}>Parse csv</ButtonTW>

      <hr />

      <ButtonTW onClick={(e) => {

        // fetch('/dev_data/db_dump_PROD_tourrequests_20230815.json')
        fetch('/dev_data/db_dump_TEST_tourrequests_20230815.json')
          .then((response) => {
            // console.log(response)
            if (!response.ok) {
              setDbError(`Failed to download tourrequests.json. ${response.status} ${response.statusText}`)
              return
            }
            response.json().then((obj) => {
              setRequestList(obj.tourrequests)
            })
          })
          .catch((err) => setDbError('Downloading dev json', err))


      }}>Download Requests JSON</ButtonTW>

      <hr />

      <ButtonTW onClick={(e) => {

        // ---
        const s = salesInfos[0]
        let list = ''
        for (const field of Object.keys(s))
          list += `${field}\n`
        console.log('LIST', list)
        // ---


        const SAVE_TO_DB = false

        const missing = []
        missing.push('number\tinvoiceDate\tclient\tproject')

        setDbUpdateCount(0)

        salesInfos.forEach((salesinfo) => {
          const salesinfoNum = salesinfo.requestNumber
          // console.log(salesinfoNum)
          const req = requestList.find((r) => r.requestNumber === salesinfoNum)
          if (req) {
            // found

            if (SAVE_TO_DB) {

              updateDoc(doc(db, 'tourrequests', req.id), {
                salesInformation: {
                  ...salesinfo,
                  dateKintoneImport: serverTimestamp(),
                }
              })
                .then(() => {
                  console.log('Update succeeded')
                  setDbUpdateCount((c) => c + 1)
                })
                .catch((err) => console.error(err))

            }

          } else {
            console.log(`MISSING: number=[${salesinfoNum}] invoiceDate=${dateFormatJpShort(salesinfo.invoiceDate)} client=[${salesinfo.clientName}] project=[${salesinfo.projectName}]`)
            missing.push(`${salesinfoNum}\t${dateFormatJpShort(salesinfo.invoiceDate)}\t${salesinfo.clientName}\t${salesinfo.projectName}`)
          }

        })

        setMissingText(missing.join('\n'))

      }}>Check sales info vs requests, SAVE TO DB</ButtonTW>

      <textarea className='form-control' value={missingText} rows={20}></textarea>

      <div>Update count: {dbUpdateCount}</div>
    </div>
  )
}
