import { MouseEvent, useMemo } from 'react';
import { TourRequestTypeWithDates } from 'src/types/types_tourrequest';
import { addDays, getSpanDaysExact, isMidnightUTC, iso_from_utc0 } from 'src/util/datetools';
import { FullMonthGridCell } from '../Components/FullMonthGridCell';
import { EditWindowParamsType, MonthItemType } from '../PageTourCalendar';
import { TourEditWindow } from '../Popups/TourEditWindow';
import { WeekendBars } from '../WeekendBars';
import { GuideCalendarCellDataType, guideDayHasConflict } from '../useGuideCalendarCellData';
import { UserSimpleCalendarGuideType } from '../useGuideList';
import { addRange, checkIfMonthBoundary, getTourDayIndexes, gridCellMouseDown } from '../util_tourcalendar';
import { getTourrequestColors } from '../util_tourcalendar_colors';
import { RedTriangle } from './RedTriangle';




interface R2_ToursProps {
  ref_R2_TourCalendarGrid: React.RefObject<HTMLDivElement>;
  onScrollR2: React.UIEventHandler<HTMLDivElement>;
  calendarGridWidth: string;
  block2height: string;
  CELL_WIDTH_EM: number;
  CELL_HEIGHT_EM: number;
  FONT_SIZE_PX: number;
  CALENDAR_RIGHT_BUFFER: number;
  TOURCALENDAR_BOTTOM_BUFFER: number;
  currentHourUTC: number;
  dateutcCalendarStart: Date;
  dateutcCalendarEnd: Date;
  listDays: Date[];
  listMonths: MonthItemType[];
  numDaysInCalendar: number;
  requests: TourRequestTypeWithDates[];
  shownEditWindow: EditWindowParamsType | null;
  setShownEditWindow: (shownEditWindow: EditWindowParamsType | null) => void;
  listAllGuides: UserSimpleCalendarGuideType[];
  shownEditWindowTourRequest: TourRequestTypeWithDates | null;
  computeY: (x: number) => number;
  hoveredDayNum: number | null;
  setHoveredDayNum: (value: number | null) => void;
  selectedColumns: number[];
  isReadOnly: boolean;
  guideCalendarCellData: GuideCalendarCellDataType;
}

export function R2_Tours({
  ref_R2_TourCalendarGrid,
  onScrollR2,
  calendarGridWidth,
  block2height,
  CELL_WIDTH_EM,
  CELL_HEIGHT_EM,
  FONT_SIZE_PX,
  CALENDAR_RIGHT_BUFFER,
  TOURCALENDAR_BOTTOM_BUFFER,
  currentHourUTC,
  dateutcCalendarStart,
  dateutcCalendarEnd,
  listDays,
  listMonths,
  numDaysInCalendar,
  requests,
  shownEditWindow,
  setShownEditWindow,
  listAllGuides,
  shownEditWindowTourRequest,
  computeY,
  hoveredDayNum,
  setHoveredDayNum,
  selectedColumns,
  isReadOnly,
  guideCalendarCellData,
}: R2_ToursProps) {




  const redCurve: JSX.Element[] = []
  const showRedCurve = false
  if (showRedCurve) {
    const RES = 20
    for (let i = 0; i < requests.length * RES; i++) {
      const x = i / RES
      const y = computeY(x)
      redCurve.push(
        <div style={{
          position: 'absolute',
          left: `${y * CELL_WIDTH_EM}em`,
          top: `${x * CELL_HEIGHT_EM}em`,
          width: '1px',
          height: '1px',
          backgroundColor: 'red',
        }}></div>
      )
    }
  }



  const rows = useMemo(() => {
    console.log('render rows')

    return requests.map((tourrequest, iTourRequest) => {

      if (!isMidnightUTC(tourrequest._dateutcTourStart)) {
        throw new Error(`tourrequest._dateutcTourStart is not midnight UTC: ${tourrequest._dateutcTourStart.getTime()} ${tourrequest._dateutcTourStart} ${tourrequest.id} ${tourrequest.requestCode}`)
      }
      if (!isMidnightUTC(tourrequest._dateutcTourEnd)) {
        throw new Error(`tourrequest._dateutcTourEnd is not midnight UTC: ${tourrequest._dateutcTourEnd.getTime()} ${tourrequest._dateutcTourEnd} ${tourrequest.id} ${tourrequest.requestCode}`)
      }

      // CSS grid style indexes: start at 1, count the lines.
      // E.g. 1 cell in first column starts at 1, ends at 2.

      const { tourIsFirstOfMonth, tourIsLastOfMonth } = checkIfMonthBoundary(requests, iTourRequest)

      const monthDivs: JSX.Element[] = []
      for (let iMonth = 0; iMonth < listMonths.length; iMonth++) {
        const month = listMonths[iMonth]

        // FULL MONTHS
        // provides the black border on month start and end, and top/bottom border throughout.
        monthDivs.push(
          <FullMonthGridCell
            key={`month_${iMonth}_full`}
            iRow={iTourRequest}
            indexStart={month.monthStartDayIndex}
            indexEnd={month.monthEndDayIndexExc}
            className={`leftMonthBorder rightMonthBorder ${
              //iTourRequest === hoveredRequestRow ? 'hoveredRequestRow' : '' // this caused too many rerenders causing the page to become too slow. we use pure css :hover instead.
              ''}`}
            CELL_WIDTH_EM={CELL_WIDTH_EM}
            CELL_HEIGHT_EM={CELL_HEIGHT_EM}
          />
        )
      }

      // TOUR ITSELF

      const tourDivs: JSX.Element[] = []

      // We render 1 cell per tour day, except that if multiple consecutive cells have
      // *identical* *non-empty* content, they are merged together.
      //  
      // Note: we don't combine empty cells into one multi-cell, in order to visually preserve the cell borders


      // `eachCellContent` will have 1 item per cell (day):
      const eachCellContent: { dayIndex: number, sGuides: string, sAdditionalText: string, cellContent: string | null, hasGuideConflict: boolean }[] = []

      const dayIndexes = getTourDayIndexes(tourrequest, dateutcCalendarStart)
      for (const dayIndex of dayIndexes) { // 0-based from calendar start
        if (dayIndex < 0 || dayIndex >= numDaysInCalendar)
          // outside of calendar range
          continue

        const dateutc = addDays(dateutcCalendarStart, dayIndex)
        const dateiso = iso_from_utc0(dateutc)
        const guides = tourrequest.calendarDays?.[dateiso]?.guides || []

        let cellContent = null
        const sGuides = guides.map((guide) => guide.name).join(', ')
        const sAdditionalText = tourrequest.calendarDays?.[dateiso]?.additionalText || '' // ||'' is so that never defined additional text (undefined) equals defined then later deleted additional text ('')
        if (sGuides && sAdditionalText)
          cellContent = `${sGuides} ${sAdditionalText}`
        else if (sGuides)
          cellContent = sGuides
        else if (sAdditionalText)
          cellContent = sAdditionalText

        let hasGuideConflict = false
        for (const guide of guides) {
          const days = guideCalendarCellData.lookup.get(guide.id)
          if (days) {
            const guideContents = days.get(dayIndex)
            if (guideContents && guideDayHasConflict(guideContents) === 'CONFLICT') {
              hasGuideConflict = true
              break
            }
          }
        }

        eachCellContent.push({ dayIndex, sGuides, sAdditionalText, cellContent, hasGuideConflict })
      }

      // colored_cells can span multiple days, and have a background color and text content
      const colored_cells: { start: number, length: number }[] = []
      let cur_start = -1
      let cur_length = -1

      for (let i = 0; i <= eachCellContent.length; i++) {
        if (i > 0 && i < eachCellContent.length
          && eachCellContent[i].cellContent // we don't merge empty cells
          && eachCellContent[i].dayIndex === eachCellContent[i - 1].dayIndex + 1 // check they are consecutive
          && eachCellContent[i].sGuides === eachCellContent[i - 1].sGuides
          && eachCellContent[i].sAdditionalText === eachCellContent[i - 1].sAdditionalText
          && eachCellContent[i].hasGuideConflict === eachCellContent[i - 1].hasGuideConflict
        ) {

          cur_length++
        } else {
          // push the previous multi-cell that just ended
          if (cur_start >= 0) {
            colored_cells.push({ start: cur_start, length: cur_length })
          }
          cur_start = i
          cur_length = 1
        }
      }

      // render the colored_cells into `divs` with a background color
      for (let k = 0; k < colored_cells.length; k++) {
        const { start, length } = colored_cells[k]
        const cellContent = eachCellContent[start]

        const tourColors = getTourrequestColors(tourrequest)
        let backgroundColor: string
        let textColor: string
        if (cellContent.hasGuideConflict) {
          backgroundColor = '#ff4433'
          textColor = 'white'
        } else if (!cellContent.sGuides) {
          backgroundColor = tourColors.paleBg
          textColor = tourColors.paleText
        } else {
          backgroundColor = tourColors.mainBg
          textColor = tourColors.mainText
        }


        tourDivs.push(
          <div
            key={`tour_multicell_${k}`}
            className='tour_multicell'
            style={{
              position: 'absolute',
              left: `${cellContent.dayIndex * CELL_WIDTH_EM}em`,
              top: `${iTourRequest * CELL_HEIGHT_EM}em`,
              width: `calc(${length * CELL_WIDTH_EM}em - 1px)`,
              height: `calc(${CELL_HEIGHT_EM}em - 1px)`,
              backgroundColor,
              color: textColor,
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '2px',
              overflow: 'hidden',
              cursor: 'default',
            }}
          >
            {/* Nested div is necessary to change the font-size, as the cell's width/height are in 'em' unit */}
            <div className='cellText'>{cellContent.sGuides}{cellContent.sAdditionalText && <RedTriangle />}</div>
          </div>
        )
      }



      return (

        <div key={tourrequest.id} className={`calendarRow ${tourIsFirstOfMonth ? 'firstTourOfMonth' : ''} ${tourIsLastOfMonth ? 'lastTourOfMonth' : ''}`}>

          {monthDivs}
          {tourDivs}

        </div>
      )

    }) // each row (each request)

  }, [CELL_HEIGHT_EM, CELL_WIDTH_EM, dateutcCalendarStart, listMonths, requests, numDaysInCalendar, guideCalendarCellData.lookup])


  const selectionDivs = useMemo(() => {

    // render border of selected cells
    const selectionDivs: JSX.Element[] = []
    if (!shownEditWindow)
      return []

    const iTourRequest = shownEditWindow.iRow

    const fragmentList: { index: number, width: number }[] = []
    const selectedDayIndexesSorted = [...shownEditWindow.selectedDayIndexesU].sort((a, b) => a - b)
    for (let i = 0; i < selectedDayIndexesSorted.length; i++) {
      const index = selectedDayIndexesSorted[i] // date index on calendar grid (0-based)
      const width = 1
      if (fragmentList.length === 0
        || fragmentList[fragmentList.length - 1].index + fragmentList[fragmentList.length - 1].width !== index) {
        fragmentList.push({ index, width })
      } else {
        fragmentList[fragmentList.length - 1].width++
      }
    }

    for (let i = 0; i < fragmentList.length; i++) {
      const { index, width } = fragmentList[i]
      selectionDivs.push(
        <div
          key={`tour_selected_${index}`}
          style={{
            position: 'absolute',
            left: `${index * CELL_WIDTH_EM}em`,
            top: `${iTourRequest * CELL_HEIGHT_EM}em`,
            width: `${width * CELL_WIDTH_EM}em`,
            height: `${CELL_HEIGHT_EM}em`,
            backgroundColor: '#ddccee55',
            border: '3px solid darkblue',
          }}
        ></div>
      )
    }

    return selectionDivs

  }, [CELL_HEIGHT_EM, CELL_WIDTH_EM, shownEditWindow])


  return (
    <div className='calendarGrid'
      ref={ref_R2_TourCalendarGrid
        //(el) => {
        // This function is called twice, once with el=null and one with el=<HTMLDivElement>, at every page render.
        // console.log(`<div calendarGrid> ref called with el=[${el}]`)
        //ref_R2_TourCalendarGrid.current = el
        // make sure we only scroll to today on initial page load, not later when div is re-rendered
        // NEW: now the call to `scrollToToday()` is handled in useEffect in PageTourCalendar instead
        // if (!pageLoaded && el) {
        //   console.log('PAGE LOADED (calendarGrid div ref set)')
        //   setPageLoaded(true)
        //   scrollToToday()
        // }
        //}
      }
      style={{
        // putting border on the display:grid element itself causes lots of alignment issues. instead, put the border on the grid cells
        position: 'relative',
        width: calendarGridWidth,
        height: block2height,
        overflowY: 'scroll',
        overflowX: 'scroll',
        // we don't set a background color here as it would overflow in the case of very few tours
        borderBottom: '1px solid #666',
      }}
      onScroll={onScrollR2}
    >
      <div className='horizontal-spacer' style={{
        position: 'absolute',
        width: `${(numDaysInCalendar + CALENDAR_RIGHT_BUFFER) * CELL_WIDTH_EM}em`,
        height: '1px',
      }}></div>

      <div className='vertical-spacer' style={{
        position: 'absolute',
        height: `${(requests.length + TOURCALENDAR_BOTTOM_BUFFER) * CELL_HEIGHT_EM}em`,
        width: '1px',
      }}></div>

      <div id='calendarGridBackground'
        style={{
          width: `${numDaysInCalendar * CELL_WIDTH_EM}em`,
          height: `${requests.length * CELL_HEIGHT_EM}em`,
        }}

        onClick={(e: MouseEvent<HTMLDivElement>) => {
          e.preventDefault() // is this needed?
          e.stopPropagation() // is this needed?
        }}
        onMouseDown={(e: MouseEvent<HTMLDivElement>) => {
          e.preventDefault()
          e.stopPropagation()

          const rect = e.currentTarget.getBoundingClientRect();
          const x = e.clientX - rect.left;
          const y = e.clientY - rect.top;
          const iCol = Math.floor(x / FONT_SIZE_PX / CELL_WIDTH_EM) // 0-based
          const iRow = Math.floor(y / FONT_SIZE_PX / CELL_HEIGHT_EM) // 0-based

          const thisTourAlreadySelected = shownEditWindow && shownEditWindow.iRow === iRow

          const tourrequest = requests[iRow]
          const allDayIndexes = getTourDayIndexes(tourrequest, dateutcCalendarStart)


          if (!allDayIndexes.includes(iCol)) {
            // clicked before beginning or after end of tour
            setShownEditWindow(null)
          } else if (!thisTourAlreadySelected || (!e.ctrlKey && !e.metaKey && !e.shiftKey)) {
            // reset Edit Window position
            setShownEditWindow({
              tourrequestId: tourrequest.id,
              iRow,
              cssLeft: `${(iCol + 0.2) * CELL_WIDTH_EM}em`,
              cssTop: `${(iRow + 1.2) * CELL_HEIGHT_EM}em`,
              selectedDayIndexesU: [iCol]
            })
          } else {
            // preserve Edit Window position
            gridCellMouseDown(e, shownEditWindow, setShownEditWindow, iCol, dateutcCalendarStart, tourrequest)
          }

        }}
        onMouseMove={(e: MouseEvent<HTMLDivElement>) => {
          e.preventDefault()
          // e.stopPropagation() // do NOT stop propagation, because it prevents the mousemove event necessary to resize the tour calendar and guide calendar blocks

          const rect = e.currentTarget.getBoundingClientRect();
          const x = e.clientX - rect.left;
          const y = e.clientY - rect.top;
          const iCol = Math.floor(x / FONT_SIZE_PX / CELL_WIDTH_EM) // 0-based
          const iRow = Math.floor(y / FONT_SIZE_PX / CELL_HEIGHT_EM) // 0-based

          // sometimes due to rounding, and due to clientY always being an integer vs rect.top being a float, we can get a negative y value, which causes errors
          if (iRow < 0 || iRow >= requests.length)
            return

          if (iCol < 0 || iCol >= numDaysInCalendar)
            return

          setHoveredDayNum(iCol)
          // setHoveredRequestRow(iRow)

          if (e.buttons !== 1) return

          const thisTourAlreadySelected = shownEditWindow && shownEditWindow.iRow === iRow

          const tourrequest = requests[iRow]
          const allDayIndexes = getTourDayIndexes(tourrequest, dateutcCalendarStart)

          if (e.buttons === 1
            && thisTourAlreadySelected
            && allDayIndexes.includes(iCol)
            && !shownEditWindow.selectedDayIndexesU.includes(iCol)) {

            const allDayIndexes = getTourDayIndexes(tourrequest, dateutcCalendarStart)

            const newSelectedDayIndexes = addRange(shownEditWindow.selectedDayIndexesU, iCol, allDayIndexes)

            setShownEditWindow({
              ...shownEditWindow,
              // Here we use getRange instead of just adding the current day, because sometimes if mouse moves fast enough, it could skip a day. getRange ensures that all intermediate days are included.
              selectedDayIndexesU: newSelectedDayIndexes,
            })

          }

        }}
        onMouseUp={(e: MouseEvent<HTMLDivElement>) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onDoubleClick={(e: MouseEvent<HTMLDivElement>) => {
          console.log('double click')
          e.preventDefault()
          e.stopPropagation()

          const rect = e.currentTarget.getBoundingClientRect();
          const x = e.clientX - rect.left;
          const y = e.clientY - rect.top;
          const iCol = Math.floor(x / FONT_SIZE_PX / CELL_WIDTH_EM) // 0-based
          const iRow = Math.floor(y / FONT_SIZE_PX / CELL_HEIGHT_EM) // 0-based
          const tourrequest = requests[iRow]
          const dayIndexTourStart = getSpanDaysExact(dateutcCalendarStart, tourrequest._dateutcTourStart) // 0-based, can be <0 or >=numDaysInCalendar
          const dayIndexTourEnd = getSpanDaysExact(dateutcCalendarStart, tourrequest._dateutcTourEnd) // 0-based, can be <0 or >=numDaysInCalendar

          const selectedDayIndexes: number[] = []
          for (let i = dayIndexTourStart; i <= dayIndexTourEnd; i++) {
            if (i >= 0 && i < numDaysInCalendar) {
              selectedDayIndexes.push(i)
            }
          }

          setShownEditWindow({
            tourrequestId: tourrequest.id,
            iRow,
            cssLeft: `${(iCol + 0.2) * CELL_WIDTH_EM}em`,
            cssTop: `${(iRow + 1.2) * CELL_HEIGHT_EM}em`,
            selectedDayIndexesU: selectedDayIndexes,
          })
        }}

      >

        <WeekendBars
          listDays={listDays}
          numRows={requests.length}
          currentHourUTC={currentHourUTC}
          dateutcCalendarStart={dateutcCalendarStart}
          dateutcCalendarEnd={dateutcCalendarEnd}
          CELL_WIDTH_EM={CELL_WIDTH_EM}
          CELL_HEIGHT_EM={CELL_HEIGHT_EM}
        />

        {hoveredDayNum !== null && (
          <div className='hoveredDayColumn' style={{
            position: 'absolute',
            left: `${hoveredDayNum * CELL_WIDTH_EM}em`,
            width: `${1 * CELL_WIDTH_EM}em`,
            height: `${requests.length * CELL_HEIGHT_EM}em`,
            zIndex: 1,
            pointerEvents: 'none',
          }}></div>
        )}

        {selectedColumns.map((colNum) => {
          return (
            <div key={colNum} className='' style={{
              position: 'absolute',
              left: `${colNum * CELL_WIDTH_EM}em`,
              width: `${1 * CELL_WIDTH_EM}em`,
              height: `${requests.length * CELL_HEIGHT_EM}em`,
              zIndex: 1,
              pointerEvents: 'none',
              backgroundColor: '#ddccee55',
              border: '1px solid #ddccee',
            }}></div>
          )
        })}

        {rows}

        {selectionDivs}

        {redCurve}

        {!isReadOnly && (
          <TourEditWindow
            shownEditWindow={shownEditWindow}
            setShownEditWindow={setShownEditWindow}
            tourrequest={shownEditWindowTourRequest}
            listAllGuides={listAllGuides}
            dateutcCalendarStart={dateutcCalendarStart}
          />
        )}

      </div>
    </div>
  )
}
