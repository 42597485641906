import { User } from 'firebase/auth'
import { Firestore, doc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { FirebaseStorage, ref, uploadBytes } from 'firebase/storage'
import { dateFormatCompact } from './dateformattools'


export async function saveProfilePhoto(db: Firestore, storage: FirebaseStorage, user: User) {
  const { uid, email, photoURL } = user
  await saveProfilePhotoBase(db, storage, uid, email, photoURL)
}

async function saveProfileOnePhoto(db: Firestore, storage: FirebaseStorage, uid: string, email: string, photoURL: string, sLarge: string) {

  //await delay(5000)

  console.log('fetch photo')

  // here we sometimes get a CORS error, sometimes not...
  const fetchResult = await fetch(photoURL,
    { referrerPolicy: 'no-referrer' }
    // https://stackoverflow.com/a/61042200/
    // referrerPolicy was added to attempt to bypass CORS errors, but did not work. now we get the photo on the server (cloud functions) instead anyway.
  )

  const imageBlob = await fetchResult.blob()

  const datetime = dateFormatCompact(new Date())
  const imagePath = `profilepics/${uid}/${email}_${sLarge}_${datetime}`
  const snapshot = await uploadBytes(ref(storage, imagePath), imageBlob)

  const newUserDetails = {
    [`photo${sLarge}FileURL`]: photoURL,
    [`photo${sLarge}File`]: imagePath,
    [`photo${sLarge}FileLastFetched`]: serverTimestamp(),
  }
  await updateDoc(doc(db, 'users', uid), newUserDetails)

}
export async function saveProfilePhotoBase(db: Firestore, storage: FirebaseStorage, uid: string, email: string, photoURL: string) {
  const promise1 = saveProfileOnePhoto(db, storage, uid, email, photoURL, '')
  const photoURLlarge = photoURL.replace('=s96-c', '')
  if (photoURLlarge !== photoURL) {
    console.log('saving large profile pic')
    // note: if user's original pic size is smaller than 512x512, this will retrieve it at the original size
    // if user's original pic size is larger than 512x512, it will retrieved a scaled down image at 512x512
    // it's possible to get a higher quality image by passing a higher pixel size explicitly, e.g. =s2000
    await saveProfileOnePhoto(db, storage, uid, email, photoURLlarge, 'Large')
  }
  await promise1
}
