import { QuotationType } from 'src/types/types_quotation';
import { UserDetailsType } from 'src/types/types_user';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { getUserSimpleUid } from 'src/util/util_users';



export function addMetadataModifiedQuotations(updateObj: Partial<QuotationType>, userDetails: UserDetailsType) {

  updateObj.metadataModified = {
    dateModified: serverTimestampAsDate(),
    userModified: getUserSimpleUid(userDetails),
  }

}

