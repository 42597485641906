import * as Sentry from '@sentry/react';
import { User } from 'firebase/auth';
import { Firestore } from 'firebase/firestore';
import { useCallback, useState } from 'react';
import { isDevMode } from 'src/util/util_getbuildtime';
import { UserDetailsBasicType } from '../types/types_user';
import { log_error } from '../util/util_log';


export type setDbErrorType = (desc: string, errorObj?: any, isFatalError?: boolean) => void

type errType = { desc: string, errorObj: any, isFatalError: boolean }

export type currentErrorType = { errorHtml: JSX.Element, isFatalError: boolean }

export function useDbError(
  db: Firestore,
  user: User | null | undefined, // user could be null/undefined if not logged in
): [currentErrorType | null, setDbErrorType] {

  // Fatal error occurs mainly when app is "hard expired"

  //const { db, userDetails } = useAppContext()

  const [err, setErr] = useState<errType | null>(null)

  // setDbError must absolutely not change between calls, because it is passed as a dependency on many many useEffects throughout the app...
  const setDbError: setDbErrorType = useCallback(
    (desc, errorObj, isFatalError) => {
      console.error(`80D DB Error ${desc}. ${errorObj ? errorObj.toString() : ''}`)
      console.log({ errorObj })
      if (desc || errorObj) {

        setErr({ desc, errorObj, isFatalError: !!isFatalError })

        if (!isDevMode()) {
          if (errorObj)
            Sentry.captureException(errorObj, { data: desc })
          else if (desc)
            Sentry.captureMessage(desc)

          // make a simplified `userDetails` with just the three fields for compatibility with log_error
          const userDetails: UserDetailsBasicType = {
            id: user?.uid ?? '',
            email: user?.email ?? '',
            displayNameEn: user?.displayName ?? '',
          }
          log_error({ db, userDetails, logkey: 'error.setdberror', desc, errorObj, errorData: null })
        }

      } else {
        setErr(null)
      }
    }, [setErr, db, user])

  if (!err)
    return [null, setDbError]

  const errorHtml = (
    <div className='alert alert-danger'>
      <div>Db error: {err.desc}.</div>
      {err.errorObj && (
        <div>Details: {err.errorObj.toString()}</div>
      )}
      {/* <div><ButtonTW onClick={(e) => setErr(null)} variant='outline-secondary btn-sm' className='mt-3'>Dismiss</ButtonTW></div> */}
    </div>
  )

  const currentError: currentErrorType = {
    errorHtml,
    isFatalError: err.isFatalError,
  }

  return [currentError, setDbError]

}
