import { getNewHistoryInfoObj } from 'src/hooks/autosave/util_undoredo'
import { FileStoragePathAndDownloadUrlType, InvoiceType } from 'src/types/types_invoices'
import { UserDetailsType, UserSimpleUidType } from 'src/types/types_user'
import { getTodayIso } from 'src/util/datetools'
import { serverTimestampAsDate } from 'src/util/util_firestoredates'


export function getUpdateObjAfterInvoiceFileUpload(
  invoice: InvoiceType,
  uploadedFilePathsAndURLs: FileStoragePathAndDownloadUrlType[],
) {
  const updateObj: Partial<InvoiceType> = {
    files: [...invoice.files, ...uploadedFilePathsAndURLs],
    filesAll: [...(invoice.filesAll || []), ...uploadedFilePathsAndURLs],
  }
  if (invoice.status === 'EXPECTED') {
    updateObj.status = 'RECEIVED' // now we have the pdf, mark as received
  }

  const userAction = `Attach file [${uploadedFilePathsAndURLs.map((x) => x.storagePath).join(', ')}]`

  return { updateObj, userAction }
}

export function getUpdateObjToDeleteFile(
  invoice: InvoiceType,
  file: FileStoragePathAndDownloadUrlType,
) {

  const updateObj: Partial<InvoiceType> = {
    files: invoice.files.filter((f) => f.storagePath !== file.storagePath),
    filesDeleted: [...(invoice.filesDeleted ?? []), file],
  }

  const userAction = `Delete file [${file.storagePath}]`

  return { updateObj, userAction }
}

export function getDefaultPaymentDate(isScheduling: boolean, invoice: InvoiceType) {
  if (isScheduling) {
    // marking scheduled: always default to deadline
    return invoice.dateisoDeadline
  } else {
    // marking paid: if there was already a date (probably from scheduling)
    // *and* that date is in the past, use that, otherwise use NOW
    const todayiso = getTodayIso()
    if (invoice.paymentDateiso && invoice.paymentDateiso !== 'Z' && invoice.paymentDateiso < todayiso) {
      return invoice.paymentDateiso
    } else {
      return todayiso
    }
  }
}

export function getIsOld(invoice: InvoiceType) {
  return (invoice.status === 'SCHEDULED' || invoice.status === 'PAID') && invoice.dateisoDeadline < getTodayIso()
}

export function isInvoiceMultiple(invoice: InvoiceType) {
  return invoice && invoice.tripcode && invoice.tripcode.trim().toLowerCase() === 'multiple'
}

export function getBlankInvoice(userSimple: UserSimpleUidType) {
  const invoice: InvoiceType = {

    id: null,
    _isDeleted: false,
    history: getNewHistoryInfoObj(userSimple, 'Newly created invoice'),

    tripcode: '',
    paxname: '',
    servicePurchased: '',
    amount: null,
    currency: 'JPY',
    files: [],
    dateisoInvoiceReceived: getTodayIso(),
    dateisoDeadline: getTodayIso(),
    memorandum: '',

    payeeId: null,         // required before saving
    payeeNameEn: null,     // required before saving
    payeeNameJa: null,     // required before saving
    payeeCategories: null, // required before saving

    paymentDateiso: 'Z',
    paymentSourceAccount: null,
    paymentUserEmail: null,
    paymentUserUid: null,
    paymentSetDatetime: null,

    status: 'EXPECTED',    // changes to RECEIVED if file is attached
    statusPaidOrScheduled: false,

    dateCreated: serverTimestampAsDate(), // this gets re-set when saving to db
    uid: userSimple.uid,                  // this gets re-set when saving to db
    userEmail: userSimple.email,          // this gets re-set when saving to db
    userCreatedName: userSimple.name,     // this gets re-set when saving to db
    dateModified: serverTimestampAsDate(),// this gets re-set when saving to db
    userModifiedUid: userSimple.uid,      // this gets re-set when saving to db
    userModifiedEmail: userSimple.email,  // this gets re-set when saving to db
    userModifiedName: userSimple.name,    // this gets re-set when saving to db
  }

  return invoice
}

export function addMetadataModifiedInvoices(updateObj: Partial<InvoiceType>, userDetails: UserDetailsType) {
  updateObj.dateModified = serverTimestampAsDate()
  updateObj.userModifiedUid = userDetails.id
  updateObj.userModifiedEmail = userDetails.email
  updateObj.userModifiedName = userDetails.displayNameEn
}
