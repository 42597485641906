import { useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { DeleteButton } from 'src/components/Buttons/DeleteButton'
import { MoveUpDownButtons } from 'src/components/Buttons/MoveUpDownButtons'
import { EditableFieldExpenses } from 'src/components/EditableField/EditableFieldExpenses'
import { EditableFieldTypeahead } from 'src/components/EditableFieldTypeahead/EditableFieldTypeahead'
import { GeneralExpenseRowType, GeneralExpenseType } from 'src/types/types_generalexpense'
import { formatNum } from 'src/util/util_formatnum'
import { laxCalc } from 'src/util/util_misc'
import { getNewGeneralExpenseItem, getTotalAmount } from '../util_generalexpense'
import { GeneralExpenseReceipts } from './GeneralExpenseReceipts'


interface ExpenseDetailsTableProps {
  generalExpense: GeneralExpenseType
  autosaveOrSetNewStep: (
    userAction: string,
    updateObj: Partial<GeneralExpenseType>,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => void
  isReadOnly: boolean
}

export function ExpenseDetailsTable({
  generalExpense,
  autosaveOrSetNewStep,
  isReadOnly,
}: ExpenseDetailsTableProps) {


  const [editedCell, setEditedCell] = useState<string | null>(null)
  // const [itemList, setItemList] = useState([])

  const itemList = generalExpense.itemList

  const rowIsEmpty = (row: GeneralExpenseRowType) => {
    if (row.isTransportation) {
      if (row.transportationMode) { console.log('row not empty: 1'); return false }
      if (row.transportationLine) { console.log('row not empty: 2'); return false }
      if (row.transportationFrom) { console.log('row not empty: 3'); return false }
      if (row.transportationTo) { console.log('row not empty: 4'); return false }
    } else {
      if (row.description) { console.log('row not empty: 5'); return false }
    }

    if (row.unitCost) { console.log('row not empty: 6'); return false }
    if (row.quantity !== 1) { console.log('row not empty: 7'); return false }
    if (row.receipts.length) { console.log('row not empty: 8'); return false }
    if (row.memorandum) { console.log('row not empty: 9'); return false }
    console.log('row empty'); return true
  }

  return (
    <table className='table mb-1 inputtingTable'>
      <colgroup>
        <col style={{ width: '2em' }} />
        <col style={{ width: '4em' }} />
        <col style={{ width: '6em' }} />
        <col style={{ width: '6em' }} />
        <col style={{ width: '6em' }} />
        <col style={{ width: '6em' }} />
        <col style={{ width: '6em' }} />
        <col style={{ width: '5ch' }} />
        <col style={{ width: '6em' }} />
        <col style={{ width: '6em' }} />
        <col style={{ width: '12em' }} />
        <col style={{ width: '4em' }} />
      </colgroup>
      <thead>
        <tr className='row-header'>
          <th></th>
          <th>№</th>
          <th colSpan={4}>Description
            {/* <br />Line / From / To */}
          </th>
          <th>Unit cost</th>
          <th>Qty</th>
          <th>Total</th>
          <th>Receipt</th>
          <th>Memo</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {itemList.map((row: GeneralExpenseRowType, index: number) => {

          const rowInfo = {
            rowid: row.id,
            tableid: 'generalExpenseDetail',
            isClickableToEdit: !isReadOnly,
            editedCell: editedCell,
            setEditedCell: setEditedCell,
          }

          return (
            <tr key={index}>
              <td>
              </td>
              <td>
                {1 + index}
              </td>
              {row.isTransportation ? (
                <>
                  <td>
                    <div className='transportationTitle'>Mode</div>
                    <EditableFieldTypeahead
                      tableid='generalExpenseDetail'
                      rowid={row.id}
                      fieldname='transportationMode'
                      currentValue={row.transportationMode}
                      isClickableToEdit={!isReadOnly}
                      editedCell={editedCell}
                      setEditedCell={setEditedCell}
                      callbackCommitChange={(dbvalue) => {
                        if (row.transportationMode === dbvalue)
                          return // no change

                        const updateObj: Partial<GeneralExpenseType> = {
                          itemList: itemList.map((item, i) => {
                            if (i === index) {
                              const newItem: GeneralExpenseRowType = {
                                ...item,
                                transportationMode: dbvalue,
                              }
                              return newItem
                            } else {
                              return item
                            }
                          }),
                        }
                        autosaveOrSetNewStep(`Change transportation mode to ‘${dbvalue}’`, updateObj, 'u')
                        setEditedCell(null)
                      }}
                      typeaheadOptionsList={['Train', 'Bus', 'Shinkansen', 'Taxi', 'Bike rental', 'Other']}
                      // menuMinWidth='7.5rem'
                      hasButtonForEditing={false}
                    />
                  </td>
                  <td>
                    <div className='transportationTitle'>Line</div>
                    <EditableFieldExpenses
                      fieldname='transportationLine'
                      currentValue={row.transportationLine}
                      validationType=''
                      previousField='transportationMode'
                      nextField='transportationFrom'
                      {...rowInfo}
                      customSaveCallback={(dbvalue) => {
                        if (row.transportationLine === dbvalue)
                          return // no change

                        const updateObj: Partial<GeneralExpenseType> = {
                          itemList: itemList.map((item, i) => {
                            if (i === index) {
                              const newItem: GeneralExpenseRowType = {
                                ...item,
                                transportationLine: dbvalue,
                              }
                              return newItem
                            } else {
                              return item
                            }
                          }),
                        }
                        autosaveOrSetNewStep(`Change transportation line to ‘${dbvalue}’`, updateObj, 'u')
                      }}
                    />
                  </td>
                  <td>
                    <div className='transportationTitle'>From</div>
                    <EditableFieldExpenses
                      fieldname='transportationFrom'
                      currentValue={row.transportationFrom}
                      validationType=''
                      previousField='transportationLine'
                      nextField='transportationTo'
                      {...rowInfo}
                      customSaveCallback={(dbvalue) => {
                        if (row.transportationFrom === dbvalue)
                          return // no change

                        const updateObj: Partial<GeneralExpenseType> = {
                          itemList: itemList.map((item, i) => {
                            if (i === index) {
                              const newItem: GeneralExpenseRowType = {
                                ...item,
                                transportationFrom: dbvalue,
                              }
                              return newItem
                            } else {
                              return item
                            }
                          }),
                        }
                        autosaveOrSetNewStep(`Change transportation origin to ‘${dbvalue}’`, updateObj, 'u')
                      }}
                    />
                  </td>
                  <td>
                    <div className='transportationTitle'>To</div>
                    <EditableFieldExpenses
                      fieldname='transportationTo'
                      currentValue={row.transportationTo}
                      validationType=''
                      previousField='transportationFrom'
                      nextField='unitCost'
                      {...rowInfo}
                      customSaveCallback={(dbvalue) => {
                        if (row.transportationTo === dbvalue)
                          return // no change

                        const updateObj: Partial<GeneralExpenseType> = {
                          itemList: itemList.map((item, i) => {
                            if (i === index) {
                              const newItem: GeneralExpenseRowType = {
                                ...item,
                                transportationTo: dbvalue,
                              }
                              return newItem
                            } else {
                              return item
                            }
                          }),
                        }
                        autosaveOrSetNewStep(`Change transportation destination to ‘${dbvalue}’`, updateObj, 'u')
                      }}
                    />
                  </td>
                </>
              ) : (
                <>
                  <td colSpan={4}>
                    <EditableFieldExpenses
                      fieldname='description'
                      currentValue={row.description}
                      validationType=''
                      //previousField=''
                      nextField='unitCost'
                      {...rowInfo}
                      customSaveCallback={(dbvalue) => {
                        if (row.description === dbvalue)
                          return // no change

                        const updateObj: Partial<GeneralExpenseType> = {
                          itemList: itemList.map((item, i) => {
                            if (i === index) {
                              const newItem: GeneralExpenseRowType = {
                                ...item,
                                description: dbvalue,
                              }
                              return newItem
                            } else {
                              return item
                            }
                          }),
                        }
                        autosaveOrSetNewStep(`Change item description to ‘${dbvalue}’`, updateObj, 'u')
                      }}
                    />
                  </td>
                </>
              )}
              <td>
                <EditableFieldExpenses
                  fieldname='unitCost'
                  currentValue={row.unitCost}
                  validationType='number'
                  previousField={row.isTransportation ? 'transportationTo' : 'description'}
                  nextField='quantity'
                  {...rowInfo}
                  customSaveCallback={(dbvalue) => {
                    if (row.unitCost === dbvalue)
                      return // no change

                    const itemListNew = itemList.map((item, i) => {
                      if (i === index) {
                        const newItem: GeneralExpenseRowType = {
                          ...item,
                          unitCost: dbvalue,
                          totalCost: laxCalc(dbvalue * item.quantity, dbvalue, item.quantity),
                        }
                        return newItem
                      } else {
                        return item
                      }
                    })

                    const updateObj: Partial<GeneralExpenseType> = {
                      itemList: itemListNew,
                      totalAmount: getTotalAmount(itemListNew),
                    }
                    autosaveOrSetNewStep(`Change item unit cost to ‘${formatNum(dbvalue)}’`, updateObj, 'u')
                  }}
                />
              </td>
              <td>
                <EditableFieldExpenses
                  fieldname='quantity'
                  currentValue={row.quantity}
                  validationType='number'
                  previousField='unitCost'
                  nextField='memorandum'
                  {...rowInfo}
                  customSaveCallback={(dbvalue) => {
                    if (row.quantity === dbvalue)
                      return // no change

                    const itemListNew = itemList.map((item, i) => {
                      if (i === index) {
                        const newItem: GeneralExpenseRowType = {
                          ...item,
                          quantity: dbvalue,
                          totalCost: laxCalc(item.unitCost * dbvalue, item.unitCost, dbvalue),
                        }
                        return newItem
                      } else {
                        return item
                      }
                    })

                    const updateObj: Partial<GeneralExpenseType> = {
                      itemList: itemListNew,
                      totalAmount: getTotalAmount(itemListNew),
                    }
                    autosaveOrSetNewStep(`Change item quantity to ‘${formatNum(dbvalue)}’`, updateObj, 'u')
                  }}
                />
              </td>
              <td>
                {formatNum(row.totalCost)}
              </td>
              <td>
                <GeneralExpenseReceipts
                  isReadOnly={isReadOnly}
                  isExpandable={!isReadOnly}
                  row={row}
                  tableid='generalExpenseDetail'
                  callbackUploadSuccess={(uploadedFilePathsAndURLs) => {
                    const updateObj: Partial<GeneralExpenseType> = {
                      itemList: itemList.map((item, i) => {
                        if (i === index) {
                          const newItem: GeneralExpenseRowType = {
                            ...item,
                            receipts: [...item.receipts, ...uploadedFilePathsAndURLs],
                          }
                          return newItem
                        } else {
                          return item
                        }
                      }),
                      receiptsAll: [...(generalExpense.receiptsAll ?? []), ...uploadedFilePathsAndURLs],
                    }
                    autosaveOrSetNewStep(`Uploaded file ${uploadedFilePathsAndURLs.map((f) => `[${f.storagePath}]`).join('')}`, updateObj, 'u')
                  }}
                  handleDeleteFile={(file) => {
                    const updateObj: Partial<GeneralExpenseType> = {
                      itemList: itemList.map((item, i) => {
                        if (i === index) {
                          const newItem: GeneralExpenseRowType = {
                            ...item,
                            receipts: item.receipts.filter((f) => f.storagePath !== file.storagePath),
                          }
                          return newItem
                        } else {
                          return item
                        }
                      }),
                    }
                    autosaveOrSetNewStep(`Deleted file ‘${file.storagePath}’`, updateObj, 'u')
                  }}
                  generalExpenseIdForLog={generalExpense.id}
                />
              </td>
              <td>
                <EditableFieldExpenses
                  fieldname='memorandum'
                  currentValue={row.memorandum}
                  validationType=''
                  previousField='cost'
                  {...rowInfo}
                  customSaveCallback={(dbvalue) => {
                    if (row.memorandum === dbvalue)
                      return // no change

                    const updateObj: Partial<GeneralExpenseType> = {
                      itemList: itemList.map((item, i) => {
                        if (i === index) {
                          const newItem: GeneralExpenseRowType = {
                            ...item,
                            memorandum: dbvalue,
                          }
                          return newItem
                        } else {
                          return item
                        }
                      }),
                    }
                    autosaveOrSetNewStep(`Change item memo to ‘${formatNum(dbvalue)}’`, updateObj, 'u')
                  }}
                />
              </td>
              <td>
                {!isReadOnly && (
                  <div className='d-flex'>

                    <DeleteButton onClick={() => {
                      const isEmpty = rowIsEmpty(row)
                      if (!isEmpty && !window.confirm('Delete row?'))
                        return

                      const itemListNew = itemList.filter((r, i) => i !== index)
                      const updateObj: Partial<GeneralExpenseType> = {
                        itemList: itemListNew,
                        totalAmount: getTotalAmount(itemListNew),
                      }
                      autosaveOrSetNewStep(`Delete item on row ${1 + index}`, updateObj, 'u')
                    }} />

                    <MoveUpDownButtons index={index} array={itemList}
                      setArray={(itemListNew => {
                        const updateObj: Partial<GeneralExpenseType> = {
                          itemList: itemListNew,
                        }
                        autosaveOrSetNewStep('Reorder rows', updateObj, 'u')
                      })} />

                  </div>
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td></td>
          <td colSpan={4}></td>
          <td></td>
          <td></td>
          <td className='numeric'></td>
          <td></td>
          <td></td>
          <td>
            {!isReadOnly && (
              <>
                <DropdownButton id='dropdown-button-add' variant='outline-primary btn-sm' title={<i className={'bi bi-plus-circle'}></i>}>
                  <Dropdown.Item onClick={(e) => {
                    e.preventDefault()
                    const updateObj: Partial<GeneralExpenseType> = {
                      itemList: [...itemList, getNewGeneralExpenseItem(false)],
                    }
                    autosaveOrSetNewStep('Add non-transportation item', updateObj, 'u')
                  }}>Non-transportation</Dropdown.Item>
                  <Dropdown.Item onClick={(e) => {
                    e.preventDefault()
                    const updateObj: Partial<GeneralExpenseType> = {
                      itemList: [...itemList, getNewGeneralExpenseItem(true)],
                    }
                    autosaveOrSetNewStep('Add transportation item', updateObj, 'u')
                  }}>Transportation</Dropdown.Item>
                </DropdownButton>
              </>
            )}
          </td>
        </tr>
      </tfoot>
    </table>
  )
}
