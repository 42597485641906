
// inspired by Wanakana but simplified
// https://github.com/WaniKani/WanaKana/blob/master/src/utils/katakanaToHiragana.js

// Below ranges are only relevant for *conversion* between hiragana and katakana.
// For example there are some katakana outside of the range (ヷ 0x30F7) but they
// are not convertible into hiragana.
const HIRAGANA_START = 0x3041;
const HIRAGANA_END = 0x3096;
const KATAKANA_START = 0x30A1;
const KATAKANA_END = 0x30F6;

// Below range is the entire Unicode blocks for Hiragana and Katakana
const KANA_START = 0x3040;
const KANA_END = 0x30FF;

// Super-rough separation between non-kanji and kanji based on
// https://upload.wikimedia.org/wikipedia/commons/0/01/Unifont_Full_Map.png
const KANJI_START = 0x3400;

export function isKatakana(code: number) {
  return code >= KATAKANA_START && code <= KATAKANA_END
}

export function isHiragana(code: number) {
  return code >= HIRAGANA_START && code <= HIRAGANA_END
}

export function isAnyKana(code: number) {
  return code >= KANA_START && code <= KANA_END
}

export function isKanji(code: number) {
  return code >= KANJI_START
}

export function stringIsAllKana(text: string) {
  for (let i = 0; i < text.length; i++) {
    if (!isAnyKana(text.charCodeAt(i)))
      return false
  }
  return true
}

export function containsKanji(text: string) {
  for (let i = 0; i < text.length; i++) {
    if (isKanji(text.charCodeAt(i)))
      return true
  }
  return false
}

export function getCodeHiraFromKata(code: number) {
  return code + (HIRAGANA_START - KATAKANA_START);
}

export function getCodeKataFromHira(code: number) {
  return code + (KATAKANA_START - HIRAGANA_START);
}

export function katakanaToHiragana(text: string) {
  let result = ''
  for (let i = 0; i < text.length; i++) {
    const code = text.charCodeAt(i);
    if (isKatakana(code)) {
      const codeHira = getCodeHiraFromKata(code);
      const hiraChar = String.fromCharCode(codeHira);
      result += hiraChar
    } else {
      result += text[i]
    }
  }
  // console.log(`${text}→${result}`)
  return result
}
