import { SearchResponse } from '@algolia/client-search'
import { QuerySnapshot, collection, onSnapshot, query, where } from 'firebase/firestore'
import { useEffect, useMemo, useState } from 'react'
import { Form, Modal, Tab, Tabs } from 'react-bootstrap'
import { BeatLoader } from 'react-spinners'
import { useAppContext } from 'src/hooks/useAppContext'
import { RequestListTable } from 'src/pages/Requests/RequestsList/TableParts/RequestListTable'
import { RequestListTableRow } from 'src/pages/Requests/RequestsList/TableParts/RequestListTableRow'
import { useSearchRequestList } from 'src/pages/Requests/RequestsList/useSearchRequestList'
import { TourRequestHitType, TourRequestType } from 'src/types/types_tourrequest'
import { ButtonTW } from '../Buttons/ButtonTW'
import { RequestQuickSummaryGrid } from '../ContextMenus/RequestQuickSummaryGrid'


interface RequestCodeSelectorProps {
  tripCode: string;
  paxName: string;
  setRequestData: (data: { tourrequestId: string, requestCode: string, paxName: string }) => void;
  classNameDivLabel?: string;
  classNameDivInput?: string;
  disabled?: boolean;
}

export function RequestCodeSelector({
  tripCode,
  paxName,
  setRequestData,
  classNameDivLabel,
  classNameDivInput,
  disabled,
}: RequestCodeSelectorProps) {

  const { db, setDbError, userDetails, _lang } = useAppContext()

  const [selectedTab, setSelectedTab] = useState<'search' | 'manual'>('search')

  const [searchField, setSearchField] = useState<string>(`${tripCode ?? ''} ${paxName ?? ''}`.trim())

  const [searchResults, setSearchResults] = useState<SearchResponse<TourRequestHitType> | null>(null)

  const [manualRequestCode, setManualRequestCode] = useState<string>(tripCode)
  const [manualPaxName, setManualPaxName] = useState<string>(paxName)
  useEffect(() => {
    // keep them in sync
    setManualRequestCode(tripCode)
    setManualPaxName(paxName)
    console.log(`tripcode=[${tripCode}]`)
  }, [tripCode, paxName])


  const {
    currentlyTyping,
    waitingForSearchResults,
    onRefreshButtonClick,
  } = useSearchRequestList(searchField, setSearchResults)


  const [popupOpen, setPopupOpen] = useState(false)



  const tablerows = useMemo(() => {
    //if (!isLoading || true) {
    if (!searchResults || disabled)
      return []

    if (searchResults.nbHits === 0)
      return [
        <tr key='noresults'>
          <td colSpan={14} style={{ textAlign: 'center', fontStyle: 'italic' }}>
            No results
          </td>
        </tr>
      ]

    return searchResults.hits.map((tourrequest, index) => {
      return (
        <RequestListTableRow
          key={tourrequest.id}
          tourrequest={tourrequest}
          statusKey='all'
          showAllColumns={false}
          showSalesInfo1={false}
          showPayments={false}
          showExpensesMiniTable={false}
          expandPaymentsTable={false}
          setExpandPaymentsTable={() => { }}
          indexFirstItem={0}
          index={index}
          showTravellerList={[]}
          setShowTravellerList={() => { }}
          hideEditButton={true}
          rowClickCallback={() => {
            setRequestData({
              requestCode: tourrequest.requestCode,
              paxName: tourrequest.travellerName,
              tourrequestId: tourrequest.id,
            })

            // algolia data is not reliable, updates lag behind, so here we do NOT provide the algolia object to the caller
            // if (setTourRequestAlgolia)
            //   setTourRequestAlgolia(tourrequest)

            // setSearchResults(null) // we keep the results in case user opens the popup again
            setPopupOpen(false)
          }}
        />
      )
    })
  }, [searchResults, setRequestData, disabled])

  const resultsTable = useMemo(() => {
    return (
      <RequestListTable
        tablerows={tablerows}
        showAllColumns={false}
        showSalesInfo1={false}
        showPayments={false}
        isLoadingTableRows={waitingForSearchResults}
        columnFilterAppliedData={undefined}
        columnFilterCacheData={undefined}
        allowEditingTourStatus={false}
      />
    )
  }, [tablerows, waitingForSearchResults])

  const [loadingRequest, setLoadingRequest] = useState(false)
  const [requestObj, setRequestObj] = useState<TourRequestType>(null)
  useEffect(() => {
    if (!tripCode || tripCode === 'Multiple') {
      setLoadingRequest(false)
      setRequestObj(null)
      return
    }

    setLoadingRequest(true)

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const numDocs = snapshot.docs.length
      if (numDocs > 1) {
        setDbError(`Multiple requests with code ${tripCode}`)
        throw new Error(`Multiple requests with code ${tripCode}`)
      }

      setLoadingRequest(false)

      if (numDocs === 1) {
        setRequestObj({ ...snapshot.docs[0].data(), id: snapshot.docs[0].id } as TourRequestType)
      } else {
        setRequestObj(null)
      }
    }

    const q = query(collection(db, 'tourrequests'), where('_isDeleted', '==', false), where('requestCode', '==', tripCode))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError(`Getting tour request list by request code [${tripCode}]`, err));

    return unsubscribe

  }, [db, setDbError, tripCode])


  const searchResultsContents =
    searchResults ? resultsTable : null



  return (
    <>
      <div className={`col1 ${classNameDivLabel ?? ''}`}>
        <label htmlFor='tripCode'>{_lang('Request code', '旅コード')}</label>
      </div>
      <div className={classNameDivInput}>

        <div className='white card' style={{
          backgroundColor: 'white',
          borderRadius: '0.5em',
          padding: '0.5em',
          border: '1px solid #ccc',
          boxShadow: '0 0 0.5em rgba(0,0,0,0.1)',
          width: '20em',
          marginBottom: '0.5em',
        }}>
          <div style={{
            display: 'flex',
            alignSelf: 'end',
          }}>
            <ButtonTW variant={disabled ? 'darkgray_outline' : 'blue_outline'} disabled={disabled} onClick={() => {
              if (disabled)
                return
              setPopupOpen(true)
            }}>
              <i className='bi bi-search'></i>{' '}
              Set Request Code
            </ButtonTW>
          </div>

          {tripCode ? (
            requestObj ? (
              <RequestQuickSummaryGrid
                tourrequest={requestObj}
                requestCodeLinksToRequestPage={false}
              />
            ) : (
              <div>
                <div>Request code: <b>{tripCode}</b></div>
                <div>Pax name: <b>{paxName}</b></div>
                {loadingRequest ? (
                  <div>
                    Loading...
                  </div>
                ) : (tripCode !== 'Multiple') ? (
                  <div style={{
                    color: 'darkred'
                  }}>
                    <i className='bi bi-exclamation-triangle'></i>{' '}
                    Trip code <b>{tripCode}</b> doesn’t exist
                  </div>
                ) : null}
              </div>
            )
          ) : (
            <div>
              Select request code by clicking the button above
            </div>
          )}

        </div>


      </div>

      <Modal
        show={popupOpen}
        onHide={() => setPopupOpen(false)}
        dialogClassName='modal-90w'
      >

        {/* <Modal.Header closeButton>
          {/* <Modal.Title>Select Request Code</Modal.Title> * /}

        </Modal.Header> */}
        <Modal.Body>

          <div style={{
            position: 'absolute',
            right: '0.5em',
            top: '0.5em',
            fontSize: '1.5em',
          }}>
            <i className='bi bi-x-square' style={{ cursor: 'pointer' }} onClick={() => setPopupOpen(false)}></i>
          </div>

          <Tabs id='tabs' className='mb-3'
            activeKey={selectedTab}
            onSelect={(k) => {
              if (k === 'multiple') {
                setRequestData({
                  requestCode: 'Multiple',
                  paxName: '',
                  tourrequestId: null,
                })
                setPopupOpen(false)
                return
              }
              setSelectedTab(k as 'search' | 'manual')
            }}>
            <Tab eventKey='search' title='Search'>

              <div className='tw-pb-4'>
                <div className='tw-flex tw-items-center tw-gap-4 tw-pb-2'>
                  <div className='tw-pr-4'>
                    <b>Search query:</b>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <input
                      id='tripCode'
                      disabled={disabled}
                      className={`form-control ${currentlyTyping ? 'currentlyTyping' : ''}`}
                      value={searchField}
                      onChange={(e) => {
                        console.log('search', e.target.value)
                        setSearchField(e.target.value)
                      }}
                      autoFocus={true}
                      onFocus={(e) => e.target.select()}
                    />
                  </div>
                  <div>
                    <ButtonTW variant='blue_outline' onClick={() => {
                      onRefreshButtonClick()
                    }}><i className='bi bi-arrow-repeat'></i></ButtonTW>
                  </div>
                </div>
                <div>
                  <i className='bi bi-info-circle'></i>{' '}
                  You can search by Request Code, customer name, agency name, travel designer name, etc.
                </div>
              </div>

              <div>
                {(waitingForSearchResults || searchResults) ? (
                  <>
                    <div>
                      {(waitingForSearchResults && !searchResults) ? ( // if searchResults is not null, loading state will be indicated by transparency on results table. in that case, we don't show the BeatLoader as showing it shifts the layout down
                        <div className='barloader'>
                          <BeatLoader />
                        </div>
                      ) : null}
                    </div>
                    <div className='colSpanAll requestSearchResults' style={{
                      border: '1px solid #aaa',
                      overflow: 'scroll',
                    }}>
                      {searchResultsContents}
                    </div>
                  </>
                ) : null}


              </div>

            </Tab>
            <Tab eventKey='multiple' title='Set to “Multiple”'>

            </Tab>
            <Tab eventKey='manual' title='Set manually'>
              <div style={{
                display: 'grid',
                gridTemplateColumns: '8em 1fr',
                gap: '1em',
              }}>
                <div className='col1 pt-2 fw-bold'>Request Code</div>
                <div>
                  <Form.Control type='text' value={manualRequestCode} onChange={(e) => setManualRequestCode(e.target.value)} />
                </div>

                <div className='col1 pt-2 fw-bold'>Pax name</div>
                <div>
                  <Form.Control type='text' value={manualPaxName} onChange={(e) => setManualPaxName(e.target.value)} />
                </div>

                <div className='col1 pt-2'></div>
                <div>
                  <ButtonTW variant='blue' onClick={() => {
                    setRequestData({
                      requestCode: manualRequestCode,
                      paxName: manualPaxName,
                      tourrequestId: null,
                    })
                    setPopupOpen(false)
                  }}>Set Request code and pax name</ButtonTW>
                </div>

              </div>

            </Tab>
          </Tabs>

        </Modal.Body>
        {/* <Modal.Footer> */}
        {/* <ButtonTW variant="blue" onClick={() => {
            setPopupOpen(false)
          }}>
            OK
          </ButtonTW> */}
        {/* <ButtonTW variant="bsDarkGray" onClick={() => {
            setPopupOpen(false)
          }}>
            Cancel
          </ButtonTW> */}
        {/* </Modal.Footer> */}

      </Modal>

    </>
  )
}
