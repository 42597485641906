import { dateFormatJpShort } from 'src/util/dateformattools';
import { formatNum } from 'src/util/util_formatnum';
import { EditableField, ValidationType } from './EditableField';


interface EditableFieldExpensesProps {
  rowid: string;
  tableid: string;
  fieldname: string;
  currentValue: any;
  validationType: ValidationType;
  isClickableToEdit: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  nextField?: string;
  previousField?: string;
  customSaveCallback: (dbvalue: any) => void;
  callbackOnChange?: (value: string) => void; // called on each key press. used for showing station name suggestions etc.
  isTextArea?: boolean;
}

export function EditableFieldExpenses({
  rowid,
  tableid,
  fieldname,
  currentValue,
  validationType,
  isClickableToEdit,
  editedCell,
  setEditedCell,
  nextField,
  previousField,
  customSaveCallback,
  callbackOnChange, // called on each key press. used for showing station name suggestions etc.
  isTextArea,
}: EditableFieldExpensesProps) {


  // convert non-string types (number, date) to string for displaying to user
  if (validationType === 'date') {
    currentValue = dateFormatJpShort(currentValue)
  }
  // if (validationType === 'datepicker')
  //   currentValue = dateFormatJpShort(currentValue)
  // -> now currentValue comes in as Date, and is fed to date picker as Date
  if (validationType === 'number') {
    // currentValue can be null
    currentValue = formatNum(currentValue)
  }

  const callbackCommitChange = (dbvalue: any, tabKey: -1 | 1) => {

    customSaveCallback(dbvalue)

    if (tabKey === 1 && nextField) {
      const nextFieldId = `${tableid}_${rowid}_${nextField}`
      setEditedCell(nextFieldId)
    } else if (tabKey === -1 && previousField) {
      const previousFieldId = `${tableid}_${rowid}_${previousField}`
      setEditedCell(previousFieldId)
    } else {
      setEditedCell('')
    }
  }

  return (
    <EditableField
      tableid={tableid}
      rowid={rowid}
      fieldname={fieldname}
      validationType={validationType}
      currentValue={currentValue}
      isClickableToEdit={isClickableToEdit}
      editedCell={editedCell}
      setEditedCell={setEditedCell}
      callbackCommitChange={callbackCommitChange}
      callbackOnChange={callbackOnChange} // on each key press
      isTextArea={isTextArea}
    />
  )
}
