

export function stripFinalLf(contents: string) {
  if (contents[contents.length - 1] === '\n') {
    if (contents[contents.length - 2] === '\r') {
      //unexpected
      throw new Error('File has CrLf')
    }
    contents = contents.substring(0, contents.length - 1)
  }
  return contents
}

