import { Form } from 'react-bootstrap';



interface HoursDropdownProps {
  hours: number;
  setHours: (value: number) => void;
  className?: string;
}

export function HoursDropdown({
  hours,
  setHours,
  className,
}: HoursDropdownProps) {


  return (
    <Form.Select id='dropdown-select-guide-hours' className={className} value={hours} style={{
      fontSize: '1em',
    }} onChange={(e) => {
      setHours(Number(e.currentTarget.value))
    }
    }>
      {[...Array(12).keys()].map((i) => {
        const n = i + 1
        return (
          <option key={n} value={n}>{n}hr</option>
        )
      })}
    </Form.Select>
  )
}
