import { useState } from 'react';
import { ColumnFilterButton } from 'src/components/ColumnFilters/ColumnFilterButton';
import { ColumnFilterPopupDate } from 'src/components/ColumnFilters/ColumnFilterPopup_Date';
import { ColumnFilterPopupSimpleAmount } from 'src/components/ColumnFilters/ColumnFilterPopup_SimpleAmount';
import { ColumnFilterPopupSimpleDate } from 'src/components/ColumnFilters/ColumnFilterPopup_SimpleDate';
import { ColumnFilterPopupString } from 'src/components/ColumnFilters/ColumnFilterPopup_String';
import { InfoToolTip } from 'src/components/ToolTip/InfoToolTip';
import { useAppContext } from 'src/hooks/useAppContext';
import { FreeePadlock } from 'src/pages/Invoices/FreeePadlock';
import { userrole_canEditFreee } from 'src/util/user_roles';
import { RequestListColumnFilterAppliedData, RequestListColumnFilterCacheData } from '../../types_tourrequest_state';
import { SalesInfo1th } from './SalesInfo1th';


type FilterColumnsType =
  'teamCategory'
  | 'customerType'
  | 'agencyId'
  | 'country'
  | 'paxName'
  | 'requestCode'
  | 'tourStart'
  | 'tourEnd'
  | 'paymentAmount'
  | 'paymentDate'
  | null



interface ExtendedRequestListTableProps {
  // these props are used in main table listing the requests, but not in request search results, nor in the popup request code selector
  freeeUnlocked: boolean;
  setFreeeUnlocked: (value: boolean) => void;
}

interface RequestListTableProps {
  tablerows: JSX.Element[];
  showAllColumns: boolean;
  showSalesInfo1: boolean;
  showPayments: boolean;
  columnFilterCacheData: RequestListColumnFilterCacheData;
  columnFilterAppliedData: RequestListColumnFilterAppliedData;
  extendedProps?: ExtendedRequestListTableProps;
  isLoadingTableRows: boolean;
  allowEditingTourStatus: boolean;
}

export function RequestListTable({
  tablerows,
  showAllColumns,
  showSalesInfo1,
  showPayments,
  columnFilterCacheData,
  columnFilterAppliedData,
  extendedProps,
  isLoadingTableRows,
  allowEditingTourStatus,
}: RequestListTableProps) {

  const { userDetails } = useAppContext()

  const [filterPopupColumn, setFilterPopupColumn] = useState<FilterColumnsType>()


  return (
    <table className={`
        table-requests
        tw-border-collapse
        [&_th]:tw-align-middle
        [&_th]:tw-whitespace-nowrap
        [&_td]:tw-align-top
        [&>*>tr>*]:tw-bg-white
        [&>*>tr>*]:tw-p-2
        [&>*>tr>*]:tw-border-b
        [&>*>tr>*]:tw-border-solid
        [&>*>tr>*]:tw-border-slate-200
        ${isLoadingTableRows ? 'tw-opacity-50' : ''}
      `}>
      <thead>
        <tr>
          <th></th>
          {showAllColumns && (
            <>
              <th>Row<br />№</th>
              <th>ID</th>
              <th>Kintone<br />record №</th>
            </>
          )}
          <th>
            Team /<br />
            category
            {columnFilterCacheData && (
              <>
                {' '}
                <ColumnFilterButton
                  isPopupOpen={filterPopupColumn === 'teamCategory'}
                  isFilterActive={!!columnFilterAppliedData.appliedFilterTeamCategory}
                  openPopup={() => setFilterPopupColumn('teamCategory')}
                  closePopup={() => setFilterPopupColumn(null)}
                  columnName='tourrequest/teamCategory'
                />
                <ColumnFilterPopupString
                  urlParameterName='teamCategory'
                  allValues={columnFilterCacheData.filterCacheTeamCategories.map((item) => item.items).flat()} // todo: separate by first level title with headers
                  appliedFilter={columnFilterAppliedData.appliedFilterTeamCategory}
                  popupIsOpen={filterPopupColumn === 'teamCategory'}
                  closePopup={() => setFilterPopupColumn(null)}
                  sortCaretProps={null}
                />
              </>
            )}
          </th>
          <th>
            Customer type
            {columnFilterCacheData && (
              <>
                {' '}
                <ColumnFilterButton
                  isPopupOpen={filterPopupColumn === 'customerType'}
                  isFilterActive={!!columnFilterAppliedData.appliedFilterCustomerType}
                  openPopup={() => setFilterPopupColumn('customerType')}
                  closePopup={() => setFilterPopupColumn(null)}
                  columnName='tourrequest/customerType'
                />
                <ColumnFilterPopupString
                  urlParameterName='customerType'
                  allValues={columnFilterCacheData.filterCacheCustomerTypes}
                  appliedFilter={columnFilterAppliedData.appliedFilterCustomerType}
                  popupIsOpen={filterPopupColumn === 'customerType'}
                  closePopup={() => setFilterPopupColumn(null)}
                  sortCaretProps={null}
                />
              </>
            )}
          </th>
          {showAllColumns && (
            <>
              <th>Request №</th>
            </>
          )}
          <th>
            Request code
            {columnFilterCacheData && (
              <>
                {' '}
                <ColumnFilterButton
                  isPopupOpen={filterPopupColumn === 'requestCode'}
                  isFilterActive={!!columnFilterAppliedData.appliedFilterRequestCode}
                  openPopup={() => setFilterPopupColumn('requestCode')}
                  closePopup={() => setFilterPopupColumn(null)}
                  columnName='tourrequest/requestCode'
                />
                <ColumnFilterPopupString
                  urlParameterName='requestCode'
                  allValues={columnFilterCacheData.filterCacheRequestCodes}
                  appliedFilter={columnFilterAppliedData.appliedFilterRequestCode}
                  popupIsOpen={filterPopupColumn === 'requestCode'}
                  closePopup={() => setFilterPopupColumn(null)}
                  sortCaretProps={null}
                />
              </>
            )}
          </th>
          <th>
            Agency/platform
            {columnFilterCacheData && (
              <>
                {' '}
                <ColumnFilterButton
                  isPopupOpen={filterPopupColumn === 'agencyId'}
                  isFilterActive={!!columnFilterAppliedData.appliedFilterAgency}
                  openPopup={() => setFilterPopupColumn('agencyId')}
                  closePopup={() => setFilterPopupColumn(null)}
                  columnName='tourrequest/agencyId'
                />
                <ColumnFilterPopupString
                  urlParameterName='agencyId'
                  allValues={columnFilterCacheData.filterCacheAgencies}
                  appliedFilter={columnFilterAppliedData.appliedFilterAgency}
                  popupIsOpen={filterPopupColumn === 'agencyId'}
                  closePopup={() => setFilterPopupColumn(null)}
                  sortCaretProps={null}
                />
              </>
            )}

          </th>
          <th>
            Pax name
            {columnFilterCacheData && (
              <>
                {' '}
                <ColumnFilterButton
                  isPopupOpen={filterPopupColumn === 'paxName'}
                  isFilterActive={!!columnFilterAppliedData.appliedFilterPaxName}
                  openPopup={() => setFilterPopupColumn('paxName')}
                  closePopup={() => setFilterPopupColumn(null)}
                  columnName='tourrequest/paxName'
                />
                <ColumnFilterPopupString
                  urlParameterName='paxName'
                  allValues={columnFilterCacheData.filterCachePaxNames}
                  appliedFilter={columnFilterAppliedData.appliedFilterPaxName}
                  popupIsOpen={filterPopupColumn === 'paxName'}
                  closePopup={() => setFilterPopupColumn(null)}
                  sortCaretProps={null}
                />
              </>
            )}
          </th>
          <th>
            Country
            {columnFilterCacheData && (
              <>
                {' '}
                <ColumnFilterButton
                  isPopupOpen={filterPopupColumn === 'country'}
                  isFilterActive={!!columnFilterAppliedData.appliedFilterCountry}
                  openPopup={() => setFilterPopupColumn('country')}
                  closePopup={() => setFilterPopupColumn(null)}
                  columnName='tourrequest/country'
                />
                <ColumnFilterPopupString
                  urlParameterName='country'
                  allValues={columnFilterCacheData.filterCacheCountries}
                  appliedFilter={columnFilterAppliedData.appliedFilterCountry}
                  popupIsOpen={filterPopupColumn === 'country'}
                  closePopup={() => setFilterPopupColumn(null)}
                  sortCaretProps={null}
                />
              </>
            )}
          </th>
          {showAllColumns && (
            <>
              <th>State/region</th>
            </>
          )}

          {showSalesInfo1 && <SalesInfo1th />}

          <th>
            Tour start
            {columnFilterCacheData && (
              <>
                {' '}
                <ColumnFilterButton
                  isPopupOpen={filterPopupColumn === 'tourStart'}
                  isFilterActive={!!columnFilterAppliedData.appliedFilterTourStart}
                  openPopup={() => setFilterPopupColumn('tourStart')}
                  closePopup={() => setFilterPopupColumn(null)}
                  columnName='tourrequest/tourStart'
                />
                <ColumnFilterPopupDate
                  urlParameterName='tourStart'
                  allDates={columnFilterCacheData.filterCacheTourStartDates}
                  appliedFilter={columnFilterAppliedData.appliedFilterTourStart}
                  popupIsOpen={filterPopupColumn === 'tourStart'}
                  closePopup={() => setFilterPopupColumn(null)}
                  sortCaretProps={null}
                  emptyValueSelectorTitle='Empty'
                />
              </>
            )}
          </th>
          <th>
            Tour end
            {columnFilterCacheData && (
              <>
                {' '}
                <ColumnFilterButton
                  isPopupOpen={filterPopupColumn === 'tourEnd'}
                  isFilterActive={!!columnFilterAppliedData.appliedFilterTourEnd}
                  openPopup={() => setFilterPopupColumn('tourEnd')}
                  closePopup={() => setFilterPopupColumn(null)}
                  columnName='tourrequest/tourEnd'
                />
                <ColumnFilterPopupDate
                  urlParameterName='tourEnd'
                  allDates={columnFilterCacheData.filterCacheTourEndDates}
                  appliedFilter={columnFilterAppliedData.appliedFilterTourEnd}
                  popupIsOpen={filterPopupColumn === 'tourEnd'}
                  closePopup={() => setFilterPopupColumn(null)}
                  sortCaretProps={null}
                  emptyValueSelectorTitle='Empty'
                />
              </>
            )}
          </th>
          <th>
            <div>Booking</div>
            <div className='tw-flex tw-gap-1'>
              <div>status</div>
              {allowEditingTourStatus && (
                <InfoToolTip
                  tooltipId='bookingStatusTableHeader'
                  tooltipPlace='right'
                  tooltipHtml='Click the booking<br>status to change it'
                />
              )}
            </div>
          </th>
          {showAllColumns && (
            <>
              <th>Num of pax</th>
              <th>Date created</th>
              <th>Date modified</th>
            </>
          )}
          <th>Travel<br />Designers</th>
          {showAllColumns && (
            <>
              <th>Status</th>
              <th>Total price</th>
            </>
          )}
          {showPayments && (
            <th>
              Payments received
              {columnFilterCacheData && (
                <>
                  {' '}
                  <ColumnFilterButton
                    isPopupOpen={filterPopupColumn === 'paymentAmount'}
                    isFilterActive={!!columnFilterAppliedData.appliedFilterPaymentAmount}
                    openPopup={() => setFilterPopupColumn('paymentAmount')}
                    closePopup={() => setFilterPopupColumn(null)}
                    columnName='tourrequest/paymentAmount'
                  />
                  <ColumnFilterPopupSimpleAmount
                    urlParameterName='paymentAmount'
                    allAmounts={columnFilterCacheData.filterCachePaymentAmounts}
                    appliedFilter={columnFilterAppliedData.appliedFilterPaymentAmount}
                    popupIsOpen={filterPopupColumn === 'paymentAmount'}
                    closePopup={() => setFilterPopupColumn(null)}
                    sortCaretProps={null}
                  />
                  {' '}
                  <ColumnFilterButton
                    isPopupOpen={filterPopupColumn === 'paymentDate'}
                    isFilterActive={!!columnFilterAppliedData.appliedFilterPaymentDate}
                    openPopup={() => setFilterPopupColumn('paymentDate')}
                    closePopup={() => setFilterPopupColumn(null)}
                    columnName='tourrequest/paymentDate'
                  />
                  <ColumnFilterPopupSimpleDate
                    urlParameterName='paymentDate'
                    allDates={columnFilterCacheData.filterCachePaymentDates}
                    appliedFilter={columnFilterAppliedData.appliedFilterPaymentDate}
                    popupIsOpen={filterPopupColumn === 'paymentDate'}
                    closePopup={() => setFilterPopupColumn(null)}
                    sortCaretProps={null}
                  />
                </>
              )}
              {(userrole_canEditFreee(userDetails.roles) && extendedProps) && (
                <FreeePadlock
                  freeeUnlocked={extendedProps.freeeUnlocked}
                  setFreeeUnlocked={extendedProps.setFreeeUnlocked}
                />
              )}
            </th>
          )}
          {showAllColumns && (
            <>
              <th>Sales info 2</th>
              <th>si2.quotations.length</th>
              <th>Confirmed</th>
              <th>Received<br />deposit</th>
              <th>Received<br />total trip fee</th>
              <th>User created ID</th>
              <th>User created Email</th>
              <th>User created Name</th>
              <th>User modified ID</th>
              <th>User modified Email</th>
              <th>User modified Name</th>
              <th>Special occasion</th>
              <th>Guide preference</th>
              <th>Guide language</th>
              <th>Dietary<br />requirements</th>
              <th>Customer’s<br />interests</th>
              <th>Request comments</th>
              <th>Updated comment</th>

              <th>Accommodation<br />stars</th>
              <th>Accommodation<br />preference</th>
              <th>Pax list</th>
            </>
          )}
          <th>
            {/* actions */}
          </th>
        </tr>
      </thead>
      <tbody>
        {tablerows}
      </tbody>
    </table>
  )
}
