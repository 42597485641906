import { UserDetailsType } from 'src/types/types_user';



interface UserListPermissionsSummaryProps {
  userList: UserDetailsType[];
}

export function UserListPermissionsSummary({
  userList,
}: UserListPermissionsSummaryProps) {

  const usersByRole = new Map<string, UserDetailsType[]>()

  for (const user of userList) {
    const role = user.roles?.mainRole || '[role undefined]' // for newly created users that have never been configured, user.role will be undefined

    if (!usersByRole.has(role)) {
      usersByRole.set(role, [])
    }
    usersByRole.get(role).push(user)
  }

  const sorted = [...usersByRole].sort(([role1, list1], [role2, list2]) => {
    return (list1.length - list2.length) * -1
  })


  return (
    <table className='[&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-800 [&>*>tr>*]:tw-border '>
      <thead>
        <tr>
          <th>Perm</th>
          <th>Users</th>
        </tr>
      </thead>
      <tbody>
        {sorted.map(([role, users]) => {
          return (
            <tr key={role}>
              <td>{role}</td>
              <td>
                <ul>
                  {users.map((user) => (
                    <li key={user.id}>{user.displayNameEn}</li>
                  ))}
                </ul>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
