import { memo, UIEventHandler } from 'react';
import { TourRequestTypeWithDates } from 'src/types/types_tourrequest';
import { arraySum } from 'src/util/util_misc';
import { TourListColType } from '../PageTourCalendar';
import { checkIfMonthBoundary, isHiddenTour } from '../util_tourcalendar';
import { getTourrequestColors } from '../util_tourcalendar_colors';



interface L2_TourListProps {
  TOUR_LIST_COLS: TourListColType[];
  CELL_HEIGHT_EM: number;
  TOURCALENDAR_BOTTOM_BUFFER: number;
  block2height: string;
  ref_L2_TourListGrid: React.RefObject<HTMLDivElement>;
  onScrollL2: UIEventHandler<HTMLDivElement>;
  requests: TourRequestTypeWithDates[];
}


export const L2_TourList = memo(function L2_TourList({
  TOUR_LIST_COLS,
  CELL_HEIGHT_EM,
  TOURCALENDAR_BOTTOM_BUFFER,
  block2height,
  ref_L2_TourListGrid,
  onScrollL2,
  requests,
}: L2_TourListProps) {

  const tourListtotalWidth = arraySum(TOUR_LIST_COLS.map((col) => col.widthEm))

  return (
    <div
      className='L2_tourListGrid'
      style={{
        // putting border on the display:grid element itself causes lots of alignment issues. instead, put the border on the grid cells
        position: 'relative',
        // left: '0em',
        // top: block2top,
        width: `${tourListtotalWidth}em`,
        height: block2height,
        overflowY: 'scroll',
        // we don't put the background-color here as it would overflow in the case of very few tours
        borderBottom: '1px solid #666',
      }}
      ref={ref_L2_TourListGrid}
      onScroll={onScrollL2}
    >
      <div className='vertical-spacer' style={{
        position: 'absolute',
        height: `${(requests.length + TOURCALENDAR_BOTTOM_BUFFER) * CELL_HEIGHT_EM}em`,
        width: '1px',
      }}></div>

      {requests.map((tourrequest: TourRequestTypeWithDates, iTourRequest: number) => {
        const { tourIsFirstOfMonth, tourIsLastOfMonth } = checkIfMonthBoundary(requests, iTourRequest)

        return (
          <div key={tourrequest.id}
            className={
              'tourListRow tourListRowContents '
              + (isHiddenTour(tourrequest) ? 'hiddenTour ' : '')
              + (tourIsFirstOfMonth ? 'firstTourOfMonth ' : '')
              + (tourIsLastOfMonth ? 'lastTourOfMonth ' : '')
              //+ (hoveredRequestRow === iTourRequest ? 'hoveredRequestRow ' : '') // this caused too many rerenders causing the page to become too slow
            }>
            {TOUR_LIST_COLS.map((col, i) => {
              if (!col.show)
                return

              const colStart = i + 1
              let colEnd = i + 2
              let colWidth = col.widthEm

              // special treatment for Explore links to span 2 columns
              const isExploreSeries = tourrequest.agencyOrPlatform === 'Explore'
                && (tourrequest.requestCode.includes(' ') || tourrequest.requestCode.match(/^E[0-9][1-9XYZ].-.+$/))
              if (isExploreSeries) {
                if (i === 0) {
                  colEnd = i + 3
                  colWidth += TOUR_LIST_COLS[i + 1].widthEm
                }
                if (i === 1) {
                  return
                }
              }

              const tourColors = getTourrequestColors(tourrequest)

              return (
                <div key={i} style={{
                  // gridColumn: `${colStart} / ${colEnd}`,
                  position: 'absolute',
                  left: `${TOUR_LIST_COLS.reduce((acc, val, index) => index < i ? acc + val.widthEm : acc, 0)}em`,
                  top: `${iTourRequest * CELL_HEIGHT_EM}em`,
                  width: `${colWidth}em`,
                  height: `${CELL_HEIGHT_EM}em`,
                  backgroundColor: // hoveredRequestRow === iTourRequest ? '#edd8ae' : // this caused too many rerenders causing the page to become too slow
                    tourColors.mainBg,
                  color: // hoveredRequestRow === iTourRequest ? 'black' : // this caused too many rerenders causing the page to become too slow
                    tourColors.mainText,
                  overflow: col.title === 'Code' ? undefined : 'hidden', // don't hide overflow on Code column, so that the aggregator popup display correctly
                }}>
                  {col.func(tourrequest, iTourRequest)}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>


  )
})
