import { useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { useAppContext } from 'src/hooks/useAppContext'
import { FileStoragePathAndDownloadUrlType } from 'src/types/types_invoices'
import { ButtonTW } from '../Buttons/ButtonTW'
import { uploadFilesToFirebase } from './util_fileupload'


interface FileUploadButtonProps {
  itemId: string
  storageFolder: string
  callbackUploadSuccess: (uploadedFilePaths: FileStoragePathAndDownloadUrlType[]) => void
  isLargeButton?: boolean
}

export function FileUploadButton({
  itemId,
  storageFolder,
  callbackUploadSuccess,
  isLargeButton,
}: FileUploadButtonProps) {

  const { storage } = useAppContext()

  const [isUploading, setIsUploading] = useState(false)

  return (
    <>
      {isUploading && (
        <span className='pe-1'>
          <ClipLoader size={15} color='#000' cssOverride={{}} />
        </span>
      )}


      {isLargeButton ? (
        <ButtonTW
          variant='blue_outline'
          className='tw-ml-1 tw-mb-1'
          onClick={() => {
            document.getElementById(`quickUpload_${itemId}`)?.click()
          }}>
          <i className='bi-cloud-arrow-up'></i>
          {' '}
          Upload file
        </ButtonTW>
      ) : (
        <label htmlFor={`quickUpload_${itemId}`} style={{ cursor: 'pointer' }}>
          <i className='bi-cloud-arrow-up'></i>
        </label>
      )}
      <div style={{ display: 'none' }}>
        <input type='file' id={`quickUpload_${itemId}`} onChange={(e) => {
          setIsUploading(true)

          const elemInputFile = e.target

          const filelist = elemInputFile.files // elemInputFile.value is just the file path and name, without the actual file data

          uploadFilesToFirebase(storage, storageFolder, filelist)
            .then((uploadedFilePaths: FileStoragePathAndDownloadUrlType[]) => {

              // if this reset is not done, we can't upload the same file twice in a row as 'onChange' will not trigger when selecting the same file a second time
              e.target.value = '' // https://stackoverflow.com/a/20552042/

              setIsUploading(false)

              callbackUploadSuccess(uploadedFilePaths)
            })
            .catch((uploadError) => {
              console.error('upload error', uploadError)
              window.alert('Upload error: ' + uploadError)
            })
        }} />
      </div>
    </>
  )
}
