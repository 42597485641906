import React from 'react'
import { FileDownloadButton } from 'src/components/FileDownloadUpload/FileDownloadButton'
import { EitherLegType, ModeOfTransportationType, NonTransportationLegType, TransportationLegType } from 'src/types/types_commute'
import { FileStoragePathAndDownloadUrlType } from 'src/types/types_invoices'
import { formatNum } from 'src/util/util_formatnum'


function getModeEmoji(modeOfTransportation: ModeOfTransportationType) {
  const mapping = {
    'Train': '🚃',
    'Bus': '🚌',
    'Shinkansen': '🚄',
    'Taxi': '🚕',
    'Bike rental': '🚲',
    'Other': '🚁',
  }
  const emoji = mapping[modeOfTransportation]
  if (!emoji) return '?'
  return emoji
}


interface TripLegGridProps {
  category: string;
  legList: EitherLegType[];
}

export function TripLegGrid({ category, legList }: TripLegGridProps) {

  // This is the list of trip legs displayed in the main commuting expense page
  // (Not the white table displayed in the Modal for editing purposes)

  if (!legList)
    return null


  const isCommute = category === 'commuteToWork' || category === 'commuteFromWork'
  const isOtherTransportation = category === 'otherTransportationExpenses'
  const isTransportation = isCommute || isOtherTransportation
  const isNonTransportation = category === 'otherNonTransportationExpenses'


  //const legList = commuteMonthly && commuteMonthly[category][sIsoDate]
  let totalCost = 0
  const gridrows = []

  if (isTransportation) {
    (legList as TransportationLegType[]).forEach((leg, index) => {
      gridrows.push(
        <React.Fragment key={index}>
          <div className='tw-col-start-1'>
            {getModeEmoji(leg.modeOfTransportation)}
            {leg.trainLine}
          </div>
          <div>
            {leg.stationFrom}&#x200b;➡&#x200b;
            {leg.stationTo}
          </div>
          <div className='tw-text-right'>
            {formatNum(leg.legCost)}
          </div>
          <div>
            {leg.receipts && leg.receipts.map((filenameOrObj: FileStoragePathAndDownloadUrlType) => {
              const { storagePath: filename, downloadURL } = filenameOrObj
              return <FileDownloadButton
                key={filename}
                filename={filename}
                downloadURL={downloadURL}
                loginfo={`non-commute expenses category=${category}`}
              />
            })}
          </div>
          <div>
            {leg.memorandum}
          </div>
        </React.Fragment>
      )
      totalCost += leg.legCost
    })
  } else { // not isTransportation
    (legList as NonTransportationLegType[]).forEach((item, index) => {
      gridrows.push(
        <React.Fragment key={`other_${index}`}>
          <div className='tw-col-start-1'>
            {item.name}
          </div>
          <div>
            {formatNum(item.cost)}
          </div>
          <div>
            {item.receipts && item.receipts.map((filenameOrObj: FileStoragePathAndDownloadUrlType) => {
              const { storagePath: filename, downloadURL } = filenameOrObj
              return <FileDownloadButton
                key={filename}
                filename={filename}
                downloadURL={downloadURL}
                loginfo={`non-commute expenses category=${category}`}
              />
            })}
          </div>
          <div>
            {item.memorandum}
          </div>
        </React.Fragment>
      )
      totalCost += item.cost
    })
  }

  if (gridrows.length > 1) {
    gridrows.push(
      <React.Fragment key='total'>
        <div className='tw-col-start-1'>
          {/* mode of transportation */}
        </div>
        <div className='tw-text-right'>
          Total
        </div>
        <div className='tw-text-right'>
          {formatNum(totalCost)}
        </div>
        <div></div>
        <div></div>
      </React.Fragment>
    )
  }
  return (
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(5, auto)', // mode, from/to, cost, receipts, memo
      // gap: '0 0.5em',
    }} className='
    tw-border-l tw-border-t tw-border-r-0 tw-border-b-0 tw-border-solid tw-border-slate-400
    [&>div]:tw-border-l-0 [&>div]:tw-border-t-0 [&>div]:tw-border-r [&>div]:tw-border-b  [&>div]:tw-border-solid [&>div]:tw-border-slate-400
    [&>div]:tw-p-1
    '>
      {gridrows}
    </div>
  )
}
