
export function parseDateCsv(text: string) {
  throw new Error('Not implemented')
  //return dayjs.tz(text, 'MM/DD/YYYY', 'Asia/Tokyo').toDate()
}

export function parseDateTimeCsv(text: string) {
  throw new Error('Not implemented')
  // return dayjs.tz(text, 'MM/DD/YYYY HH:mm', 'Asia/Tokyo').toDate()
}
