import { ColumnFilterAmountType, ColumnFilterDateisoType, ColumnFilterPayeeType, ColumnFilterRequestCodeType, ColumnFilterSimpleAmountType, ColumnFilterStringType } from 'src/types/types_columnfilters'
import { InvoiceType } from 'src/types/types_invoices'
import { dateisoFormatJp } from 'src/util/dateformattools'
import { addDaysIso, dateiso_from_parts, dateparts_from_iso, dateparts_from_utc0, try_utc0_from_iso } from 'src/util/datetools'



export function refreshTernaryState<T>(data: Map<T, 0 | 1>) {
  let oneOn = false
  let oneOff = false
  data.forEach((state) => {
    if (state === 1) oneOn = true
    if (state === 0) oneOff = true
  })
  if (oneOn && oneOff) {
    return 0.5
  } else if (oneOn) {
    return 1
  } else {
    return 0
  }
}


function applyDateFilter(dateiso: string, appliedFilter: ColumnFilterDateisoType) {

  // Client side filtering here should only be required when multiple non-contiguous checkboxes have been ticked,
  // as the query will return the entire range of invoices from the earliest payment date to the latest.

  if (appliedFilter.filterMode === 'equals') {
    if (dateiso
      && dateiso >= appliedFilter.filterEquals
      && dateiso < addDaysIso(appliedFilter.filterEquals, 1))
      return null
    return 'payment date does not match equals filter'
  } else if (appliedFilter.filterMode === 'range') {
    if (dateiso
      && (!appliedFilter.filterGreaterThan || dateiso >= appliedFilter.filterGreaterThan)
      && (!appliedFilter.filterSmallerThan || dateiso < addDaysIso(appliedFilter.filterSmallerThan, 1)))
      return null
    return 'payment date not within range'
  } else if (appliedFilter.filterMode === 'checkboxes') {
    if (!dateiso) {
      if (appliedFilter.treeListState.emptyValues === 1)
        return null
      return 'payment date empty, but unpaid invoices not selected'
    } else {
      const [year, month, day] = dateparts_from_iso(dateiso)
      // if (!appliedFilter.treeListState.years.has(year))
      //   return 'year missing'
      const yearState = appliedFilter.treeListState.years.get(year)
      if (yearState.ternarySelected === 1)
        return null
      if (yearState.ternarySelected === 0)
        return 'payment date year not selected'
      // if (!yearState.months.has(month))
      //   return 'month missing'
      const monthState = yearState.months.get(month)
      if (monthState.ternarySelected === 1)
        return null
      if (monthState.ternarySelected === 0)
        return 'payment date month not selected'
      if (monthState.days.get(day) === 0)
        return 'payment date day not selected'
      return null
    }
  }
}

export function clientSideFiltering(
  invoiceList: InvoiceType[],
  appliedFilterDeadline: ColumnFilterDateisoType | null,
  appliedFilterRequestCode: ColumnFilterRequestCodeType | null,
  appliedFilterPayee: ColumnFilterPayeeType | null,
  appliedFilterAmount: ColumnFilterAmountType | null,
  appliedFilterPaymentDate: ColumnFilterDateisoType | null,
) {



  const checkIfInvoiceShouldBeExcluded = (invoice: InvoiceType) => {
    // return null if invoice should be kept, or a string with the reason if invoice should be excluded

    if (invoice._isDeleted)
      return 'invoice deleted'


    if (appliedFilterPayee) {
      if (!appliedFilterPayee.has(invoice.payeeId))
        return 'payeeId does not match filter'
    } else if (appliedFilterRequestCode) {
      if (!appliedFilterRequestCode.has(invoice.tripcode))
        return `invoice tripcode [${invoice.tripcode}] does not match filter`
      return null
    } else if (appliedFilterPaymentDate) {

      return applyDateFilter((!invoice.paymentDateiso || invoice.paymentDateiso === 'Z') ? null : invoice.paymentDateiso, appliedFilterPaymentDate)

    } else if (appliedFilterDeadline) {

      return applyDateFilter(invoice.dateisoDeadline, appliedFilterDeadline)

    } else if (appliedFilterAmount) {
      if (appliedFilterAmount.filterMode === 'equals') {
        if (invoice.amount !== appliedFilterAmount.filterEquals)
          return 'amount does not match equals filter'
        return null
      } else if (appliedFilterAmount.filterMode === 'range') {
        if ((!appliedFilterAmount.filterGreaterThan || invoice.amount >= appliedFilterAmount.filterGreaterThan)
          && (!appliedFilterAmount.filterSmallerThan || invoice.amount <= appliedFilterAmount.filterSmallerThan))
          return null
        else
          return 'amount not within range'
      } else if (appliedFilterAmount.filterMode === 'checkboxes') {
        if (appliedFilterAmount.treeListState.amounts.get(invoice.amount) === 0)
          return 'amount not ticked'
        return null
      }
    } else {

      // if (invoiceIds && !invoiceIds.includes(invoice.id))
      //   return false
    }

    return null
  }



  const invoiceListDisplayed = invoiceList.filter((invoice) => {
    const excludeReason = checkIfInvoiceShouldBeExcluded(invoice)
    if (excludeReason) {
      console.log(`EXCLUDED: ${excludeReason}`)
      return false
    }
    return true
  })

  const filteredOut = invoiceList.filter((invoice) => !invoiceListDisplayed.includes(invoice))

  const filteredOnClient = invoiceList.length - invoiceListDisplayed.length
  if (filteredOnClient > 0) {
    console.log(`FILTERED ON CLIENT: ${filteredOnClient} (${invoiceList.length} → ${invoiceListDisplayed.length})`)
    console.log('filteredOut', filteredOut)
  } else {
    console.log('NO FILTERING ON CLIENT')
  }

  return invoiceListDisplayed

}


export function convertDateListToThreeLevelCache(datelist: string[]) {
  const cache = new Map<number, Map<number, number[]>>() // year => month => days[]
  datelist.forEach((datestr: string) => {
    const dateutc = try_utc0_from_iso(datestr)
    if (!dateutc) {
      // shouldn't really ever happen, but happened on 2024-08-02. string was: '+020255-01'
      console.error(`Invalid date in date cache: [${datestr}]`)
      return
    }
    const [year, month, day] = dateparts_from_utc0(dateutc)
    if (!cache.has(year))
      cache.set(year, new Map<number, number[]>())
    const monthMap = cache.get(year)
    if (!monthMap.has(month))
      monthMap.set(month, [])
    const dayList = monthMap.get(month)
    if (!dayList.includes(day))
      dayList.push(day)
  })

  return cache
}

export function getStringFilterFromParam(paramValue: string | null): ColumnFilterStringType | null {
  if (!paramValue) return null

  let list: string[]
  if (paramValue.match(/^\[.+\]$/)) {
    // enclosed in brackets
    list = paramValue.slice(1, -1).split(',')
  } else {
    list = [paramValue]
  }

  return new Set(list)
}

export function getSimpleAmountFilterFromParam(paramValue: string | null): ColumnFilterSimpleAmountType | null {
  return getStringFilterFromParam(paramValue)
}

export function nodeNotNull(node: JSX.Element | null): node is JSX.Element {
  // Won't be needed with TypeScript 5.5
  return node !== null
}

export const filterBaseUrls = ['/general-expenses/', '/invoices/', '/requests/worklog/', '/requests/list/']
export type BaseUrlType = typeof filterBaseUrls[number]

export function applyFilterToUrl(baseUrl: BaseUrlType, urlParameterName: string, queryparam: string) {
  if (!queryparam)
    return baseUrl
  return `${baseUrl}?${urlParameterName}=${queryparam}`
}


export function getTreeFilterString(appliedFilter: ColumnFilterDateisoType) {
  if (!appliedFilter)
    return ''

  if (appliedFilter.filterMode === 'equals') {
    return dateisoFormatJp(appliedFilter.filterEquals)
  } else if (appliedFilter.filterMode === 'range') {
    return dateisoFormatJp(appliedFilter.filterGreaterThan) + '~' + dateisoFormatJp(appliedFilter.filterSmallerThan)
  } else if (appliedFilter.filterMode === 'checkboxes') {
    const list = []
    for (const [year, yearTree] of appliedFilter.treeListState.years.entries()) {
      if (yearTree.ternarySelected === 1) {
        list.push(year)
      } else if (yearTree.ternarySelected === 0) {
        continue
      } else {
        for (const [month, monthTree] of yearTree.months.entries()) {
          if (monthTree.ternarySelected === 1) {
            list.push(`${year}/${month}`)
          } else if (monthTree.ternarySelected === 0) {
            continue
          } else {
            for (const [day, dayState] of monthTree.days.entries()) {
              if (dayState === 1) {
                list.push(dateisoFormatJp(dateiso_from_parts(year, month, day)))
              }
            }
          }
        }
      }
    }
    return list.join(',')
  }

  const _x: never = appliedFilter
  throw new Error('unreachable')
}
