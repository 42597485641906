import { stringify } from 'csv-stringify/browser/esm';
import { DocumentSnapshot, Query, QuerySnapshot, and, collection, doc, getDocs, onSnapshot, or, query, updateDoc, where } from 'firebase/firestore';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { ColumnFilterButton } from 'src/components/ColumnFilters/ColumnFilterButton';
import { ColumnFilterPopupAmount } from 'src/components/ColumnFilters/ColumnFilterPopup_Amount';
import { ColumnFilterPopupDate } from 'src/components/ColumnFilters/ColumnFilterPopup_Date';
import { ColumnFilterPopupString } from 'src/components/ColumnFilters/ColumnFilterPopup_String';
import { getAmountFilterFromParam, getQueryWhenFilteringByAmount } from 'src/components/ColumnFilters/util_filter_amount';
import { getDateFilterFromParam } from 'src/components/ColumnFilters/util_filter_dates';
import { getQueryWhenFilteringByDeadline } from 'src/components/ColumnFilters/util_filter_dates_deadline';
import { getQueryWhenFilteringByPaymentDate } from 'src/components/ColumnFilters/util_filter_dates_paymentdate';
import { getPayeeFilterFromParam } from 'src/components/ColumnFilters/util_filter_payee';
import { getQueryWhenFilteringByRequestCode } from 'src/components/ColumnFilters/util_filter_requestcode';
import { clientSideFiltering, convertDateListToThreeLevelCache, getStringFilterFromParam } from 'src/components/ColumnFilters/util_filters';
import { SortCaret } from 'src/components/ColumnSorter/SortCaret';
import { useColumnSorter } from 'src/components/ColumnSorter/useColumnSorter';
import { LoadingSpinner } from 'src/components/Spinner/LoadingSpinner';
import { GenericPill, InvoiceStatusPill } from 'src/components/StatusPill/StatusPill';
import { useAppContext } from 'src/hooks/useAppContext';
import { FilterCacheInvoicesType } from 'src/types/objectTypes';
import { ColumnFilterAmountType, ColumnFilterDateisoType, ColumnFilterPayeeType, ColumnFilterRequestCodeType, ThreeLevelDateTree } from 'src/types/types_columnfilters';
import { InvoiceRawType, InvoiceType } from 'src/types/types_invoices';
import { PayeeSimpleType, PayeeType } from 'src/types/types_payee';
import { UserDetailsType } from 'src/types/types_user';
import { dateFormatCsvDate, dateFormatJp } from 'src/util/dateformattools';
import { addDays, addMonthsIso, getTodayIso, getTodayJST } from 'src/util/datetools';
import { userrole_canAddInvoice, userrole_canDeleteInvoices, userrole_canEditAnyInvoice, userrole_canEditFreee, userrole_canListInvoices, userrole_canMarkPaid, userrole_isAdmin } from 'src/util/user_roles';
import { formatNum, formatNumCcy } from 'src/util/util_formatnum';
import { log_db_read } from 'src/util/util_log';
import { arraySum } from 'src/util/util_misc';
import { getMonthList } from 'src/util/util_monthlist';
import { FreeePadlock } from './FreeePadlock';
import { InvoiceTableRow } from './InvoiceTableRow';
import { InvoiceTableRowExtended } from './InvoiceTableRowExtended';
import { ModalPopupMarkPaid, ModalPopupMarkPaidActionType } from './ModalPopupMarkPaid';
import './invoicetable.css';
import { convertInvoice } from './util_convertinvoice';
import { getIsOld } from './util_invoices';


export type InvoiceFieldType =
  'dateisoDeadline'
  | 'tripcode'
  | 'paxname'
  | 'servicePurchased'
  | 'payeeNameEn'
  | 'payeeNameJa'
  | 'amount'
  | 'userEmail'
  | 'paymentDateiso'
  | 'dateCreated'
  | 'dateModified'



interface InvoiceTableProps {
  recentlyInsertedId: string;
  setRecentlyInsertedId: (id: string) => void;
  payeeList: PayeeType[];
}

export function InvoiceTable({ recentlyInsertedId, setRecentlyInsertedId, payeeList }: InvoiceTableProps) {

  const { db, setDbError, userDetails, perm, _lang } = useAppContext()

  const navigate = useNavigate()

  // listing modes:
  // - normal: past unpaid + future
  // - payment month
  // - invoiceIds (NOT USED)
  // - column filters:
  //   * payment date
  //   * deadline
  //   * amount
  //   * request code
  //   * payee id

  const [searchParams] = useSearchParams()
  const paramMonth = searchParams.get('month')
  const paramInvoiceIds = searchParams.get('invoiceIds')
  // column filters:
  const paramDeadline = searchParams.get('deadline')
  const paramRequestCode = searchParams.get('requestCode')
  const paramPayeeId = searchParams.get('payeeId')
  const paramAmount = searchParams.get('amount')
  const paramPaymentDate = searchParams.get('paymentDate')

  const [appliedFilterDeadline, setAppliedFilterDeadline] = useState<ColumnFilterDateisoType | null>(null)
  const [appliedFilterRequestCode, setAppliedFilterRequestCode] = useState<ColumnFilterRequestCodeType | null>(null)
  const [appliedFilterPayee, setAppliedFilterPayee] = useState<ColumnFilterPayeeType | null>(null)
  const [appliedFilterAmount, setAppliedFilterAmount] = useState<ColumnFilterAmountType | null>(null)
  const [appliedFilterPaymentDate, setAppliedFilterPaymentDate] = useState<ColumnFilterDateisoType | null>(null)

  const normalMode = true
    && !paramMonth
    && !paramInvoiceIds
    && !paramDeadline
    && !paramRequestCode
    && !paramPayeeId
    && !paramAmount
    && !paramPaymentDate

  if ((normalMode ? 1 : 0)
    + (paramMonth ? 1 : 0)
    + (paramInvoiceIds ? 1 : 0)
    + (paramDeadline ? 1 : 0)
    + (paramRequestCode ? 1 : 0)
    + (paramPayeeId ? 1 : 0)
    + (paramAmount ? 1 : 0)
    + (paramPaymentDate ? 1 : 0)
    !== 1)
    throw new Error('Multiple query criteria specified')

  if (paramInvoiceIds) {
    // This was needed before the payeeId was saved on the invoice object.
    // currently, we don't ever pass an invoiceIds search parameter to this page.
    // Current implementation downloads ALL invoices and then filters client side
    // for invoice ID, so would need to be redone to query only the necessary
    // invoices
    // This could be done with either the 'in' operator:
    //   where(firebase.firestore.FieldPath.documentId(), 'in',["123","345","111"])
    //   see https://stackoverflow.com/a/58780369/
    // or by multiple calls to getDoc, one per id.
    throw new Error('Not supported')
  }

  const [filterCachePaymentDates, setFilterCachePaymentDates] = useState<ThreeLevelDateTree>() // year => month => days[]
  const [filterCacheAmounts, setFilterCacheAmounts] = useState<number[]>()
  const [filterCacheRequestCodes, setFilterCacheRequestCodes] = useState<string[]>()
  const [filterCacheDeadlines, setFilterCacheDeadlines] = useState<ThreeLevelDateTree>() // year => month => days[]
  const [filterCachePayees, setFilterCachePayees] = useState<PayeeSimpleType[]>()


  const [isLoading, setIsLoading] = useState(false)
  const [payeeInEnglish, setPayeeInEnglish] = useState<boolean>(userDetails.preferences?.invoicesPayeesEnglish ?? false)
  const payeeNameColName = payeeInEnglish ? 'payeeNameEn' : 'payeeNameJa'

  const [invoiceList, setInvoiceList] = useState<InvoiceType[]>()

  const [extendedInfoShownIds, setExtendedInfoShownIds] = useState<string[]>([])
  const [editedInvoice, setEditedInvoice] = useState('') // id of invoice being edited
  const [editedCell, setEditedCell] = useState<string | null>(null) // id of table + '_' + id of invoice being edited + '_' + name of field being edited

  const [modalActionMarkPaid, setModalActionMarkPaid] = useState<ModalPopupMarkPaidActionType | null>(null)

  const downloadLinkRef = useRef<HTMLAnchorElement>(null)

  const [freeeUnlocked, setFreeeUnlocked] = useState(false)

  // const [showPastInvoices, setShowPastInvoices] = useState(false)
  // const [loadPastInvoices, setLoadPastInvoices] = useState(false)

  // if (showPastInvoices && !loadPastInvoices) setLoadPastInvoices(true) // one-way: once loaded, never unloaded, even if user hides past invoices

  // if (paramInvoiceIds && !showPastInvoices) setShowPastInvoices(true)

  const [showDateCreatedModified, setShowDateCreatedModified] = useState(false)

  const [sortCol_0, sortDir, setSortSetting, sortFunc] = useColumnSorter(['dateisoDeadline', 1]) // [colName, (-1|1)] where (1)=asc (-1)=desc
  const sortCol = sortCol_0 as InvoiceFieldType

  const [filterPopupColumn, setFilterPopupColumn] = useState<InvoiceFieldType>()

  const [shownPopup, setShownPopup] = useState<string | null>(null)

  // if user clicks 'view list' in the RequestCodeAggregator for a given tour code, close the popup:
  useEffect(() => {
    setShownPopup(null)
  }, [paramRequestCode])


  // Switch column sorting to payment date when selecting past payment month;
  // Switch back to deadline when selecting 'Current'.
  useEffect(() => {
    if (normalMode || (paramMonth === 'ALL_INVOICES')) {
      setSortSetting(['dateisoDeadline', 1])
    } else if (paramMonth) {
      setSortSetting(['paymentDateiso', 1])
    }
  }, [normalMode, paramMonth, setSortSetting])


  const user_canMarkPaid = userrole_canMarkPaid(userDetails.roles)
  const user_canEditAnyInvoice = userrole_canEditAnyInvoice(userDetails.roles)
  const user_canDeleteInvoices = userrole_canDeleteInvoices(userDetails.roles)
  const user_canAddInvoice = userrole_canAddInvoice(userDetails.roles)
  const user_canEditFreee = userrole_canEditFreee(userDetails.roles)
  const user_canEditOwnInvoices = user_canAddInvoice
  const user_hasActions = user_canMarkPaid || user_canEditAnyInvoice || user_canDeleteInvoices || user_canEditOwnInvoices

  if (!userrole_canListInvoices(userDetails.roles))
    throw new Error('unauthorized') // this shouldn't really be necessary as permissions are already checked in PageInvoices


  const sToday = getTodayIso()

  const [monthListItems, monthListCodes] = useMemo(() => {
    const pastMonths = getMonthList(2023, 7)
    const listCodes = ['CURRENT', ...pastMonths, 'ALL_PAID', 'ALL_INVOICES']
    const pastMonthsLabels = pastMonths.map((month) => `Paid in ${month}`)
    const listLabels = ['Current', ...pastMonthsLabels, 'All paid invoices', 'All invoices']
    const listItems = listCodes.map((code, index) => { return { code, label: listLabels[index] } })
    return [listItems, listCodes]
  }, [])

  if (paramMonth && !monthListCodes.includes(paramMonth))
    throw new Error('invalid selected month')




  useEffect(() => {

    const processSnapshotInvoiceList = function (snapshot: QuerySnapshot, pastOrFuture: string) {
      console.log(`snapshot.size ${pastOrFuture}`, snapshot.size)
      const invoicesFromDb: InvoiceType[] = []
      snapshot.docs.forEach((doc) => {
        const invoiceRaw = { ...doc.data(), id: doc.id } as InvoiceRawType
        const invoice = convertInvoice(invoiceRaw)
        // assign default status value if missing in db
        if (!invoice.status)
          invoice.status = invoice.files ? 'RECEIVED' : 'EXPECTED'
        invoicesFromDb.push(invoice)
      })

      const invoicesFiltered = clientSideFiltering(
        invoicesFromDb,
        appliedFilterDeadline,
        appliedFilterRequestCode,
        appliedFilterPayee,
        appliedFilterAmount,
        appliedFilterPaymentDate,
      )

      setInvoiceList(invoicesFiltered)

      setIsLoading(false)
    }


    let qInvoices: Query
    let desc: string

    if (paramPayeeId) {

      if (!appliedFilterPayee)
        // loading
        return

      const payeeList = [...appliedFilterPayee.keys()]

      qInvoices = query(
        collection(db, 'invoices'),
        where('_isDeleted', '==', false),
        where('payeeId', 'in', payeeList),
      );

      desc = `PAYEE=${paramPayeeId}`

    } else if (normalMode) {
      // Current

      // We want to get the following invoices:
      //    DEADLINE >= TODAY || STATUS_UNPAID (UNPAID means status is neither PAID nor SCHEDULED)
      // Firestore supports OR queries by running multiple queries internally on the server:
      //    DEADLINE >= TODAY
      //    DEADLINE <  TODAY && STATUS_UNPAID   // (DEADLINE <  TODAY part is optional)

      /*                                UNPAID                                PAID
                                                                PAYDATE<today     PAYDATE>=today

           DEADLINE < today             X                         -                    X (TODO: currently '-')


           DEADLINE >= today            X                         X                    X

      
      */

      qInvoices = query(
        collection(db, 'invoices'),
        and(
          where('_isDeleted', '==', false),
          or(
            where('dateisoDeadline', '>=', sToday),
            and(
              where('dateisoDeadline', '<', sToday),
              where('statusPaidOrScheduled', '==', false),
            )
          )
        )
      );

      desc = 'FUTURE_OR_PAST_UNPAID'

    } else if (paramPaymentDate) {

      desc = `FILTER_PAYMENT_DATE ${paramPaymentDate}`

      if (!appliedFilterPaymentDate)
        // not finished loading
        return;

      [desc, qInvoices] = getQueryWhenFilteringByPaymentDate(db, paramPaymentDate, appliedFilterPaymentDate, 'FILTER_PAYMENT_DATE')

    } else if (paramDeadline) {

      desc = `FILTER_DEADLINE ${paramDeadline}`

      if (!appliedFilterDeadline)
        // not finished loading
        return;

      [desc, qInvoices] = getQueryWhenFilteringByDeadline(db, paramDeadline, appliedFilterDeadline, 'FILTER_DEADLINE')

    } else if (paramAmount) {

      desc = `FILTER_AMOUNT ${paramAmount}`

      if (!appliedFilterAmount)
        // not finished loading
        return;


      [desc, qInvoices] = getQueryWhenFilteringByAmount(db, paramAmount, appliedFilterAmount)


    } else if (paramRequestCode) {

      desc = `FILTER_REQUESTCODE ${paramRequestCode}`

      if (!appliedFilterRequestCode)
        // not finished loading
        return;

      [desc, qInvoices] = getQueryWhenFilteringByRequestCode(db, paramRequestCode, appliedFilterRequestCode)

    } else if (paramMonth) {

      if (paramMonth === 'ALL_INVOICES') {

        qInvoices = query(
          collection(db, 'invoices'),
          where('_isDeleted', '==', false),
        );

        desc = 'ALL_INVOICES'

      } else if (paramMonth === 'ALL_PAID') {

        qInvoices = query(
          collection(db, 'invoices'),
          where('_isDeleted', '==', false),
          where('statusPaidOrScheduled', '==', true),
        );

        desc = 'ALL_PAID'

      } else {
        // month is 2024-XX
        if (!/^\d{4}-\d{2}$/.test(paramMonth))
          throw new Error('invalid selected month')
        const dateisoMonthStart = `${paramMonth}-01`
        const dateisoMonthEndExc = addMonthsIso(dateisoMonthStart, 1)

        qInvoices = query(
          collection(db, 'invoices'),
          where('_isDeleted', '==', false),
          where('statusPaidOrScheduled', '==', true),
          where('paymentDateiso', '>=', dateisoMonthStart),
          where('paymentDateiso', '<', dateisoMonthEndExc),
        );

        desc = `PAID_IN ${paramMonth}`

      }

    } else {
      throw new Error('unreachable: invoice query mode not recognized')
    }

    log_db_read({ db, userDetails, logkey: 'db_read.list_invoices', desc: `List invoices ${desc}` })

    setIsLoading(true)

    const unsubscribe = onSnapshot(qInvoices,
      (snapshot) => processSnapshotInvoiceList(snapshot, desc),
      (err) => setDbError(`Getting invoice list ${desc}`, err));

    return unsubscribe
  }, [db, setDbError, userDetails, sToday,
    normalMode, paramMonth, paramPayeeId, paramPaymentDate, paramAmount, paramRequestCode, paramDeadline,
    appliedFilterPaymentDate, appliedFilterAmount, appliedFilterRequestCode, appliedFilterDeadline, appliedFilterPayee])



  const [userList, setUserList] = useState<Record<string, UserDetailsType>>() // dictionary indexed by uid
  useEffect(() => {
    getDocs(query(collection(db, 'users')))
      .then((snapshot) => {
        const userList: Record<string, UserDetailsType> = {}
        snapshot.forEach((docUser) => {
          userList[docUser.id] = { ...docUser.data(), id: docUser.id } as UserDetailsType
        })
        setUserList(userList)
      })
      .catch((err) => setDbError('Getting users list', err))
  }, [db, setDbError])



  useEffect(() => {

    const processSnapshot = function (snapshot: DocumentSnapshot) {
      const cache = snapshot.data()?.filterCache as FilterCacheInvoicesType

      // payment dates
      const cache_paymentDates = convertDateListToThreeLevelCache(Object.keys(cache.paymentDates)) // year => month => days[]
      setFilterCachePaymentDates(cache_paymentDates)

      // deadlines
      const cache_deadlines = convertDateListToThreeLevelCache(Object.keys(cache.deadlines)) // year => month => days[]
      setFilterCacheDeadlines(cache_deadlines)

      // amounts
      const amountList = Object.keys(cache.amounts).map((x) => Number(x))
      amountList.sort((a, b) => a - b)
      setFilterCacheAmounts(amountList)

      // request codes
      const requestCodeList = [...Object.keys(cache.requestCodes)]
      requestCodeList.sort()
      setFilterCacheRequestCodes(requestCodeList)
    }

    const unsubscribe = onSnapshot(doc(db, '_cachedlists', 'filterCacheInvoices'), processSnapshot,
      (err) => setDbError('Getting _cachedlists/filterCacheInvoices', err)
    );

    return unsubscribe
  }, [db, setDbError])

  useEffect(() => {

    const processSnapshot = function (snapshot: DocumentSnapshot) {
      const docu = snapshot.data()
      const objlist = docu.objlist as PayeeSimpleType[]
      objlist.sort((a, b) => a.nameEn.localeCompare(b.nameEn))

      // check ids are unique (bug is causing them not to be)
      const payeeIds = new Set<string>()
      objlist.forEach((payee) => {
        if (payeeIds.has(payee.id)) {
          console.error(`_cachedlists/cachedlistPayees has duplicate id: ${payee.id}  ${payee.nameEn}`)
        }
        payeeIds.add(payee.id)
      })

      setFilterCachePayees(objlist)
    }

    const unsubscribe = onSnapshot(doc(db, '_cachedlists', 'cachedlistPayees'), processSnapshot,
      (err) => setDbError('Getting _cachedlists/cachedlistPayees', err)
    );

    return unsubscribe
  }, [db, setDbError])


  // convert the 'paymentDate' search parameter to a ColumnFilterDateisoType object
  useEffect(() => {
    setAppliedFilterPaymentDate(
      (paramPaymentDate && filterCachePaymentDates)
        ? getDateFilterFromParam(paramPaymentDate, filterCachePaymentDates)
        : null
    )
  }, [paramPaymentDate, filterCachePaymentDates])


  // convert the 'amount' search parameter to a ColumnFilterAmountType object
  useEffect(() => {
    setAppliedFilterAmount(getAmountFilterFromParam(paramAmount, filterCacheAmounts))
  }, [paramAmount, filterCacheAmounts])


  // convert the 'requestCode' search parameter to a ColumnFilterRequestCodeType object
  useEffect(() => {
    setAppliedFilterRequestCode(getStringFilterFromParam(paramRequestCode))
  }, [paramRequestCode])


  // convert the 'deadline' search parameter to a ColumnFilterDateType object
  useEffect(() => {
    setAppliedFilterDeadline(
      (paramDeadline && filterCacheDeadlines)
        ? getDateFilterFromParam(paramDeadline, filterCacheDeadlines)
        : null
    )
  }, [filterCacheDeadlines, paramDeadline])


  // convert the 'payee' search parameter to a ColumnFilterPayeeType (Set<string>) object
  useEffect(() => {
    if (!paramPayeeId) {
      setAppliedFilterPayee(null)
      return
    }

    setAppliedFilterPayee(getPayeeFilterFromParam(paramPayeeId))

  }, [paramPayeeId])


  const [payeeObjects, setPayeeObjects] = useState<PayeeSimpleType[]>()
  useEffect(() => {
    if (!paramPayeeId || !appliedFilterPayee || !filterCachePayees) {
      setPayeeObjects(null) // needed if user clicks 'Invoices' in navbar after viewing payee-specific invoices
      return
    }
    // getDoc(doc(db, 'payees', paramPayeeId))
    //   .then((docu) => {
    //     if (!docu.exists())
    //       setDbError(`Payee ${paramPayeeId} not found`)
    //     setPayeeObj({ ...docu.data(), id: paramPayeeId } as PayeeType)
    //   })

    const list = [...appliedFilterPayee.keys()].map((payeeId) => filterCachePayees.find((payee) => payee.id === payeeId))

    setPayeeObjects(list)

  }, [db, setDbError, paramPayeeId, appliedFilterPayee, filterCachePayees])


  // *** all hooks above this line



  // `invoiceList` is null if `userList` is null, or `payeeObj` is required and null etc,
  // so here we don't have granular loading information to display to user.
  // So we don't use getLoadingSpinnerOrNull() here.

  if (!userList || !invoiceList)
    return <LoadingSpinner list={['list of invoices']} />



  const tablerows: JSX.Element[] = []



  // sort the ones we display
  invoiceList.sort(sortFunc)


  let indexNonOld = 0

  invoiceList.forEach((invoice, index) => {
    const showExtended = extendedInfoShownIds.includes(invoice.id)

    const isOld = getIsOld(invoice)

    const isRowBeingEdited = editedInvoice === invoice.id

    const isRowRecentlyInserted = recentlyInsertedId === invoice.id

    const classRowBgColor =
      isRowBeingEdited ? 'editedRow'
        : (isRowRecentlyInserted && false) ? 'recentlyInsertedRow'
          : isOld ? 'oldInvoice'
            : indexNonOld % 2 === 0 ? 'evenRow' : 'oddRow'

    const showExtendedInfo = () => setExtendedInfoShownIds((extendedInfoShownIds) => [...extendedInfoShownIds, invoice.id])
    const hideExtendedInfo = () => {
      setExtendedInfoShownIds((extendedInfoShownIds) => extendedInfoShownIds.filter((x) => x !== invoice.id))
      if (isRowBeingEdited) {
        setEditedInvoice('')
        setEditedCell('')
      }
    }



    tablerows.push(
      <InvoiceTableRow
        key={invoice.id}
        {...{
          invoice,
          index,
          indexNonOld,
          classRowBgColor,
          invoiceListDisplayed: invoiceList,
          showExtended,
          showExtendedInfo,
          hideExtendedInfo,
          paramRequestCode,
          paramPayeeId,
          freeeUnlocked,
          editedInvoice,
          setEditedInvoice,
          editedCell,
          setEditedCell,
          shownPopup,
          setShownPopup,
          user_canEditOwnInvoices,
          user_canEditAnyInvoice,
          user_canMarkPaid,
          user_canDeleteInvoices,
          user_canEditFreee,
          sortCol,
          recentlyInsertedId,
          payeeInEnglish,
          showDateCreatedModified,
          userList,
          payeeList,
        }}
      />
    )


    if (showExtended) {

      tablerows.push(
        <InvoiceTableRowExtended
          key={`ext_${invoice.id}`}
          {...{
            invoice,
            classRowBgColor,
            hideExtendedInfo,

            editedInvoice,
            setEditedInvoice,
            editedCell,
            setEditedCell,
            modalActionMarkPaid,
            setModalActionMarkPaid,

            user_canMarkPaid,

            showDateCreatedModified,
          }}
        />
      )

    }

    if (!isOld)
      indexNonOld++
  })

  const changePayeeInEnglish = function (payeeInEnglish: boolean) {
    setPayeeInEnglish(payeeInEnglish)
    if (!userDetails.preferences) userDetails.preferences = {}
    userDetails.preferences.invoicesPayeesEnglish = payeeInEnglish
    updateDoc(doc(db, 'users', userDetails.id), { 'preferences.invoicesPayeesEnglish': payeeInEnglish })
  }




  // useEffect(() => {
  // 	const rows = document.querySelectorAll('.recentlyInsertedRow') // should get 1 or 2 rows, the main and possibly the extended info
  // 	if (rows.length > 0) {
  // 		const normalColor = rows[0].classList.contains('evenRowFlag') ? '#eee' : '#fff'
  // 		const tds = Array.flat(rows.map(tr => Array.from(tr.getElementsByTagName('td'))))
  // 		// trigger fade to white or gray
  // 		tds.forEach(td => td.style.backgroundColor = normalColor)
  // 	}

  // })





  // Below code is to get the list of payment dates based on the *currently displayed* set of invoices.
  // Now, we use the cached payment dates based on the entire invoice inventory, so we don't use
  // the code below.

  // const paymentDates = new Map<number, Map<number, number[]>>()
  // invoiceList.forEach((invoice) => {
  //   if (!invoice.paymentDatexxx)
  //     return
  //   const ust0 = getUtcMidnightBasedOnJstTime(invoice.paymentDatexxx)
  //   const [year, month, day] = [ust0.getUTCFullYear(), ust0.getUTCMonth() + 1, ust0.getUTCDate()]
  //   if (!paymentDates.has(year)) paymentDates.set(year, new Map<number, number[]>())
  //   const monthMap = paymentDates.get(year)
  //   if (!monthMap.has(month)) monthMap.set(month, [])
  //   const dayList = monthMap.get(month)
  //   if (!dayList.includes(day)) dayList.push(day)
  // })





  const currencies = [...new Set(invoiceList.map((invoice) => invoice.currency))].sort()
  const totalsByCcy = new Map<string, number>()
  for (const currency of currencies) {
    const sum = arraySum(invoiceList.filter((invoice) => invoice.currency === currency).map((invoice) => invoice.amount || 0))
    totalsByCcy.set(currency, sum)
  }




  const pageTitle = `Invoices${payeeObjects ? ` [${payeeObjects.map((payeeObj) => payeeObj.nameEn).join(',')}]` : paramRequestCode ? ` [${paramRequestCode}]` : ''}`

  return (
    <div className='p-3'>
      <Helmet><title>{pageTitle}</title></Helmet>


      <div className='invoiceToolbar'>

        <Link
          to='add'
          className='tw-bg-transparent tw-rounded tw-border tw-border-solid tw-border-slate-400 tw-px-4 tw-py-2 hover:tw-bg-slate-300 tw-no-underline tw-text-slate-600'>
          Add invoice
        </Link>

        {(normalMode || paramMonth) && (
          <DropdownButton id='dropdown-month'
            title={paramMonth ? monthListItems.find((item) => item.code === paramMonth).label : monthListItems[0].label}>
            {monthListItems.map(({ code, label }) => {
              return (
                <Dropdown.Item key={code} onClick={() => {
                  navigate(`/invoices/${code !== 'CURRENT' ? `?month=${code}` : ''}`)
                }}>{label}</Dropdown.Item>
              )
            })}
          </DropdownButton>
        )}

        {/* 
      {!paramPayeeId && (
        <CheckboxSwitch id='flexSwitchShowPastInvoices' label={_lang('Show past invoices', '過去の請求書を表示する')} className='mb-3' checked={showPastInvoices} onChange={(e) => {
          setShowPastInvoices(e.target.checked)
        }} />
      )}
       */}

        {(paramPayeeId || paramInvoiceIds || paramRequestCode) && (
          <div className='me-4'>Filtering by <b>{paramPayeeId ? 'Supplier' : paramInvoiceIds ? 'Invoice ID' : paramRequestCode ? `Request Code [${paramRequestCode}]` : '??'}</b>. <a href='/invoices/' onClick={(e) => {
            e.preventDefault()
            // setShowPastInvoices(false)
            navigate('/invoices/')
          }}> Remove filter</a></div>
        )}


        <DropdownButton id='dropdown-displayoptions' title={<i className='bi bi-gear'></i>} autoClose='outside'>

          <Dropdown.ItemText>
            <CheckboxSwitch id='flexSwitchShowDateCreatedModified' label={_lang('Show date added/modified', '作成日と修正日を表示する')} className='mb-3' checked={showDateCreatedModified} onChange={(e) => {
              setShowDateCreatedModified(e.target.checked)
            }} />
          </Dropdown.ItemText>

          <Dropdown.ItemText>
            <CheckboxSwitch id='flexSwitchPayeeLanguage' label={_lang('Show suppliers in English', '支払先を英語で表示する')} className='mb-3' checked={payeeInEnglish} onChange={(e) => {
              changePayeeInEnglish(e.target.checked)
            }} />
          </Dropdown.ItemText>

        </DropdownButton>

        {userrole_isAdmin(userDetails.roles) && (

          <ButtonTW variant='bsOrange' textSize='md' onClick={() => {

            const columns = {
              id: 'invoiceId',
              dateisoDeadline: 'dateisoDeadline',
              tripcode: 'requestCode',
              paxname: 'paxname',
              servicePurchased: 'servicePurchased',
              payeeNameEn: 'payeeNameEn',
              payeeNameJa: 'payeeNameJa',
              payeeId: 'payeeId',
              amount: 'amount',
              currency: 'currency',
              userEmail: 'userEmail',
              paymentDateiso: 'paymentDateiso',
              status: 'status',
            };

            stringify(
              invoiceList,
              {
                header: true,
                columns: columns,
                cast: {
                  // https://stackoverflow.com/questions/45302027/node-csv-stringify-format-timestamp-column
                  date: (value: any) => dateFormatCsvDate(value),
                  boolean: (value: any) => value ? 'TRUE' : 'FALSE',
                },
              },
              (err: any, output: any) => {
                if (err) throw err;
                // console.log('output', output)

                // https://stackoverflow.com/a/44661948/
                const element = downloadLinkRef.current;
                // include the UTF-8 BOM as it is requred for Excel to treat the csv as UTF-8
                const file = new Blob(['\ufeff', output], { type: 'text/csv' });
                element.href = URL.createObjectURL(file);
                element.download = 'invoices.csv';
                // document.body.appendChild(element); // Required for this to work in FireFox
                element.click();

              });

          }}>Download CSV</ButtonTW>

        )}

        <a ref={downloadLinkRef} href='' download='invoices.csv' style={{ display: 'none' }}></a>

        <div>
          {formatNum(invoiceList.length)} {invoiceList.length === 1 ? 'invoice' : 'invoices'}
          {' '}
          {perm('payments_create') && (
            <>
              ({currencies.map((ccy) => `${formatNumCcy(totalsByCcy.get(ccy), ccy)} ${ccy}`).join(', ')})
            </>
          )}
        </div>

      </div>

      <table className='table invoice-list' style={{ opacity: isLoading ? '0.5' : '1' }}>
        <thead>
          <tr>
            <th></th>
            <th className='text-nowrap'>
              <SortCaret colName='dateisoDeadline' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} />
              {' '}
              {_lang('Pay by', '支払期限')}
              {' '}
              <ColumnFilterButton
                isPopupOpen={filterPopupColumn === 'dateisoDeadline'}
                isFilterActive={!!appliedFilterDeadline}
                openPopup={() => setFilterPopupColumn('dateisoDeadline')}
                closePopup={() => setFilterPopupColumn(null)}
                columnName='invoice/dateisoDeadline'
              />
              <ColumnFilterPopupDate
                urlParameterName='deadline'
                allDates={filterCacheDeadlines}
                appliedFilter={appliedFilterDeadline}
                popupIsOpen={filterPopupColumn === 'dateisoDeadline'}
                closePopup={() => setFilterPopupColumn(null)}
                sortCaretProps={{
                  colName: 'dateisoDeadline',
                  sortCol,
                  sortDir,
                  setSortSetting,
                }}
                emptyValueSelectorTitle={null}
              />
            </th>
            <th className='text-nowrap'>
              <SortCaret colName='tripcode' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} />
              {' '}
              {_lang('Request code', '旅コード')}
              {' '}
              <ColumnFilterButton
                isPopupOpen={filterPopupColumn === 'tripcode'}
                isFilterActive={!!appliedFilterRequestCode}
                openPopup={() => setFilterPopupColumn('tripcode')}
                closePopup={() => setFilterPopupColumn(null)}
                columnName='invoice/tripcode'
              />
              <ColumnFilterPopupString
                urlParameterName='requestCode'
                allValues={filterCacheRequestCodes}
                appliedFilter={appliedFilterRequestCode}
                popupIsOpen={filterPopupColumn === 'tripcode'}
                closePopup={() => setFilterPopupColumn(null)}
                sortCaretProps={{
                  colName: 'tripcode',
                  sortCol,
                  sortDir,
                  setSortSetting,
                }}
              />
            </th>
            <th className='text-nowrap'>
              <SortCaret colName='paxname' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} />
              {' '}
              {_lang('Pax name', '旅行者名')}
            </th>
            <th className='text-nowrap'>
              <SortCaret colName='servicePurchased' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} />
              {' '}
              {_lang('Service', 'サービス')}
            </th>
            <th className='text-nowrap'>
              <SortCaret colName={payeeNameColName} sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} />
              {_lang('Supplier', '支払先')}
              {' '}
              <img src={payeeInEnglish ? '/united-kingdom.png' : '/japan.png'}
                width={20} height={20} className={payeeInEnglish ? 'roundFlagEn' : 'roundFlagJa'}
                onClick={() => {
                  changePayeeInEnglish(!payeeInEnglish)
                }}
                title='Switch supplier name language'
              />
              {' '}
              <ColumnFilterButton
                isPopupOpen={filterPopupColumn === payeeNameColName}
                isFilterActive={!!appliedFilterPayee}
                openPopup={() => setFilterPopupColumn(payeeNameColName)}
                closePopup={() => setFilterPopupColumn(null)}
                columnName='invoice/payeeName'
              />
              <ColumnFilterPopupString
                urlParameterName='payeeId'
                allValues={filterCachePayees.map((payee) => ({ id: payee.id, name: `${payee.nameEn} | ${payee.nameJa}` }))}
                appliedFilter={appliedFilterPayee}
                popupIsOpen={filterPopupColumn === payeeNameColName}
                closePopup={() => setFilterPopupColumn(null)}
                sortCaretProps={{
                  colName: payeeNameColName,
                  sortCol,
                  sortDir,
                  setSortSetting,
                }}
              />
            </th>
            <th className='text-nowrap'>
              {_lang('Supplier category', '支払先カテゴリー')}
            </th>
            <th className='text-nowrap'>
              <SortCaret colName='amount' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} />
              {' '}
              {_lang('Amount', '金額')}
              {' '}
              <ColumnFilterButton
                isPopupOpen={filterPopupColumn === 'amount'}
                isFilterActive={!!appliedFilterAmount}
                openPopup={() => setFilterPopupColumn('amount')}
                closePopup={() => setFilterPopupColumn(null)}
                columnName='invoice/amount'
              />
              <ColumnFilterPopupAmount
                urlParameterName='amount'
                allAmounts={filterCacheAmounts}
                appliedFilter={appliedFilterAmount}
                popupIsOpen={filterPopupColumn === 'amount'}
                closePopup={() => setFilterPopupColumn(null)}
                sortCaretProps={{
                  colName: 'amount',
                  sortCol,
                  sortDir,
                  setSortSetting,
                }}
              />
            </th>
            <th className='text-nowrap'>
              <SortCaret colName='userEmail' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} />
              {' '}
              {_lang('Inputter', '入力者')}
            </th>
            <th className='text-nowrap'>
              <SortCaret colName='paymentDateiso' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} />
              {' '}
              {_lang('Payment date', '支払日')}
              {' '}
              <ColumnFilterButton
                isPopupOpen={filterPopupColumn === 'paymentDateiso'}
                isFilterActive={!!appliedFilterPaymentDate}
                openPopup={() => setFilterPopupColumn('paymentDateiso')}
                closePopup={() => setFilterPopupColumn(null)}
                columnName='invoice/paymentDate'
              />
              <ColumnFilterPopupDate
                urlParameterName='paymentDate'
                allDates={filterCachePaymentDates}
                appliedFilter={appliedFilterPaymentDate}
                popupIsOpen={filterPopupColumn === 'paymentDateiso'}
                closePopup={() => setFilterPopupColumn(null)}
                sortCaretProps={{
                  colName: 'paymentDateiso',
                  sortCol,
                  sortDir,
                  setSortSetting,
                }}
                emptyValueSelectorTitle='Not yet paid'
              />
            </th>
            <th className='text-nowrap'>
              {_lang('Status', 'ステータス')}
            </th>
            {user_canEditFreee && (
              <th>
                {_lang('Freee', 'Freee')}
                <FreeePadlock
                  freeeUnlocked={freeeUnlocked}
                  setFreeeUnlocked={setFreeeUnlocked}
                />
              </th>
            )}
            <th className='text-nowrap'>
              {_lang('Files', '添付ファイル')}
            </th>
            {showDateCreatedModified && (
              <>
                <th className='text-nowrap'>
                  <SortCaret colName='dateCreated' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} />
                  {' '}
                  {_lang('Added', '作成日')}
                </th>
                <th className='text-nowrap'>
                  <SortCaret colName='dateModified' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} />
                  {' '}
                  {_lang('Modified', '修正日')}
                </th>
              </>
            )}
            <th className='text-nowrap'>
              {user_hasActions && _lang('Actions', '編集\xa0\xa0\xa0\xa0\xa0\xa0\xa0')}
            </th>
          </tr>
        </thead>
        <tbody>
          {tablerows}
        </tbody>
      </table>

      {perm('payments_create') && (
        <div>
          <h4 className='mt-5'>Table summary</h4>
          <table className='[&_td]:tw-px-3 [&_td]:tw-border [&_td]:tw-border-solid [&_td]:tw-border-slate-500 '>
            <tbody>
              <tr>
                <td>Number of invoices</td>
                <td className='tw-text-right'>{formatNum(invoiceList.length)}</td>
              </tr>
              {currencies.map((currency) => {
                return (
                  <tr key={currency}>
                    <td>Total amount ({currency})</td>
                    <td className='tw-text-right'>{formatNumCcy(totalsByCcy.get(currency), currency)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}


      <div>
        <h4 className='mt-5'>{_lang('Invoice statuses', '請求書のステータス')}</h4>
        <ul>
          <li><InvoiceStatusPill status='EXPECTED' /> {_lang('invoice expected but not received yet from supplier', '請求書を今後受け取る予定')}</li>
          <li><InvoiceStatusPill status='RECEIVED' /> <i>{_lang('(not shown by default)', '(非表示)')}</i> {_lang('invoice received but not yet paid', '請求書が受取済み、未払い')}</li>
          <li><InvoiceStatusPill status='NEEDS CHECKING' /> {_lang('invoice awaiting approval after review by multiple people', '複数人の確認待ち')}</li>
          <li><InvoiceStatusPill status='DONE CHECKING' /> {_lang('invoice approved following review by multiple people', '複数人に確認済み')}</li>
          <li><InvoiceStatusPill status='SCHEDULED' /> {_lang('invoice payment has been scheduled with bank (no longer considered outstanding)', '支払いが予約済み（支払済み扱い）')}</li>
          <li><InvoiceStatusPill status='PAID' /> {_lang('invoice paid and no longer outstanding', '支払済み')}</li>
        </ul>
        <h4 className='mt-5'>{_lang('‘Pay by’ date color coding', '支払期限の背景色について')}</h4>
        <ul>
          {/* @ts-expect-error Passing NodeElement instead of string. TODO: Need to make _lang() generic somehow. */}
          <li><GenericPill color='danger' content={dateFormatJp(addDays(getTodayJST(), -1))} /> = <b>{_lang('[OVERDUE]', '[支払期限が過ぎている]')}</b> {_lang(<>invoice not yet <i>SCHEDULED</i> or <i>PAID</i> with ‘Pay by’ date in the past</>, <>支払期限が過ぎていることにも関わらず、<b>支払予約済み</b>または<b>支払済み</b>の状態になっていない</>)}</li>
          {/* @ts-expect-error Passing NodeElement instead of string. TODO: Need to make _lang() generic somehow. */}
          <li><GenericPill color='warning' content={dateFormatJp(getTodayJST())} /> = <b>{_lang('[DUE TODAY]', '[支払期限が当日]')}</b> {_lang(<>invoice not yet <i>SCHEDULED</i> or <i>PAID</i> with ‘Pay by’ date today</>, <>支払期限が当日にも関わらず、<b>支払予約済み</b>または<b>支払済み</b>の状態になっていない</>)}</li>
        </ul>
      </div>


      {user_canMarkPaid && (
        <ModalPopupMarkPaid
          modalActionMarkPaid={modalActionMarkPaid}
          setModalActionMarkPaid={setModalActionMarkPaid}
          callbackOnSuccess={() => {
            setExtendedInfoShownIds(extendedInfoShownIds.filter((id) => id !== modalActionMarkPaid.invoice.id))
          }}
        />
      )}

    </div>
  )
}
