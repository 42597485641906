import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { useAppContext } from 'src/hooks/useAppContext';
import { GuideCalendarDaysOffType } from 'src/types/types_tourrequest';
import { UserSimpleType } from 'src/types/types_user';
import { addDays, iso_from_utc0 } from 'src/util/datetools';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { GuideCalendarSelectedCellType } from '../CalendarBlocks/R3_Guides';
import { setDocGuidecal } from '../util_db_calendar';



interface GuideCalendarDaysoffButtonsProps {
  userSimple: UserSimpleType;
  dateutcCalendarStart: Date;
  selectedCell: GuideCalendarSelectedCellType;
  selectedEventDates: string[]; // ['2024-06-01', '2024-06-02', ...]
  showDeletionConfirmationPopup: string | null;
  setShowDeletionConfirmationPopup: (value: string | null) => void;
  closePopup: () => void;
}

export function GuideCalendarDaysoffButtons({
  userSimple,
  dateutcCalendarStart,
  selectedCell,
  selectedEventDates,
  showDeletionConfirmationPopup,
  setShowDeletionConfirmationPopup,
  closePopup,
}: GuideCalendarDaysoffButtonsProps) {

  const { db, userDetails } = useAppContext()


  const hasEvent = selectedEventDates.length > 0

  if (!hasEvent) {
    // cells selected by the user do NOT have days off in them already
    return (
      <ButtonTW variant='bsDarkGray'
        onMouseDown={(e) => {
          e.stopPropagation()
        }}
        onClick={(e) => {
          e.stopPropagation()

          const updateObj = {
            daysOff: {} as Record<string, GuideCalendarDaysOffType>,
            dateModified: serverTimestampAsDate(),
            userModified: userSimple,
          }

          for (const dayIndex of selectedCell.colList) {
            const dateutc = addDays(dateutcCalendarStart, dayIndex)
            const dateiso = iso_from_utc0(dateutc)
            const dayOff: GuideCalendarDaysOffType = {
              _isDeleted: false,
              dateiso,
              dateModified: serverTimestampAsDate(),
              userModified: userSimple,
            }
            updateObj.daysOff[dateiso] = dayOff
          }

          // we cannot use updateDoc here as document may not exist yet
          setDocGuidecal(db, selectedCell.guideObj, updateObj, 'Set days off', 'tourcalendar.guide_action.days_off.set', userDetails,
            `Set days off on ${Object.keys(updateObj.daysOff).map((dateiso) => `[${dateiso}]`).join('')}`
          )

          closePopup()
        }}>
        Set days off
      </ButtonTW>
    )

  } else {
    // cells selected by the user DO have days off in them already

    return (
      <div style={{
        // this div's purpose is to be position:relative, to be able to correctly absloute-position the deletion confirmation popup
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'stretch',
      }}>
        <ButtonTW variant='bsDarkGray'
          onMouseDown={(e) => {
            e.stopPropagation()
          }}
          onClick={(e) => {
            e.stopPropagation()
            setShowDeletionConfirmationPopup('daysoff')
          }}>
          Delete days off
        </ButtonTW>
        {showDeletionConfirmationPopup === 'daysoff' && (
          <div className='guideCalendarPopup' style={{
            position: 'absolute',
            top: '0.5em',
            left: '0.75em',
            width: 'calc(100% + 1em)',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5em',
            zIndex: 1,
          }}>
            <div>Delete selected days off?</div>
            <ButtonTW variant='bsDarkGray' onClick={() => {
              const datesToDelete = selectedEventDates

              if (datesToDelete.length > 0) {

                const updateObj = {
                  daysOff: {} as Record<string, Omit<GuideCalendarDaysOffType, 'dateiso'>>,
                  dateModified: serverTimestampAsDate(),
                  userModified: userSimple,
                }

                for (const dateiso of datesToDelete) {
                  const dayOff: Omit<GuideCalendarDaysOffType, 'dateiso'> = {
                    _isDeleted: true,
                    dateModified: serverTimestampAsDate(),
                    userModified: userSimple,
                  }
                  updateObj.daysOff[dateiso] = dayOff
                }

                setDocGuidecal(db, selectedCell.guideObj, updateObj, 'Cancel days off', 'tourcalendar.guide_action.days_off.cancel', userDetails,
                  `Cancel days off on ${datesToDelete.map((dateiso) => `[${dateiso}]`).join('')}`
                )
              }
              setShowDeletionConfirmationPopup(null)
              closePopup()
            }}>Confirm</ButtonTW>
            <ButtonTW variant='darkgray_outline' onClick={() =>
              setShowDeletionConfirmationPopup(null)
            }>Cancel</ButtonTW>
          </div>
        )}
      </div>
    )
  }

}
