import { useContext } from 'react'
import { AppContext, IAppContext } from 'src/contexts/AppContext'



export function useAppContext(): IAppContext {
  const context = useContext(AppContext)
  if (!context)
    throw new Error('AppContext is null')
  return context
}
