import { PlacesType, Tooltip } from 'react-tooltip';


interface InfoToolTipProps {
  tooltipId: string;
  tooltipHtml: string;
  tooltipPlace: PlacesType;
}

export function InfoToolTip({ tooltipId, tooltipHtml, tooltipPlace }: InfoToolTipProps) {


  return (
    <div>
      <a data-tooltip-id={tooltipId} data-tooltip-html={tooltipHtml} data-tooltip-place={tooltipPlace}><i className='bi bi-info-circle'></i></a>
      <Tooltip id={tooltipId} place={tooltipPlace} variant='dark' />
    </div>

  )
}
