import { CSSProperties, MouseEvent } from 'react'
import { TourRequestType, TourRequestTypeWithDates } from 'src/types/types_tourrequest'
import { dateisoYearMonth, getSpanDaysExact, utc0_from_iso } from 'src/util/datetools'
import { EditWindowParamsType } from './PageTourCalendar'



export function getRange(array: number[]) {
  const min = Math.min(...array)
  const max = Math.max(...array)
  return Array.from({ length: max - min + 1 }, (_, i) => i + min) // copilot code
}

export function addRange(array: number[], newPoint: number, tourDayIndexes: number[]) {
  // for the current selection `array` and the newly clicked point `newPoint`,
  // add all the days in between the last selected day in `array` and `newPoint`,
  // on the condition that the day is in `tourDayIndexes`

  if (array.includes(newPoint))
    return array

  if (array.length === 0)
    return tourDayIndexes.includes(newPoint) ? [newPoint] : []

  const newArray = [...array]
  const last = array.at(-1)! // `array` is not empty so `last` is defined
  const step = newPoint > last ? 1 : -1
  for (let i = last + step; i !== newPoint + step; i += step) {
    if (!array.includes(i) && tourDayIndexes.includes(i)) {
      newArray.push(i)
    }
  }
  return newArray
}


export function getSelectionBordersCss(selectedDays: number[], dayNum: number, direction: 'horizontal' | 'vertical') {
  const borderStyle = '3px solid darkblue'

  const selectionBorders: CSSProperties = {}
  if (selectedDays) {
    if (selectedDays.includes(dayNum)) {
      const hasPrev = selectedDays.includes(dayNum - 1)
      const hasNext = selectedDays.includes(dayNum + 1)
      if (direction === 'horizontal') {
        selectionBorders.borderTop = borderStyle
        selectionBorders.borderBottom = borderStyle
        if (!hasPrev)
          selectionBorders.borderLeft = borderStyle
        if (!hasNext)
          selectionBorders.borderRight = borderStyle
      } else {
        selectionBorders.borderLeft = borderStyle
        selectionBorders.borderRight = borderStyle
        if (!hasPrev)
          selectionBorders.borderTop = borderStyle
        if (!hasNext)
          selectionBorders.borderBottom = borderStyle
      }
    }
  }
  return selectionBorders
}

export function gridCellMouseDown(
  e: MouseEvent,
  shownEditWindow: EditWindowParamsType,
  setShownEditWindow: (value: EditWindowParamsType) => void,
  dayIndex: number, // 0-based, from calendar start date
  dateutcCalendarStart: Date,
  tourrequest: TourRequestTypeWithDates,
) {
  if (e.ctrlKey || e.metaKey) {
    const newSelectedDayIndexes =
      shownEditWindow.selectedDayIndexesU.includes(dayIndex)
        ? shownEditWindow.selectedDayIndexesU.filter((d) => d !== dayIndex)
        : [...shownEditWindow.selectedDayIndexesU, dayIndex]

    setShownEditWindow({
      ...shownEditWindow,
      selectedDayIndexesU: newSelectedDayIndexes
    })
    return
  }

  if (e.shiftKey) {
    if (shownEditWindow.selectedDayIndexesU.includes(dayIndex))
      return

    const allDayIndexes = getTourDayIndexes(tourrequest, dateutcCalendarStart)

    const newSelectedDayIndexes = addRange(shownEditWindow.selectedDayIndexesU, dayIndex, allDayIndexes)

    setShownEditWindow({
      ...shownEditWindow,
      selectedDayIndexesU: newSelectedDayIndexes,
    })
    return
  }

  // default: just select the clicked cell
  setShownEditWindow({
    ...shownEditWindow,
    selectedDayIndexesU: [dayIndex]
  })

}


export function getTourDayIndexes(
  tourrequest: TourRequestTypeWithDates,
  dateutcCalendarStart: Date,
) { // returns: 0-based, from calendar start date

  const dateutcTourStart = utc0_from_iso(tourrequest.dateisoTourStart)
  const dateutcTourEnd = utc0_from_iso(tourrequest.dateisoTourEnd)

  const dayindexTourStart = getSpanDaysExact(dateutcCalendarStart, dateutcTourStart) // 0-based
  const dayindexTourEnd = getSpanDaysExact(dateutcCalendarStart, dateutcTourEnd) // 0-based


  // get list of days to display in side panel:
  const dayIndexesSet = new Set<number>()

  //  1) each day from start to end of tour
  for (let i = dayindexTourStart; i <= dayindexTourEnd; i++) {
    dayIndexesSet.add(i)
  }

  //  2) any other day outside of tour dates, where a guide has been assigned
  if (tourrequest.calendarDays) {
    for (const [dateiso, dateentry] of Object.entries(tourrequest.calendarDays)) {
      if (!(dateentry.guides && dateentry.guides.length > 0) && !dateentry.additionalText)
        // this date is actually empty
        continue

      const dateutc = utc0_from_iso(dateiso)
      const dayIndex = getSpanDaysExact(dateutcCalendarStart, dateutc) // 0-based
      // include even if out of calendar range (dayIndex may be <0 or >dayindexTourEnd)
      dayIndexesSet.add(dayIndex)
    }
  }

  const dayIndexes = [...dayIndexesSet].sort((a, b) => a - b)

  return dayIndexes // 0-based, from calendar start date
}

export function checkIfMonthBoundary(requests: TourRequestTypeWithDates[], iTourRequest: number) {
  const tourrequest = requests[iTourRequest]

  let tourIsFirstOfMonth = true
  if (iTourRequest > 0) {
    const prevTourMonth = dateisoYearMonth(requests[iTourRequest - 1].dateisoTourStart)
    const thisTourMonth = dateisoYearMonth(tourrequest.dateisoTourStart)
    tourIsFirstOfMonth = prevTourMonth !== thisTourMonth
  }

  let tourIsLastOfMonth = true
  if (iTourRequest < requests.length - 1) {
    const nextTourMonth = dateisoYearMonth(requests[iTourRequest + 1].dateisoTourStart)
    const thisTourMonth = dateisoYearMonth(tourrequest.dateisoTourStart)
    tourIsLastOfMonth = nextTourMonth !== thisTourMonth
  }

  return { tourIsFirstOfMonth, tourIsLastOfMonth }
}

export function isHiddenTour(tourrequest: TourRequestType) {
  // always show any tour that has a guide booked on it
  if (tourrequest.calendarDays) {
    let hasGuide = false
    for (const [dateiso, calDay] of Object.entries(tourrequest.calendarDays)) {
      if (calDay.guides && calDay.guides.length > 0)
        hasGuide = true

      // the below is now commented: if tour has some additional text (=comments) but no guides assigned,
      // and would otherwise not be shown due to not being confirmed, we hide it. this is so that when a
      // tour is cancelled, the user just needs to unassign the guides, but isn't forced to go and delete
      // all the additional text in order for the tour to no longer show up on the calendar.
      // if (calDay.additionalText)
      //   hasGuide = true
    }


    if (hasGuide) {
      if (tourrequest.hiddenInCalendar)
        console.log(`WARNING: hidden tour has guide ${tourrequest.id} ${tourrequest.requestCode}`)
      // if (!tourrequest.statusConfirmed)
      //   console.log(`WARNING: non-confirmed tour has guide ${tourrequest.id} ${tourrequest.requestCode}`)
      if (tourrequest.agencyOrPlatform === 'Taiko-Lab') {
        // this is ok (e.g. T230800567 )
        // don't hide Taiko-Lab tour if there are guided days
      }

      return false
    }
  }

  if (tourrequest.hiddenInCalendar)
    return true
  if (!tourrequest.statusConfirmed)
    return true
  if (tourrequest.agencyOrPlatform === 'Taiko-Lab') {
    return true
  }

  return false
}
