import { doc, getDoc } from 'firebase/firestore'
import stringify from 'json-stable-stringify'
import { useCallback, useRef } from 'react'
import { useAppContext } from 'src/hooks/useAppContext'
import { HistorizedDocumentType, HistoryInfoType, HistoryStepType } from 'src/types/types_historized'
import { isDevMode } from 'src/util/util_getbuildtime'
import { PRESERVE_HISTORY_STEPS } from './util_autosave'


// List of pages that use this hook:
//  - QuotationCrud
//  - Guiding expense sheet
//  - Tour request CRUD
//  - Add Invoice page
//  - Edit General Expense page

// instructions on how to use this hook for a new type of document:
//  - add field `history: HistoryInfoType` to the Type
//  - call this hook in the CRUD page
//  - to properly populate the history cache, add the below in the onSnapshot handler of the main document:
//    if (xxx.history?.currentStepId) {
//      addToCache(xxx.history.currentStepId, xxx)
//    }
//  - add new react state variables: `enableEditing`, `saveStatus`
//  - place a <TopWhiteBarEditControls/> at the top of the CRUD page
//  - create the below three functions:   (autosaveDocument is common to all document types)
//
//  autosaveNewStep()      \
//                          ›--> autosaveAnyStep() --> autosaveDocument()
//  autosaveUndoRedoStep() /
//
//    * autosaveNewStep()
//         does some pre-processing as required before saving the doc to database: calculating totals; generating denormalized caches; etc.
//         (these are steps that are not necessary when doing undo/redo as e.g. the totals would already be correct in the historized document)
//    * autosaveUndoRedoStep()
//         cleans up the retrieved historized document, by deleting fields that should not be copied from the past step:
//         id, _isDeleted, history, parentDocumentId, metadata, etc.
//    * autosaveAnyStep()  ('any step' meaning 'new step' or 'undo/redo step')
//         does some checks (is editing enabled?), creates the history field the first time a doc is saved after historization was
//         introduced on the document type, adds metadata for modification.


export function useUndoRedo<T extends HistorizedDocumentType>(
  historyCollection: string,
) {

  const { db, setDbError } = useAppContext()

  const historyStepCache = useRef<Map<string, T>>(new Map<string, T>())

  const addToCache = useCallback((stepId: string, stepObj: T) => {
    historyStepCache.current.set(stepId, stepObj)
  }, [])

  const getOldStepFromCacheOrDb = async (stepNum: number, stepId: string) => {
    let stepObj = historyStepCache.current.get(stepId)
    if (stepObj) {
      // required step was found in cache
      console.log('undo step found in cache')
      return stepObj
    }

    // required step was not found in cache: call getDoc
    const docu = await getDoc(doc(db, historyCollection, stepId))
    stepObj = docu.data() as T
    if (stepObj) {
      // required step was found in db
      console.log('undo step was redownloaded from db')
      return stepObj
    }

    console.error(`Cannot find step_${stepNum} in database  id=${stepId}`)
    window.alert(`Cannot find step_${stepNum} in database  id=${stepId}`)
    return undefined
  }



  const getUndoRedoHistoryChanges = async (
    action: 'Undo' | 'Redo',
    targetStep: number,
    //---
    history: HistoryInfoType,
  ) => {
    const currentStep = history.currentStep
    if (action === 'Undo' && targetStep < 1) {
      window.alert('Cannot undo step 1')
      return
    }
    const targetStepId = history.steps[`step_${targetStep}`].stepId
    const targetStepIsUndoStep = history.steps[`step_${targetStep}`].isUndoStep
    const targetStepObj = await getOldStepFromCacheOrDb(targetStep, targetStepId)
    if (!targetStepObj) {
      // window.alert already shown
      return
    }

    // UNDO and REDO: any FUTURE steps in history are always 100% preserved, never trimmed
    // (can never be removed in order to ensure no data loss)
    // UNDO:
    // if the step we are moving TO is itself an undo step, its history is not correct for us now
    // (it's from the past, from the original time that step was performed)
    // if it's a regular (non-undo) step, we extend our history in the past (up to 10 steps) by
    // using the target step's history.
    // REDO:
    // do nothing. the oldest past step will be trimmed off later.

    function printStepList(document: T) {
      return Object.keys(document.history.steps).map((stepKey) => Number(stepKey.match(/^step_(\d+)$/)[1])).sort((a, b) => a - b).join(',')
    }

    const newSteps: Record<string, HistoryStepType> = {}
    if (action === 'Undo') {
      if (targetStepIsUndoStep && targetStep + 1 === currentStep) {
        // Pressing Undo brought us just 1 step back to an Undo step.
        // No need to retrieve history.
        // We still have 9 undo steps to show.
      } else {
        // We need to add old steps to history
        let stepWithHistory = targetStep
        let stepWithHistoryObj = targetStepObj

        // If the user undid multiple steps in one click, by clicking on an older step in the Undo button dropdown,
        // and then landed on an Undo step:
        // We cannot get the history from the target undo step, so we take it from the step just after, and end up with
        // 9 steps of history to show.
        // (The step just after cannot be an Undo step as we can never have 2 undo steps in a row.
        // This is because merely undoing steps just moves the current step back but does not append new history.
        // New history after an undo is only created after a new action is performed, after the undo step. At that
        // point, 2 new history steps are created, the Undo step and the new action step.)
        if (targetStepIsUndoStep) {
          stepWithHistory = targetStep + 1
          const targetStepPlusOneObj = await getOldStepFromCacheOrDb(stepWithHistory, history.steps[`step_${stepWithHistory}`].stepId)
          if (!targetStepPlusOneObj) {
            // window.alert already shown
            return
          }
          stepWithHistoryObj = targetStepPlusOneObj
        }

        // Copy old steps to document object

        for (const [stepKey, stepObj] of Object.entries(stepWithHistoryObj.history.steps)) {
          if (!history.steps[stepKey]) {
            // Step is not present on live document. Shall we add it?
            const stepNum = Number(stepKey.match(/^step_(\d+)$/)[1])
            if (stepNum >= targetStep - PRESERVE_HISTORY_STEPS) {
              // Add step to history
              newSteps[`history.steps.${stepKey}`] = stepObj
            } else {
              // Skip step as it's too old
            }
          } else {
            // We already have this step.
            // Optional: we check consistency below:
            if (isDevMode()) {
              const stepJsonFromTargetDoc = stringify(stepObj)
              const stepJsonOnLiveDoc = stringify(history.steps[stepKey])
              if (stepJsonFromTargetDoc !== stepJsonOnLiveDoc) {
                // sometimes there is a difference: step on live doc has valid dateModified, but step from target doc has null as dateModified
                console.error('JSON mismatch in history steps', stepJsonFromTargetDoc, stepJsonOnLiveDoc)
                console.log('target json', stepObj)
                console.log('orig json', history.steps[stepKey])
              }
            }
          }
        }
      }
    }

    const updateObjHistory = {
      'history.currentStep': targetStep,
      'history.currentStepId': targetStepId,
      ...newSteps,
    }

    return { updateObjHistory, targetStepObj }
  }

  return { addToCache, getUndoRedoHistoryChanges }
}

