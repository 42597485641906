import { doc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useAppContext } from 'src/hooks/useAppContext'
import { UserRoleMainType, UserRoleOthersEnumType, displayRole, userRolesOtherList } from 'src/util/user_roles'
import { saveProfilePhotoBase } from 'src/util/util_saveprofilephoto'

export function UserSettings() {

  const { db, storage, userDetails, _lang } = useAppContext()

  const [displayName, setDisplayName] = useState(userDetails.displayNameEn)
  const [inputDisplayNameDisabled, setInputDisplayNameDisabled] = useState(false)

  const roleList: (UserRoleMainType | UserRoleOthersEnumType)[] = [userDetails.roles?.mainRole ?? 'none']
  for (const role_other of userRolesOtherList) {
    const val = userDetails.roles?.roleFlags?.[role_other]
    if (!val) continue
    roleList.push(role_other)
  }

  return (
    <div className='container'>
      <Helmet><title>User Settings</title></Helmet>
      <div className='m-3 text-start'>
        <div className='row mb-2'>
          <h3 className='col-sm-5 text-start'>{_lang('User settings', 'ユーザー設定')}</h3>
        </div>
        <div className='row mb-2'>
          <div className='col-sm-2 align-bottom' style={{ position: 'relative' }}><span style={{ position: 'absolute', bottom: '0' }}>{_lang('Photo', '写真')}</span></div>
          <div className='col-sm-4'><img src={userDetails.photoURL} alt='mdo' width='96' height='96' className='rounded-circle' /></div>
        </div>
        <div className='row mb-2'>
          <div className='col-sm-2'></div>
          <div className='col-sm-4'>
            <a href='https://myaccount.google.com/' target='_blank' rel='noreferrer'>{_lang('Change', '変更')}</a>
            {' '}
            (<a href='https://support.google.com/mail/answer/35529?hl=en' target='_blank' rel='noreferrer'>{_lang('help', 'ヘルプ')}</a>)
            {' '}
            <a href='#' onClick={(e) => {
              e.preventDefault()
              // we assume the photo URL has NOT changed (we reuse the photo URL from last time, as we don't have the fresh user object here)
              saveProfilePhotoBase(db, storage, userDetails.id, userDetails.email, userDetails.photoURL)
                .then(() => {
                  alert(_lang(
                    'Profile picture has been refreshed. Please refresh the page in your browser.',
                    'プロフィールの写真がリフレッシュされまいた。ブラウザでこのページをリフレッシュしてください。'
                  ))
                })
            }}>{_lang('Refresh', 'リフレッシュ')}</a>
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-sm-2'>{_lang('E-mail', 'メールアドレス')}</div>
          <div className='col-sm-4'>{userDetails.email}</div>
        </div>
        <div className='row mb-2'>
          <div className='col-sm-2'>{_lang('Name', '氏名')}</div>
          <div className='col-sm-4'>{userDetails.displayName}</div>
        </div>
        <form onSubmit={(e) => {
          e.preventDefault()
          const newName = displayName
          setInputDisplayNameDisabled(true)
          console.log(newName)
          if (!newName)
            return
          updateDoc(doc(db, 'users', userDetails.id), { displayNameEn: newName, dateModified: serverTimestamp() })
            .then((result) => {
              setInputDisplayNameDisabled(false)
            })

        }}>
          <div className='row mb-2'>
            <div className='col-sm-2'>{_lang('Display name', '表示名')}</div>
            <div className='col-sm-4'><input id='inputDisplayName' value={displayName} onChange={(e) => setDisplayName(e.target.value)} disabled={inputDisplayNameDisabled} /></div>
          </div>
        </form>
        {userDetails.roles?.mainRole && userDetails.roles.mainRole !== 'none' && (
          <div className='row mb-2'>
            <div className='col-sm-2'>User roles</div>
            <div className='col-sm-4'>
              {roleList.map(displayRole).join(', ')}

            </div>
          </div>
        )}
      </div>
    </div>
  )
}
