import React, { useCallback, useEffect, useState } from 'react';
import { SortCaretProps } from 'src/components/ColumnSorter/SortCaret';
import { useAppContext } from 'src/hooks/useAppContext';
import { ColumnFilterSimpleDateType, ThreeLevelDateTree } from 'src/types/types_columnfilters';
import { dateiso_from_parts } from 'src/util/datetools';
import { log_info } from 'src/util/util_log';
import { ButtonTW } from '../Buttons/ButtonTW';
import { TreeListItem } from './TreeListItem';
import './columnfilterpopup.css';
import { UrlParamNames, useSetOneSearchParam } from './useSetOneSearchParam';
import { constructDefaultTreeListState } from './util_filter_dates';
import { nodeNotNull } from './util_filters';



// Simple Date = Date filter, but only possible selection is multiple single items. No ranges.
// Used for 'array-contains-any' queries



interface ColumnFilterPopupSimpleDateProps {
  urlParameterName: UrlParamNames;
  allDates: ThreeLevelDateTree; // year => month => days[]
  appliedFilter: ColumnFilterSimpleDateType | null;
  popupIsOpen: boolean;
  closePopup: () => void;
  sortCaretProps: SortCaretProps | null;
}

export function ColumnFilterPopupSimpleDate({
  urlParameterName,
  allDates,
  appliedFilter,
  popupIsOpen,
  closePopup,
  sortCaretProps,
}: ColumnFilterPopupSimpleDateProps) {

  const { db, userDetails } = useAppContext()

  const { setOneSearchParam } = useSetOneSearchParam()


  const [searchValue, setSearchValue] = useState<string>('')


  const [localFilterState, setLocalFilterState] = useState<ColumnFilterSimpleDateType | null>(null)

  // update this component's filter state if it changes in InvoiceTable
  useEffect(() => {
    setLocalFilterState(appliedFilter)
  }, [appliedFilter])


  const getDefaultTreeListState = useCallback(() => {
    return constructDefaultTreeListState(allDates)
  }, [allDates])




  // *** all hooks above

  if (!allDates)
    // still loading
    return null


  const onCheckChange = (checked: boolean, year: number, month: number, day: number) => {


    if (year === null || month === null || day === null)
      throw new Error('on SIMPLE date filter, none of year, month, day should be null')

    const sDateClicked = dateiso_from_parts(year, month, day)

    const new_set = new Set(localFilterState)

    if (checked) {
      new_set.add(sDateClicked)
    } else {
      new_set.delete(sDateClicked)
    }

    setLocalFilterState(new_set.size > 0 ? new_set : null)

  }


  return (
    <div className={`filterPopup filterPopupDate ${popupIsOpen ? 'show' : 'hide'}`}>
      {sortCaretProps && (
        <>
          <div className='clickable' onClick={() => {
            sortCaretProps.setSortSetting([sortCaretProps.colName, 1])
            log_info({ db, userDetails, logkey: 'filterpopup.sortasc', desc: `Filter popup ${urlParameterName}: Sort ascending` })
          }}>
            <i className={`bi ${(sortCaretProps.sortCol === sortCaretProps.colName && sortCaretProps.sortDir === 1) ? 'bi-caret-up-square' : 'bi-caret-up'}`}></i>
            {' '}
            Sort ascending
          </div>
          <div className='clickable' onClick={() => {
            sortCaretProps.setSortSetting([sortCaretProps.colName, -1])
            log_info({ db, userDetails, logkey: 'filterpopup.sortdesc', desc: `Filter popup ${urlParameterName}: Sort descending` })
          }}>
            <i className={`bi ${(sortCaretProps.sortCol === sortCaretProps.colName && sortCaretProps.sortDir === -1) ? 'bi-caret-down-square' : 'bi-caret-down'}`}></i>
            {' '}
            Sort descending
          </div>
          {/* <hr className='my-2' /> */}
        </>
      )}
      <div className='filterTitle'>Date filters</div>
      <hr className='my-1' />

      <div className={`filterArea ${(localFilterState && localFilterState.size > 0) ? 'filterActive' : ''}`}>
        <div>
          <input type='text' placeholder='Search' className='searchBox' value={searchValue} onChange={(e) => {
            setSearchValue(e.target.value)
          }} />
        </div>
        <div className='checkboxList'>
          {[...allDates].sort((a, b) => a[0] - b[0]).map(([year, monthsMap]) => {
            const months = [...monthsMap].sort((a, b) => a[0] - b[0])
            const monthNodes = months.map(([month, days]) => {

              days.sort((a, b) => a - b)

              const dayNodes = days.map((day) => {
                const date = new Date(Date.UTC(year, month - 1, day))
                const sDate = `${year}/${month}/${day}`
                const isodate = dateiso_from_parts(year, month, day)

                if (searchValue) {
                  if (!sDate.includes(searchValue)) {
                    return null
                  }
                }

                return (
                  <TreeListItem
                    key={day}
                    label={sDate}
                    indentation={2}
                    childNodes={null}
                    ternarySelected={localFilterState && localFilterState.has(isodate) ? 1 : 0}
                    onCheckChange={(checked) => {
                      onCheckChange(checked, year, month, day)
                    }}
                    labelClassName={date.getUTCDay() % 6 === 0 ? 'holiday' : undefined}
                    onLabelClick={() => {
                      const param = `[${isodate}]`
                      log_info({ db, userDetails, logkey: 'filterpopup.directclick', desc: `Filter popup ${urlParameterName}: Direct click ${param}` })
                      setOneSearchParam(urlParameterName, param, true)
                      closePopup()
                    }}
                  />
                )
              }).filter(nodeNotNull)

              if (dayNodes.length === 0)
                // all days were filtered out
                return null

              return (
                <TreeListItem
                  key={month}
                  label={`${year}/${month}`}
                  indentation={1}
                  childNodes={dayNodes}
                  ternarySelected={0}
                  onCheckChange={null}
                  onLabelClick={null}
                />
              )
            }).filter(nodeNotNull)

            if (monthNodes.length === 0)
              // all months were filtered out
              return null

            return (
              <React.Fragment key={year}>
                <TreeListItem
                  label={`${year}`}
                  indentation={0}
                  childNodes={monthNodes}
                  ternarySelected={0}
                  onCheckChange={null}
                  onLabelClick={null}
                />
              </React.Fragment>
            )
          })}
        </div>
      </div>
      <div className='mt-3'>
        <ButtonTW variant='blue' style={{ width: '100%' }} onClick={() => {

          let queryparam = null

          if (localFilterState && localFilterState.size > 0) {

            const list: string[] = [...localFilterState.keys()]

            queryparam = `[${list.join(',')}]`

          }

          log_info({ db, userDetails, logkey: 'filterpopup.apply', desc: `Filter popup ${urlParameterName}: Apply ${queryparam}` })

          setOneSearchParam(urlParameterName, queryparam, true)

          closePopup()

        }}>Apply</ButtonTW>
      </div>
      <div className='mt-1'>
        <ButtonTW variant='bsDarkGray' style={{ width: '100%' }} onClick={() => {

          log_info({ db, userDetails, logkey: 'filterpopup.clear', desc: `Filter popup ${urlParameterName}: Clear` })

          setSearchValue('')

          setOneSearchParam(urlParameterName, '', true)

          closePopup()

        }}>Clear</ButtonTW>
      </div>
    </div>
  )
}
