import { QuerySnapshot, collection, documentId, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { ReactNode, UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { RequestCodeLinkToAggregator } from 'src/components/ContextMenus/RequestCodeLinkToAggregator';
import { TypeaheadUserList } from 'src/components/FormControls/TypeaheadUserList';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAppContext } from 'src/hooks/useAppContext';
import { CalendarLogType } from 'src/types/objectTypes';
import { GuideCalendarType, TourRequestType, TourRequestTypeWithDates } from 'src/types/types_tourrequest';
import { UserSimpleType } from 'src/types/types_user';
import { dateFormatJpShortWithTime, dateutcFormatJp } from 'src/util/dateformattools';
import { addDays, addMonthsUtc, dateutcFormatStandardMonth, getDateutc, getFirstDayOfMonth, getFirstDayOfNextMonth, getSpanDaysExact, getTodayUTC, iso_from_utc0, local0_from_utc0, utc0_from_iso, utc0_from_local0 } from 'src/util/datetools';
import { userrole_canAddInvoice, userrole_canEditCalendar, userrole_isAdmin } from 'src/util/user_roles';
import { convertCalendarLogDates, convertTourRequestDates } from 'src/util/util_firestoredates';
import { log_info } from 'src/util/util_log';
import { arraySum } from 'src/util/util_misc';
import { DateInput } from '../ExpenseSheet/DateInput';
import { getUserListSimple, useUserDetailsList } from '../ExpenseSheet/util_getuserlist';
import { RequestStatusPillCurrent } from '../Requests/RequestsList/RequestStatusPill/RequestStatusPillCurrent';
import { L1_Header } from './CalendarBlocks/L1_Header';
import { L2_TourList } from './CalendarBlocks/L2_TourList';
import { L3_GuideList } from './CalendarBlocks/L3_GuideList';
import { R1_Header } from './CalendarBlocks/R1_Header';
import { R2_Tours } from './CalendarBlocks/R2_Tours';
import { GuideCalendarSelectedCellType, R3_Guides } from './CalendarBlocks/R3_Guides';
import { GuideDetailedHoursBreakdown, GuideDetailedHoursDataType } from './Popups/GuideDetailedHoursBreakdown';
import { TourSidePanel } from './Popups/TourSidePanel';
import { YearlyGuideCalendar } from './Popups/YearlyGuideCalendar';
import './tourcalendar.css';
import { RectangularBlockType, useGuideCalendarCellData } from './useGuideCalendarCellData';
import { CalendarGuideTeamType, UserSimpleCalendarGuideType, calendarGuideTeamList, useGuideList } from './useGuideList';
import { toggleHideRequestOnCalendar } from './util_db_calendar';
import { isHiddenTour } from './util_tourcalendar';



export type TourListColType = {
  title: string;
  func: (tourrequest: TourRequestTypeWithDates, iTourRequest: number) => ReactNode;
  widthEm: number;
  show: boolean;
}

export type MonthItemType = {
  strYearMonth: string, // yyyy-mm
  monthStartDayIndex: number, // 0-based
  monthEndDayIndexExc: number,   // 0-based
}

export type EditWindowParamsType = {
  tourrequestId: string;
  iRow: number;
  cssLeft: string;
  cssTop: string;
  selectedDayIndexesU: number[]; // ** UNSORTED **  0-based, from calendar start date. NOT sorted in ascending order
}

export type SelectionBordersType = 'start' | 'selected' | 'end' // 'start' and 'end' will always also be 'selected'

const CELL_WIDTHS = [0.5, 1, 1.5, 2, 3, 4, 6, 8, 10]
const CELL_HEIGHTS = [0.5, 1, 1.5, 2, 3, 4, 6]
const FONT_SIZES = [6, 7, 8, 9, 10.5, 12, 14, 16, 18, 20]

export function PageTourCalendar() {

  const { db, setDbError, userDetails } = useAppContext()

  // the below two are ABSOLUTE roles, based on logged in user, and don't change when e.g. admin view calendar as guide
  const isTravelDesigner = userrole_canAddInvoice(userDetails.roles)
  const isCalendarAdmin = isTravelDesigner && userrole_canEditCalendar(userDetails.roles)

  const viewAs_DEFAULT =
    // by default, user has the highest rights based on their absolute role
    isCalendarAdmin ? 'CALENDAR_ADMIN'
      : isTravelDesigner ? 'TRAVEL_DESIGNER'
        : {
          id: userDetails.id,
          name: userDetails.displayNameEn,
          email: userDetails.email,
        }
  const [viewAs, setViewAs] = useState<UserSimpleType | 'CALENDAR_ADMIN' | 'TRAVEL_DESIGNER'>(viewAs_DEFAULT)

  const viewAsCalendarAdmin = viewAs === 'CALENDAR_ADMIN'
  const viewAsTravelDesigner = viewAs === 'TRAVEL_DESIGNER' || viewAs === 'CALENDAR_ADMIN' // includes admin
  const viewAsGuide = !viewAsTravelDesigner

  const canEdit = viewAsCalendarAdmin
  const isReadOnly = !canEdit

  const [filteredGuides, setFilteredGuides] = useState<UserSimpleCalendarGuideType[] | null>(null)

  const DEFAULT_CELL_WIDTH_EM = 3
  const DEFAULT_CELL_HEIGHT_EM = 2
  const DEFAULT_FONT_SIZE_PX = 10.5
  const GUIDE_ROW_HEIGHT = 3.5;

  // eslint-disable-next-line react/hook-use-state
  const [CELL_WIDTH_EM, setCELL_WIDTH_EM] = useState(DEFAULT_CELL_WIDTH_EM)
  // eslint-disable-next-line react/hook-use-state
  const [CELL_HEIGHT_EM, setCELL_HEIGHT_EM] = useState(DEFAULT_CELL_HEIGHT_EM)
  // eslint-disable-next-line react/hook-use-state
  const [FONT_SIZE_PX, setFONT_SIZE_PX] = useState(DEFAULT_FONT_SIZE_PX)

  const CELL_HEIGHT_GUIDECAL_EM = CELL_HEIGHT_EM * GUIDE_ROW_HEIGHT

  const CALENDAR_RIGHT_BUFFER = 20 // in days
  const TOURCALENDAR_BOTTOM_BUFFER = 10 // in rows
  const GUIDECALENDAR_BOTTOM_BUFFER = 5 // in rows


  const [programmedScrollLeft, setProgrammedScrollLeft] = useState<number | null>(null) // scrollLeft set when changing zoom level, to be set via useEffect after DOM is re-rendered
  const [programmedScrollTop, setProgrammedScrollTop] = useState<number | null>(null)   // scrollTop  set when changing zoom level, to be set via useEffect after DOM is re-rendered

  const halfHeightPx = (window.innerHeight - (20 * FONT_SIZE_PX)) / 2
  const [block3heightPx, setBlock3heightPx] = useState(isTravelDesigner ? 0 : halfHeightPx)

  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])


  const [shownPopup, setShownPopup] = useState<string | null>(null)
  const [shownEditWindow, setShownEditWindow] = useState<EditWindowParamsType | null>(null)


  const getCurrentHourUTC = () => Math.floor(new Date().getTime() / (1000 * 3600)) * 1000 * 3600 + 9 * 1000 * 3600 // rounded to nearest hour and convert JST to UTC

  const [currentHourUTC, setCurrentHourUTC] = useState<number>(getCurrentHourUTC())
  useEffect(() => {
    const intervalId = window.setInterval(
      () => {
        const newCurrentHour = getCurrentHourUTC()
        console.log('current hour', newCurrentHour)
        setCurrentHourUTC(newCurrentHour)
        // `setCurrentHourUTC(newCurrentHour)` will not cause rerender if hour is the same,
        // so technically we could run this at a much shorter interval.
        // But 10 minutes should be sufficient as the red vertical line will not move a
        // visible amount within a 10 minute interval
      },
      10 * 60 * 1000) // every 10 minutes

    return () => window.clearInterval(intervalId)
  }, [])


  // restrict guides to only see next 3~4 months
  const dateutcToday = getTodayUTC()
  const dateutcFirstOfMonth = getFirstDayOfMonth(dateutcToday)
  const guideMaxDate = addDays(addMonthsUtc(dateutcFirstOfMonth, 4), -1) // last day of month


  // (1) fluid range based on today's date
  // const defaultCalendarStart = addMonthsUtc(todayUtc, -1)
  // const defaultCalendarEnd = addMonthsUtc(todayUtc, 12)

  // (2) round down to nearest month
  const defaultCalendarStart = addMonthsUtc(dateutcFirstOfMonth, -1)
  // default end date is 6~7 months from today
  let defaultCalendarEnd = addDays(addMonthsUtc(dateutcFirstOfMonth, 7), -1) // last day of month

  // (3) round to nearest quarter
  // const monthLag = 1 + ((todayUtc.getUTCMonth() + 2) % 3)
  // const defaultCalendarStart = addMonthsUtc(getFirstDayOfMonth(todayUtc), -monthLag)
  // const defaultCalendarEnd = addDays(addMonthsUtc(defaultCalendarStart, 12), -1)

  if (viewAsGuide && defaultCalendarEnd > guideMaxDate)
    defaultCalendarEnd = guideMaxDate

  // const dateutcCalendarStart = useMemo(() => parseIsoDateToUTC('2023-12-01'), [])
  const [dateutcCalendarStart, setDateutcCalendarStart] = useState(defaultCalendarStart)

  // const dateutcCalendarEnd = useMemo(() => parseIsoDateToUTC('2024-12-31'), [])
  const [dateutcCalendarEnd, setDateutcCalendarEnd] = useState(defaultCalendarEnd)
  const setDateutcCalendarEndSafe = (date: Date) => {
    if (viewAsGuide && date > guideMaxDate) {
      setDateutcCalendarEnd(guideMaxDate)
    } else {
      setDateutcCalendarEnd(date)
    }
  }

  // in case travel designer switched to view as guide:
  if (viewAsGuide && dateutcCalendarEnd > guideMaxDate) {
    // hopefully won't cause infinite loop!
    setDateutcCalendarEnd(guideMaxDate)
  }


  const datelocalCalendarStart = useMemo(() => local0_from_utc0(dateutcCalendarStart), [dateutcCalendarStart])
  const datelocalCalendarEnd = useMemo(() => local0_from_utc0(dateutcCalendarEnd), [dateutcCalendarEnd])

  const dateisoCalendarStart = useMemo(() => iso_from_utc0(dateutcCalendarStart), [dateutcCalendarStart])
  const dateisoCalendarEnd = useMemo(() => iso_from_utc0(dateutcCalendarEnd), [dateutcCalendarEnd])
  const dateisoTourRequestQueryStart = useMemo(() => {
    // we need all the tourrequests from up to 1 year ago in order to calculate the number of hours worked by each guide
    const todayutc = getTodayUTC()
    const oneYearAgo = addMonthsUtc(todayutc, -12)
    const min = oneYearAgo < dateutcCalendarStart ? oneYearAgo : dateutcCalendarStart
    return iso_from_utc0(min)
  }, [dateutcCalendarStart])

  const numDaysInCalendar = getSpanDaysExact(dateutcCalendarStart, dateutcCalendarEnd) + 1




  const userDetailsList = useUserDetailsList()
  const userListSimple = useMemo(() => getUserListSimple(userDetailsList), [userDetailsList])

  // all internal guides, internal employees, freelance guides
  const listAllGuides = useGuideList(userListSimple)

  const ref_L2_TourListGrid = useRef<HTMLDivElement>(null)
  const ref_L3_GuideListGrid = useRef<HTMLDivElement>(null)
  const ref_R1_CalendarGridHeader = useRef<HTMLDivElement>(null)
  const ref_R2_TourCalendarGrid = useRef<HTMLDivElement>(null)
  const ref_R3_GuideCalendarGrid = useRef<HTMLDivElement>(null)

  const [autoScrollDates, setAutoScrollDates] = useState(false)
  const [showWorkdays, setShowWorkdays] = useState(false)

  const [showAllRequests, setShowAllRequests] = useState(false)
  const [enableEditing, setEnableEditing] = useState(false)

  const [hoveredDayNum, setHoveredDayNum] = useState<number | null>(null) // 0-based
  const [selectedColumns, setSelectedColumns] = useState<number[]>([]) // 0-based
  // const [hoveredRequestRow, setHoveredRequestRow] = useState<number | null>(null) // 0-based

  const [selectedCellR3, setSelectedCellR3] = useState<GuideCalendarSelectedCellType | null>(null)

  const [guideCalClipboard, setGuideCalClipboard] = useState<RectangularBlockType | null>(null)

  const [guideDetailedHours, setGuideDetailedHours] = useState<GuideDetailedHoursDataType | null>(null)

  const [yearlyCalendarGuide, setYearlyCalendarGuide] = useState<number | null>(null)
  const [yearlyCalendarYear, setYearlyCalendarYear] = useState<number | null>(null)

  const [guideCalendars, setGuideCalendars] = useState<GuideCalendarType[]>()
  useEffect(() => {

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const list: GuideCalendarType[] = [];
      console.log(`Retrieved ${snapshot.docs.length} guide calendars from firestore`)
      snapshot.docs.forEach((doc) => {
        const guidecalendar = { ...doc.data(), id: doc.id } as GuideCalendarType
        // convertGuideCalendarDates(guidecalendar)
        list.push(guidecalendar)
      })
      setGuideCalendars(list)
    }

    let q = query(collection(db, 'guidecalendars'))
    if (!isTravelDesigner)
      // q = doc(db, 'guidecalendars', userDetails.id)
      q = query(q, where(documentId(), '==', userDetails.id))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting guidecalendars', err));

    return unsubscribe
  }, [db, setDbError, dateisoCalendarStart, isTravelDesigner, userDetails.id])


  const [tourrequestsFromDb, setTourrequestsFromDb] = useState<TourRequestType[]>() // raw dump from db, not yet filtered based on display criteria
  useEffect(() => {

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const tourrequestsFromDb: TourRequestType[] = [];
      console.log(`Retrieved ${snapshot.docs.length} tour requests from firestore`)
      snapshot.docs.forEach((doc) => {
        const tourrequest = { ...doc.data(), id: doc.id } as TourRequestType
        convertTourRequestDates(tourrequest)
        tourrequestsFromDb.push(tourrequest)
      })
      setTourrequestsFromDb(tourrequestsFromDb)
    }

    const q = query(
      collection(db, 'tourrequests'),
      where('dateisoTourEnd', '>=', dateisoTourRequestQueryStart),
      // orderBy('dateCreated', 'desc'),
      // limit(100)
    )
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting request list', err));

    return unsubscribe
  }, [db, setDbError, dateisoTourRequestQueryStart])



  const requests = useMemo<TourRequestTypeWithDates[] | undefined>(() => {
    if (!tourrequestsFromDb) return undefined

    const excludeRequest = (tourrequest: TourRequestType) => {
      if (!tourrequest.dateisoTourStart) return 1
      if (!tourrequest.dateisoTourEnd) return 2
      if (tourrequest.dateisoTourStart > dateisoCalendarEnd) return 3
      if (tourrequest.dateisoTourEnd < dateisoCalendarStart) return 4
      if (!showAllRequests && isHiddenTour(tourrequest)) return 5
      if (viewAsGuide || filteredGuides) {
        if (!tourrequest.calendarDays) return 6

        const guideIds = viewAsGuide ? [viewAs.id] : filteredGuides!.map((g) => g.id)

        let foundGuideOnTour = false
        for (const [dateiso, dayData] of Object.entries(tourrequest.calendarDays)) {
          if (dayData.guides && dayData.guides.some((g) => guideIds.includes(g.id))) {
            // relevant guide is guiding on this tour
            foundGuideOnTour = true
            break
          }
        }
        if (!foundGuideOnTour)
          return 7
      }
      // don't exclude this request
      return 0
    }

    const filtered = tourrequestsFromDb
      .filter((tourrequest: TourRequestType) => !excludeRequest(tourrequest))
      .map((tourrequest) => ({
        ...tourrequest,
        _dateutcTourStart: utc0_from_iso(tourrequest.dateisoTourStart),
        _dateutcTourEnd: utc0_from_iso(tourrequest.dateisoTourEnd),
      }))
      .sort((a, b) => a.dateisoTourStart.localeCompare(b.dateisoTourStart))
    // .slice(-12)

    return filtered
  }, [dateisoCalendarStart, dateisoCalendarEnd, showAllRequests, tourrequestsFromDb, viewAsGuide, viewAs, filteredGuides])



  const shownEditWindowTourRequest = useMemo(() => {
    if (!shownEditWindow) return null
    if (!requests)
      throw new Error('unreachable: uninitialized') // impossible as shownEditWindow can only be set after page finishes loading, so `requests` will be defined.

    return requests.find((request) => request.id === shownEditWindow.tourrequestId) ?? null // could theoretically be null if the request is removed from `requests` (e.g. changed from Confirmed to Cancelled) after user clicked the tour and displayed the edit window popup
  }, [shownEditWindow, requests])


  useEffect(() => {
    // we have to use useEffect here because we have to wait until the page is re-rendered before setting scrollLeft/scrollTop
    if (programmedScrollLeft === null)
      return
    console.log(`setting scrollLeft to ${programmedScrollLeft}`)
    if (ref_R2_TourCalendarGrid.current)
      ref_R2_TourCalendarGrid.current.scrollLeft = programmedScrollLeft
    if (ref_R1_CalendarGridHeader.current)
      ref_R1_CalendarGridHeader.current.scrollLeft = programmedScrollLeft
    if (ref_R3_GuideCalendarGrid.current)
      ref_R3_GuideCalendarGrid.current.scrollLeft = programmedScrollLeft
  }, [programmedScrollLeft])

  useEffect(() => {
    // we have to use useEffect here because we have to wait until the page is re-rendered before setting scrollLeft/scrollTop
    if (programmedScrollTop === null)
      return
    console.log(`setting scrollTop to ${programmedScrollTop}`)
    if (ref_R2_TourCalendarGrid.current)
      ref_R2_TourCalendarGrid.current.scrollTop = programmedScrollTop
    if (ref_L2_TourListGrid.current)
      ref_L2_TourListGrid.current.scrollTop = programmedScrollTop
  }, [programmedScrollTop])


  const usedGuides = useMemo(() => {
    if (!requests || !listAllGuides || !userDetailsList)
      return undefined

    if (viewAsGuide) {
      return listAllGuides.filter((user) => user.id === viewAs.id)
    }

    const usedGuidesSet = new Set<string>()

    if (filteredGuides) {
      for (const guide of filteredGuides) {
        usedGuidesSet.add(guide.id)
      }
    } else {
      for (const request of requests) {
        if (!request.calendarDays)
          continue
        for (const dateiso of Object.keys(request.calendarDays)) {
          if (dateiso < dateisoCalendarStart || dateiso > dateisoCalendarEnd) {
            // can happen if the tour starts before calendar start and ends after calendar start, or starts before calendar end and ends after calendar end
            continue
          }
          if (!request.calendarDays[dateiso].guides)
            continue
          for (const guide of request.calendarDays[dateiso].guides) {
            usedGuidesSet.add(guide.id)
          }
        }
      }
    }

    const usedGuides = listAllGuides.filter((user) => usedGuidesSet.has(user.id))
    usedGuides.sort((a, b) => {
      if (a.teamName !== b.teamName) {
        return calendarGuideTeamList.indexOf(a.teamName) - calendarGuideTeamList.indexOf(b.teamName)
      }

      if (a.teamName !== 'Eighty Days Tour Leaders')
        return a.name.localeCompare(b.name)

      const userDetailsA = userDetailsList.find((user) => user.id === a.id)
      const userDetailsB = userDetailsList.find((user) => user.id === b.id)

      const orderA = userDetailsA?.guideNumber || 999
      const orderB = userDetailsB?.guideNumber || 999

      return orderA - orderB
    })

    return usedGuides
  }, [listAllGuides, requests, userDetailsList, viewAsGuide, viewAs, filteredGuides, dateisoCalendarStart, dateisoCalendarEnd])


  const { listDays, listMonths }: { listDays: Date[], listMonths: MonthItemType[] } = useMemo(() => {

    const listDays: Date[] = []
    const listMonths: MonthItemType[] = []

    let curMonth = '' // yyyy-mm
    for (let i = 0; i < numDaysInCalendar; i++) {
      const dateutc = addDays(dateutcCalendarStart, i)
      listDays.push(dateutc)
      const thisMonth = dateutcFormatStandardMonth(dateutc) // yyyy-mm
      if (thisMonth !== curMonth) {
        const firstDayNextMonth = getFirstDayOfNextMonth(dateutc)
        const dayIndexFirstDayNextMonth = getSpanDaysExact(dateutcCalendarStart, firstDayNextMonth)
        const monthEndDayIndexExc = Math.min(dayIndexFirstDayNextMonth, numDaysInCalendar)
        listMonths.push({
          strYearMonth: thisMonth, // yyyy-mm
          monthStartDayIndex: i,
          monthEndDayIndexExc,
        })
        curMonth = thisMonth
      }
    }

    return { listDays, listMonths }
  }, [dateutcCalendarStart, numDaysInCalendar])


  const guideCalendarCellData = useGuideCalendarCellData(
    dateutcCalendarStart,
    dateutcCalendarEnd,
    guideCalendars,
    requests,
    usedGuides,
  )


  const TOUR_LIST_COLS = useMemo(() => {

    const TOUR_LIST_COLS_ALL: TourListColType[] = [
      // {'Title', value getter, em width, show?}
      {
        title: 'Code',
        func: (tourrequest: TourRequestTypeWithDates, iTourRequest: number) => {
          // const scrollTop = ref_L2_TourListGrid.current?.scrollTop
          return (
            <RequestCodeLinkToAggregator
              requestCode={tourrequest.requestCode}
              linkId={`link_${iTourRequest}`}
              shownPopup={shownPopup}
              setShownPopup={setShownPopup}
              linkColorInherit={true}
            />
          )
        },
        widthEm: 8,
        show: true,
      },
      {
        title: 'Agency',
        func: (tourrequest: TourRequestTypeWithDates, iTourRequest: number) => tourrequest.agencyOrPlatform,
        widthEm: 10,
        show: true,
      },
      {
        title: 'Pax name',
        func: (tourrequest: TourRequestTypeWithDates, iTourRequest: number) => {
          return <>
            {!tourrequest.statusConfirmed && (
              <div style={{
                position: 'absolute',
                right: '0.25em',
              }}>
                <RequestStatusPillCurrent tourrequest={tourrequest} vertical={false} clickHandler={null} />
              </div>
            )}
            {tourrequest.travellerName}
          </>
        },
        widthEm: 13,
        show: true,
      },
      {
        title: 'Dates',
        func: (tourrequest: TourRequestTypeWithDates, iTourRequest: number) => `${dateutcFormatJp(tourrequest._dateutcTourStart)} - ${dateutcFormatJp(tourrequest._dateutcTourEnd)}`,
        widthEm: 14,
        show: false,
      },
      {
        title: 'X',
        func: (tourrequest: TourRequestTypeWithDates, iTourRequest: number) => {
          if (isHiddenTour(tourrequest) && !tourrequest.hiddenInCalendar)
            return null
          return (
            <ButtonTW variant='darkgray_outline'
              className='tw-py-0 tw-px-0 tw-mt-[-0.25em] tw-w-[1.75em]'
              title={tourrequest.hiddenInCalendar ? 'Unhide in calendar' : 'Hide in calendar'}
              onClick={(e) => {
                console.log('hide', tourrequest.id)
                toggleHideRequestOnCalendar(db, userDetails, tourrequest)
              }}>{tourrequest.hiddenInCalendar ? 'u' : 'x'}</ButtonTW>
          )
        },
        widthEm: 2.5,
        show: enableEditing,
      },
    ]

    return TOUR_LIST_COLS_ALL.filter((col) => col.show)
  }, [db, enableEditing, shownPopup, userDetails])


  const computeY = useCallback((x: number) => {
    if (!requests)
      throw new Error('unreachable: uninitialized')

    const yArray = requests.map((request) => getSpanDaysExact(dateutcCalendarStart, request._dateutcTourStart))
    const SMOOTHING_DIST = 20
    const xFloor = Math.floor(x)
    let totalWeight = 0
    let totalWeightedY = 0
    for (let i = xFloor - SMOOTHING_DIST; i <= xFloor + SMOOTHING_DIST; i++) {
      if (i < 0 || i >= requests.length)
        continue

      const weight = Math.max(0, 1 - Math.abs(x - i) / SMOOTHING_DIST)
      if (weight === 0)
        continue

      totalWeight += weight
      totalWeightedY += weight * yArray[i]
    }

    return totalWeightedY / totalWeight
  }, [dateutcCalendarStart, requests])


  const [showChangeLog, setShowChangeLog] = useState(false)
  const [calendarLogs, setCalendarLogs] = useState<CalendarLogType[]>()
  useEffect(() => {

    if (isReadOnly) {
      setCalendarLogs([]) // so that loading spinner doesn't remain
      return
    }

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const list: CalendarLogType[] = [];
      console.log(`Retrieved ${snapshot.docs.length} calendar logs from firestore`)
      snapshot.docs.forEach((doc) => {
        const obj = { ...doc.data(), id: doc.id } as CalendarLogType
        convertCalendarLogDates(obj)
        list.push(obj)
      })
      setCalendarLogs(list)
    }

    const q = query(collection(db, 'calendarlogs'), orderBy('dateCreated', 'desc'), limit(50))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting calendarlogs', err));

    return unsubscribe
  }, [db, setDbError, isReadOnly])


  const typeaheadItemCalendarAdmin = useMemo(() => ({ id: 'CALENDAR_ADMIN', name: 'Calendar admin', email: '', teamName: 'TOP' }), [])
  const typeaheadItemTravelDesigner = useMemo(() => ({ id: 'TRAVEL_DESIGNER', name: 'Travel designer', email: '', teamName: 'TOP' }), [])


  const scrollToToday = useCallback(() => {
    if (!requests || !ref_R2_TourCalendarGrid.current)
      throw new Error('unreachable: uninitialized')

    // Calculate scrollTop
    const todayUtc = getTodayUTC()
    let iTourFirst = requests.length - 1
    let iTourLast = 0
    for (let i = 0; i < requests.length; i++) {
      const tourrequest = requests[i]
      if (tourrequest._dateutcTourStart <= todayUtc) {
        iTourLast = Math.max(iTourLast, i)
      }
      if (tourrequest._dateutcTourEnd >= todayUtc) {
        iTourFirst = Math.min(iTourFirst, i)
      }
    }

    const pxHeight = ref_R2_TourCalendarGrid.current.clientHeight
    const iHeight = Math.floor(pxHeight / (CELL_HEIGHT_EM * FONT_SIZE_PX))
    let iTop = Math.ceil((iTourFirst + iTourLast) / 2 - iHeight / 2)
    if (iTop > iTourFirst) iTop = iTourFirst
    if (iTop < 0) iTop = 0
    const scrollTop = iTop * CELL_HEIGHT_EM * FONT_SIZE_PX
    ref_R2_TourCalendarGrid.current.scrollTop = scrollTop


    // Calculate scrollLeft
    const pxWidth = ref_R2_TourCalendarGrid.current.clientWidth
    const iWidth = Math.floor(pxWidth / (CELL_WIDTH_EM * FONT_SIZE_PX))
    const daysFromStart = getSpanDaysExact(dateutcCalendarStart, todayUtc)
    let iLeft = Math.ceil(daysFromStart - iWidth / 2)
    if (iLeft < 0) iLeft = 0
    const scrollLeft = iLeft * CELL_WIDTH_EM * FONT_SIZE_PX
    ref_R2_TourCalendarGrid.current.scrollLeft = scrollLeft
  }, [CELL_HEIGHT_EM, CELL_WIDTH_EM, FONT_SIZE_PX, dateutcCalendarStart, requests])




  const refPageLoaded = useRef(false)

  useEffect(() => {
    if (!tourrequestsFromDb || !requests || !listAllGuides || !usedGuides || !guideCalendars || !calendarLogs) {
      // loading phase
    } else {
      // page fully loaded
      if (!refPageLoaded.current) {
        // first time page was fully loaded
        console.log('PAGE LOADED (useEffect)')
        refPageLoaded.current = true
        scrollToToday()
      }
    }
  }, [calendarLogs, guideCalendars, listAllGuides, requests, tourrequestsFromDb, usedGuides, scrollToToday])



  /**** ALL HOOKS ABOVE ****/


  const loadingSpinner = getLoadingSpinnerOrNull([
    ['tour requests', tourrequestsFromDb && requests],
    ['tour leader list', userDetailsList && listAllGuides && usedGuides],
    ['tour leader calendars', guideCalendars && guideCalendarCellData],
    ['change log', calendarLogs],
  ])
  if (!tourrequestsFromDb || !requests || !userDetailsList || !listAllGuides || !usedGuides || !guideCalendars || !guideCalendarCellData || !calendarLogs)
    return loadingSpinner





  const scrollToDate = () => {
    if (!ref_R1_CalendarGridHeader.current || !ref_R2_TourCalendarGrid.current)
      throw new Error('unreachable: uninitialized')

    const scrollTop = ref_R2_TourCalendarGrid.current.scrollTop
    const vStep = CELL_HEIGHT_EM * FONT_SIZE_PX // 2em * 10.5px
    const hStep = CELL_WIDTH_EM * FONT_SIZE_PX // 1.5em * 10.5px
    const x = (scrollTop) / vStep
    const y = computeY(x)
    const scrollLeft = y * hStep

    ref_R1_CalendarGridHeader.current.scrollLeft = scrollLeft
    ref_R2_TourCalendarGrid.current.scrollLeft = scrollLeft
  }

  const scrollToTour = () => {
    if (!ref_L2_TourListGrid.current || !ref_R2_TourCalendarGrid.current)
      throw new Error('unreachable: uninitialized')

    const scrollLeftPx = ref_R2_TourCalendarGrid.current.scrollLeft
    const vStep = CELL_HEIGHT_EM * FONT_SIZE_PX // 2em * 10.5px
    const hStep = CELL_WIDTH_EM * FONT_SIZE_PX // 1.5em * 10.5px
    const scrollLeftDays = scrollLeftPx / hStep
    const dayIndex = Math.floor(scrollLeftDays)
    const dateutc = addDays(dateutcCalendarStart, dayIndex)
    let iTourRequest = requests.length // by default, scroll to bottom
    for (let i = 0; i < requests.length; i++) {
      if (requests[i]._dateutcTourStart >= dateutc) {
        iTourRequest = i
        break
      }
    }
    const scrollTopPx = iTourRequest * vStep

    ref_L2_TourListGrid.current.scrollTop = scrollTopPx
    ref_R2_TourCalendarGrid.current.scrollTop = scrollTopPx
  }

  const onScrollL2 = (e: UIEvent) => {
    if (!ref_R1_CalendarGridHeader.current || !ref_R2_TourCalendarGrid.current)
      throw new Error('unreachable: uninitialized')

    const scrollTop = e.currentTarget.scrollTop
    ref_R2_TourCalendarGrid.current.scrollTop = scrollTop

    if (!autoScrollDates) return

    const vStep = CELL_HEIGHT_EM * FONT_SIZE_PX // 2em * 10.5px
    const hStep = CELL_WIDTH_EM * FONT_SIZE_PX // 1.5em * 10.5px

    // const calendarWidth = ref_R2_TourCalendarGrid.current.clientWidth
    // const calendarWidthDays = calendarWidth / hStep

    const calendarHeight = ref_R2_TourCalendarGrid.current.clientHeight
    // const x = (scrollTop + calendarHeight / 2) / vStep
    const x = (scrollTop) / vStep
    // const index = Math.floor(x)

    const y = computeY(x)
    const scrollLeft = y * hStep

    ref_R1_CalendarGridHeader.current.scrollLeft = scrollLeft
    ref_R2_TourCalendarGrid.current.scrollLeft = scrollLeft
  }

  const onScrollL3 = (e: UIEvent) => {
    if (!ref_R3_GuideCalendarGrid.current)
      throw new Error('unreachable: uninitialized')

    const scrollTop = e.currentTarget.scrollTop
    ref_R3_GuideCalendarGrid.current.scrollTop = scrollTop
  }

  const onScrollR1 = (e: UIEvent) => {
    if (!ref_R2_TourCalendarGrid.current || !ref_R3_GuideCalendarGrid.current)
      throw new Error('unreachable: uninitialized')

    const scrollLeft = e.currentTarget.scrollLeft
    ref_R2_TourCalendarGrid.current.scrollLeft = scrollLeft
    ref_R3_GuideCalendarGrid.current.scrollLeft = scrollLeft
  }

  const onScrollR2 = (e: UIEvent) => {
    if (!ref_L2_TourListGrid.current || !ref_R1_CalendarGridHeader.current || !ref_R2_TourCalendarGrid.current || !ref_R3_GuideCalendarGrid.current)
      throw new Error('unreachable: uninitialized')

    const scrollTop = e.currentTarget.scrollTop
    const scrollLeft = e.currentTarget.scrollLeft

    const calendarTotalWidthPx = numDaysInCalendar * CELL_WIDTH_EM * FONT_SIZE_PX
    const calendarTotalHeightPx = requests.length * CELL_HEIGHT_EM * FONT_SIZE_PX

    const maxScrollLeftPx = calendarTotalWidthPx - e.currentTarget.clientWidth
      + (CALENDAR_RIGHT_BUFFER - 2) * CELL_WIDTH_EM * FONT_SIZE_PX
    // the -2 is to account for vertical scrollbar width: as R1 does not have a vertical scrollbar,
    // without the -2, R1 and R2 will be misaligned when scrolling all the way to the right.

    const maxScrollTopPx = calendarTotalHeightPx - e.currentTarget.clientHeight
      + (TOURCALENDAR_BOTTOM_BUFFER - 2) * CELL_HEIGHT_EM * FONT_SIZE_PX

    const cappedScrollLeftPx = Math.min(scrollLeft, maxScrollLeftPx)
    const cappedScrollTopPx = Math.min(scrollTop, maxScrollTopPx)

    ref_L2_TourListGrid.current.scrollTop = cappedScrollTopPx
    ref_R1_CalendarGridHeader.current.scrollLeft = cappedScrollLeftPx
    ref_R3_GuideCalendarGrid.current.scrollLeft = cappedScrollLeftPx
    ref_R2_TourCalendarGrid.current.scrollLeft = cappedScrollLeftPx // needed in case we scrolled beyond cap
    ref_R2_TourCalendarGrid.current.scrollTop = cappedScrollTopPx // needed in case we scrolled beyond cap
  }

  const onScrollR3 = (e: UIEvent) => {
    if (!ref_L3_GuideListGrid.current || !ref_R1_CalendarGridHeader.current || !ref_R2_TourCalendarGrid.current || !ref_R3_GuideCalendarGrid.current)
      throw new Error('unreachable: uninitialized')

    const scrollTop = e.currentTarget.scrollTop
    const scrollLeft = e.currentTarget.scrollLeft

    const calendarTotalHeightPx = usedGuides.length * CELL_HEIGHT_GUIDECAL_EM * FONT_SIZE_PX
    const maxScrollTopPx = calendarTotalHeightPx - e.currentTarget.clientHeight
      + (GUIDECALENDAR_BOTTOM_BUFFER - 1) * CELL_HEIGHT_GUIDECAL_EM * FONT_SIZE_PX
    // the -1 is to account for horizontal scrollbar width: as L3 does not have a horizontal scrollbar,
    // without the -1, L3 and R3 will be misaligned when scrolling all the way to the bottom.

    const cappedScrollTopPx = Math.min(scrollTop, maxScrollTopPx)

    ref_L3_GuideListGrid.current.scrollTop = cappedScrollTopPx
    ref_R1_CalendarGridHeader.current.scrollLeft = scrollLeft
    ref_R2_TourCalendarGrid.current.scrollLeft = scrollLeft
    ref_R3_GuideCalendarGrid.current.scrollTop = cappedScrollTopPx // needed in case we scrolled beyond cap
  }


  const changeLogWidthEm = showChangeLog ? 20 : 0

  const tourListtotalWidth = arraySum(TOUR_LIST_COLS.map((col) => col.widthEm))

  // available height is calibrated to not make the vertical scroll bar appear on Chrome on Windows
  const availableHeightPx = windowHeight - (22 * FONT_SIZE_PX) // roughly 100vh - 22em // https://stackoverflow.com/questions/49268659/javascript-get-100vh-in-pixels
  const block2heightPx = availableHeightPx - block3heightPx

  const block2height = `${block2heightPx}px`
  const block3height = `${block3heightPx}px`

  // below width only works if we make sure total page height does not exceed window height, such that we don't have a vertical scrollbar.
  // (if we have a vertical scroll bar for the full page on Chrome on Windows, we need to use 3em instead of 2em in order to avoid a horizontal scrollbar)
  const calendarGridWidth = `max(min(42em, calc(100vw - 2em)), calc(100vw - 2em - ${tourListtotalWidth + changeLogWidthEm}em))` // 1em padding on left and right of page
  // the right hand side calc is the correct width for wide screens.
  // for narrow screens, the floor on the left hand side kicks in.
  // we want it to be at least 42em wide, except we don't want the floor to be more than the screen width.

  const curYear = new Date().getFullYear()
  const yearList = [curYear - 1, curYear, curYear + 1]

  const dateRangeButtons = [
    {
      label: 'Default',
      dateutcStart: defaultCalendarStart,
      dateutcEnd: defaultCalendarEnd,
    }
  ]

  if (!viewAsGuide) {
    for (const year of yearList) {
      dateRangeButtons.push({
        label: year.toString(),
        dateutcStart: getDateutc(year, 1, 1),
        dateutcEnd: getDateutc(year, 12, 31),
      })
    }
  }

  function adjustScrollForZoomHorizontal(ratio: number) {
    if (!ref_R2_TourCalendarGrid.current)
      throw new Error('unreachable: uninitialized')

    const curLeftPx = ref_R2_TourCalendarGrid.current.scrollLeft
    const curWidthPx = ref_R2_TourCalendarGrid.current.clientWidth
    const curCenterPx = curLeftPx + curWidthPx / 2
    const newCenterPx = curCenterPx * ratio
    const newWidthPx = curWidthPx / ratio
    const newLeftPx = newCenterPx - newWidthPx / 2
    // ref_R2_TourCalendarGrid.current.scrollLeft = newLeftPx
    // ref_R1_CalendarGridHeader.current.scrollLeft = newLeftPx
    // console.log('ratio', ratio)
    // console.log('curLeftPx', curLeftPx)
    // console.log('curWidthPx', curWidthPx)
    // console.log('curCenterPx', curCenterPx)
    // console.log('newCenterPx', newCenterPx)
    // console.log('newWidthPx', newWidthPx)
    // console.log('newLeftPx', newLeftPx)
    setProgrammedScrollLeft(newLeftPx)
  }

  function adjustScrollForZoomVertical(ratio: number) {
    if (!ref_R2_TourCalendarGrid.current)
      throw new Error('unreachable: uninitialized')

    const curTopPx = ref_R2_TourCalendarGrid.current.scrollTop
    const curHeightPx = ref_R2_TourCalendarGrid.current.clientHeight
    const curCenterPx = curTopPx + curHeightPx / 2
    const newCenterPx = curCenterPx * ratio
    const newHeightPx = curHeightPx / ratio
    const newTopPx = newCenterPx - newHeightPx / 2
    // ref_R2_TourCalendarGrid.current.scrollTop = newTopPx
    // ref_L2_TourListGrid.current.scrollTop = newTopPx
    setProgrammedScrollTop(newTopPx)
  }


  const resizerOnMouseDown = (e: React.MouseEvent) => {
    e.preventDefault()
    log_info({ db, userDetails, logkey: 'tourcalendar.drag_resizer', desc: 'Tour calendar: Drag resizer' })

    const startY = e.clientY
    const startHeight = block3heightPx
    const onMouseMove = (e: MouseEvent) => {
      const deltaY = e.clientY - startY
      setBlock3heightPx(Math.max(0, Math.min(startHeight - deltaY, availableHeightPx)))
    }
    const onMouseUp = (e: MouseEvent) => {
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('mouseup', onMouseUp)
    }
    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('mouseup', onMouseUp)
  }

  const closeAllPopups = () => {
    setShownEditWindow(null)
    setYearlyCalendarGuide(null)
    setGuideDetailedHours(null)
    setSelectedCellR3(null)
  }

  const sEightDaysTourLeaders: CalendarGuideTeamType = 'Eighty Days Tour Leaders'

  const viewAsSelectedItem: UserSimpleType =
    viewAsCalendarAdmin ? typeaheadItemCalendarAdmin
      : viewAsTravelDesigner ? typeaheadItemTravelDesigner
        : viewAs

  return (
    <div className='container-fluid page-tourcalendar' style={{ fontSize: `${FONT_SIZE_PX}px` }}>

      <Helmet><title>Tour Calendar</title></Helmet>
      {/* <Helmet htmlAttributes={{ style: `font-size: ${FONT_SIZE_PX}px;` }} /> */}

      <div className='titleRow tw-flex tw-justify-between tw-items-center'>
        <div className='tw-flex tw-items-baseline tw-gap-[3em]'>
          <h1 className='tw-my-4 tw-text-[2em] tw-font-medium'>Tour Calendar</h1>
          {/*isTravelDesigner && (
            <div className='[&>div>div]:tw-text-[1em] tw-flex tw-gap-2 tw-items-baseline'>
              View calendar as:
              <TypeaheadUserList
                id='typeaheadUserList_viewAsUser'
                multiple={false}
                userList={[
                  ...(isCalendarAdmin ? [typeaheadItemCalendarAdmin] : []),
                  typeaheadItemTravelDesigner,
                  ...listAllGuides
                ]}
                guidesFirst={true}
                selected={[viewAsSelectedItem]}
                onChange={(selected: UserSimpleTeamType[]) => {
                  console.log('selected', selected)
                  closeAllPopups()
                  if (selected.length === 0) {
                    setViewAs(viewAs_DEFAULT)
                  } else {
                    const item = selected[0]
                    if (item.id === 'CALENDAR_ADMIN') {
                      setViewAs('CALENDAR_ADMIN')
                    } else if (item.id === 'TRAVEL_DESIGNER') {
                      setViewAs('TRAVEL_DESIGNER')
                    } else {
                      setViewAs(item)
                      // show both tour calendar and TL schedule
                      setBlock3heightPx(halfHeightPx)
                    }
                  }
                }}
                customPlaceholder={'View as...'}
                teamList={['TOP', sEightDaysTourLeaders]}
              />
            </div>
          )*/}

          {viewAsTravelDesigner && (
            <div className='[&>div>div]:tw-text-[1em] tw-flex tw-gap-2 tw-items-baseline'>
              Filter TL:
              <TypeaheadUserList
                id='typeaheadUserList_filterTL'
                multiple={true}
                userList={[{ id: '_CLEAR_', name: 'Clear filter', email: '', teamName: 'TOP' }, ...listAllGuides]}
                guidesFirst={true}
                selected={filteredGuides ?? []}
                onChange={(selected: UserSimpleCalendarGuideType[]) => {
                  console.log('filtered guides', selected)
                  closeAllPopups()
                  if (selected.length === 0) {
                    setFilteredGuides(null)
                  } else if (selected.some((g) => g.id === '_CLEAR_')) {
                    setFilteredGuides(null)
                  } else {
                    const uniqueGuides: UserSimpleCalendarGuideType[] = []
                    for (const item of selected) {
                      if (!uniqueGuides.find((u) => u.id === item.id)) {
                        uniqueGuides.push(item)
                      }
                    }
                    setFilteredGuides(uniqueGuides)

                    // show both tour calendar and TL schedule
                    if (block3heightPx === 0)
                      setBlock3heightPx(halfHeightPx)

                  }
                  log_info({ db, userDetails, logkey: 'tourcalendar.set_guide_filter', desc: `Tour calendar: set guide filter to [${selected.map((x) => `[${x.email}]`).join('')}]` })
                }}
                customPlaceholder={'Filter TL...'}
                teamList={['TOP', ...calendarGuideTeamList]}
              />
            </div>
          )}
        </div>

        <div className='zoomControls tw-flex tw-items-center tw-gap-[0.5em]'>
          <div>
            <ButtonTW variant='blue_outline' onClick={() => {
              setBlock3heightPx(0)
              closeAllPopups()
              log_info({ db, userDetails, logkey: 'tourcalendar.show_only_tours', desc: 'Tour calendar: Show only tours' })
            }}>Tours</ButtonTW>
          </div>
          <div>
            <ButtonTW variant='blue_outline' onClick={() => {
              setBlock3heightPx(availableHeightPx)
              closeAllPopups()
              log_info({ db, userDetails, logkey: 'tourcalendar.show_only_guides', desc: 'Tour calendar: Show only guides' })
            }}>TL schedule</ButtonTW>
          </div>
          <div className='me-3'>
            <ButtonTW variant='blue_outline' onClick={() => {
              setBlock3heightPx(availableHeightPx / 2)
              closeAllPopups()
              log_info({ db, userDetails, logkey: 'tourcalendar.show_both', desc: 'Tour calendar: Show both tours and guides' })
            }}>Both</ButtonTW>
          </div>
          <div>
            H-zoom: {Math.round(CELL_WIDTH_EM / DEFAULT_CELL_WIDTH_EM * 100)}%
          </div>
          <div className='removeMinHeight'>
            <ButtonTW variant={CELL_WIDTH_EM > DEFAULT_CELL_WIDTH_EM ? 'bsOrange' : 'blue_outline'} onClick={(e) => {
              const newVal = CELL_WIDTHS[CELL_WIDTHS.indexOf(CELL_WIDTH_EM) + 1] || CELL_WIDTH_EM
              const ratio = newVal / CELL_WIDTH_EM
              setCELL_WIDTH_EM(newVal)
              adjustScrollForZoomHorizontal(ratio)
              log_info({ db, userDetails, logkey: 'tourcalendar.zoom_horizontal_wider', desc: `Tour calendar: Zoom horizontal wider ${CELL_WIDTH_EM} to ${newVal}` })
            }}><i className='bi bi-arrows-expand-vertical'></i></ButtonTW>
          </div>
          <div className='removeMinHeight'>
            <ButtonTW variant={CELL_WIDTH_EM < DEFAULT_CELL_WIDTH_EM ? 'bsOrange' : 'blue_outline'} onClick={(e) => {
              const newVal = CELL_WIDTHS[CELL_WIDTHS.indexOf(CELL_WIDTH_EM) - 1] || CELL_WIDTH_EM
              const ratio = newVal / CELL_WIDTH_EM
              setCELL_WIDTH_EM(newVal)
              adjustScrollForZoomHorizontal(ratio)
              log_info({ db, userDetails, logkey: 'tourcalendar.zoom_horizontal_narrower', desc: `Tour calendar: Zoom horizontal narrower ${CELL_WIDTH_EM} to ${newVal}` })
            }}><i className='bi bi-arrows-collapse-vertical'></i></ButtonTW>
          </div>
          <div>
            V-zoom: {Math.round(CELL_HEIGHT_EM / DEFAULT_CELL_HEIGHT_EM * 100)}%
          </div>
          <div className='removeMinHeight'>
            <ButtonTW variant={CELL_HEIGHT_EM > DEFAULT_CELL_HEIGHT_EM ? 'bsOrange' : 'blue_outline'} onClick={(e) => {
              const newVal = CELL_HEIGHTS[CELL_HEIGHTS.indexOf(CELL_HEIGHT_EM) + 1] || CELL_HEIGHT_EM
              const ratio = newVal / CELL_HEIGHT_EM
              setCELL_HEIGHT_EM(newVal)
              adjustScrollForZoomVertical(ratio)
              log_info({ db, userDetails, logkey: 'tourcalendar.zoom_vertical_taller', desc: `Tour calendar: Zoom vertical taller ${CELL_HEIGHT_EM} to ${newVal}` })
            }}><i className='bi bi-arrows-expand'></i></ButtonTW>
          </div>
          <div className='removeMinHeight'>
            <ButtonTW variant={CELL_HEIGHT_EM < DEFAULT_CELL_HEIGHT_EM ? 'bsOrange' : 'blue_outline'} onClick={(e) => {
              const newVal = CELL_HEIGHTS[CELL_HEIGHTS.indexOf(CELL_HEIGHT_EM) - 1] || CELL_HEIGHT_EM
              const ratio = newVal / CELL_HEIGHT_EM
              setCELL_HEIGHT_EM(newVal)
              adjustScrollForZoomVertical(ratio)
              log_info({ db, userDetails, logkey: 'tourcalendar.zoom_vertical_shorter', desc: `Tour calendar: Zoom vertical shorter ${CELL_HEIGHT_EM} to ${newVal}` })
            }}><i className='bi bi-arrows-collapse'></i></ButtonTW>
          </div>
          <div>
            Text-zoom: {Math.round(FONT_SIZE_PX / DEFAULT_FONT_SIZE_PX * 100)}%
          </div>
          <div className='removeMinHeight'>
            <ButtonTW variant={FONT_SIZE_PX > DEFAULT_FONT_SIZE_PX ? 'bsOrange' : 'blue_outline'} onClick={(e) => {
              const newVal = FONT_SIZES[FONT_SIZES.indexOf(FONT_SIZE_PX) + 1] || FONT_SIZE_PX
              const ratio = newVal / FONT_SIZE_PX
              setFONT_SIZE_PX(newVal)
              adjustScrollForZoomVertical(ratio)
              adjustScrollForZoomHorizontal(ratio)
              log_info({ db, userDetails, logkey: 'tourcalendar.zoom_text_larger', desc: `Tour calendar: Zoom text larger ${FONT_SIZE_PX} to ${newVal}` })
            }}><i className='bi bi-zoom-in'></i></ButtonTW>
          </div>
          <div className='removeMinHeight'>
            <ButtonTW variant={FONT_SIZE_PX < DEFAULT_FONT_SIZE_PX ? 'bsOrange' : 'blue_outline'} onClick={(e) => {
              const newVal = FONT_SIZES[FONT_SIZES.indexOf(FONT_SIZE_PX) - 1] || FONT_SIZE_PX
              const ratio = newVal / FONT_SIZE_PX
              setFONT_SIZE_PX(newVal)
              adjustScrollForZoomVertical(ratio)
              adjustScrollForZoomHorizontal(ratio)
              log_info({ db, userDetails, logkey: 'tourcalendar.zoom_text_smaller', desc: `Tour calendar: Zoom text smaller ${FONT_SIZE_PX} to ${newVal}` })
            }}><i className='bi bi-zoom-out'></i></ButtonTW>
          </div>
        </div>
      </div>

      <div className='toolBarRow tw-flex tw-justify-between'>
        <div className='tourCalendarDateRangeSelector tw-flex tw-items-center tw-gap-[0.5em] tw-mb-[0.5em]'>
          {dateRangeButtons.map(({ label, dateutcStart, dateutcEnd }) => {
            const selected =
              dateutcCalendarStart.getTime() === dateutcStart.getTime()
              && dateutcCalendarEnd.getTime() === dateutcEnd.getTime()
            return (
              <div key={label}>
                <ButtonTW
                  disabled={selected}
                  variant={selected ? 'blue' : 'blue_outline'}
                  onClick={() => {
                    console.log('CLICK')
                    setDateutcCalendarStart(dateutcStart)
                    setDateutcCalendarEndSafe(dateutcEnd)
                    log_info({ db, userDetails, logkey: 'tourcalendar.select_year', desc: `Tour calendar: Select calendar year [${label}]` })
                  }}>{label}</ButtonTW>
              </div>
            )
          })}
          <div>
            From
          </div>
          <div>
            <DateInput value_local0={datelocalCalendarStart} onChange={(date_local0) => {
              if (!date_local0)
                // if user type an invalid date or clears the field, we ignore the input
                return
              const newdateutc = utc0_from_local0(date_local0)
              setDateutcCalendarStart(new Date(Math.min(newdateutc.getTime(), dateutcCalendarEnd.getTime())))
              log_info({ db, userDetails, logkey: 'tourcalendar.set_start_date', desc: `Tour calendar: Set start date to ${iso_from_utc0(newdateutc)}` })
            }} />
          </div>
          <div>
            to
          </div>
          <div>
            <DateInput value_local0={datelocalCalendarEnd} onChange={(date_local0) => {
              if (!date_local0)
                // if user type an invalid date or clears the field, we ignore the input
                return
              const newdateutc = utc0_from_local0(date_local0)
              setDateutcCalendarEndSafe(new Date(Math.max(newdateutc.getTime(), dateutcCalendarStart.getTime())))
              log_info({ db, userDetails, logkey: 'tourcalendar.set_end_date', desc: `Tour calendar: Set end date to ${iso_from_utc0(newdateutc)}` })
            }} />
          </div>
          <div className='tw-ms-3'>
            <ButtonTW variant='blue_outline' onClick={(e) => {
              scrollToToday()
              log_info({ db, userDetails, logkey: 'tourcalendar.center_on_today', desc: 'Tour calendar: Center on today' })
            }}>Center on today</ButtonTW>
          </div>
          <div className='tw-ms-1'>
            <ButtonTW variant='blue_outline' onClick={(e) => {
              scrollToDate()
              log_info({ db, userDetails, logkey: 'tourcalendar.scroll_to_date', desc: 'Tour calendar: Scroll to date' })
            }} className='tw-whitespace-nowrap'>
              <i className='bi bi-chevron-double-left'></i>
              Center-H
              <i className='bi bi-chevron-double-right'></i>
            </ButtonTW>
          </div>
          <div className='tw-ms-1'>
            <ButtonTW variant='blue_outline' onClick={(e) => {
              scrollToTour()
              log_info({ db, userDetails, logkey: 'tourcalendar.scroll_to_tour', desc: 'Tour calendar: Scroll to tour' })
            }} className='tw-whitespace-nowrap'>
              <i className='bi bi-chevron-bar-contract'></i>
              Center-V
              <i className='bi bi-chevron-bar-contract'></i>
            </ButtonTW>
          </div>
          {viewAsTravelDesigner && (
            <div className='removeMinHeight ms-3'>
              <CheckboxSwitch id='chkShowAllRequests' label='Show all (including unconfirmed trips)' checked={showAllRequests} onChange={(e) => {
                setShowAllRequests(e.target.checked)
                log_info({ db, userDetails, logkey: 'tourcalendar.toggle_showall', desc: `Tour calendar: toggle ‘Show All’ to ${e.target.checked}` })
              }} />
            </div>
          )}
          {userrole_isAdmin(userDetails.roles) && !isReadOnly && (
            <div className='removeMinHeight ms-3'>
              <CheckboxSwitch id='chkEditMode' label='Edit mode' checked={enableEditing} onChange={(e) => {
                setEnableEditing(e.target.checked)
                log_info({ db, userDetails, logkey: 'tourcalendar.toggle_edit_mode', desc: `Tour calendar: toggle Edit mode to ${e.target.checked}` })
              }} />
            </div>
          )}
          {true && (
            <div className='removeMinHeight ms-3'>
              <CheckboxSwitch id='chkAutoScrollDates' label='Auto-scroll dates' checked={autoScrollDates} onChange={(e) => {
                setAutoScrollDates(e.target.checked)
                log_info({ db, userDetails, logkey: 'tourcalendar.toggle_auto_scroll_dates', desc: `Tour calendar: toggle Auto-scroll dates to ${e.target.checked}` })
              }} />
            </div>
          )}
          {viewAsCalendarAdmin && (
            <div className='removeMinHeight ms-3'>
              <CheckboxSwitch id='chkShowWorkdays' label='Show workdays calc' checked={showWorkdays} onChange={(e) => {
                setShowWorkdays(e.target.checked)
                log_info({ db, userDetails, logkey: 'tourcalendar.toggle_show_workdays', desc: `Tour calendar: toggle Show Workdays to ${e.target.checked}` })
              }} />
            </div>
          )}



        </div>

        <div className='toolBarRightAlign'>
          {!isReadOnly && (
            <ButtonTW variant={showChangeLog ? 'blue' : 'blue_outline'} onClick={() => {
              setShowChangeLog(!showChangeLog)
              log_info({ db, userDetails, logkey: 'tourcalendar.toggle_changelog', desc: `Tour calendar: toggle change log ${showChangeLog} to ${!showChangeLog}` })
            }}>Changelog</ButtonTW>
          )}
        </div>
      </div>

      <div className='tourCalendar tw-flex tw-relative'>

        <div className='columnTourList'>

          <L1_Header
            TOUR_LIST_COLS={TOUR_LIST_COLS}
            CELL_HEIGHT_EM={CELL_HEIGHT_EM}
          />

          <L2_TourList
            TOUR_LIST_COLS={TOUR_LIST_COLS}
            CELL_HEIGHT_EM={CELL_HEIGHT_EM}
            TOURCALENDAR_BOTTOM_BUFFER={TOURCALENDAR_BOTTOM_BUFFER}
            block2height={block2height}
            ref_L2_TourListGrid={ref_L2_TourListGrid}
            onScrollL2={onScrollL2}
            requests={requests}
          />

          <div style={{
            position: 'relative',
            height: '0.5em',
            backgroundColor: 'orange',
            cursor: 'row-resize',
          }}
            onMouseDown={resizerOnMouseDown}
          ></div>

          <L3_GuideList
            isReadOnly={isReadOnly}
            block3height={block3height}
            CELL_HEIGHT_EM={CELL_HEIGHT_EM * GUIDE_ROW_HEIGHT}
            GUIDECALENDAR_BOTTOM_BUFFER={GUIDECALENDAR_BOTTOM_BUFFER}
            ref_L3_GuideListGrid={ref_L3_GuideListGrid}
            onScrollL3={onScrollL3}
            tourListtotalWidth={tourListtotalWidth}
            usedGuides={usedGuides}
            userDetailsList={userDetailsList}
            tourrequestsAll={tourrequestsFromDb}
            guideCalendars={guideCalendars}
            setYearlyCalendarGuide={setYearlyCalendarGuide}
            setGuideDetailedHours={setGuideDetailedHours}
            showWorkdays={showWorkdays}
          />

        </div>


        <div className='columnCalendarGrid'>


          <R1_Header
            CELL_WIDTH_EM={CELL_WIDTH_EM}
            CELL_HEIGHT_EM={CELL_HEIGHT_EM}
            CALENDAR_RIGHT_BUFFER={CALENDAR_RIGHT_BUFFER}
            calendarGridWidth={calendarGridWidth}
            ref_R1_CalendarGridHeader={ref_R1_CalendarGridHeader}
            onScrollR1={onScrollR1}
            listMonths={listMonths}
            listDays={listDays}
            numDaysInCalendar={numDaysInCalendar}
            hoveredDayNum={hoveredDayNum}
            setHoveredDayNum={setHoveredDayNum}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />

          <R2_Tours
            ref_R2_TourCalendarGrid={ref_R2_TourCalendarGrid}
            onScrollR2={onScrollR2}
            calendarGridWidth={calendarGridWidth}
            block2height={block2height}
            CELL_WIDTH_EM={CELL_WIDTH_EM}
            CELL_HEIGHT_EM={CELL_HEIGHT_EM}
            FONT_SIZE_PX={FONT_SIZE_PX}
            CALENDAR_RIGHT_BUFFER={CALENDAR_RIGHT_BUFFER}
            TOURCALENDAR_BOTTOM_BUFFER={TOURCALENDAR_BOTTOM_BUFFER}
            currentHourUTC={currentHourUTC}
            dateutcCalendarStart={dateutcCalendarStart}
            dateutcCalendarEnd={dateutcCalendarEnd}
            listDays={listDays}
            listMonths={listMonths}
            numDaysInCalendar={numDaysInCalendar}
            requests={requests}
            shownEditWindow={shownEditWindow}
            setShownEditWindow={setShownEditWindow}
            listAllGuides={listAllGuides}
            shownEditWindowTourRequest={shownEditWindowTourRequest}
            computeY={computeY}
            hoveredDayNum={hoveredDayNum}
            setHoveredDayNum={setHoveredDayNum}
            selectedColumns={selectedColumns}
            isReadOnly={isReadOnly}
            guideCalendarCellData={guideCalendarCellData}
          />

          <div style={{
            position: 'relative',
            height: '0.5em',
            backgroundColor: 'orange',
            cursor: 'row-resize',
          }}
            onMouseDown={resizerOnMouseDown}
          ></div>

          <R3_Guides
            CELL_WIDTH_EM={CELL_WIDTH_EM}
            CELL_HEIGHT_GUIDECAL_EM={CELL_HEIGHT_GUIDECAL_EM}
            FONT_SIZE_PX={FONT_SIZE_PX}
            CALENDAR_RIGHT_BUFFER={CALENDAR_RIGHT_BUFFER}
            GUIDECALENDAR_BOTTOM_BUFFER={GUIDECALENDAR_BOTTOM_BUFFER}
            block3height={block3height}
            calendarGridWidth={calendarGridWidth}
            ref_R3_GuideCalendarGrid={ref_R3_GuideCalendarGrid}
            onScrollR3={onScrollR3}
            currentHourUTC={currentHourUTC}
            dateutcCalendarStart={dateutcCalendarStart}
            dateutcCalendarEnd={dateutcCalendarEnd}
            listDays={listDays}
            listMonths={listMonths}
            numDaysInCalendar={numDaysInCalendar}
            usedGuides={usedGuides}
            requests={requests}
            hoveredDayNum={hoveredDayNum}
            setHoveredDayNum={setHoveredDayNum}
            selectedColumns={selectedColumns}
            isReadOnly={isReadOnly}
            guideCalendarCellData={guideCalendarCellData}
            guideCalClipboard={guideCalClipboard}
            setGuideCalClipboard={setGuideCalClipboard}
            selectedCellR3={selectedCellR3}
            setSelectedCellR3={setSelectedCellR3}
          />

        </div>{/* columnCalendarGrid */}

        {showChangeLog && (
          <div className='columnChangeLog' style={{
            width: `${changeLogWidthEm}em`,
            padding: '0 1em',
            overflowY: 'scroll',
            height: `${availableHeightPx + 2 * CELL_HEIGHT_EM * FONT_SIZE_PX}px`,
          }}>
            {calendarLogs.map((calendarLog) => {


              return (
                <div key={calendarLog.id} style={{
                  backgroundColor: 'white',
                  border: '1px solid #aaa',
                  marginBottom: '0.5em',
                  borderRadius: '0.25em',
                  boxShadow: '0 0 0.5em 0 #00000020',
                }}>

                  {calendarLog.objectChanged.objectType === 'tourrequest' ? (
                    <div style={{
                      padding: '0.25em',
                      backgroundColor: '#b3e2bf',
                      fontWeight: 'bold',
                    }}>
                      {calendarLog.objectChanged.tourrequestDetails.requestCode}
                      {' '}
                      {calendarLog.objectChanged.tourrequestDetails.paxName}
                    </div>
                  ) : (
                    <div style={{
                      padding: '0.25em',
                      backgroundColor: '#b3bfe2',
                      fontWeight: 'bold',
                    }}>
                      Tour Leader: {calendarLog.objectChanged.guideObj.name}
                    </div>
                  )}
                  <div style={{
                    padding: '0.25em',
                  }}>
                    {calendarLog.message}
                  </div>
                  <div className='line-metadata tw-flex tw-justify-between' style={{
                    padding: '0.25em',
                    backgroundColor: '#eee',
                  }}>
                    <div>{calendarLog.userCreated.name}</div>
                    <div>{dateFormatJpShortWithTime(calendarLog.dateCreated)}</div>
                  </div>
                </div>
              )
            })}
          </div>
        )}

        {(shownEditWindow && shownEditWindowTourRequest) && (
          <div className='columnSideBar' style={{
            position: 'absolute',
            top: `${CELL_HEIGHT_EM * 2.5}em`,
            right: `${1 + changeLogWidthEm}em`,
            maxWidth: '30em',
            zIndex: 10, // to be above the hovered day highlight column
          }}>
            <TourSidePanel
              shownEditWindow={shownEditWindow}
              setShownEditWindow={setShownEditWindow}
              tourrequest={shownEditWindowTourRequest}
              isReadOnly={isReadOnly}
              dateutcCalendarStart={dateutcCalendarStart}
              guideCalendarCellData={guideCalendarCellData}
            />
          </div>
        )}

        <YearlyGuideCalendar
          guideCalendarCellData={guideCalendarCellData}
          dateutcCalendarStart={dateutcCalendarStart}
          dateutcCalendarEnd={dateutcCalendarEnd}
          numDaysInCalendar={numDaysInCalendar}
          iGuide={yearlyCalendarGuide}
          guideObj={yearlyCalendarGuide !== null ? usedGuides[yearlyCalendarGuide] : null}
          closePopup={() => setYearlyCalendarGuide(null)}
          yearlyCalendarYear={yearlyCalendarYear}
          setYearlyCalendarYear={setYearlyCalendarYear}
          viewAsCalendarAdmin={viewAsCalendarAdmin}
        />

        {guideDetailedHours && (
          <GuideDetailedHoursBreakdown
            guideData={guideDetailedHours}
            closePopup={() => setGuideDetailedHours(null)}
          />
        )}

      </div>{/* tourCalendar */}


    </div> // container
  )
}
