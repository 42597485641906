// import { InstantSearch, SearchBox, Hits, Highlight, RefinementList } from 'react-instantsearch';
// import { InstantSearch, RefinementList } from 'react-instantsearch';
import { SearchResponse } from '@algolia/client-search';
import algoliasearch from 'algoliasearch/lite';
import stringify from 'json-stable-stringify';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

const APP_ID = import.meta.env.VITE_ALGOLIA_APP;
const ADMIN_KEY = import.meta.env.VITE_ALGOLIA_SEARCH_KEY;

const client = algoliasearch(APP_ID, ADMIN_KEY);
const index = client.initIndex('index_tourrequests');



// function Hit({ hit }) {
//   return (
//     <div style={{ height: '18.75rem', overflow: 'scroll' }} className='my-3'>
//       {/* <img src={hit.image} alt={hit.name} /> */}
//       {/* <p>{hit.categories[0]}</p> */}
//       {/* <h1>{hit.name}</h1> */}
//       {/* <p>${hit.price}</p> */}
//       <div style={{ backgroundColor: '#fcc', height: '3.125rem' }}>
//         <Highlight attribute="country" hit={hit} />
//       </div>
//       <pre style={{ background: 'white' }}>
//         {stringify(hit, { space: 2 })}
//       </pre>
//     </div>
//   );
// }

export function RequestSearchTestPage() {


  const [searchTerms, setSearchTerms] = useState<string>('')

  const [searchResults, setSearchResults] = useState<SearchResponse>()

  useEffect(() => {

    index.search(searchTerms)
      .then((results) => {
        console.log(results)
        setSearchResults(results)
      })

  }, [searchTerms])


  return (
    <div className='container'>
      <h2>Search for a Tour Request</h2>

      <Form.Control type='text' value={searchTerms} onChange={(e) => {
        setSearchTerms(e.target.value)
      }} />

      {/* <InstantSearch searchClient={searchClient} indexName="index_tourrequests">
        <SearchBox />

        <RefinementList attribute="country" />

        <Hits hitComponent={Hit} />

      </InstantSearch> */}

      {searchResults && (
        <>
          <div className='fw-bold my-2'>
            {searchResults.hits.length} results
          </div>
          {searchResults.hits.map((hit) => {

            return (
              <div style={{ height: '12.5rem', overflow: 'scroll' }} className='my-3' key={hit.objectID}>
                {stringify(hit, { space: 2 })}
              </div>
            )
          })}
        </>
      )}

    </div>
  )
}
