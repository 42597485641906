import { createNullCache } from '@algolia/cache-common';
import { SearchResponse } from '@algolia/client-search';
import algoliasearch from 'algoliasearch/lite';
import { collection, documentId, onSnapshot, query, where } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useAppContext } from 'src/hooks/useAppContext';
import { TourRequestHitType, TourRequestType } from 'src/types/types_tourrequest';
import { convertTourRequestDates } from 'src/util/util_firestoredates';
import { log_info } from 'src/util/util_log';
import { HighlightMatch } from './TableParts/HighlightField';

const APP_ID = import.meta.env.VITE_ALGOLIA_APP;
const ADMIN_KEY = import.meta.env.VITE_ALGOLIA_SEARCH_KEY;

const client = algoliasearch(APP_ID, ADMIN_KEY, {
  // protocol: 'https:', // https://discourse.algolia.com/t/setsettings-method-is-not-working/2194
  responsesCache: createNullCache(),
});
const indexTourRequests = client.initIndex('index_tourrequests');


function examineObjForMatchingFields(obj: any, path: string) {
  // search recursively for which fields caused the tourrequest to be included in search results
  // (used for debugging)

  for (const [key, value] of Object.entries(obj)) {
    const matchLevel = (value as HighlightMatch).matchLevel
    if (matchLevel === 'none') continue
    if (!matchLevel) {
      examineObjForMatchingFields(value, path + '.' + key)
    } else {
      console.log('key', path + '.' + key, 'match level', matchLevel)
    }
  }
}


export function useSearchRequestList(
  searchTerms: string,
  setSearchResults: (results: SearchResponse<TourRequestHitType>) => void,
) {

  const { db, setDbError, userDetails } = useAppContext()

  const [currentlyTyping, setCurrentlyTyping] = useState(false)
  const [waitingForSearchResults, setWaitingForSearchResults] = useState(false)
  // const [indexTourRequests, setIndexTourRequests] = useState()

  const [localSearchResults, setLocalSearchResults] = useState<SearchResponse<TourRequestHitType>>()

  useEffect(() => {

    if (!localSearchResults?.hits?.length) {
      return
    }

    const searchResultsIds = localSearchResults.hits.map((hit) => hit.objectID)

    const q = query(collection(db, 'tourrequests'), where(documentId(), 'in', searchResultsIds))

    return onSnapshot(q,
      (snapshot) => {
        const map = new Map<string, TourRequestType>()
        for (const docu of snapshot.docs) {
          const tourrequest = { ...docu.data(), id: docu.id } as TourRequestType
          convertTourRequestDates(tourrequest)
          map.set(docu.id, tourrequest)
        }

        const newResults = {
          ...localSearchResults,
          hits: localSearchResults.hits.map((hit) => {
            const firestoreDoc = map.get(hit.objectID)
            if (!firestoreDoc) {
              console.log('problem: firestoreDoc not found for hit', hit)
              return hit
            }
            const updatedHit = {
              ...hit, // <- contains _highlightResult which we don't touch
              ...firestoreDoc, // overwrite all normal fields with values from Firestore
            }
            return updatedHit
          })
        }

        // window.setTimeout(() => setSearchResults(newResults), 3000) // make it slow
        console.log('Search results with firestore data', newResults)
        setSearchResults(newResults)

      },
      (err) => setDbError('Getting search result documents', err)
    )
  }, [db, setDbError, setSearchResults, localSearchResults])

  // init the algolia index
  // useEffect(() => {
  //   console.log('***** USE EFFECT *****')
  //   const index = client.initIndex('index_tourrequests');
  //   setIndexTourRequests(index)
  // }, [])


  const executeSearch = useCallback(() => {
    log_info({ db, userDetails, logkey: 'search_tourrequest', desc: `Search tour request: ${searchTerms}` })

    setCurrentlyTyping(false)
    setWaitingForSearchResults(true)

    const executeSearch2 = () => {
      indexTourRequests.search<TourRequestHitType>(searchTerms,
        // {
        //   disableTypoToleranceOnAttributes: [
        //     'requestCode',
        //   ]
        // }
      )
        .then((results) => {
          results.hits.forEach((hit) => {
            hit.id = hit.objectID
            convertTourRequestDates(hit)

            // determine it was included in search results
            // examineObjForMatchingFields(hit._highlightResult, '')
          })
          console.log('Search results', results)
          setSearchResults(results)
          setLocalSearchResults(results)
          setWaitingForSearchResults(false)
        })
    }

    // window.setTimeout(executeSearch2, 2000) // simulate Algolia being slow
    executeSearch2()
  }, [db, userDetails, setSearchResults, searchTerms])


  useEffect(() => {

    if (!searchTerms || !searchTerms.trim() || !indexTourRequests) {
      // empty search string would actually return *all* items in index
      setSearchResults(null)
      setLocalSearchResults(null)
      setCurrentlyTyping(false)
      setWaitingForSearchResults(false)
      return
    }

    // set a 0.5 second delay between when user finishes timing and triggering the search
    setCurrentlyTyping(true)
    setWaitingForSearchResults(false)
    const timeoutId = window.setTimeout(executeSearch, 500)
    return () => window.clearTimeout(timeoutId);

  }, [setSearchResults, executeSearch, searchTerms])


  const onRefreshButtonClick = useCallback(() => {

    if (!searchTerms || !searchTerms.trim() || !indexTourRequests) {
      // empty search string would actually return *all* items in index
      setSearchResults(null)
      setLocalSearchResults(null)
      setCurrentlyTyping(false)
      setWaitingForSearchResults(false)
      return
    }

    executeSearch()

  }, [setSearchResults, executeSearch, searchTerms])


  return {
    currentlyTyping,
    waitingForSearchResults,
    onRefreshButtonClick,
  }
}
