import { IconButton } from './IconButton';


interface AddButtonProps {
  onClick: () => void;
}

export function AddButton({ onClick }: AddButtonProps) {
  return (
    <IconButton icon='bi-plus-circle' onClick={onClick} />
  )
}
