import { useState } from 'react'
import { WorkflowWidgetAll, WorkflowWidgetFinance, WorkflowWidgetSales } from 'src/components/FormControls/WorkflowWidget/WorkflowWidget'


export function TestPage() {

  const [selected, setSelected] = useState('NEW_REQUEST')

  // new request
  // first itinerary sent
  // tuning
  // confirmed
  // invoice sent
  // deposit paid
  // fully paid

  // const statusList1 = [
  //   { status: 'NEW_REQUEST', label: 'New request' },
  //   { status: 'FIRST_ITINERARY_SENT', label: 'First itinerary sent' },
  //   { status: 'TUNING', label: 'Tuning' },
  //   { status: 'CONFIRMED', label: 'Confirmed' },
  // ]

  // const statusList2 = [
  //   { status: 'DEPOSIT_INVOICE_SENT', label: <>Deposit<br /> invoice sent</> },
  //   { status: 'DEPOSIT_PAID', label: <>Deposit<br /> received</> },
  //   { status: 'BALANCE_INVOICE_SENT', label: <>Balance/full amount<br /> invoice sent</> },
  //   { status: 'INITIAL_QUOTE_FULLY_PAID', label: 'Main trip fee received', sublabel: '(open to add-ons)' },
  //   { status: 'ACCOUNT_CLOSED', label: 'Account closed' },
  // ]

  // const statusLabels = {
  //   NOT_CONFIRMED: { label: 'Not confirmed' },
  //   CONFIRMED: { label: 'Confirmed' },
  //   DEPOSIT_PAID: { label: 'Deposit paid' },
  //   INITIAL_QUOTE_FULLY_PAID: { label: 'Initial quote fully paid', sublabel: '(open to add-ons)' },
  //   ACCOUNT_CLOSED: { label: 'Account closed' },
  // }

  return (
    <div className='container'>
      <h1>Test Page</h1>

      <h5 className='workflowWidgetTitle'>Sales workflow</h5>
      {/* <WorkflowWidget
        statusList={statusList1}
        selected={selected}
        setSelected={setSelected}
      /> */}
      <WorkflowWidgetSales
        selected={selected}
        setSelected={setSelected}
      />
      {/* <h5 className='workflowWidgetTitle'>Finance workflow</h5> */}
      <br />
      <WorkflowWidgetFinance
        selected={selected}
        setSelected={setSelected}
      />
      <br />

      <WorkflowWidgetAll
        selected={selected}
        setSelected={setSelected}
      />

    </div>
  )

}
