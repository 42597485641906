


export function SalesInfo1th() {

  return (
    <>
      <th className='salesinfo'>has si2</th>

      <th className='salesinfo'>usersInCharge</th>
      <th className='salesinfo'>paymentMethod</th>

      <th className='salesinfo'>quotedDate</th>
      <th className='salesinfo'>quotedAmount</th>

      <th className='salesinfo'>invoiceDate</th>
      <th className='salesinfo'>invoicedAmount</th>

      <th className='salesinfo'>depositDueBy</th>
      <th className='salesinfo'>depositAmount</th>
      <th className='salesinfo'>depositPaymentDone</th>

      <th className='salesinfo'>balanceDueBy</th>
      <th className='salesinfo'>balanceAmount</th>
      <th className='salesinfo'>balancePaymentDone</th>

      <th className='salesinfo'>note</th>
    </>
  )
}
