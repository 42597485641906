import { doc, DocumentSnapshot, onSnapshot, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAppContext } from 'src/hooks/useAppContext';
import { EditableFieldTourRequest } from 'src/pages/Requests/RequestsCrud/EditableFieldTourRequest';
import { AgencyType } from 'src/types/objectTypes';
import { verifyNotDeleted } from 'src/util/util_db_misc';
import { log_db_write } from 'src/util/util_log';



export function PageAgencyCrud() {

  const { db, setDbError, storage, userDetails, _lang } = useAppContext()
  const { agencyId } = useParams()

  const [editedCell, setEditedCell] = useState<string | null>(null)

  const [agency, setAgency] = useState<AgencyType>()
  useEffect(() => {

    if (!agencyId) {
      setAgency(undefined)
      setDbError('No client id')
      return
    }

    const processSnapshot = function (docu: DocumentSnapshot) {
      const agency = { ...docu.data(), id: docu.id } as AgencyType
      verifyNotDeleted(docu.exists(), agency, agencyId, setDbError, 'agency')
      // convertAgencyDates(agency)
      console.log('agency', agency)
      setAgency(agency)

      // if (agency.history?.currentStepId) {
      //   addToCache(agency.history.currentStepId, agency)
      // }
    }

    const q = doc(db, 'agencies', agencyId)
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError(`Getting agency ${agencyId}`, err))
    return unsubscribe
  }, [db, setDbError, agencyId])


  const loadingSpinner = getLoadingSpinnerOrNull([
    ['agency', agency],
  ])
  if (!agency)
    return loadingSpinner



  return (
    <div className='tw-container mx-auto px-4 py-4'>
      <h1>Edit Agency/Platform name</h1>

      <EditableFieldTourRequest
        tableid='agency'
        rowid='main'
        fieldname='name'
        validationType=''
        currentValue={agency.name}
        isClickableToEdit={true}
        editedCell={editedCell}
        setEditedCell={setEditedCell}
        callbackCommitChange={(value) => {
          const updateObj: Partial<AgencyType> = {
            name: value,
          }

          log_db_write({ db, userDetails, logkey: 'db_write.agency.rename', desc: `Agency ${agencyId} renamed from ${agency.name} to ${value}` })

          updateDoc(doc(db, 'agencies', agencyId), updateObj)

          setEditedCell(null)
        }}
      />
    </div>
  )
}
