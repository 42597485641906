import React, { useEffect, useState } from 'react';
import { SortCaretProps } from 'src/components/ColumnSorter/SortCaret';
import { useAppContext } from 'src/hooks/useAppContext';
import { IdNamePairType } from 'src/types/objectTypes';
import { ColumnFilterStringType } from 'src/types/types_columnfilters';
import { log_info } from 'src/util/util_log';
import { capitalizeFirstLetter } from 'src/util/util_misc';
import { ButtonTW } from '../Buttons/ButtonTW';
import { TreeListItem } from './TreeListItem';
import './columnfilterpopup.css';
import { UrlParamNames, useSetOneSearchParam } from './useSetOneSearchParam';





interface ColumnFilterPopupStringProps {
  urlParameterName: UrlParamNames;
  allValues: string[] | IdNamePairType[];
  appliedFilter: ColumnFilterStringType | null;
  popupIsOpen: boolean;
  closePopup: () => void;
  sortCaretProps: SortCaretProps | null;
}

export function ColumnFilterPopupString({
  urlParameterName,
  allValues,
  appliedFilter,
  popupIsOpen,
  closePopup,
  sortCaretProps,
}: ColumnFilterPopupStringProps) {

  const { db, userDetails } = useAppContext()

  const { setOneSearchParam } = useSetOneSearchParam()

  const [searchValue, setSearchValue] = useState<string>('')
  const searchTerms: string[] = searchValue ? searchValue.toLowerCase().split(' ') : []


  const [localFilterState, setLocalFilterState] = useState<ColumnFilterStringType | null>(null)

  // update this component's filter state if it changes in InvoiceTable
  useEffect(() => {
    setLocalFilterState(appliedFilter)
  }, [appliedFilter])



  // *** all hooks above

  if (!allValues)
    // still loading
    return null


  const onCheckChange = (checked: boolean, id: string) => {

    const new_filter: ColumnFilterStringType = new Set<string>(localFilterState)
    if (checked)
      new_filter.add(id)
    else
      new_filter.delete(id)

    if (new_filter.size !== 0) {
      setLocalFilterState(new_filter)
    } else {
      // nothing at all was ticked
      setLocalFilterState(null)
    }

  }


  return (
    <div className={`filterPopup filterPopup${capitalizeFirstLetter(urlParameterName)} ${popupIsOpen ? 'show' : 'hide'}`}>
      {sortCaretProps && (
        <>
          <div className='clickable' onClick={() => {
            sortCaretProps.setSortSetting([sortCaretProps.colName, 1])
            log_info({ db, userDetails, logkey: 'filterpopup.sortasc', desc: `Filter popup ${urlParameterName}: Sort ascending` })
          }}>
            <i className={`bi ${(sortCaretProps.sortCol === sortCaretProps.colName && sortCaretProps.sortDir === 1) ? 'bi-caret-up-square' : 'bi-caret-up'}`}></i>
            {' '}
            Sort ascending
          </div>
          <div className='clickable' onClick={() => {
            sortCaretProps.setSortSetting([sortCaretProps.colName, -1])
            log_info({ db, userDetails, logkey: 'filterpopup.sortdesc', desc: `Filter popup ${urlParameterName}: Sort descending` })
          }}>
            <i className={`bi ${(sortCaretProps.sortCol === sortCaretProps.colName && sortCaretProps.sortDir === -1) ? 'bi-caret-down-square' : 'bi-caret-down'}`}></i>
            {' '}
            Sort descending
          </div>
          {/* <hr className='my-2' /> */}
        </>
      )}
      <div className='filterTitle'>Text filters</div>
      <hr className='my-1' />

      <div className={`filterArea ${localFilterState && localFilterState.size > 0 ? 'filterActive' : ''}`}>
        <div>
          <input type='text' placeholder='Search' className='searchBox' value={searchValue} onChange={(e) => {
            setSearchValue(e.target.value)
          }} />
        </div>
        <div className='checkboxList'>
          {allValues.map((item) => {

            const str = typeof item === 'string' ? item : item.name
            const id = typeof item === 'string' ? item : item.id
            const desc = typeof item === 'string' ? item : `${item.id}:${item.name}`

            if (searchTerms && searchTerms.length > 0) {
              // check that ALL search terms are included
              for (const searchTerm of searchTerms) {
                if (!str.toLowerCase().includes(searchTerm))
                  return null
              }
            }

            return (
              <React.Fragment key={id}>
                <TreeListItem
                  label={str}
                  indentation={0}
                  childNodes={null}
                  ternarySelected={localFilterState && localFilterState.has(id) ? 1 : 0}
                  onCheckChange={(checked) => {
                    onCheckChange(checked, id)
                  }}
                  onLabelClick={() => {
                    log_info({ db, userDetails, logkey: 'filterpopup.directclick', desc: `Filter popup ${urlParameterName}: Direct click ${desc}` })
                    setOneSearchParam(urlParameterName, `${id}`, true)
                    closePopup()
                  }}
                />
              </React.Fragment>
            )
          })}
          {/* <TreeListItem
            label='(empty)'
            indentation={0}
            childNodes={null}
            ternarySelected={localFilterState.treeListState?.emptyValues ?? 0}
            onCheckChange={(checked) => {
              onCheckChange(checked, null)
            }}
          /> */}

        </div>
      </div>
      <div className='mt-3'>
        <ButtonTW variant='blue' style={{ width: '100%' }} onClick={() => {

          let queryparam = null

          if (localFilterState && localFilterState.size > 0) {

            const list: string[] = [...localFilterState.keys()]

            queryparam = `[${list.join(',')}]`

          }

          log_info({ db, userDetails, logkey: 'filterpopup.apply', desc: `Filter popup ${urlParameterName}: Apply ${queryparam}` })

          setOneSearchParam(urlParameterName, queryparam, true)

          closePopup()

        }}>Apply</ButtonTW>
      </div>
      <div className='mt-1'>
        <ButtonTW variant='bsDarkGray' style={{ width: '100%' }} onClick={() => {

          log_info({ db, userDetails, logkey: 'filterpopup.clear', desc: `Filter popup ${urlParameterName}: Clear` })

          setSearchValue('')

          setOneSearchParam(urlParameterName, '', true)

          closePopup()

        }}>Clear</ButtonTW>
      </div>
    </div>
  )
}
