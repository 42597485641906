import { DocumentSnapshot, doc, onSnapshot, setDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { ButtonTW } from 'src/components/Buttons/ButtonTW'
import { useAppContext } from 'src/hooks/useAppContext'



// Test page to compare latency of updating state directly, vs updating Firestore and getting state back via onSnapshot.

// Conclusion: there is not difference because Firestore uses optimistic updates, so even when going via Firestore,
// the screen updates instantly.

export function TestLatency() {

  const { db, setDbError } = useAppContext()

  const [numState, setNumState] = useState(0)

  const [numFirestore, setNumFirestore] = useState(0)
  useEffect(() => {

    const processSnapshot = function (snapshot: DocumentSnapshot) {
      setNumFirestore(snapshot.data()?.count || 1)
    }

    const q = doc(db, 'settings', '000000')
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting setting', err));

    return unsubscribe
  }, [db, setDbError])




  return (
    <div className='container'>
      <h5>Hello world</h5>

      <div>numState: {numState}</div>

      <ButtonTW onMouseDown={() => setNumState(numState + 1)}>Increment</ButtonTW>

      <div>numFirestore: {numFirestore}</div>

      <ButtonTW onMouseDown={() => {
        setDoc(doc(db, 'settings', '000000'), { count: numFirestore + 1 })
      }}>Increment</ButtonTW>

    </div>
  )
}
