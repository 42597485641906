import { Route, Routes } from 'react-router-dom';
import { useAppContext } from 'src/hooks/useAppContext';
import { userrole_canListPayees } from 'src/util/user_roles';
import { Unauthorized } from '../Unauthorized';
import { ListPayees } from './ListPayees/ListPayees';
import { PayeeCrud } from './PayeeCrud/PayeeCrud';


export function PagePayees() {

  const { userDetails } = useAppContext()

  if (!userrole_canListPayees(userDetails.roles))
    return <Unauthorized />

  return (
    <Routes>
      <Route path='/' element={<ListPayees />} />
      <Route path='/add' element={<PayeeCrud action='create' />} />
      <Route path='/edit/:payeeId' element={<PayeeCrud action='edit' />} />
    </Routes>
  )
}
