import { QueryFieldFilterConstraint, QueryOrderByConstraint } from 'firebase/firestore';
import { setDbErrorType } from 'src/hooks/useDbError';



export function verifyNotDeleted(
  exists: boolean,
  obj: { _isDeleted: boolean },
  id: string,
  setDbError: setDbErrorType,
  objectType: 'invoice' | 'tourrequest' | 'quotationsheet' | 'generalexpense' | 'expensesheet' | 'payee' | 'agency',
) {

  if (!exists || obj._isDeleted) {
    const msg = exists
      ? `Object was deleted or does not exist. type=${objectType} id=${id}`
      : `Object does not exist or was deleted. type=${objectType} id=${id}`
    setDbError(msg)
    // to prevent remainder of code of component from running, we throw an error:
    throw new Error(msg)
  }

}

export function formatConstraint(constraint: QueryFieldFilterConstraint | QueryOrderByConstraint) {
  // this function is for logging/debugging
  // type='where', _field, _op, _value
  // type='orderBy', _field, _direction

  const c = constraint as any

  if (constraint.type === 'where') {
    return `WHERE:     ${c._field?.segments?.join('.')}  ${c._op}  ${c._value}`
  } else if (constraint.type === 'orderBy') {
    return `ORDER BY:  ${c._field?.segments?.join('.')}  ${c._direction}`
  } else {
    return constraint
  }

}
