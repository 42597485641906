import { HistoryInfoType } from './types_historized.js'
import { FileStoragePathAndDownloadUrlType, InvoiceFreeeStatusType } from './types_invoices.js'
import { UserSimpleType } from './types_user.js'



export const paymentFlowEnumList = ['VIA_EMPLOYEE', 'COMPANY_CREDITCARD', 'CEO_CREDITCARD', 'DIRECT'] as const // add null?
export type PaymentFlowEnumType = typeof paymentFlowEnumList[number]

export const companyCreditCardList = ['', 'FRANCE_7748', 'ITALIA_4910', 'FIT_2956', 'GENERAL_5136', 'AMEX_1005', 'OTHER_6713'] as const
export type CompanyCreditCardEnumType = typeof companyCreditCardList[number]

export type GeneralExpenseType = {
  id: string;
  _isDeleted: boolean;
  history: HistoryInfoType;

  description: string;
  expenseDate: string; // iso
  itemList: GeneralExpenseRowType[];
  memorandum: string;
  paxName: string;
  paymentFlow: PaymentFlowEnumType;
  companyCreditCard: CompanyCreditCardEnumType;
  paymentType: string;
  requestCode: string;
  status: 'DRAFT' | 'SUBMITTED' | 'PAID' | 'DONE';
  totalAmount: number;
  userOwner: UserSimpleType;
  userReimbursed: UserSimpleType;
  usersCollaborators: Record<string, UserSimpleType>;
  receiptsAll?: FileStoragePathAndDownloadUrlType[]; // receiptsAll will serve as a record of all files ever uploaded on this invoice, even after they are deleted by the user. (receiptsDeleted cannot track files that were removed by the user pressing Undo after the upload)
  // Freee status
  freeeStatus: InvoiceFreeeStatusType;

  // metadata
  dateCreated: Date;
  userCreated: UserSimpleType;
  dateModified: Date;
  userModified: UserSimpleType;
}

export type GeneralExpenseRowType = {
  id: string;
  isTransportation: boolean;
  description?: string;
  memorandum: string;
  quantity: number;
  unitCost: number;
  totalCost: number;
  receipts: FileStoragePathAndDownloadUrlType[];
  receiptsDeleted?: FileStoragePathAndDownloadUrlType[];
  // transportation
  transportationMode?: string;
  transportationLine?: string;
  transportationFrom?: string;
  transportationTo?: string;
}

