import React from 'react'



export function convertLfToBr(currentValue: string): JSX.Element[] {

  const displayValue: JSX.Element[] = []
  const text = (currentValue ?? '').trim()
  text.split('\n').forEach((line: string, index: number) => {
    displayValue.push(
      <React.Fragment key={`text_${index}`}>
        {line}
      </React.Fragment>
    )
    displayValue.push(<br key={`br_${index}`} />)
  })
  displayValue.pop() // remove last <br />

  return displayValue
}
