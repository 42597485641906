import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { HomePage } from './HomePage'
import { useAppContext } from './hooks/useAppContext'
import { PageAdmin } from './pages/Admin/PageAdmin'
import { UserSettings } from './pages/Admin/UserSettings'
import { PageAgencyCrud } from './pages/Agencies/AgencyCrud/PageAgencyCrud'
import { PageAgencies } from './pages/Agencies/PageAgencies'
import { PageAgencyMonthlyStats } from './pages/Agencies/PageAgencyMonthlyStats'
import { CommutingExpenses } from './pages/CommutingExpenses/CommutingExpenses'
import { MonthlySummaryAllEmployees } from './pages/CommutingExpenses/MonthlySummaryAllEmployees/MonthlySummaryAllEmployees'
import { ExploreBookings } from './pages/ExploreBookings/ExploreBookings'
import { EditGeneralExpense } from './pages/GeneralExpenses/EditGeneralExpense/EditGeneralExpense'
import { GeneralExpensesList } from './pages/GeneralExpenses/GeneralExpensesList'
import { PageGuide } from './pages/Guide/PageGuide'
import { PageInvoices } from './pages/Invoices/PageInvoices'
import { PageNotFound } from './pages/PageNotFound'
import { PagePayees } from './pages/Payees/PagePayees'
import { PageQuotations } from './pages/Quotations/PageQuotations'
import { PageTourRequests } from './pages/Requests/PageTourRequests'
import { PageTourCalendar } from './pages/TourCalendar/PageTourCalendar'
import { log_page_path } from './util/util_log'


export function PageContent() {

  // App.jsx takes care of initializing db, storage, userDetails, etc.

  // AppContent takes care of routing to the right page, and also providing setDbError

  const { userDetails, db } = useAppContext()


  const location = useLocation()
  useEffect(() => {
    log_page_path({ db, userDetails, logkey: 'page_path', desc: location.pathname })
  }, [db, userDetails, location.pathname])



  const defaultContent = <HomePage />

  return (

    <Routes>
      <Route path='/invoices/*' element={<PageInvoices />} />
      <Route path='/suppliers/*' element={<PagePayees />} />
      <Route path='/clients/' element={<PageAgencies />} />
      <Route path='/clients/edit/:agencyId' element={<PageAgencyCrud />} />
      <Route path='/admin/*' element={<PageAdmin />} />
      <Route path='/guide/*' element={<PageGuide />} />
      <Route path='/requests/*' element={<PageTourRequests />} />
      <Route path='/quotations/*' element={<PageQuotations />} />
      <Route path='/userprofile' element={<UserSettings />} />
      <Route path='/commute/allemployees' element={<MonthlySummaryAllEmployees />} />
      <Route path='/commute' element={<CommutingExpenses />} />
      <Route path='/general-expenses/new' element={<EditGeneralExpense action='create' />} />
      <Route path='/general-expenses/edit/:generalExpenseId' element={<EditGeneralExpense action='edit' />} />
      <Route path='/general-expenses' element={<GeneralExpensesList />} />
      <Route path='/agency-monthly-stats' element={<PageAgencyMonthlyStats />} />
      <Route path='/tourcalendar' element={<PageTourCalendar />} />
      <Route path='/explore-bookings' element={<ExploreBookings />} />
      <Route path='/' element={defaultContent} />
      <Route path='*' element={<PageNotFound />} />
    </Routes>

  )
}
