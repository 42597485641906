import Mexp from 'math-expression-evaluator'
import { ValidationType } from 'src/components/EditableField/EditableField'
import { formatNum } from 'src/util/util_formatnum'
import { tryParseDateToIso, tryParseDateToJst0 } from './datetools'
import { isDevMode } from './util_getbuildtime'


export function validateInput(validationType: ValidationType, value: string, caretPos?: number): [boolean, any, string, number?] {
  // returns [
  //           isValid,
  //           db value for storage,
  //           formatted value for input textbox,
  //           caret position after formatting,
  //         ]

  //console.log(`validation ${validationType}`, value)


  // first, handle the case where value is already a number separately (for typescript type safety reasons)
  // value will already be of type 'number' if user has not edited the cell before pressing Enter
  if (typeof value !== 'string') {
    if (isDevMode()) throw new Error('Editablefield value is not a string')
    if (validationType === 'number' || validationType === 'percent') {
      return [true, value, formatNum(value)]
    } else {
      // this should basically never happen??
      return [false, undefined, formatNum(value)]
    }
  }

  // from hereon, value is a string

  // if invalid, we return the original value as 'formattedValue' (third item in returned array)
  const _invalid: [boolean, any, string] = [false, undefined, value]


  if (validationType === 'dateOrNull') {
    if (value === '') {
      return [true, null, '']
    }
    const date = tryParseDateToJst0(value)
    if (!date)
      return _invalid
    return [true, date, value]
  }

  if (validationType === 'date') {
    const date = tryParseDateToJst0(value)
    if (!date)
      return _invalid
    return [true, date, value]
  }

  if (validationType === 'dateiso') {
    const dateiso = tryParseDateToIso(value)
    if (!dateiso)
      return _invalid
    return [true, dateiso, value]
  }

  if (validationType === 'formula') {
    if (value.startsWith('=')) {
      const formula = value.substring(1)
      const mexp = new Mexp()
      let result
      try {
        result = mexp.eval(formula)
      } catch (error) {
        return _invalid
      }
      return [true, result, value]
    } else {
      return validateInput('number', value, caretPos)
    }
  }

  if (validationType === 'number' || validationType === 'percent') {
    if (value === '') {
      // preserve empty values without replacing them with zero
      // (Number('') produces 0)
      return [true, '', '']
    }

    const num = Number(value.replaceAll(',', ''))
    if (isNaN(num) || value.endsWith('.'))
      return _invalid

    let formatted: string
    if (caretPos !== undefined) {
      let formatted1 = ''
      // 1,23,45,67.999
      for (let i = 0; i < value.length; i++) {
        if (value[i] === ',') {
          if (i < caretPos) {
            caretPos--
          }
          continue
        }
        formatted1 += value[i]
      }
      // 1234567.999
      let indexAfterUnits = formatted1.indexOf('.')
      if (indexAfterUnits === -1)
        indexAfterUnits = formatted1.length // 7
      let formatted2 = formatted1.substring(indexAfterUnits) // .999
      for (let j = 1; ; j++) { // j = power of 1000
        const index = indexAfterUnits - 3 * j // 4, 1
        if (index <= 0) {
          formatted2 = formatted1.substring(0, 3 + index) + formatted2
          break
        }
        formatted2 = ',' + formatted1.substring(index, index + 3) + formatted2
        if (caretPos > index)
          caretPos++
      }
      // 1,234,567.999
      formatted = formatted2
    } else {
      formatted = formatNum(num)
    }

    return [true, num, formatted, caretPos]
  }

  if (validationType === 'currency') {
    value = value.toUpperCase()
    if (!value.match(/^[A-Z]{3}$/))
      return _invalid
    return [true, value, value]
  }

  if (validationType !== '') {
    const _never: never = validationType
  }

  return [true, value.trim(), value]
}
