import { useRef } from 'react';
import { useOutsideAlerter } from 'src/components/EditableField/util_useOutsideAlerter';
import { PayeeType } from 'src/types/types_payee';


interface PayeeNameInputProps {
  name: string;
  payee: PayeeType;
  setPayee: (payee: PayeeType) => void;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  getDefaultString: (payee: PayeeType) => string;
  getHtml: (payee: PayeeType) => JSX.Element;
  onInputChange: (value: string, payee: PayeeType) => void;
  extraClassName?: string;
}

export function PayeeNameInput({ name, payee, setPayee, editedCell, setEditedCell, getDefaultString, getHtml, onInputChange, extraClassName }: PayeeNameInputProps) {

  const commitChange = () => {
    setEditedCell('')
  }

  const inputRef = useRef(null);
  // _eslint-disable-next-line react-compiler/react-compiler -- https://github.com/facebook/react/issues/30745
  useOutsideAlerter(inputRef, commitChange);

  return (
    <>
      {editedCell === name ? (
        <input type='text' className={`form-control ${extraClassName}`} id={name} autoFocus
          ref={inputRef}
          value={getDefaultString(payee)}
          onChange={(e) => {
            onInputChange(e.target.value, payee)
            setPayee({ ...payee })
          }}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              commitChange()
            }
            if (e.key === 'Enter') {
              e.preventDefault() // prevent form submission
              commitChange()
            }
          }}
        />
      ) : (
        <div className={`form-control cursor-text ${extraClassName}`} onClick={(e) => {
          setEditedCell(name)
        }}>{getHtml(payee)}</div>
      )}
    </>
  )
}
