

export const firebaseConfigProd = {
  apiKey: 'AIzaSyDY65-HyqANeoKJKhokMm0ybsgfImU6Bqk',
  authDomain: 'eighty-days-invoice-manager.firebaseapp.com',
  projectId: 'eighty-days-invoice-manager',
  storageBucket: 'eighty-days-invoice-manager.appspot.com',
  messagingSenderId: '39585323553',
  appId: '1:39585323553:web:8fd0c405914a6f4c35a160',
  measurementId: 'G-Z66SYEH5EB',
};
