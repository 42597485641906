import { QuerySnapshot, collection, onSnapshot, query } from 'firebase/firestore'
import { useEffect, useMemo, useState } from 'react'
import { isTestDb } from 'src/config/config-select'
import { useAppContext } from 'src/hooks/useAppContext'
import { UserDetailsType, UserSimpleTeamType, UserSimpleTeamUidType } from 'src/types/types_user'
import { convertUserDetailsDates } from 'src/util/util_firestoredates'



export function useUserListSimple(includeOutsideUsers = false): UserSimpleTeamType[] | undefined {
  const userDetailsList = useUserDetailsList(includeOutsideUsers)
  // useMemo required to avoid infinite loops, as userListSimple is often passed as a useEffect dependency
  const userListSimple = useMemo(() => getUserListSimple(userDetailsList), [userDetailsList])
  return userListSimple
}

export function getUserListSimple(userDetailsList: UserDetailsType[] | undefined): UserSimpleTeamType[] | undefined {
  if (!userDetailsList)
    return undefined

  return userDetailsList.map((user) => {
    const userSimple: UserSimpleTeamType = {
      id: user.id,
      email: user.email,
      name: user.displayNameEn,
      teamName: user.teamName,
    }
    return userSimple
  })
}

export function useUserListSimpleUid(): UserSimpleTeamUidType[] | undefined {
  const userDetailsList = useUserDetailsList()
  // useMemo required to avoid infinite loops, as userListSimple is often passed as a useEffect dependency
  const userListSimple = useMemo(() => getUserListSimpleUid(userDetailsList), [userDetailsList])
  return userListSimple
}

export function getUserListSimpleUid(userDetailsList: UserDetailsType[] | undefined): UserSimpleTeamUidType[] | undefined {
  if (!userDetailsList)
    return undefined

  return userDetailsList.map((user) => {
    const userSimple: UserSimpleTeamUidType = {
      uid: user.id,
      email: user.email,
      name: user.displayNameEn,
      teamName: user.teamName,
    }
    return userSimple
  })
}

export function useUserDetailsList(includeOutsideUsers: boolean = false) {

  const { db, setDbError } = useAppContext()

  const [userDetailsList, setUserDetailsList] = useState<UserDetailsType[]>()

  useEffect(() => {

    const processSnapshot = (snapshot: QuerySnapshot) => {
      console.log(`Retrieved ${snapshot.docs.length} users from db`)
      const list: UserDetailsType[] = []
      snapshot.forEach((docu) => {
        const userObj = { ...docu.data(), id: docu.id } as UserDetailsType
        convertUserDetailsDates(userObj)
        if (!isTestDb() && !includeOutsideUsers) {
          if (!userObj.email.toLowerCase().endsWith('@80days.co.jp'))
            return
          if (userObj.displayNameEn.toLowerCase().endsWith('(test)'))
            return
        }
        list.push(userObj)
      })

      setUserDetailsList(list)
    }

    const q = query(collection(db, 'users'))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting user list', err));

    return unsubscribe

  }, [db, setDbError, includeOutsideUsers])

  return userDetailsList
}

export function getDisplayName(userList: UserDetailsType[], uid: string, email: string) {
  // this function shouldn't be needed as we denormalize the user name and store it where needed
  const user = userList.find((u) => u.id === uid)
  if (user)
    return user.displayNameEn
  else
    return email
}
