import { UserDetailsType, UserSimpleType, UserSimpleUidType } from 'src/types/types_user';



export function getUserSimpleUid(userDetails: UserDetailsType) {
  const userSimple: UserSimpleUidType = {
    uid: userDetails.id,
    email: userDetails.email,
    name: userDetails.displayNameEn,
  }
  return userSimple
}

export function getUserSimple(userDetails: UserDetailsType) {
  const userSimple: UserSimpleType = {
    id: userDetails.id,
    email: userDetails.email,
    name: userDetails.displayNameEn,
  }
  return userSimple
}
