import { EditableField, ValidationType } from 'src/components/EditableField/EditableField';
import { convertLfToBr } from 'src/components/EditableField/util_textformatting';



interface EditableFieldTourRequestProps {
  tableid: string;
  rowid: string;
  fieldname: string;
  validationType: ValidationType;
  currentValue: any;
  isClickableToEdit: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  callbackCommitChange: (value: any, tabKey?: -1 | 1, formula?: string) => void;
  isTextArea?: boolean;
  textareaRows?: number;
}

export function EditableFieldTourRequest({
  tableid,
  rowid,
  fieldname,
  validationType,
  currentValue,
  isClickableToEdit,
  editedCell,
  setEditedCell,
  callbackCommitChange, // save value AND end editing (=setEditedField(''))
  isTextArea,
  textareaRows,
}: EditableFieldTourRequestProps) {


  return (
    <div>
      <EditableField
        tableid={tableid}
        rowid={rowid}
        fieldname={fieldname}
        validationType={validationType}
        currentValue={currentValue}
        isClickableToEdit={isClickableToEdit}
        editedCell={editedCell}
        setEditedCell={setEditedCell}
        callbackCommitChange={callbackCommitChange}
        isTextArea={isTextArea}
        textareaRows={textareaRows}
        getDisplayValue={(value) => {

          const text = isTextArea ? convertLfToBr(value) : value

          return (
            <div className={`tw-border tw-border-solid ${isClickableToEdit ? 'tw-border-blue-300' : 'tw-border-slate-300'} tw-rounded tw-px-3 tw-py-1`}
              style={{
                minHeight: `${2 * (textareaRows || 1)}rem`,
              }}>
              {text}
            </div>
          )
        }}
      />
    </div>
  )

}
