import { FallbackProps } from 'react-error-boundary';


// Docs:
// https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
// https://www.npmjs.com/package/react-error-boundary


export function ErrorBoundaryFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div className='alert alert-danger'>
      <div>Rendering error: {error.message}</div>
    </div>
  )
}
