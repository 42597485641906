import { MoonLoader } from 'react-spinners';


interface LoadingSpinnerProps {
  list?: string[];
}

export function LoadingSpinner({ list }: LoadingSpinnerProps) {
  // console.log('LoadingSpinner', list)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', padding: '3.125rem' }}>
      <div style={{ margin: 'auto' }}>
        <MoonLoader color='#3b5a88' />
      </div>
      {list && (
        <div style={{ textAlign: 'center', marginTop: '2em', fontStyle: 'italic' }}>
          Loading{' '}
          {list.join(', ')}
          ...
        </div>
      )}
    </div>
  )
}
