import { useLocation, useSearchParams } from 'react-router-dom';
import { filterBaseUrls } from './util_filters';



export type UrlParamNames =
  // tour requests
  'requestCode' | 'tourStart' | 'tourEnd' | 'paymentDate' | 'paymentAmount' | 'paxName' | 'teamCategory' | 'customerType' | 'agencyId' | 'country' | 'designer'
  // invoices
  | 'payeeId' | 'amount' | 'deadline'
  // general expenses
  | 'expenseDate' | 'expenseAmount'

export function useSetOneSearchParam(filtersAvailable = true) {

  const [searchParams, setSearchParams] = useSearchParams()

  const location = useLocation()
  const baseUrl = location.pathname

  if (!filtersAvailable) {
    // e.g. on Request Search page
    return {
      setOneSearchParam: undefined,
      getNewUrl: undefined,
    }
  }

  if (!filterBaseUrls.includes(baseUrl)) {
    console.error(`unexpected path for filter: ${baseUrl}`)
  }

  const setOneSearchParam = (key: UrlParamNames, value: string, preserveConstraints: boolean) => {

    const newSearchParams = getNewSearchParams(searchParams, key, value, preserveConstraints)

    setSearchParams(newSearchParams)
  }

  const getNewUrl = (key: UrlParamNames, value: string, preserveConstraints: boolean) => {
    const newSearchParams = getNewSearchParams(searchParams, key, value, preserveConstraints)
    return baseUrl + '?' + newSearchParams.toString()
  }

  return { setOneSearchParam, getNewUrl }
}

export function getNewSearchParams(
  searchParams: URLSearchParams,
  key: UrlParamNames,
  value: string,
  preserveConstraints: boolean,
): URLSearchParams {

  const newSearchParams = new URLSearchParams(searchParams)

  // always preserve: fulllist ; designer
  // for now, delete all other constraints.
  // ideally, we should keep the other constraints if all constraints are equality constraints. (inequality constraints cause issues)

  if (!preserveConstraints) {
    for (const key of searchParams.keys()) {
      if (key !== 'fulllist' && key !== 'designer') {
        newSearchParams.delete(key)
      }
    }
  }

  // paymentAmount, paymentDate and designer filters cannot be applied simultaneously because Firestore only allows one array-contains filter per query
  if (key === 'paymentAmount') {
    newSearchParams.delete('paymentDate')
    newSearchParams.delete('designer')
  } else if (key === 'paymentDate') {
    newSearchParams.delete('paymentAmount')
    newSearchParams.delete('designer')
  } else if (key === 'designer') {
    newSearchParams.delete('paymentAmount')
    newSearchParams.delete('paymentDate')
  }

  // apply our filter
  if (value) {
    newSearchParams.set(key, value)
  } else {
    newSearchParams.delete(key)
  }

  return newSearchParams
}

