


interface FreeePadlockProps {
  freeeUnlocked: boolean;
  setFreeeUnlocked: (value: boolean) => void;
}

export function FreeePadlock({
  freeeUnlocked,
  setFreeeUnlocked,
}: FreeePadlockProps) {

  return (
    <i
      className={`bi ${freeeUnlocked ? 'bi-unlock' : 'bi-lock'}`}
      style={{ fontSize: '1.5em' }}
      onClick={() => {
        setFreeeUnlocked(!freeeUnlocked)
      }}></i>
  )
}
