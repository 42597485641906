import { TourRequestType } from 'src/types/types_tourrequest'
import { serverTimestampAsDate } from 'src/util/util_firestoredates'
import { PillListMutuallyExclusiveType, PillListType } from './RequestStatusOnePill'




export function getSelectedPill(tourrequest: TourRequestType): PillListMutuallyExclusiveType {
  if (tourrequest.statusOngoing) return 'ONGOING'
  if (tourrequest.statusLostSale) return 'LOST'
  if (tourrequest.statusCancelled) return 'CANCELLED'
  if (tourrequest.statusConfirmed_pretour) return 'Pre-tour'
  if (tourrequest.statusConfirmed_ontour) return 'On-tour'
  if (tourrequest.statusConfirmed_posttour) return 'Post-tour'
  if (tourrequest.statusConfirmed_archived) return 'Archived'
  throw new Error('unreachable: getSelectedPill')
}

export function saveTourStatusToDb_getUpdateObj(
  clicked: PillListType,
) {

  const updateObj: Partial<TourRequestType> = {
    statusOngoing: clicked === 'ONGOING',
    statusCancelled: clicked === 'CANCELLED',
    statusLostSale: clicked === 'LOST',
    statusConfirmed_pretour: clicked === 'CONFIRMED' || clicked === 'Pre-tour',
    statusConfirmed_ontour: clicked === 'On-tour',
    statusConfirmed_posttour: clicked === 'Post-tour',
    statusConfirmed_archived: clicked === 'Archived',
    statusSetDatetime: serverTimestampAsDate(),
  }

  updateObj.statusConfirmed_active = updateObj.statusConfirmed_pretour || updateObj.statusConfirmed_ontour || updateObj.statusConfirmed_posttour
  updateObj.statusConfirmed = updateObj.statusConfirmed_pretour || updateObj.statusConfirmed_ontour || updateObj.statusConfirmed_posttour || updateObj.statusConfirmed_archived
  updateObj.statusCancelled_or_lost = updateObj.statusCancelled || updateObj.statusLostSale

  const userAction = `Set status to ‘${clicked}’`

  return { userAction, updateObj }

}
