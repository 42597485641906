import groupBy from 'lodash/groupBy';
import React, { useState } from 'react';
import { Highlighter, Menu, MenuItem, RenderMenuProps, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Option, TypeaheadManagerChildProps } from 'react-bootstrap-typeahead/types/types';
import { getTeamList } from 'src/pages/Admin/util_userlist';
import { UserSimpleTeamType, UserSimpleTeamUidType, UserSimpleType, UserSimpleUidType } from 'src/types/types_user';
import { compare } from 'src/util/util_misc';
import './typeaheaduserlist.css';


// see
// https://stackoverflow.com/questions/73990255/multiple-datasets-grouping-with-react-bootstrap-typeahead
// https://ericgio.github.io/react-bootstrap-typeahead/#rendering -> custom menu


interface TypeaheadUserListProps {
  id: string
  userList: (UserSimpleTeamType | UserSimpleTeamUidType)[]
  multiple: boolean
  selected: (UserSimpleType | UserSimpleUidType)[]
  onChange: (array: any[]) => void
  guidesFirst?: boolean
  disabled?: boolean
  customPlaceholder?: string
  labelKey?: 'name' | 'email'
  teamList?: string[]
}

export function TypeaheadUserList({
  id,
  userList,
  multiple,
  selected,
  onChange,
  guidesFirst,
  disabled,
  customPlaceholder,
  labelKey = 'name',
  teamList: teamListParam,
}: TypeaheadUserListProps) {

  // one or more characters have been input for filtering the user list
  const [isTyping, setIsTyping] = useState(false)

  const teamList = teamListParam ?? getTeamList(guidesFirst ?? false)

  function renderMenu(
    results: Option[],
    {
      newSelectionPrefix,
      paginationText,
      renderMenuItemChildren,
      ...menuProps
    }: RenderMenuProps,
    state: TypeaheadManagerChildProps) {
    let index = 0

    // if user is not filtering by typing some text, we show ALL users
    // (including those already selected in the typeahead, which are not included in `results`)
    let userListToDisplay = results as (UserSimpleTeamType | UserSimpleTeamUidType)[]
    if (state.text === '') userListToDisplay = userList

    // Group the results by the "teamName" key in each option.
    const teams = groupBy(userListToDisplay, 'teamName');
    const items =
      //Object.keys(teams)
      //.sort()
      teamList
        .map((teamName) => {
          const teamMembers = teams[teamName] ?? [] // if ALL team members have already been added (when multiple is true), teams[teamName] will be undefined

          // if team has no members to display, we don't show the header either
          if (teamMembers.length === 0)
            return

          // sort alphabetically by display name
          teamMembers.sort((a: (UserSimpleTeamType | UserSimpleTeamUidType), b: (UserSimpleTeamType | UserSimpleTeamUidType)) => compare(a.name, b.name))
          return (
            <React.Fragment key={teamName}>
              {index !== 0 && false && <Menu.Divider />}
              {teamName !== 'CEO' && teamName !== 'TOP' && (
                <Menu.Header>{teamName}</Menu.Header>
              )}
              {teamMembers.map((user: (UserSimpleTeamType | UserSimpleTeamUidType)) => {
                const id = 'id' in user ? user.id : user.uid
                const item = (
                  <MenuItem key={id} option={user} position={index}>
                    <Highlighter search={state.text}>{user[labelKey]}</Highlighter>
                  </MenuItem>
                );

                index += 1;
                return item
              })}
            </React.Fragment>
          )
        })

    // increase width of dropdown menu, to allow menu header 'Eighty Days Tour Leaders' to be fully visible
    menuProps.style = {
      ...menuProps.style,
      width: '20em',
    }

    return <Menu {...menuProps}>{items}</Menu>
  }

  return (
    <Typeahead
      className={`typeahead-userlist typeahead-suppress-popper-warning ${isTyping ? 'isTyping' : ''}`}
      id={id}
      options={userList}

      // we always use the 'multiple' style of Typeahead, but we
      // manually prevent multiple selections in the onChange event
      // if user-specified 'multiple' is false.
      multiple={true}

      // placeholder has a high up z-index so it interferes (appears on top of) datepicker
      // if datepicker is placed just above the typeahead
      placeholder={customPlaceholder !== undefined ? customPlaceholder : multiple ? 'Select one or several users...' : 'Select a user...'}

      renderMenu={renderMenu}
      labelKey={labelKey}
      filterBy={['name', 'email']}
      selected={selected}
      onChange={(array) => {
        setIsTyping(false)

        // prevent multiple selection if `multiple` is false
        if (!multiple && array.length > 1)
          array = array.slice(-1)

        onChange(array)
      }}
      disabled={disabled}
      onInputChange={(text, e) => {
        setIsTyping(text !== '')
      }}
    />
  )
}

