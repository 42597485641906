

// https://en.wikipedia.org/wiki/Luhn_mod_N_algorithm#Algorithm_in_JavaScript

export const luhnCodePoints = '0123456789ABCDEFGHJKLMNPQRSTUVWXYZ';
// used for travel designers->' x     x  xxxxx xxxxxx     xxx    '; // 16
// exclude O and I

function numberOfValidInputCharacters() {
    return luhnCodePoints.length;
}

function codePointFromCharacter(character: string) {
    return luhnCodePoints.indexOf(character);
}

function characterFromCodePoint(codePoint: number) {
    return luhnCodePoints.charAt(codePoint);
}

export function generateCheckCharacter(input: string) {
    let factor = 2;
    let sum = 0;
    const n = numberOfValidInputCharacters();

    // Starting from the right and working leftwards is easier since
    // the initial "factor" will always be "2".
    for (let i = input.length - 1; i >= 0; i--) {
        const codePoint = codePointFromCharacter(input.charAt(i));
        let addend = factor * codePoint;

        // Alternate the "factor" that each "codePoint" is multiplied by
        factor = (factor === 2) ? 1 : 2;

        // Sum the digits of the "addend" as expressed in base "n"
        addend = (Math.floor(addend / n)) + (addend % n);
        sum += addend;
    }

    // Calculate the number that must be added to the "sum"
    // to make it divisible by "n".
    const remainder = sum % n;
    const checkCodePoint = (n - remainder) % n;
    return characterFromCodePoint(checkCodePoint);
}

export function validateCheckCharacter(input: string) {
    let factor = 1;
    let sum = 0;
    const n = numberOfValidInputCharacters();

    // Starting from the right, work leftwards
    // Now, the initial "factor" will always be "1"
    // since the last character is the check character.
    for (let i = input.length - 1; i >= 0; i--) {
        const codePoint = codePointFromCharacter(input.charAt(i));
        let addend = factor * codePoint;

        // Alternate the "factor" that each "codePoint" is multiplied by
        factor = (factor === 2) ? 1 : 2;

        // Sum the digits of the "addend" as expressed in base "n"
        addend = (Math.floor(addend / n)) + (addend % n);
        sum += addend;
    }
    const remainder = sum % n;
    return (remainder === 0);
}
