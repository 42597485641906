import { doc, updateDoc } from 'firebase/firestore'
import { Helmet } from 'react-helmet-async'
import { useAppContext } from 'src/hooks/useAppContext'
import { userrole_isAdmin } from 'src/util/user_roles'



export function RulesTesting() {

  const { db, userDetails } = useAppContext()


  if (!userrole_isAdmin(userDetails.roles))
    return ''

  if (!import.meta.env.DEV)
    return ''


  return (

    <div>
      <Helmet><title>Rules Testing</title></Helmet>
      <div>Try to change my own role to admin:</div>
      <button className='btn btn-primary' onClick={(e) => {
        updateDoc(doc(db, 'users', userDetails.id), {
          'roles.mainRole': 'admin'
        })
          .then(() => {
            alert('success')
          })
          .catch((err) => {
            alert('error: ' + err)
          })
      }}>
        Go
      </button>

    </div>

  )

}
