import { DocumentSnapshot, Firestore, doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { MaintenanceMessageType } from 'src/types/objectTypes';
import { setDbErrorType } from './useDbError';




export function useMaintenanceMessage(db: Firestore, setDbError: setDbErrorType): [MaintenanceMessageType | null] {

  const [maintenance, setMaintenance] = useState<MaintenanceMessageType | null>(null)
  useEffect(() => {

    const processSnapshot = function (snapshot: DocumentSnapshot) {
      const maintenance = snapshot.data() as MaintenanceMessageType;
      setMaintenance((maintenance && maintenance.enabled) ? maintenance : null)
    }

    const unsubscribe = onSnapshot(doc(db, 'settings', 'maintenance'), processSnapshot, (err) => setDbError('Getting settings maintenance', err));
    return unsubscribe
  }, [db, setDbError])

  return [maintenance]

}
