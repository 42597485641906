import { addDoc, collection, doc, DocumentSnapshot, getDoc, getDocs, onSnapshot, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAutosaveDocumentInList } from 'src/hooks/autosave/util_autosave';
import { useAppContext } from 'src/hooks/useAppContext';
import { addMetadataModifiedInvoices } from 'src/pages/Invoices/util_invoices';
import { InvoiceType } from 'src/types/types_invoices';
import { PayeeCategoryType, PayeeType, PayeeTypeAdding, PayeeTypeUpdating } from 'src/types/types_payee';
import { verifyNotDeleted } from 'src/util/util_db_misc';
import { log_db_read, log_db_write } from 'src/util/util_log';
import { detectHalfWidthKatakana } from 'src/util/util_misc';
import { getBlankPayee, parsePrefix } from '../payee_prefixes';
import { getFullDisplayNameKana, getShortDisplayNameEn, getShortDisplayNameJa, stripCommas } from '../util_payees';
import { PayeeCategoryEditor } from './PayeeCategoryEditor';
import { PayeeNameInput } from './PayeeNameInput';
import { getKana } from './hiragana_api';
import './payeecrud.css';


function autofillKana(payee: PayeeType, setPayee: Dispatch<SetStateAction<PayeeType>>) {
  if (payee.nameJaMain) {
    getKana(payee.nameJaMain).then((kana) => setPayee((p) => ({ ...p, nameKanaMain: kana })))
  }
}

function autofillKanaBrandName(payee: PayeeType, setPayee: Dispatch<SetStateAction<PayeeType>>) {
  if (payee.nameBrandJa) {
    getKana(payee.nameBrandJa).then((kana) => setPayee((p) => ({ ...p, nameBrandKana: kana })))
  }
}

function autofillKanaPersonName(payee: PayeeType, setPayee: Dispatch<SetStateAction<PayeeType>>) {
  if (payee.namePersonJa) {
    getKana(payee.namePersonJa).then((kana) => setPayee((p) => ({ ...p, namePersonKana: kana })))
  }
}

function getDefaultNameJaString(payee: PayeeType) {
  return `${payee.nameJaPrefix ? `${payee.nameJaPrefix} ` : ''}${payee.nameJaMain}${payee.nameJaSuffix ? ` ${payee.nameJaSuffix}` : ''}`
}


interface PayeeCrudProps {
  action: 'create' | 'edit';
}

export function PayeeCrud({ action }: PayeeCrudProps) {

  if (action !== 'create' && action !== 'edit')
    throw new Error('invalid action')


  const { db, userDetails, setDbError } = useAppContext()


  const [searchParams] = useSearchParams()
  const { payeeId } = useParams()

  const [selectedCategories, setSelectedCategories] = useState<string[]>([])

  const defaultName = searchParams.get('name')?.trim()
  const invoiceIdList = searchParams.get('invoiceIds')?.trim()
  const returnTo = searchParams.get('returnTo')?.trim()

  const navigate = useNavigate()

  const autosaveInvoice = useAutosaveDocumentInList('invoices', addMetadataModifiedInvoices)

  const [payee, setPayee] = useState<PayeeType>()
  useEffect(() => {
    if (action === 'create') {
      const payee = getBlankPayee()

      if (defaultName) {
        // rough check to detect Japanese characters
        const hasJapanese = !!defaultName.match(/[\u2E80-\u9FFF]/)
        if (hasJapanese) {
          parsePrefix(defaultName, payee)
          autofillKana(payee, setPayee)
        } else {
          // English name
          payee.nameEnMain = defaultName
        }
      }

      setPayee(payee)

    } else if (action === 'edit') {
      if (!payeeId)
        throw new Error('payeeId missing')
      getDoc(doc(db, 'payees', payeeId))
        .then((doc) => {
          if (!doc.data()) {
            setDbError(`Payee not found with id ${payeeId}`)
            return
          }

          const payee = { ...doc.data(), id: doc.id } as PayeeType
          verifyNotDeleted(doc.exists(), payee, payeeId, setDbError, 'payee')
          setPayee(payee)
          const selectedCats: string[] = []
          payee.categories.forEach((c) => {
            selectedCats.push(c)
          })
          setSelectedCategories(selectedCats)
          log_db_read({ db, userDetails, logkey: 'db_read.open_payee', desc: `Open payee [${payee.nameJaMain}] [${payee.nameEnMain}] [${payee.id}]` })
        })
        .catch((err) => setDbError(`Getting payee ${payeeId}`, err))
    }
  }, [db, action, payeeId, defaultName, setDbError, userDetails])


  const [payeeCategories, setPayeeCategories] = useState<PayeeCategoryType[]>()
  useEffect(() => {
    getDoc(doc(db, 'settings', 'payeecategories'))
      .then((catsDoc) => {
        const cats = catsDoc.data().categories as PayeeCategoryType[]
        setPayeeCategories(cats)
      })
      .catch((err) => setDbError('Getting payee categories', err))
  }, [db, setDbError])


  const invoiceIds = useMemo(() => invoiceIdList ? invoiceIdList.split(',') : [], [invoiceIdList])

  // we are forced to download the invoice object from the db, as we need the history field (not just the id) in order to update them
  const [invoicesMap, setInvoicesMap] = useState<Map<string, InvoiceType>>()
  useEffect(() => {
    setInvoicesMap(new Map())
    if (!invoiceIds) {
      return
    }

    const unsubscribeList = new Array<() => void>()
    for (const invoiceId of invoiceIds) {
      const processSnapshot = (snapshot: DocumentSnapshot) => {
        if (!snapshot.exists()) {
          setDbError(`Invoice not found with id ${invoiceId}`)
          return
        }
        const invoice = { ...snapshot.data(), id: snapshot.id } as InvoiceType
        setInvoicesMap((map) => new Map(map).set(invoiceId, invoice)) // no mutation
      }

      const q = doc(db, 'invoices', invoiceId)
      const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError(`Getting invoice ${invoiceId}`, err))
      unsubscribeList.push(unsubscribe)
    }

    return () => {
      for (const unsubscribe of unsubscribeList) {
        unsubscribe()
      }
    }
  }, [db, setDbError, invoiceIds])


  const [err, setErr] = useState<string>()

  const [editingCategories, setEditingCategories] = useState(false)

  const [editedCell, setEditedCell] = useState<string | null>(null)


  // *** all hooks above this line ***


  const loadingSpinner = getLoadingSpinnerOrNull([
    ['supplier', payee],
    ['categories', payeeCategories],
    ['invoices', invoicesMap && invoicesMap.size === invoiceIds.length],
  ])
  if (!payee || !payeeCategories)
    return loadingSpinner


  const invoices = [...invoicesMap.values()]

  const categoriesRendered: JSX.Element[] = []

  const renderCategoryGroup = ({ name, subcategories }: PayeeCategoryType) => {
    const subcatsHtml: JSX.Element[] = []
    subcategories.forEach((subitem) => {
      const code = subitem.replaceAll(' ', '_')
      const id = `category_${code}`
      subcatsHtml.push(
        <div className='ms-3' key={id}>
          <input type='checkbox' className='form-check-input' id={id} value={subitem}
            checked={selectedCategories.includes(subitem)}
            onChange={(e) => {
              if (e.target.checked)
                setSelectedCategories([...selectedCategories, subitem])
              else
                setSelectedCategories(selectedCategories.filter((cat) => cat !== subitem))
            }} />
          {' '}
          <label htmlFor={id}>{subitem}</label>
        </div>
      )
    })

    categoriesRendered.push(
      <div className='my-2' key={name} style={name === 'Extra' ? { color: 'red' } : {}}>
        <div><b>{name}</b></div>
        {subcatsHtml}
      </div>
    )
  }

  payeeCategories.forEach(renderCategoryGroup)

  // check for orphan categories
  const orphanCats = []
  for (const cat of selectedCategories) {
    if (payeeCategories.map((group) => group.subcategories).flat().includes(cat))
      continue
    orphanCats.push(cat)
  }
  if (orphanCats.length > 0) {
    renderCategoryGroup({ name: 'Extra', subcategories: orphanCats })
  }


  const title = action === 'edit' ? 'Edit Supplier' : 'Add Supplier'

  return (
    <div className='container'>
      <Helmet><title>{title} {stripCommas(payee.nameJaMain)}</title></Helmet>
      <h2 className='my-4'>{title}</h2>

      {invoices.length > 0 && <div className='tw-font-bold'>Affects <b>{invoices.length}</b> {invoices.length === 1 ? 'invoice' : 'invoices'}.</div>}

      <form onSubmit={async (e) => {
        try {
          e.preventDefault()

          setErr('Saving...')

          const categories = selectedCategories

          let payeeObj: PayeeTypeUpdating | PayeeTypeAdding = {
            nameJaPrefix: payee.nameJaPrefix.trim(),
            nameJaMain: payee.nameJaMain.trim(),
            nameJaSuffix: payee.nameJaSuffix.trim(),
            nameKanaPrefix: payee.nameKanaPrefix.trim(),
            nameKanaMain: payee.nameKanaMain.trim(),
            nameKanaSuffix: payee.nameKanaSuffix.trim(),
            nameEnMain: payee.nameEnMain.trim(),

            nameBrandJa: payee.nameBrandJa?.trim() ?? '',
            nameBrandKana: payee.nameBrandKana?.trim() ?? '',
            nameBrandEn: payee.nameBrandEn?.trim() ?? '',

            namePersonJa: payee.namePersonJa.trim(),
            namePersonKana: payee.namePersonKana.trim(),
            namePersonEn: payee.namePersonEn.trim(),

            isOverseasPayee: !!payee.isOverseasPayee,
            isFreelanceGuide: !!payee.isFreelanceGuide,

            categories,

            dateModified: serverTimestamp(),
            userModifiedUid: userDetails.id,
            userModifiedEmail: userDetails.email,
            userModifiedName: userDetails.displayNameEn,
          }

          for (const key of Object.keys(payeeObj)) {
            if (key.startsWith('name')) {
              const value = payeeObj[key as keyof typeof payeeObj] as string
              if (detectHalfWidthKatakana(value)) {
                setErr(`Half width katakana found in ${key}`)
                return
              }
              if (value.replaceAll('　', ' ').includes('  ')) {
                setErr(`Double space found in  ${key}`)
                return
              }
            }
          }

          if (action === 'create') {
            payeeObj = {
              ...payeeObj,
              _isDeleted: false,
              dateCreated: serverTimestamp(),
              userCreatorUid: userDetails.id,
              userCreatorEmail: userDetails.email,
              userCreatorName: userDetails.displayNameEn,
            }
          }

          if (!payeeObj.nameJaMain) {
            setErr('Provide a name in Japanese')
            return
          }

          if (!payeeObj.nameKanaMain) {
            setErr('Provide a Japanese reading in katakana')
            return
          }

          if (!payeeObj.nameEnMain) {
            setErr('Provide a name in English')
            return
          }

          if (categories.length === 0) {
            setErr('Select at least 1 category. You can create new categories if needed by clicking the Edit button on the left')
            return
          }

          const snapshot = await getDocs(query(collection(db, 'payees'), where('_isDeleted', '==', false)))

          const allPayees = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as PayeeType)).filter((p) => !p._isDeleted)

          const sameNameJa = allPayees.filter((p) => stripCommas(p.nameJaMain) === stripCommas(payeeObj.nameJaMain) && p.id !== payeeId)
          if (sameNameJa.length > 0) {
            console.log(sameNameJa)
            setErr(`Name (Japanese) already present in supplier list [${stripCommas(payeeObj.nameJaMain)}]. ${sameNameJa.map((p) => p.id).join()}`)
            return
          }

          const sameNameEn = allPayees.filter((p) => p.nameEnMain === payeeObj.nameEnMain && p.id !== payeeId)
          if (sameNameEn.length > 0) {
            setErr(`Name (English) already present in supplier list ${payeeObj.nameEnMain}`)
            return
          }

          if (action === 'create') {
            const docref = await addDoc(collection(db, 'payees'), payeeObj)
            //console.log(docref)
            //console.log('id', docref.id)
            // now update the invoices, if any
            if (invoices.length > 0) {
              const promises = new Array<Promise<void>>()
              for (const invoice of invoices) {

                const updateObj: Partial<InvoiceType> = {
                  payeeId: docref.id,
                  payeeNameJa: getShortDisplayNameJa(payeeObj),
                  payeeNameEn: getShortDisplayNameEn(payeeObj),
                  payeeCategories: payeeObj.categories,
                }

                const promise = autosaveInvoice(`Set supplier to newly created ‘${updateObj.payeeNameEn}’`, invoice, updateObj, 'UNDOWALL') // done outside crud page -> therefore not undoable
                promises.push(promise)
              }

              await Promise.all(promises)
            }

            log_db_write({ db, userDetails, logkey: 'db_write.payee.save_new', desc: `Saved NEW payee [${payee.nameJaMain}] [${payee.nameEnMain}] [${docref.id}]` })

            if (returnTo === 'addinvoice')
              navigate('/invoices/add')
            else if (returnTo === 'invoicelist')
              navigate('/invoices/')
            else
              navigate('/suppliers/')


          } else if (action === 'edit') {

            await updateDoc(doc(db, 'payees', payeeId), payeeObj)
            console.log('payee changes saved to db')

            log_db_write({ db, userDetails, logkey: 'db_write.payee.save_existing', desc: `Saved payee [${payee.nameJaMain}] [${payee.nameEnMain}] [${payeeId}]` })

            navigate('/suppliers/')
          }
        }
        catch (err) {
          setDbError('Saving payee', err)
        }
      }}>
        <div className='gridPayeeInput'>

          <h5>Company / Individual name</h5>

          <div></div>
          <div className='column-header' style={{ gridColumn: '2 / span 3' }}>Company / Individual name</div>

          <div>Japanese (Kanji)</div>
          <div style={{ gridColumn: '2 / span 3' }}>
            <PayeeNameInput
              name='payeeNameJa'
              payee={payee}
              setPayee={setPayee}
              editedCell={editedCell}
              setEditedCell={setEditedCell}
              getDefaultString={(payee) => getDefaultNameJaString(payee)}
              getHtml={(payee) => (
                <>
                  <span className='prefix'>{payee.nameJaPrefix}</span> {stripCommas(payee.nameJaMain) || <>&nbsp;</>} <span className='suffix'>{payee.nameJaSuffix}</span>
                </>
              )}
              onInputChange={(value, payee) => {
                parsePrefix(value.trim(), payee)
              }}
            />
          </div>

          <div>
            Reading (Katakana)
            {' '}
            <ButtonTW variant='blue_outline' onClick={(e) => {
              autofillKana(payee, setPayee)
            }}>auto</ButtonTW>
          </div>
          <div style={{ gridColumn: '2 / span 3' }}>
            <PayeeNameInput
              name='payeeNameKana'
              payee={payee}
              setPayee={setPayee}
              editedCell={editedCell}
              setEditedCell={setEditedCell}
              getDefaultString={(payee) => payee.nameKanaMain}
              getHtml={(payee) => (
                <>
                  <span className='prefix'>{payee.nameKanaPrefix}</span> {stripCommas(payee.nameKanaMain) || <>&nbsp;</>} <span className='suffix'>{payee.nameKanaSuffix}</span>
                </>
              )}
              onInputChange={(value, payee) => {
                payee.nameKanaMain = value
              }}
              extraClassName='kana'
            />
          </div>

          <div>English</div>
          <div style={{ gridColumn: '2 / span 3' }}>
            <input type='text' className='form-control' id='payeeNameEnMain'
              value={payee.nameEnMain}
              onChange={(e) => {
                setPayee({
                  ...payee,
                  nameEnMain: e.target.value,
                })
              }}
            />
          </div>

          <div>
            <label htmlFor='payeeIsOverseasPayee'>
              <i className='bi bi-globe-americas me-1'></i>
              Overseas supplier
            </label>
          </div>
          <div className='span3'>
            <Form.Check type='checkbox' id='payeeIsOverseasPayee'
              checked={!!payee.isOverseasPayee}
              onChange={(e) => {
                setPayee({
                  ...payee,
                  isOverseasPayee: e.target.checked,
                })
              }}
              label='(Non-JPY invoice, SWIFT wire transfer, company located outside Japan, etc.)'
            />
          </div>

          <div>
            <label htmlFor='payeeIsFreelanceGuide'>
              <i className='bi bi-person-badge me-1'></i>
              Freelance guide
            </label>
          </div>
          <div className='span3'>
            <Form.Check type='checkbox' id='payeeIsFreelanceGuide'
              checked={!!payee.isFreelanceGuide}
              onChange={(e) => {
                setPayee({
                  ...payee,
                  isFreelanceGuide: e.target.checked,
                })
              }}
              label='Include in list of freelance guides on tour calendar'
            />
          </div>

          <div className='explanationAboutPrefixes'>
            <p>
              Company prefixes and suffixes (株式会社, 有限会社, etc.) are detected automatically.
              (Prefix will be ignored when sorting by company name.)
            </p>
            <p>
              Katakana for company prefixes/suffixes (カブシキガイシャ, etc.) are added automatically and should not be input manually.
            </p>
            <p>
              In the <i>‘Japanese (Kanji)’</i> and <i>‘Reading (Katakana)’</i> fields, commas can be used to group furigana together.
              (Commas will not be displayed in supplier table or invoice table.)
              Example: 富士観光開発 and フジカンコウカイハツ can be changed to 富士,観光,開発 and フジ,カンコウ,カイハツ
              to properly align furigana.
            </p>
          </div>



          <div className='span4'>
            <h5>Brand name, Hotel name, etc. (if different from company name above)</h5>
            <div>If not applicable, leave blank.</div>
          </div>

          <div>Japanese (Kanji)</div>
          <div className='span3'>
            <input type='text' className='form-control' id='payeeNameBrandJa'
              value={payee.nameBrandJa}
              onChange={(e) => {
                setPayee({
                  ...payee,
                  nameBrandJa: e.target.value,
                })
              }}
            />
          </div>

          <div>
            Reading (Katakana)
            {' '}
            <ButtonTW variant='blue_outline' onClick={(e) => {
              autofillKanaBrandName(payee, setPayee)
            }}>auto</ButtonTW>
          </div>
          <div className='span3'>
            <input type='text' className='form-control kana' id='payeeNameBrandKana'
              value={payee.nameBrandKana}
              onChange={(e) => {
                setPayee({
                  ...payee,
                  nameBrandKana: e.target.value,
                })
              }}
            />
          </div>

          <div>English</div>
          <div className='span3'>
            <input type='text' className='form-control' id='payeeNameBrandEn'
              value={payee.nameBrandEn}
              onChange={(e) => {
                setPayee({
                  ...payee,
                  nameBrandEn: e.target.value,
                })
              }}
            />
          </div>



          <div className='span4'>
            <h5>Person or department within company (if applicable) for contact purposes</h5>
            <div>If not applicable, leave blank.</div>
          </div>

          <div>Japanese (Kanji)</div>
          <div className='span3'>
            <input type='text' className='form-control' id='payeeNamePersonJa'
              value={payee.namePersonJa}
              onChange={(e) => {
                setPayee({
                  ...payee,
                  namePersonJa: e.target.value,
                })
              }}
            />
          </div>

          <div>
            Reading (Katakana)
            {' '}
            <ButtonTW variant='blue_outline' onClick={(e) => {
              autofillKanaPersonName(payee, setPayee)
            }}>auto</ButtonTW>
          </div>
          <div className='span3'>
            <input type='text' className='form-control kana' id='payeeNamePersonKana'
              value={payee.namePersonKana}
              onChange={(e) => {
                setPayee({
                  ...payee,
                  namePersonKana: e.target.value,
                })
              }}
            />
          </div>

          <div>English</div>
          <div className='span3'>
            <input type='text' className='form-control' id='payeeNamePersonEn'
              value={payee.namePersonEn}
              onChange={(e) => {
                setPayee({
                  ...payee,
                  namePersonEn: e.target.value,
                })
              }}
            />
          </div>


          {invoices.length > 0 && (
            <>
              <h5>Full supplier display name</h5>

              <div>Previous</div>
              <div className='span3'><input type='text' className='form-control' disabled={true} id='payeeFullDisplayNameJa' defaultValue={defaultName} /></div>

              <div>New</div>
              <div className='span3'><input type='text' className='form-control' disabled={true} id='payeeFullDisplayNameJa' value={getShortDisplayNameJa(payee)} /></div>

              <div></div>
              <div className='span3'>Supplier name will be replaced with the above on <b>{invoices.length}</b> {invoices.length === 1 ? 'invoice' : 'invoices'}.</div>

              <div>Reading (Katakana)</div>
              <div className='span3'><input type='text' className='form-control kana' disabled={true} id='payeeFullDisplayNameKana' value={getFullDisplayNameKana(payee)} /></div>
            </>
          )}


          <h5>Categories</h5>

          <div>
            <div>Categories</div>
            <div>(generally select just one)</div>
            <div>
              {!editingCategories && (
                <ButtonTW variant='blue_outline' onClick={(e) => {
                  setEditingCategories(true)
                }}>Edit</ButtonTW>
              )}
            </div>
          </div>
          <div className='span3'>
            {editingCategories ? (
              <PayeeCategoryEditor
                payeeCategories={payeeCategories}
                setPayeeCategories={setPayeeCategories}
                finishEditing={() => setEditingCategories(false)}
              />
            ) : (
              <>
                {categoriesRendered}
                <ButtonTW variant='blue' textSize='md' type='submit'>{action === 'create' ? 'Add supplier' : 'Save changes'}</ButtonTW>
              </>
            )}
          </div>

          <div></div>
          <div className='span3'>
            {err && <div className='alert alert-danger'>{err}</div>}
          </div>


        </div>
      </form>
    </div>
  )
}
