import { Navigate, Route, Routes } from 'react-router-dom'
import { useAppContext } from 'src/hooks/useAppContext'
import { userrole_isAdmin } from 'src/util/user_roles'
import { ExpenseSheet } from '../ExpenseSheet/ExpenseSheet'
import { ExpenseAccounting } from '../Expenses/ExpenseAccounting/ExpenseAccounting'
import { ExpenseList } from '../Expenses/ExpenseList/ExpenseList'
import { ExpenseRulesPage } from '../Expenses/ExpenseList/ExpenseRulesPage'
import { Unauthorized } from '../Unauthorized'



export function PageGuide() {

  const { userDetails, perm } = useAppContext()

  if (!userrole_isAdmin(userDetails.roles) && !perm('guiding_expenses_view_own')) {
    return <Unauthorized />
  }

  return (
    <Routes>
      <Route path='/expenses/sheet/:expenseId' element={<ExpenseSheet />} />
      <Route path='/expenses/rules' element={<ExpenseRulesPage />} />
      <Route path='/expenses/' element={<ExpenseList />} />
      <Route path='/expenseaccounting' element={<ExpenseAccounting />} />
      <Route path='/' element={<Navigate to='expenses/' />} />
    </Routes>
  )
}

