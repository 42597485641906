import { ReactNode } from 'react';
import { useAppContext } from 'src/hooks/useAppContext';


interface InvoiceStatusPillProps {
  status: string;
}

export function InvoiceStatusPill({ status }: InvoiceStatusPillProps) {

  const { _lang } = useAppContext()

  const statusColor =
    status === 'EXPECTED' ? 'dark'
      : status === 'RECEIVED' ? 'primary'
        : status === 'NEEDS CHECKING' ? 'warning'
          : status === 'DONE CHECKING' ? 'primary'
            : status === 'SCHEDULED' ? 'success'
              : status === 'PAID' ? 'success'
                : status === 'TRASH' ? 'dark'
                  : 'info';

  const text = _lang(status,
    status === 'EXPECTED' ? '請求書待ち'
      : status === 'RECEIVED' ? '受取済み'
        : status === 'NEEDS CHECKING' ? '要確認'
          : status === 'DONE CHECKING' ? '確認済み'
            : status === 'SCHEDULED' ? '支払予約済み'
              : status === 'PAID' ? '支払済み'
                : status === 'TRASH' ? 'ゴミ箱'
                  : status)

  return (
    <GenericPill color={statusColor} content={text} />
  )
}


interface GeneralExpenseStatusPillProps {
  status: string;
}

export function GeneralExpenseStatusPill({ status }: GeneralExpenseStatusPillProps) {

  const { _lang } = useAppContext()

  const statusColor =
    status === 'DRAFT' ? 'dark'
      : status === 'SUBMITTED' ? 'primary'
        : status === 'PAID' ? 'success'
          : status === 'DONE' ? 'success'
            : 'info';

  const text = _lang(status,
    status === 'DRAFT' ? '下書き'
      : status === 'SUBMITTED' ? '提出済み'
        : status === 'PAID' ? '支払済み'
          : status === 'DONE' ? '処理済み'
            : status)

  return (
    <GenericPill color={statusColor} content={text} />
  )
}


interface GenericPillProps {
  color: string;
  content: ReactNode;
}

export function GenericPill({ color, content }: GenericPillProps) {
  return (
    <div className={`d-inline-flex px-2 py-0 fw-semibold text-${color}-emphasis bg-${color}-subtle border border-${color}-subtle rounded-2 text-nowrap`}>{content}</div>
  )
}
