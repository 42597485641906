import equal from 'fast-deep-equal';
import { collection, doc, getDocs, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { SortCaret } from 'src/components/ColumnSorter/SortCaret';
import { useColumnSorter } from 'src/components/ColumnSorter/useColumnSorter';
import { EditableField } from 'src/components/EditableField/EditableField';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAppContext } from 'src/hooks/useAppContext';
import { AgencySimpleType } from 'src/types/objectTypes';
import { dateFormatUserFriendly } from 'src/util/datelayouttools';
import { userrole_isAdmin } from 'src/util/user_roles';
import { log_db_write } from 'src/util/util_log';
import { compare } from 'src/util/util_misc';
import { Unauthorized } from '../Unauthorized';
import './pageagencies.css';
import { useAgencyList } from './useAgencyList';
import { validateTwoCharacterCode } from './util_agencies';


export function PageAgencies() {

  const { db, setDbError, userDetails } = useAppContext()

  const [sortCol, sortDir, setSortSetting, sortFunc] = useColumnSorter(['name', 1]) // [colName, (-1|1)] where (1)=asc (-1)=desc

  const [isEditing, setIsEditing] = useState(false)
  const [editedCell, setEditedCell] = useState<string | null>(null)

  const agencyList = useAgencyList()

  // *** all hooks above ***

  if (!userrole_isAdmin(userDetails.roles))
    return <Unauthorized />

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['agency list', agencyList],
  ])
  if (!agencyList)
    return loadingSpinner


  agencyList.sort(sortFunc)


  function updateDb(agencyId: string, field: string, dbvalue: any) {
    updateDoc(doc(db, 'agencies', agencyId), {
      [field]: dbvalue,
      dateModified: serverTimestamp(),
      userModifiedUid: userDetails.id,
      userModifiedEmail: userDetails.email,
      userModifiedName: userDetails.displayNameEn,
    })
      .then(() => {
        setEditedCell('')
        log_db_write({ db, userDetails, logkey: 'db_write.agency.update', desc: `Updated agency ${field}=${dbvalue} [${agencyId}]` })
      })
      .catch((err) => setDbError(`Editing agency ${agencyId}`, err))
  }


  return (
    <div className='container'>
      <Helmet><title>Clients</title></Helmet>

      <h2 className='my-4'>Agencies/Platforms/Clients</h2>

      <ButtonTW onClick={(e) => {
        const q = query(collection(db, 'tourrequests'), where('_isDeleted', '==', false)) //, limit(1000))
        getDocs(q)
          .then((snapshot) => {
            console.log(`Retrieved full list of requests: ${snapshot.docs.length} requests`)
            const mapAgencyReqlist = new Map()
            snapshot.docs.forEach((doc) => {
              const agency = doc.data().agencyOrPlatform
              const reqBasic = {
                id: doc.id,
                // travellerName: doc.data().travellerName,
                // requestNumber: doc.data().requestNumber,
                requestCode: doc.data().requestCode,
                dateCreated: doc.data().dateCreated.toDate(),
                userCreatedName: doc.data().userCreatedName,
              }

              const newlist = [...(mapAgencyReqlist.get(agency) ?? []), reqBasic]
              mapAgencyReqlist.set(agency, newlist)
            })

            const promises: Promise<void>[] = []

            agencyList.forEach((agency) => {
              const requestList = mapAgencyReqlist.get(agency.name) ?? []

              // sorting is important for when we check if the array has changed or not
              requestList.sort((a: AgencySimpleType, b: AgencySimpleType) => compare(a.id, b.id))

              // check if needs updating
              if (agency.requestList) {
                if (equal(agency.requestList, requestList)) {
                  console.log('No changes in agency’s requests')
                  return
                } else {
                  console.log('Changes in agency’s requests', agency.requestList, requestList)
                }
              }

              console.log(`Updating agency request list on agency [${agency.name}]`)

              const promise = updateDoc(doc(db, 'agencies', agency.id),
                {
                  requestList
                }
              )
                .then(() => {
                  console.log(`Updated request list on agency [${agency.name}]`)
                })
                .catch((err) => setDbError(`Updating request list on agency [${agency.name}]`, err))

              promises.push(promise)
            })

            if (promises.length === 0) {
              window.alert('No changes found in any agency’s requests')
            } else {
              Promise.all(promises)
                .then((results) => {
                  window.alert(`Updated ${results.length} agencies's request lists`)
                })
                .catch((err) => setDbError('At least one of the agency updates failed', err))
            }

          })
          .catch((err) => setDbError('Getting request list for agency req list updating', err))
      }}>Update each agency’s request list</ButtonTW>

      <CheckboxSwitch id='editing-mode' label='Enable editing' checked={isEditing} onChange={(e) => {
        setIsEditing(e.target.checked)
      }} />

      <div className='my-3'>Number of agencies in list: {agencyList.length}</div>

      <table className={`table ${isEditing ? 'editing' : ''}`}>
        <thead>
          <tr>
            <th><SortCaret colName='name' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Name</th>
            <th><SortCaret colName='officialName' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Official name</th>
            <th><SortCaret colName='country' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Country</th>
            <th><SortCaret colName='twoCharacterCode' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Two character code</th>
            <th><SortCaret colName='requestListLength' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Requests (⚠ Not updated automatically)</th>
            <th><SortCaret colName='dateCreated' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Created</th>
            <th><SortCaret colName='dateModified' sortCol={sortCol} sortDir={sortDir} setSortSetting={setSortSetting} /> Modified</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {agencyList.map((agency) => {

            return (
              <tr key={agency.id}>
                <td title={agency.id}>
                  <EditableField
                    tableid='agenciestable'
                    rowid={agency.id}
                    fieldname='name'
                    validationType=''
                    currentValue={agency.name}
                    isClickableToEdit={isEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(dbvalue) => {
                      updateDb(agency.id, 'name', dbvalue)
                    }}
                  />
                </td>
                <td>
                  <EditableField
                    tableid='agenciestable'
                    rowid={agency.id}
                    fieldname='officialName'
                    validationType=''
                    currentValue={agency.officialName}
                    isClickableToEdit={isEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(dbvalue) => {
                      updateDb(agency.id, 'officialName', dbvalue)
                    }}
                  />
                </td>
                <td>
                  <EditableField
                    tableid='agenciestable'
                    rowid={agency.id}
                    fieldname='country'
                    validationType=''
                    currentValue={agency.country}
                    isClickableToEdit={isEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(dbvalue) => {
                      updateDb(agency.id, 'country', dbvalue)
                    }}
                  />
                </td>
                <td>
                  <EditableField
                    tableid='agenciestable'
                    rowid={agency.id}
                    fieldname='twoCharacterCode'
                    validationType=''
                    currentValue={agency.twoCharacterCode || ''}
                    isClickableToEdit={isEditing}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(dbvalue) => {
                      if (dbvalue !== '') {
                        const { str, err } = validateTwoCharacterCode(dbvalue)
                        if (err) {
                          // failed validation
                          window.alert(err)
                          return
                        }
                        dbvalue = str

                        const dupes = agencyList.filter((agency) => agency.twoCharacterCode === dbvalue)
                        if (dupes.length > 0) {
                          alert(`Already used on\n${dupes.map((a) => a.name).join('\n')}`)
                          return
                        }
                      }
                      updateDb(agency.id, 'twoCharacterCode', dbvalue)
                    }}
                  />
                </td>
                <td>
                  {agency.requestListLength}
                </td>
                <td className='colDate'>
                  <div>{dateFormatUserFriendly(agency.dateCreated, true)}</div>
                  <div>{agency.userCreatedName}</div>
                </td>
                <td className='colDate'>
                  <div>{dateFormatUserFriendly(agency.dateModified, true)}</div>
                  <div>{agency.userModifiedName}</div>
                </td>
                <td>
                  <ButtonTW variant='blue_outline' onClick={(e) => {
                    if (window.confirm('Are you sure you want to delete this row?')) {

                      updateDoc(doc(db, 'agencies', agency.id), {
                        _isDeleted: true,
                        status: 'DELETED',
                        dateModified: serverTimestamp(),
                        userModifiedUid: userDetails.id,
                        userModifiedEmail: userDetails.email,
                        userModifiedName: userDetails.displayNameEn,
                      })
                        .then(() => setEditedCell(''))
                        .catch((err) => setDbError(`Editing agency ${agency.id}`, err))

                      log_db_write({ db, userDetails, logkey: 'db_write.agency.delete', desc: `Deleted agency ${agency.id} ${agency.name}` })
                    }
                  }}>Delete</ButtonTW>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
