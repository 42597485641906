import { useMemo } from 'react';
import { isBankHoliday, isWeekend } from 'src/util/util_holidays';
import { MonthItemType } from '../PageTourCalendar';



interface R1_HeaderProps {
  CELL_WIDTH_EM: number;
  CELL_HEIGHT_EM: number;
  CALENDAR_RIGHT_BUFFER: number;
  calendarGridWidth: string;
  ref_R1_CalendarGridHeader: React.RefObject<HTMLDivElement>;
  onScrollR1: React.UIEventHandler<HTMLDivElement>;
  listMonths: MonthItemType[];
  listDays: Date[];
  numDaysInCalendar: number;
  hoveredDayNum: number | null;
  setHoveredDayNum: (value: number | null) => void;
  selectedColumns: number[];
  setSelectedColumns: (value: number[]) => void;
}

export function R1_Header({
  CELL_WIDTH_EM,
  CELL_HEIGHT_EM,
  CALENDAR_RIGHT_BUFFER,
  calendarGridWidth,
  ref_R1_CalendarGridHeader,
  onScrollR1,
  listMonths,
  listDays,
  numDaysInCalendar,
  hoveredDayNum,
  setHoveredDayNum,
  selectedColumns,
  setSelectedColumns,
}: R1_HeaderProps) {


  const divs = useMemo(() => {

    const monthDivs = listMonths.map((month, iMonth) => {
      return (
        <div key={`month_${iMonth}`} className='calendarGridHeaderMonthCell'
          style={{
            top: '0em',
            left: `${month.monthStartDayIndex * CELL_WIDTH_EM}em`,
            width: `${(month.monthEndDayIndexExc - month.monthStartDayIndex) * CELL_WIDTH_EM}em`,
            height: `${CELL_HEIGHT_EM}em`,
          }}>
          {month.strYearMonth}
        </div>
      )
    })

    const dayDivs = listDays.map((dateutc, iDay) => {

      const isHoliday = isWeekend(dateutc) || isBankHoliday(dateutc)
      const textColor = isHoliday ? '#da0000' : 'black'
      let bgColor
      if (selectedColumns.includes(iDay)) {
        bgColor = '#dce'
      } else {
        if (isHoliday) {
          bgColor = '#fff1f1'
        } else {
          bgColor = 'white'
        }
      }

      return (
        <div key={`day_${iDay}`}
          className='calendarGridHeaderDayCell'
          style={{
            top: `${CELL_HEIGHT_EM}em`,
            left: `${iDay * CELL_WIDTH_EM}em`,
            width: `${CELL_WIDTH_EM}em`,
            height: `${CELL_HEIGHT_EM}em`,
            gridColumn: (1 + iDay),
            backgroundColor: bgColor,
            color: textColor,
            cursor: 'default',
          }}
          onMouseEnter={() => setHoveredDayNum(iDay)}
          onMouseLeave={() => setHoveredDayNum(null)}
          onClick={(event) => {
            if (event.shiftKey) {
              if (selectedColumns.length === 0) {
                setSelectedColumns([iDay])
              } else if (selectedColumns.includes(iDay)) {
                setSelectedColumns(selectedColumns.filter((col) => col !== iDay))
              } else {
                const iFrom = Math.max(...selectedColumns.filter((i) => i < iDay))
                if (iFrom >= 0) {
                  const newArray = [
                    ...[...Array(iDay - iFrom).keys()].map((i) => iFrom + 1 + i),
                    ...selectedColumns,
                  ]
                  newArray.sort((a, b) => a - b)
                  setSelectedColumns(newArray)
                } else {
                  const iTo = Math.min(...selectedColumns)
                  const newArray = [
                    ...[...Array(iTo - iDay).keys()].map((i) => iDay + i),
                    ...selectedColumns,
                  ]
                  // newArray is already sorted in ascending order
                  setSelectedColumns(newArray)
                }
              }
            } else if (event.metaKey || event.ctrlKey) {
              if (selectedColumns.includes(iDay)) {
                setSelectedColumns(selectedColumns.filter((col) => col !== iDay))
              } else {
                setSelectedColumns([...selectedColumns, iDay])
              }
            } else {
              if (selectedColumns.length === 1 && selectedColumns[0] === iDay) {
                setSelectedColumns([])
              } else {
                setSelectedColumns([iDay])
              }
            }
          }}
        >
          {dateutc.getUTCDate()}
        </div>
      )
    })

    return [...monthDivs, ...dayDivs]

  }, [CELL_HEIGHT_EM, CELL_WIDTH_EM, listDays, listMonths, selectedColumns, setHoveredDayNum, setSelectedColumns])

  return (
    <div className='R1_calendarGridHeader'
      ref={ref_R1_CalendarGridHeader
        // (el) => {
        //   ref_R1_CalendarGridHeader.current = el // as of 20240721, react compiler eslint complains 'Mutating component props or hook arguments is not allowed' (warning might be incorrect as we should be able to mutate refs)
        //   // cbScrollLeft(el)
        // }
      }
      style={{
        width: calendarGridWidth,
        height: `${CELL_HEIGHT_EM * 2}em`,
      }}
      onScroll={onScrollR1}>

      <div className='horizontal-spacer' style={{
        position: 'absolute',
        width: `${(numDaysInCalendar + CALENDAR_RIGHT_BUFFER) * CELL_WIDTH_EM}em`,
        height: '1px',
      }}></div>

      {divs}

      {hoveredDayNum !== null && (
        <div className='hoveredDayColumn' style={{
          position: 'absolute',
          top: `${CELL_HEIGHT_EM}em`,
          left: `${hoveredDayNum * CELL_WIDTH_EM}em`,
          width: `${1 * CELL_WIDTH_EM}em`,
          height: `${1 * CELL_HEIGHT_EM}em`,
          zIndex: 1,
          pointerEvents: 'none',
        }}></div>
      )}



    </div>
  )
}
