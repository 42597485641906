import { Route, Routes } from 'react-router-dom';
import { QuotationCrud } from './QuotationCrud/QuotationCrud';
import { QuotationList } from './QuotationList';



export function PageQuotations() {


  return (
    <Routes>
      {/* <Route path='/add' element={<QuotationCrud action='create' />} /> */}
      <Route path='/edit/:quotationId' element={<QuotationCrud />} />
      <Route path='/' element={<QuotationList />} />

    </Routes>
  )

}
