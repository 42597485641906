import { UserPermissionAllEnumType, UserRolesType } from '../util/user_roles.js';



export const userTeamList = [
  'CEO',
  'Administration',
  'Group & MICE',
  'FIT',
  'Operations',
  'Guide',
  'Former employees',
  'Freelance guides',
  'External accountant',
  'Other',
] as const
export type UserTeamType = typeof userTeamList[number]

export const userTeamNonEmployeeList = [
  'Former employees',
  'Freelance guides',
  'External accountant',
  'Other',
] as const




export type UserPermissionType = {
  id: string;
  email: string;
  dateModified: Date;
  _aliasUid?: string;
} & Record<UserPermissionAllEnumType, boolean>



export type LanguagePreferenceType = 'en' | 'ja'

export type UserDetailsBasicType = {
  id: string;
  email: string;
  displayNameEn: string;

  // not stored in db
  trueUid?: string;
  trueEmail?: string;
  trueName?: string;
}

export type UserDetailsType = UserDetailsBasicType & {
  displayName: string;
  teamName: UserTeamType;
  // role: UserRoleMainType;
  roles: UserRolesType;

  photoURL: string; // from Google auth api

  photoFileURL: string; // URL on Google's server used to get the file that was saved to storage
  photoFile: string; // Storage path
  photoFileDownloadURL: string; // URL to download the file from Storage
  photoFileLastFetched: Date; // DateTime

  photoLargeFileURL: string; // URL on Google's server used to get the file that was saved to storage
  photoLargeFile: string; // Storage path
  photoLargeFileDownloadURL: string; // URL to download the file from Storage
  photoLargeFileLastFetched: Date; // DateTime

  singleCharacterCode: string;
  dateCreated: Date;
  dateModified: Date;
  dateLastLoggedIn: Date; // DateTime
  preferences?: {
    defaultEmergencyPhone?: string,
    invoicesPayeesEnglish?: boolean,
  };
  languagePreference?: LanguagePreferenceType;
  freeeNumber?: number;
  freeeName?: string;
  manager?: {
    uid: string;
    email: string;
    name: string;
  };
  directreports?: Record<string, boolean>; // uid => true
  guideNumber?: number;
  guideYearStartsOn?: string;
  guidePaidTimeOff?: number;
  // calendarRole: 'editor' | null;
  // roleViewAllGuidesExpenseAccounting?: boolean;
}

export type UserSimpleType = {
  id: string;
  email: string;
  name: string;
}

export type UserSimpleUidType = {
  uid: string;
  email: string;
  name: string;
}

export type UserSimpleTeamType = {
  id: string;
  email: string;
  name: string;
  teamName: string;
}

export type UserSimpleHoursType = {
  id: string;
  email: string;
  name: string;
  hours?: number; // optional: not specified on some old entries. currently it's not possible to add guiding without specifying hours.
  am_pm_only?: 'am_only' | 'pm_only' | ''; // optional: not specified on some old entries. currently it's not possible to add guiding without specifying hours.
}

export type UserSimpleTeamUidType = {
  uid: string;
  email: string;
  name: string;
  teamName: string;
}

