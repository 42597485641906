

interface RequestStatusIconProps {
  statusConfirmed?: boolean;
  statusReceivedDeposit?: boolean;
  statusReceivedTotalTripFee?: boolean;
}

export function RequestStatusIcon({ statusConfirmed, statusReceivedDeposit, statusReceivedTotalTripFee }: RequestStatusIconProps) {
  return statusReceivedTotalTripFee ? <i className='bi bi-check-circle-fill text-large' title='Received full fee'></i>
    : statusReceivedDeposit ? <i className='bi bi-check-circle text-large' title='Received deposit'></i>
      : statusConfirmed ? <i className='bi bi-check text-large' title='Confirmed'></i>
        : ''
}

export function StatusIconExplanation() {
  return (
    <div>
      Status check marks:
      {' '}<span className='px-2'><RequestStatusIcon statusConfirmed={true} /> Confirmed</span>
      {' '}<span className='px-2'><RequestStatusIcon statusReceivedDeposit={true} /> Received deposit</span>
      {' '}<span className='px-2'><RequestStatusIcon statusReceivedTotalTripFee={true} /> Received full payment</span>
    </div>
  )
}
