import algoliasearch from 'algoliasearch';
import { collection, getDocs, query } from 'firebase/firestore';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { useAppContext } from 'src/hooks/useAppContext';

const APP_ID = import.meta.env.VITE_ALGOLIA_APP;
const ADMIN_KEY = import.meta.env.VITE_ALGOLIA_ADMIN_KEY;

const client = algoliasearch(APP_ID, ADMIN_KEY);
const index = client.initIndex('index_tourrequests');



export function AlgoliaInit() {

  const { db, setDbError } = useAppContext()

  return (
    <div className='container'>

      <hr />

      <ButtonTW onClick={(e) => {
        const q = query(collection(db, 'tourrequests'))
        getDocs(q)
          .then((snapshot) => {
            const algoliaDocs = snapshot.docs.map((doc) => ({
              ...doc.data(),
              objectID: doc.id,
            }))

            console.log(algoliaDocs)

            console.log('Saving...')

            index.saveObjects(algoliaDocs)
              .then(((result) => {
                console.log('All saved to algolia')
                console.log(result.objectIDs)
              }))

          })

      }}>Save all Requests to Algolia</ButtonTW>

      <hr />


    </div>
  )
}
