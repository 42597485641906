import { PercentCrop } from 'react-image-crop';
import { setDbErrorType } from 'src/hooks/useDbError';
import { InvoiceContentsFileType, InvoicePartialAssignmentType } from 'src/types/types_invoices';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { nano_id } from 'src/util/util_nano_id';


export const PAYEE_ID_GREENTOMATO = 'PpTGllziZbT1CDADKCNX'


export function auto_breakdown_greentomato(
  invoiceId: string,
  selectedFile: string,
  selectedFileContents: InvoiceContentsFileType,
  partialAssignmentList: InvoicePartialAssignmentType[],
  setDbError: setDbErrorType,
  autosaveNewStep: (
    userAction: string,
    updateObj: any,
    sUndoWall: 'u' | 'UNDOWALL',
  ) => Promise<void>,
) {
  if (!selectedFileContents) {
    window.alert('No file contents')
    return
  }
  if (!selectedFileContents.pages || selectedFileContents.pages.length === 0) {
    window.alert('No pages')
    return
  }
  if (selectedFileContents.conversionTool !== 'xpdf') {
    window.alert(`Please contact Laurent for XPDF conversion. invoiceId=${invoiceId}`)
    return
  }
  if (partialAssignmentList.length > 0) {
    if (!window.confirm('This invoice already has some rows in the breakdown table. Running auto-breakdown may result in duplicate rows. Continue?'))
      return
  }


  const result: { desc: string, price: number }[] = []

  const updateObj: Record<string, any> = {}

  const userSimpleAuto = {
    uid: '',
    name: 'Auto',
    email: '',
  }

  let curNum = 1
  for (let iPage = 0; iPage < selectedFileContents.pages.length; iPage++) {
    if (iPage === 0)
      // skip first page
      continue

    console.log(`iPage=${iPage}`)

    const page = selectedFileContents.pages[iPage]
    if (!page.textContent)
      continue

    // remove empty lines
    const pageLines = page.textContent.split('\n').filter((line) => line.trim() !== '')

    let curPageIndent = -1

    const tableRows = []

    for (let iLine = 0; iLine < pageLines.length; iLine++) {
      const line = pageLines[iLine].trim()
      const match_startwithdate = line.match(/^\d{2}\/\d{2} /)
      if (!match_startwithdate) {
        continue
      }

      const line1 = line

      let line2
      if (iLine + 1 >= pageLines.length) {
        // problem
        //throw new Error(`Unexpected end of page. iPage=${iPage} iLine=${iLine} line1=[${line1}]`)
        line2 = ''
      } else {
        line2 = pageLines[iLine + 1]
      }


      if (!line2.startsWith(' ')) {
        // no second line
        tableRows.push({ line1, line2: '', pdfLine: iLine })
        continue
      }

      tableRows.push({ line1, line2, pdfLine: iLine })

      if (!line2)
        continue

      const match2 = line2.match(/^( *)[^ ]/)
      if (!match2)
        throw new Error(`Unexpected line 2: [${line2}]`)

      const indent = match2[1].length
      if (indent < 10 || indent > 50) {
        console.log(`Unexpected indent: [${indent}] on line [${line2}]`)
        continue
      }

      if (line1[indent - 1] !== ' ')
        throw new Error('Line 1 and line 2 have different indents')

      if (curPageIndent !== -1) {
        if (curPageIndent !== indent) {
          throw new Error(`Indent mismatch: ${curPageIndent} vs ${indent}`)
        }
      } else {
        curPageIndent = indent
      }

    }

    for (let iLine = 0; iLine < tableRows.length; iLine++) {
      console.log(`iLine=${iLine}`)
      const line1 = tableRows[iLine].line1.trim()
      const line2 = tableRows[iLine].line2
      const pdfLine = tableRows[iLine].pdfLine

      const indent = curPageIndent

      const date_pax = line1.slice(0, indent).trim()
      const match_datepax = date_pax.match(/^(?<month>\d{2})\/(?<day>\d{2}) +(?<paxname>[^ ].*)/)
      if (!match_datepax)
        throw new Error(`Couldn't find date and pax name: [${date_pax}]`)

      const sDate = `${match_datepax.groups!.month}/${match_datepax.groups!.day}`

      // determine year
      const month = Number(match_datepax.groups!.month)
      const now = new Date()
      const curMonth = now.getMonth() + 1
      let year
      if (month <= curMonth) {
        year = now.getFullYear()
      } else {
        // e.g. Dec invoice when current month is Jan
        year = now.getFullYear() - 1
      }

      const paxName = match_datepax.groups!.paxname

      const remainder = line1.slice(indent).trim()

      const match_yoyakucode = remainder.match(/^(.*) (2[3-9]0[0-9][0-9A-F]{3}) (.*)$/)
      if (!match_yoyakucode)
        throw new Error(`Couldn't find yoyaku code: [${remainder}]`)

      const details_tantousha = match_yoyakucode[1]

      // const tantousha_min_length = 14

      // if (details_tantousha.at(-tantousha_min_length) !== ' ')
      //   throw new Error(`Couldnt split details from tantousha: [${details_tantousha}]`)

      const match_dettan = details_tantousha.match(/^(.*[^A-Za-z0-9 ])([A-Za-z0-9 ]+)$/)
      if (!match_dettan)
        throw new Error(`Couldnt split details from tantousha: [${details_tantousha}]`)

      const details = match_dettan[1].trim() //details_tantousha.slice(0, -tantousha_min_length).trim()
      const tantousha = match_dettan[2].trim() // details_tantousha.slice(-tantousha_min_length + 1).trim()

      const yoyakucode = match_yoyakucode[2]
      const reqcode_numpax_price = match_yoyakucode[3]

      const details_length = details.length
      const details_line2 = line2.slice(indent, indent + details_length).trim()

      const match_end = reqcode_numpax_price.match(/^(?<reqcode>.*) (?<numPax>[0-9]+) +¥ +(?<price1>[0-9,]+) +¥ +(?<price2>[0-9,]+)$/)
      if (!match_end) {

        // const tmp = ' Simply  Japan  15      1  ¥  7,800  ¥  7,800'
        // const match_end = tmp.match(/^(?<reqcode>.*) (?<numPax>[0-9]+) +¥ +(?<price1>[0-9,]+) +¥ (?<price2>[0-9,]+)$/)


        throw new Error(`Coulndt match end: [${reqcode_numpax_price}]`)
      }

      const price = Number(match_end.groups!.price2.replaceAll(',', ''))

      const reqCode1 = match_end.groups!.reqcode.trim()
      const reqCode2 = line2.slice(indent + details_length).trim()
      let requestCode
      if (reqCode2) {
        requestCode = `${reqCode1} ${reqCode2}`
      } else {
        requestCode = reqCode1
      }
      if (requestCode.includes(' ')) {
        // is Explore tour
        requestCode = `${requestCode} (${year})`
        requestCode = requestCode.replaceAll('    ', ' ')
        requestCode = requestCode.replaceAll('   ', ' ')
        requestCode = requestCode.replaceAll('  ', ' ')
      }


      if (iPage === 2 && iLine === 2) {
        console.log(`Line1: [${line1}]`)
        console.log(`Line2: [${line2}]`)
      }

      const crop: PercentCrop = {
        x: 3,
        width: 94,
        y: 5 + (iPage === 1 ? 2 : 0) + pdfLine * 3.45,
        height: 3.5 * (line2 ? 2 : 1),
        unit: '%',
      }


      const newPartialAssignment: InvoicePartialAssignmentType = { // here we explicitly type, so that if fields are added in the future, they will not be forgotten here
        id: nano_id(),
        amount: price,
        requestCode,
        paxName: '',
        paxNameInvoice: '',
        fileStoragePath: selectedFile,
        pageNumber: 1 + iPage,
        crop: crop,
        isAutoBreakdown: true,
        autoBreakdownData: [line1, line2],
        needsManualConfirmation: true,
        //metadata
        userCreated: userSimpleAuto,
        dateCreated: serverTimestampAsDate(),
        userModified: userSimpleAuto,
        dateModified: serverTimestampAsDate(),
      }

      const partialId = newPartialAssignment.id
      const { id: _, ...dbObj } = newPartialAssignment

      // updateObj[`partialAssignments.${partialId}`] = dbObj
      updateObj[`${partialId}`] = dbObj

      curNum++

    } // each line of text
  } // each page

  const updateObj2 = { partialAssignments: updateObj }
  autosaveNewStep('Auto-assigning breakdown: Green Tomato', updateObj2, 'UNDOWALL')
    .catch((err) => setDbError('Auto-assigning breakdown', err))

}
