import { ReactNode } from 'react';


interface CheckboxSwitchProps {
  id: string;
  label: ReactNode;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean; // disables checkbox, and changes label text color
}

export function CheckboxSwitch({ id, label, checked, onChange, className, disabled }: CheckboxSwitchProps) {

  return (
    <div className={`form-check form-switch ${className ?? ''}`}>
      <label className='form-check-label' htmlFor={id} style={disabled ? { color: 'gray' } : undefined}>
        <input className='form-check-input' type='checkbox' role='switch' id={id} checked={checked} onChange={onChange} disabled={!!disabled}
          // default border color #dee2e6 is too light and makes the switch look disabled
          style={(!disabled && !checked) ? { borderColor: '#8a8d91' } : undefined}
        />
        {label}
      </label>
    </div>
  )
}
