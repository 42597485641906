import { HistoryInfoType } from '../../types/types_historized.js'
import { UserSimpleUidType } from '../../types/types_user.js'
import { serverTimestampAsDate } from '../../util/util_firestoredates.js'
import { nano_id } from '../../util/util_nano_id.js'



export function getNewHistoryInfoObj(userSimple: UserSimpleUidType, userAction: string) {
  return getNewHistoryInfoObj0(userSimple, userAction, serverTimestampAsDate())
}

export function getNewHistoryInfoObj0(userSimple: UserSimpleUidType, userAction: string, serverTimestamp: Date) {
  const stepId = nano_id()
  const history: HistoryInfoType = {
    currentStep: 1,
    currentStepId: stepId,
    lastStep: 1,
    steps: {
      step_1: {
        stepId: stepId,
        stepAction: userAction,
        dateModified: serverTimestamp,
        userModified: userSimple,
        isUndoStep: false,
        isUndoWall: true,
      },
    },
  }

  return history
}

export function getIsUndoWall(sUndoWall: 'u' | 'UNDOWALL') {
  let isUndoWall: boolean
  if (sUndoWall === 'u')
    isUndoWall = false
  else if (sUndoWall === 'UNDOWALL')
    isUndoWall = true
  else
    throw new Error(`invalid sUndoWall [${sUndoWall}]`)

  return isUndoWall
}
