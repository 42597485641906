import { useState } from 'react';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { InputTW } from 'src/components/Buttons/InputTW';
import { useAppContext } from 'src/hooks/useAppContext';
import { GuideCalendarOfficeWorkType } from 'src/types/types_tourrequest';
import { UserSimpleType } from 'src/types/types_user';
import { addDays, iso_from_utc0 } from 'src/util/datetools';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { GuideCalendarSelectedCellType } from '../CalendarBlocks/R3_Guides';
import { setDocGuidecal } from '../util_db_calendar';
import { HoursDropdown } from './HoursDropdown';



interface GuideCalendarOfficeButtonsProps {
  userSimple: UserSimpleType;
  dateutcCalendarStart: Date;
  selectedCell: GuideCalendarSelectedCellType;
  selectedEventDates: string[]; // ['2024-06-01', '2024-06-02', ...]
  showDeletionConfirmationPopup: string | null;
  setShowDeletionConfirmationPopup: (value: string | null) => void;
  closePopup: () => void;
}

export function GuideCalendarOfficeButtons({
  userSimple,
  dateutcCalendarStart,
  selectedCell,
  selectedEventDates,
  showDeletionConfirmationPopup,
  setShowDeletionConfirmationPopup,
  closePopup,
}: GuideCalendarOfficeButtonsProps) {

  const { db, userDetails } = useAppContext()

  const [hours, setHours] = useState(8)
  const [workContent, setWorkContent] = useState('Office')

  const saveOfficeWork = () => {
    const updateObj = {
      officeWork: {} as Record<string, GuideCalendarOfficeWorkType>,
      dateModified: serverTimestampAsDate(),
      userModified: userSimple,
    }

    for (const dayIndex of selectedCell.colList) {
      const dateutc = addDays(dateutcCalendarStart, dayIndex)
      const dateiso = iso_from_utc0(dateutc)
      const officeWork: GuideCalendarOfficeWorkType = {
        _isDeleted: false,
        dateiso,
        workHours: hours,
        workContent,
        dateModified: serverTimestampAsDate(),
        userModified: userSimple,
      }
      updateObj.officeWork[dateiso] = officeWork
    }

    console.log('updating guidecalendar', selectedCell.guideObj.id)

    setDocGuidecal(db, selectedCell.guideObj, updateObj, 'Set office work', 'tourcalendar.guide_action.office_work.set', userDetails,
      `Set office work to ${hours}hrs on ${Object.keys(updateObj.officeWork).map((dateiso) => `[${dateiso}]`).join('')}`
    )


    setShowDeletionConfirmationPopup(null)
    closePopup()
  }

  const hasEvent = selectedEventDates.length > 0

  if (!hasEvent) {
    // cells selected by the user do NOT have office work in them already
    return (
      <div style={{
        // this div's purpose is to be position:relative, to be able to correctly absloute-position the deletion confirmation popup
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'stretch',
      }}>
        <ButtonTW variant='bsOrange'
          onMouseDown={(e) => {
            e.stopPropagation()
          }}
          onClick={(e) => {
            e.stopPropagation()
            setShowDeletionConfirmationPopup('create officework')
          }}>
          Set office work
        </ButtonTW>
        {showDeletionConfirmationPopup === 'create officework' && (
          <div className='guideCalendarPopup' style={{
            position: 'absolute',
            top: '0.5em',
            left: '0.75em',
            width: 'calc(100% + 1em)',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5em',
            zIndex: 1, // z-index needed to be above the 'Set calendar note' button which has position:relative and appears later in the DOM, causing to be shown on top by default.
          }}>
            <div>Office work details</div>
            <div>
              <HoursDropdown hours={hours} setHours={setHours} />
            </div>
            <div>
              <InputTW type='text'
                style={{ fontSize: 'inherit' }}
                value={workContent}
                onChange={(e) => setWorkContent(e.currentTarget.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter')
                    saveOfficeWork()
                }} />
            </div>
            <ButtonTW variant='bsOrange' onClick={(e) => {
              e.stopPropagation()
              saveOfficeWork()
            }}>Confirm</ButtonTW>
            <ButtonTW variant='darkgray_outline' onClick={() =>
              setShowDeletionConfirmationPopup(null)
            }>Cancel</ButtonTW>
          </div>
        )}
      </div>
    )

  } else {
    // cells selected by the user DO have office work in them already

    return (
      <div style={{
        // this div's purpose is to be position:relative, to be able to correctly absloute-position the deletion confirmation popup
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'stretch',
      }}>
        <ButtonTW variant='bsOrange'
          onMouseDown={(e) => {
            e.stopPropagation()
          }}
          onClick={(e) => {
            e.stopPropagation()
            setShowDeletionConfirmationPopup('delete officework')
          }}>
          Delete office work
        </ButtonTW>
        {showDeletionConfirmationPopup === 'delete officework' && (
          <div className='guideCalendarPopup' style={{
            position: 'absolute',
            top: '0.5em',
            left: '0.75em',
            width: 'calc(100% + 1em)',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5em',
            zIndex: 1, // z-index needed to be above the 'Set calendar note' button which has position:relative and appears later in the DOM, causing to be shown on top by default.
          }}>
            <div>Delete selected office work?</div>
            <ButtonTW variant='bsOrange' onClick={() => {
              const datesToDelete = selectedEventDates

              if (datesToDelete.length > 0) {

                const updateObj = {
                  officeWork: {} as Record<string, Omit<GuideCalendarOfficeWorkType, 'dateiso' | 'workHours' | 'workContent'>>,
                  dateModified: serverTimestampAsDate(),
                  userModified: userSimple,
                }

                for (const dateiso of datesToDelete) {
                  const officeWork: Omit<GuideCalendarOfficeWorkType, 'dateiso' | 'workHours' | 'workContent'> = {
                    _isDeleted: true,
                    dateModified: serverTimestampAsDate(),
                    userModified: userSimple,
                  }
                  updateObj.officeWork[dateiso] = officeWork
                }

                setDocGuidecal(db, selectedCell.guideObj, updateObj, 'Cancel office work', 'tourcalendar.guide_action.office_work.cancel', userDetails,
                  `Cancel office work on ${datesToDelete.map((dateiso) => `[${dateiso}]`).join('')}`
                )
              }
              setShowDeletionConfirmationPopup(null)
              closePopup()
            }}>Confirm</ButtonTW>
            <ButtonTW variant='darkgray_outline' onClick={() =>
              setShowDeletionConfirmationPopup(null)
            }>Cancel</ButtonTW>
          </div>
        )}
      </div>
    )
  }

}
