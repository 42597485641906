

interface TeamNameProps {
  name: string;
}

export function TeamName({ name }: TeamNameProps) {
  if (name.toLowerCase() === 'disney tickets') {
    // 484 x 414
    const H = 28
    const W = (H * 484 / 414)
    return <img src='/mickey.png' height={H} width={W} alt={name} title={name} />
  } else if (name.toLowerCase() === 'taiko-lab') {
    // 685 x 465
    const H = 28
    const W = (H * 685 / 465)
    return <img src='/taiko.png' height={H} width={W} alt={name} title={name} />
  } else {
    return name
  }
}
