import { AdvancePaymentType, ExpensePaymentType, ExpenseSheetType } from 'src/types/types_expensesheet';
import { dateisoFormatJpShort } from 'src/util/dateformattools';
import { formatNum } from 'src/util/util_formatnum';
import { LinkToExpenseSheet } from './LinkToExpenseSheet';



interface UnpaidAdvancePaymentsTableProps {
  expenseSheetList: ExpenseSheetType[];
  donePayments: Map<string, ExpensePaymentType>;
  shownPopup: string;
  setShownPopup: (popup: string) => void;
}

export function UnpaidAdvancePaymentsTable({
  expenseSheetList,
  donePayments,
  shownPopup,
  setShownPopup,
}: UnpaidAdvancePaymentsTableProps) {

  const unpaidAdvancePayments: { sheet: ExpenseSheetType, ap: AdvancePaymentType }[] = []
  expenseSheetList.forEach((sheet) => {
    sheet.advancePayments.forEach((ap) => {
      if (ap.isDeleted)
        return
      const advancePayment = donePayments.get(`advance: ${sheet.id} ${ap.id}`)
      if (!advancePayment) {
        unpaidAdvancePayments.push({ sheet, ap })
      }
    })
  })


  return (
    <div className='mt-4' style={{
      border: '1px solid #ccc',
      padding: '1em',
      borderRadius: '0.5em',
      backgroundColor: unpaidAdvancePayments.length > 0 ? '#ffdc9c' : 'lightgreen',
    }}>
      <h5>Unpaid advance payments</h5>
      <table className='table mt-3'>
        <thead>
          <tr>
            <th>Guide</th>
            <th>Expense sheet</th>
            <th>Amount</th>
            <th>Desired payment date</th>
          </tr>
        </thead>
        <tbody>
          {unpaidAdvancePayments.length > 0 ? (
            unpaidAdvancePayments.map(({ sheet, ap }) => {
              return (
                <tr key={ap.id}>
                  <td>{sheet.userGuideName}</td>
                  <td>
                    <LinkToExpenseSheet
                      sheet={sheet}
                      shownPopup={shownPopup}
                      setShownPopup={setShownPopup}
                    />
                  </td>
                  <td>{formatNum(ap.amount)}</td>
                  <td>{dateisoFormatJpShort(ap.dateiso)}</td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={4}>
                <i>None</i>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
